import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

export const refineTutorial = [
  // GOTO previous page.
  {
    selector: '[class="btn  menuBtnItem Select "]',
    action: (node) => node.click(),
    content: <div />,
  },
  // Title
  {
    position: "center",
    content: (
      <div>
        <h3>Refine MarketData</h3>
        This page provides an overview of all currently selected market data.
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Select from dropdown.
  {
    selector: '[class="Select-control"]',
    position: "bottom",
    content: (
      <div>
        <h3>Refine MarketData</h3>
        <div>
          Filter your selected market data by type using this dropdown.This allows
          for greater specificity when large amounts of market data are selected
        </div>
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Check All Permissions
  {
    selector: '[class="btn checkAllPermissions "]',
    position: "center",
    content: (
      <div>
        <h3>Check MarketData Permission</h3>
        <div>
          Request all the selected market data permissons, by doing this, it allows
          you so refine your basket to ensure you have the correct permissions,.
          if you want to classify or enrich the meta.
        </div>
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Check Remove read only
  {
    selector: '[id="RemoveReadOnly"]',
    position: "center",
    content: (
      <div>
        <h3>Remove Read Only</h3>
        <div>
          Quick action function that removes all the selcted market data with read
          permisions only.
        </div>
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Main table.
  {
    selector: '[class="react-bs-container-body refineCurvesTableBody"]',
    position: "center",
    content: (
      <div>
        <h3>Refine MarketData Table</h3>
        <div>
          The Refine market data Table shows all selected market data. These can be removed
          by clicking the “X” button on any given market data.
        </div>
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Remove all market data
  {
    selector:
      '[class="btn close close-default remove-box-shadow btn-circle removeSelectedCurve "]',
    position: "bottom",
    content: (
      <div>
        <h3>Remove All Selected MarketData</h3>
        <div>
          If you want to remove all selected market data this button will empty your
          basket, redirecting you back to the MarketData Page.
        </div>
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Show button
  {
    selector: '[class="btn active menuBtnItem Classify "]',
    position: "right",
    content: (
      <div>
        <h3>Go to Classification Page</h3>
        To continue this tutorial with information on how to Classify MarketData
        through Artesian.
        <br /> Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    style: toolTipStyleLeftMargin,
  },
  // GOTO Classify Page
  {
    selector: '[class="btn  menuBtnItem Classify "]',
    action: (node) => {
      node.click();
    },
    content: <div />,
  },
];

export default refineTutorial;
