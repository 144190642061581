import React from "react";
import { Tooltip } from "react-tippy";
import { compose } from "ramda";
import { withState, withHandlers } from "recompose";
import { Button } from "../../components";

const Sub = ({
  closeGroupAdmin,
  activeGroupAdmin,
  closeUserAdmin,
  activeUserAdmin,
}) => (
  <div className="noPadding d-block">
    <div className="d-flex subMenuStyle">
      <span
        id="MenuGroupAdmin"
        className={`col ${activeGroupAdmin ? "active" : ""} subMenuBtn`}
        onClick={closeGroupAdmin}
        aria-hidden="true"
      >
        <i
          className={`fa fa-users-cog ${
            activeGroupAdmin ? "active" : "inactive"
          }`}
        />
        &nbsp;Group Admin
      </span>
    </div>
    <div className="d-flex subMenuStyle">
      <span
        id="MenuUserAdmin"
        className={`col ${activeUserAdmin ? "active" : ""} subMenuBtn`}
        onClick={closeUserAdmin}
        aria-hidden="true"
      >
        <i
          className={`fa fa-user-cog ${
            activeUserAdmin ? "active" : "inactive"
          }`}
        />
        &nbsp;User Management
      </span>
    </div>
  </div>
);

const EnabledTooltip = (props) => (
  <Tooltip
    arrow
    touchHold
    position="right"
    interactive
    open={props.state}
    onRequestClose={props.close}
    className="EnrichTooltip"
    hideOnClick
    trigger="click focus"
    theme="arkDefaultTooltip adminMenu"
    html={
      <Sub
        openGroupAdmin={props.btnFnGroupAdmin}
        openGroupUser={props.btnFnGroupUser}
        navColour={props.navColour}
        activeGroupAdmin={props.activeGroupAdmin}
        closeGroupAdmin={props.closeGroupAdmin}
        activeUserAdmin={props.activeUserAdmin}
        closeUserAdmin={props.closeUserAdmin}
      />
    }
    duration={props.duration || 375}
  >
    <Button
      onClick={props.toggleTooltip}
      className={`${props.btnClass} ${props.itemNameId}`}
      btnText={props.btnText}
    />
  </Tooltip>
);
const MenuItem = (props) => {
  if (props.globalAdmin) {
    return (
      <div className="menuIcons" id={props.itemNameId}>
        <EnabledTooltip {...props} />
      </div>
    );
  }
  return props.permissionAccess ? null : (
    <div className="menuIcons" id={props.itemNameId}>
      <EnabledTooltip {...props} />
    </div>
  );
};
const enhancer = compose(
  withState("state", "updateState", false),
  withHandlers({
    toggleTooltip: (props) => () => props.updateState(!props.state),
    close: (props) => () => props.updateState(false),
    closeGroupAdmin: (props) => () => {
      props.btnFnGroupAdmin();
      props.updateState(false);
    },
    closeUserAdmin: (props) => () => {
      props.btnFnUserAdmin();
      props.updateState(false);
    },
  })
);

export default enhancer(MenuItem);
