import React from "react";
import { compose, equals, isNil } from "ramda";
import { connect } from "react-redux";
import { lifecycle, withHandlers, withProps, withState } from "recompose";
import moment from "moment";
import { Tooltip } from "react-tippy";
import {
  requestUsersApiKeysAction,
  deleteApiKeyAction,
} from "../../../redux/modules/settings";
import { Button, RenderSizePerPageDropDown } from "../../../components";
import { APIKeysSpinner, APIKeysList } from "../../../settings/components";

const checkIfExpired = (ExpiresAt) => {
  if (equals(ExpiresAt, null)) {
    return "Active";
  }
  return moment().diff(moment(ExpiresAt), "hours") < 0 ? "Active" : "Expired";
};

const APIKeysContainers = (props) => {
  switch (props.requestState) {
    case "FETCHING":
      return <APIKeysSpinner />;
    case "RECIEVED":
      return (
        <APIKeysList
          apiKeys={props.allUserApiKeys}
          expiresAt={props.expiresAt}
          expiresAtFormat={props.expiresAtFormat}
          infoFormat={props.infoFormat}
          dataFormat={props.dataFormat}
          tableSettings={props.tableSettings}
        />
      );
    default:
      return <div />;
  }
};

const mapStateToProps = (state) => ({
  ...state.Settings,
});

const enhancer = compose(
  connect(mapStateToProps, {
    requestUsersApiKeysAction,
    deleteApiKeyAction,
  }),
  lifecycle({
    componentWillMount() {
      this.props.requestUsersApiKeysAction();
    },
  }),
  withState("pageSize", "updatePageSize", 50),
  withHandlers({
    setPageSize: (props) => (size) => props.updatePageSize(size),
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(props.pageSize, props),
  }),
  withProps((props) => ({
    tableSettings: {
      sizePerPage: props.pageSize,
      sizePerPageDropDown: props.renderDropDown,
    },
    infoFormat: (cell) => (
      <Tooltip
        position="bottom"
        html={<span className="tooltipOverflow">{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
    expiresAtFormat: (cell) =>
      isNil(cell) ? "Never" : moment(cell).format("YYYY-MM-DD"),
    expiresAt: (cell) => checkIfExpired(cell),
    dataFormat: (cell, row) => (
      <Tooltip
        arrow
        position="left"
        html={<span>Delete Access Token Permanently</span>}
        theme="arkDefaultTooltip"
      >
        <Button
          btnText={<i className="fas fa-trash" />}
          onClick={() => props.deleteApiKeyAction(row.Id)}
          className="apiKeyBtn"
        />
      </Tooltip>
    ),
  }))
);

export default enhancer(APIKeysContainers);
