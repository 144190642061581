import { prop, equals, map, compose, last, filter } from "ramda";

export const getTrId = (arr, val) =>
  equals(val, "None Selected")
    ? null
    : compose(
        last,
        map(prop("ID")),
        filter((x) => x.Name === val)
      )(arr);

export const getTrName = (arr, id) =>
  arr.filter((x) => x.ID === id).map(prop("Name"));

export const filterTable = (data, filterVal) => {
  const keys = ["Type"];
  const filterArr = [];
  filterVal.map((val) => filterArr.push(val.value));
  return data.filter((curve) =>
    keys.every((val) => filterArr.includes(curve[val]))
  );
};

const ranges = [
  "\ud83c[\udf00-\udfff]", // U+1F300 to U+1F3FF
  "\ud83d[\udc00-\ude4f]", // U+1F400 to U+1F64F
  "\ud83d[\ude80-\udeff]", // U+1F680 to U+1F6FF
];

export const removeInvalidChars = (str) =>
  str.replace(new RegExp(ranges.join("|"), "g"), "");

export default getTrId;
