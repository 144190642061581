import { combineReducers } from "redux";
import { always } from "ramda";

const coreTags = [
  "marketdataid",
  "etag",
  "providername",
  "marketdataname",
  "originalgranularity",
  "type",
  "originaltimezone",
  "aggregationrule",
  "providerdescription",
  "lastupdated",
  "created",
];

export default combineReducers({
  coreTags: always(coreTags),
});
