import React from "react";
import { connect } from "react-redux";
import { compose, isEmpty } from "ramda";
import { CurvePreviewContainer } from "./curvePreview";
import { ClearError } from "../redux/modules/errorHandler";
import { ErrorModal, ModalAlert, NavigateModal } from "../components";
import { closeNavigationModalAction } from "../redux/modules/menu";
import {
  closeCurveWarningModalAction,
  confirmCurveWarningModalAction,
  closePermissionCheckModalAction,
  confirmPermissionCheckModalAction,
} from "../redux/modules/curves";
import {
  confirmSendSelectedCurveEnrichWarningModalAction,
  closeSendSelectedCurveEnrichWarningModalAction,
} from "../redux/modules/curveEnrich";
import {
  confirmSettingsWarningModalAction,
  closeSettingsWarningModalAction,
} from "../redux/modules/settings";
import {
  confirmDeleteRowAction,
  closeDeleteModalAction,
} from "../redux/modules/enrichTimeTransform";
import {
  updateMASFillerModal,
  closeMASFillerModal,
  updateBAFillerModal,
  closeBAFillerModal,
} from "../redux/modules/curveExtract/index";
import {
  deletePathAction,
  closeDeletePathModalAction,
} from "../redux/modules/adminGroups";
import {
  closeUserDeleteModalAction,
  removeUserAction,
} from "../redux/modules/adminUsers";
import { EnrichTimeTransformModalContainer } from "./enrichTimeTransform";
import { ExpectationMonitorModal } from "../expectationAlertDashboard/components";
import {
  AdminGroupsModalContainer,
  AdminDeleteGroupsModalContainer,
  AdminEditGroupsModalContainer,
} from "./adminGroup";
import { AdminAddUsersContainer } from "./adminUsers";
import { CustomFilterModalContainer } from "./curves";
import MASCustomFiller from "./curveExtract/curvesMarketAssessment/customFiller";
import BACustomFiller from "./curveExtract/bidAsk/customFiller";

const Main = (props) => (
  <div>
    <ErrorModal showError={props.errorHandler} closeModal={props.setError} />
    <CurvePreviewContainer />
    <EnrichTimeTransformModalContainer />
    <ExpectationMonitorModal />
    <AdminGroupsModalContainer />
    <AdminDeleteGroupsModalContainer />
    {isEmpty(props.customFilterModal.row) ? null : (
      <CustomFilterModalContainer />
    )}
    {isEmpty(props.editPathModal.row) ? null : (
      <AdminEditGroupsModalContainer />
    )}
    {isEmpty(props.addUserModal.row) ? null : <AdminAddUsersContainer />}
    <ModalAlert
      showModal={props.curveWarningModalState.showModal}
      modalMessage={props.curveWarningModalState.modalMessage}
      confirm={props.confirmCurveWarningModalAction}
      closeModal={props.closeCurveWarningModalAction}
    />
    <ModalAlert
      showModal={props.curveEnrichWarningModalState.showModal}
      modalMessage={props.curveEnrichWarningModalState.modalMessage}
      confirm={props.confirmSendSelectedCurveEnrichWarningModalAction}
      closeModal={props.closeSendSelectedCurveEnrichWarningModalAction}
    />
    <ModalAlert
      showModal={props.settingsWarningModalState.showModal}
      modalMessage={props.settingsWarningModalState.modalMessage}
      confirm={props.closeSettingsWarningModalAction}
      closeModal={props.confirmSettingsWarningModalAction}
    />
    <ModalAlert
      showModal={props.userPermissionCheckModal.open}
      modalMessage={
        <span>
          You have selected more than 100 market data, this action may take some
          time. <br /> Are you sure you want to continue?
        </span>
      }
      confirm={props.confirmPermissionCheckModalAction}
      closeModal={props.closePermissionCheckModalAction}
    />
    <ModalAlert
      showModal={props.userDeleteModal.open}
      modalMessage="Are you sure you want to delete this user?"
      confirm={props.removeUserAction}
      closeModal={props.closeUserDeleteModalAction}
    />
    <ModalAlert
      showModal={props.deleteTimeTransformModal.open}
      modalMessage="Are you sure you want to delete this Time transform?"
      confirm={props.confirmDeleteRowAction}
      closeModal={props.closeDeleteModalAction}
    />
    <ModalAlert
      showModal={props.deletePathModal.open}
      modalMessage="Are you sure you want to delete the path from the group?"
      confirm={props.deletePathAction}
      closeModal={props.closeDeletePathModalAction}
    />
    <NavigateModal
      showModal={props.navigationModalState.showModal}
      modalMessage={props.navigationModalState.modalMessage}
      closeModal={props.closeNavigationModalAction}
    />
    <MASCustomFiller
      showModal={props.fillerMASModal.open}
      row={props.fillerMASModal.row}
      defaultRow={props.fillerMASModal.default}
      confirm={props.updateMASFillerModal}
      close={props.closeMASFillerModal}
    />
    <BACustomFiller
      showModal={props.fillerBAModal.open}
      row={props.fillerBAModal.row}
      defaultRow={props.fillerBAModal.default}
      confirm={props.updateBAFillerModal}
      close={props.closeBAFillerModal}
    />
  </div>
);

const mapStateToProps = (state) => ({
  errorHandler: state.ErrorHandler,
  ...state.Settings,
  ...state.Curves,
  ...state.CurveEnrich,
  ...state.CurveExtract,
  ...state.Menu,
  ...state.EnrichTimeTransform,
  ...state.AdminGroups,
  ...state.AdminUsers,
});

export default compose(
  connect(mapStateToProps, {
    setError: ClearError,
    closeCurveWarningModalAction,
    confirmCurveWarningModalAction,
    closeNavigationModalAction,
    confirmSendSelectedCurveEnrichWarningModalAction,
    closeSendSelectedCurveEnrichWarningModalAction,
    confirmSettingsWarningModalAction,
    closeSettingsWarningModalAction,
    confirmDeleteRowAction,
    closeDeleteModalAction,
    closeUserDeleteModalAction,
    removeUserAction,
    deletePathAction,
    closeDeletePathModalAction,
    closePermissionCheckModalAction,
    confirmPermissionCheckModalAction,
    updateMASFillerModal,
    closeMASFillerModal,
    updateBAFillerModal,
    closeBAFillerModal,
  })
)(Main);
