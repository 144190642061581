import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "bootstrap/dist/js/bootstrap";
import { Provider } from "react-redux";
import { history } from "./appContext";
import CreateStore from "./redux/configureStore";
import environmentSettings from "./environment";
import App from "./App";
//import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";

import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./appContext";


const store = CreateStore({ environmentSettings }, history);
const render = Component => {
  ReactDOM.render(
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Component history={history} />
        </Provider>
      </MsalProvider>
    ,
    document.getElementById("root")
  );
};

render(App);
if (module.hot) {
  module.hot.accept("./App", () => {
    render(App);
  });
}
