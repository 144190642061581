import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { InputNumberTextBox } from "../../../components";

const ModalAlert = ({ row, defaultRow, showModal, confirm, close }) => {
  const [state, setState] = useState(R.isEmpty(row) ? defaultRow : row);

  useEffect(() => {
    setState(R.isEmpty(row) ? defaultRow : row);
  }, [showModal]);
  return (
    <div>
      <div
        className={`modal fade show ${showModal ? "d-block" : ""}`}
        id="errorModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={`modalOverlay ${showModal ? "fadeIn" : ""}`}
          id="errorModalOverlay"
          onClick={() => close()}
        />
        <div className="modal-dialog artesainModal" role="document">
          {showModal ? (
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title">Add Custom Fillers</h5>
              </div>
              <div className="modal-body text-center">
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Bid Price</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVbbp}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVbbp: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Bid Volume</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVbbq}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVbbq: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Ask Price</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVbap}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVbap: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Ask Volume</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVbaq}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVbaq: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Last Price</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVlp}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVlp: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Last Volume</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVlq}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVlq: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn confirmClose"
                  onClick={() => confirm(state)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn modalClose"
                  onClick={() => close()}
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ModalAlert;
