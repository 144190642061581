import React from "react";
import { connect } from "react-redux";
import { withProps, withState, withHandlers, lifecycle } from "recompose";
import * as RX from "rxjs";
import { tap, delay } from "rxjs/operators";
import * as R from "ramda";
import { CurvesMarketAssessmentContainer } from "./curvesMarketAssessment";
import { CurvesVersionedTimeSeriesContainer } from "./curvesVersionedTimeSeries";
import { CurvesActualTimeSeriesContainer } from "./curvesActualTimeSeries";
import { AuctionExtractContainer } from "./auctionExtract";
import { BidAskContainer } from "./bidAsk";
import { CurveExtract } from "../../curveExtract";
import { tutorialOnAction } from "../../redux/modules/tutorial";
import {
  loadCurvesTableAction,
  getAllTimeTransformDataAction,
  CurveExtractSelectors,
} from "../../redux/modules/curveExtract";
import {
  EffectCurvesSizePerPageDropDown,
  EffectedExtractionTable,
} from "../../components";
import {
  componentDisplayHelper,
  getTrName,
  componentList,
  getTypeData,
  getFacetTypeData,
  getCurvesCount,
  curveSelectionError,
} from "./helper";

const { compose, length, gt, prop, find, propEq, equals, concat } = R;

const mapStateToProps = (state) => ({
  ...state.Curves,
  ...state.CurveExtract,
  displayCurveTable: CurveExtractSelectors.displayCurveTableSelector(state),
});

export default compose(
  connect(mapStateToProps, {
    tutorialOn: tutorialOnAction,
    loadCurvesTableAction,
    getAllTimeTransformDataAction,
  }),
  withState("loaded", "onload", false),
  withState("effectedCurves", "updateEffectedCurves", true),
  withState("effectCurvePageSize", "updateEffectCurvePageSize", 30),
  lifecycle({
    componentDidMount() {
      RX.of("")
        .pipe(
          delay(800),
          tap(() => this.props.getAllTimeTransformDataAction())
        )
        .subscribe(() => this.props.loadCurvesTableAction("displayTable"));
    },
  }),
  withProps((props) => ({
    transformValues: concat(
      props.userTransformValues,
      props.systemTransformValues
    ),
    filterDropdownOptions: [
      { label: "Market Assessment", value: "MarketAssessment" },
      { label: "Auction", value: "Auction" },
      { label: "BidAsk", value: "BidAsk" },
      { label: "Actual Time Series", value: "ActualTimeSerie" },
      { label: "Versioned Time Series", value: "VersionedTimeSerie" },
    ],
  })),
  withHandlers({
    toggleEffectedCurves: (props) => () =>
      props.updateEffectedCurves(!props.effectedCurves),
    setPageSize: (props) => (val) => props.updateEffectCurvePageSize(val),
  }),
  withHandlers({
    effectedCurveTrName: (props) => (id) =>
      getTrName(props.transformValues, id),
    renderDropDown: (props) => () =>
      EffectCurvesSizePerPageDropDown(props.effectCurvePageSize, props),
  }),
  withProps((props) => ({
    facetTypeData: compose(
      prop("Values"),
      find(propEq("FacetName", "Type"))
    )(props.facetData),
    extractTitle: equals(props.extractCustomFilterState, true)
      ? "Extract Filter"
      : "Extract",
  })),
  withProps((props) => ({
    curveCount: length(props.selectedCurves.curves),
    marketAssessmentData: getTypeData({
      data: props.selectedCurves.curves,
      type: "MarketAssessment",
    }),
    auctionData: getTypeData({
      data: props.selectedCurves.curves,
      type: "Auction",
    }),
    bidAskData: getTypeData({
      data: props.selectedCurves.curves,
      type: "BidAsk",
    }),
    versionedTimeSeriesData: getTypeData({
      data: props.selectedCurves.curves,
      type: "VersionedTimeSerie",
    }),
    actualTimeSeriesData: getTypeData({
      data: props.selectedCurves.curves,
      type: "ActualTimeSerie",
    }),
    filterMasData: getFacetTypeData({
      data: props.facetTypeData,
      type: "MarketAssessment",
    }),
    filterAuctionData: getFacetTypeData({
      data: props.facetTypeData,
      type: "Auction",
    }),
    filterBidAskData: getFacetTypeData({
      data: props.facetTypeData,
      type: "BidAsk",
    }),
    filterVtsData: getFacetTypeData({
      data: props.facetTypeData,
      type: "VersionedTimeSerie",
    }),
    filterTsData: getFacetTypeData({
      data: props.facetTypeData,
      type: "ActualTimeSerie",
    }),
  })),
  withProps((props) => ({
    marketAssessmentDataCount: getCurvesCount({
      props,
      filterData: "filterMasData",
      defaultData: "marketAssessmentData",
    }),
    auctionDataCount: getCurvesCount({
      props,
      filterData: "filterAuctionData",
      defaultData: "auctionData",
    }),
    bidAskDataCount: getCurvesCount({
      props,
      filterData: "filterBidAskData",
      defaultData: "bidAskData",
    }),
    versionedTimeSeriesDataCount: getCurvesCount({
      props,
      filterData: "filterVtsData",
      defaultData: "versionedTimeSeriesData",
    }),
    actualTimeSeriesDataCount: getCurvesCount({
      props,
      filterData: "filterTsData",
      defaultData: "actualTimeSeriesData",
    }),
    marketAssessmentErrors: curveSelectionError({
      data: props.marketAssessmentData,
      filters: props.extractionFilters.mas,
    }),
    auctionErrors: curveSelectionError({
      data: props.auctionData,
      filters: props.extractionFilters.auction,
    }),
    bidAskErrors: curveSelectionError({
      data: props.bidAskData,
      filters: props.extractionFilters.bidAsk,
    }),
    versionedTimeSeriesErrors: curveSelectionError({
      data: props.versionedTimeSeriesData,
      filters: props.extractionFilters.vts,
    }),
    actualTimeSeriesErrors: curveSelectionError({
      data: props.actualTimeSeriesData,
      filters: props.extractionFilters.ats,
    }),
    tsvAsTsErrors: curveSelectionError({
      data: R.flatten([
        props.actualTimeSeriesData,
        props.versionedTimeSeriesData,
      ]),
      filters: props.extractionFilters.tsvAsTs,
    }),
  })),
  withProps((props) => ({
    componentList: componentList(props),
    filterDisplay:
      gt(props.versionedTimeSeriesDataCount, 0) &&
      gt(props.actualTimeSeriesDataCount, 0) &&
      props.extractCustomFilterState,
    effectedCurvesTableSettings: {
      sizePerPage: props.effectCurvePageSize,
      sizePerPageDropDown: props.renderDropDown,
      prePage: "Previous",
      nextPage: "Next",
      clickToExpand: true,
    },
  })),
  withState("selectedExtraction", "updateSelectedExtraction", (props) =>
    componentList(props)
  ),
  withState("excelTourIsOpen", "toggleTutorial", false),
  withHandlers({
    toggleTutorial: (props) => () => {
      props.toggleTutorial(!props.excelTourIsOpen);
    },
    closeTutorial: (props) => () => {
      props.toggleTutorial(false);
    },
    setSelectedExtraction: (props) => (val) =>
      props.updateSelectedExtraction(
        equals(val, props.selectedExtraction) ? null : val
      ),
  }),
  withProps((props) => ({
    effectedCurvesTable: (
      <EffectedExtractionTable
        userTransformValues={props.userTransformValues}
        systemTransformValues={props.systemTransformValues}
        data={props.selectedCurves.curves}
        filterVal={props.filterVal}
        showID
        selected={props.selectedExtraction}
        settings={props.effectedCurvesTableSettings}
        effectedCurveTrName={props.effectedCurveTrName}
        filterDropdownOptions={props.filterDropdownOptions}
        errors={R.flatten([
          props.versionedTimeSeriesErrors.all,
          props.actualTimeSeriesErrors.all,
          props.marketAssessmentErrors.all,
          props.auctionErrors.all,
          props.bidAskErrors.all,
          props.tsvAsTsErrors.all,
        ])}
      />
    ),
  })),
  withProps((props) => ({
    tsvAsTsComponent:
      gt(props.versionedTimeSeriesDataCount, 0) &&
      gt(props.actualTimeSeriesDataCount, 0) ? (
        <CurvesActualTimeSeriesContainer
          selected={equals(props.selectedExtraction, "tsvAsTsComponent")}
          updateSelectedExtraction={props.setSelectedExtraction}
          isFirst={equals(props.componentList, "tsvAsTsComponent")}
          key="vstAsActualTS"
          isVts
          toggleTutorial={props.toggleTutorial}
          errors={props.tsvAsTsErrors}
        />
      ) : null,
    tsvComponent: componentDisplayHelper(
      props.extractCustomFilterState,
      props.filterDisplay,
      props.versionedTimeSeriesDataCount,
      <CurvesVersionedTimeSeriesContainer
        selected={equals(props.selectedExtraction, "tsvComponent")}
        isFirst={equals(props.componentList, "tsvComponent")}
        updateSelectedExtraction={props.setSelectedExtraction}
        toggleTutorial={props.toggleTutorial}
        errors={props.versionedTimeSeriesErrors}
      />
    ),
    tsComponent: componentDisplayHelper(
      props.extractCustomFilterState,
      props.filterDisplay,
      props.actualTimeSeriesDataCount,
      <CurvesActualTimeSeriesContainer
        selected={equals(props.selectedExtraction, "tsComponent")}
        isFirst={equals(props.componentList, "tsComponent")}
        updateSelectedExtraction={props.setSelectedExtraction}
        toggleTutorial={props.toggleTutorial}
        key="actualTS"
        isVts={false}
        errors={props.actualTimeSeriesErrors}
      />
    ),
    masComponent: gt(props.marketAssessmentDataCount, 0) ? (
      <CurvesMarketAssessmentContainer
        selected={equals(props.selectedExtraction, "masComponent")}
        isFirst={equals(props.componentList, "masComponent")}
        toggleTutorial={props.toggleTutorial}
        updateSelectedExtraction={props.setSelectedExtraction}
        errors={props.marketAssessmentErrors}
      />
    ) : null,
    auctionExtractComponent: gt(props.auctionDataCount, 0) ? (
      <AuctionExtractContainer
        selected={equals(props.selectedExtraction, "auctionExtractComponent")}
        isFirst={equals(props.componentList, "auctionExtractComponent")}
        toggleTutorial={props.toggleTutorial}
        updateSelectedExtraction={props.setSelectedExtraction}
        errors={props.auctionErrors}
      />
    ) : null,
    bidAskExtractComponent: gt(props.bidAskDataCount, 0) ? (
      <BidAskContainer
        selected={equals(props.selectedExtraction, "bidAskExtractComponent")}
        isFirst={equals(props.componentList, "bidAskExtractComponent")}
        toggleTutorial={props.toggleTutorial}
        updateSelectedExtraction={props.setSelectedExtraction}
        errors={props.bidAskErrors}
      />
    ) : null,
  }))
)(CurveExtract);
