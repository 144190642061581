import React from "react";
import { Button } from "../components";

const NavigateModal = (props) => (
  <div>
    <div
      className={`modal fade show ${props.showModal ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${props.showModal ? "fadeIn" : ""}`}
        id="errorModalOverlay"
        onClick={() => {
          props.closeModal();
        }}
      />
      <div className="modal-dialog artesainModal" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <Button
              className="btn close close-default remove-box-shadow btn-circle curvePreviewClose navModal"
              onClick={() => {
                props.closeModal();
              }}
              btnText="&times;"
            />
          </div>
          <div className="modal-body text-center">
            <span>
              <h4>{props.modalMessage}</h4>
            </span>
          </div>
          <div className="modal-footer navModalFooter" />
        </div>
      </div>
    </div>
  </div>
);

export default NavigateModal;
