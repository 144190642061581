import React from "react";
import { connect } from "react-redux";
import { compose, equals, is, __, assoc, toPairs, isEmpty, any } from "ramda";
import { withHandlers, withProps } from "recompose";
import { InputTextBox, DropDown } from "../../components";
import { PeriodISOPicker, ShiftToolTip } from "./components";
import { EnrichTimeTransformModal } from "../../enrichTimeTransform";
import {
  getAllTimeTransformDataAction,
  openEditModalAction,
  closeEditModalAction,
  updateModalRowAction,
  createTimeTransformAction,
  updateTimeTransformAction,
} from "../../redux/modules/enrichTimeTransform";
import removeInvalidChars from "./helper";

const mapStateToProps = (state) => ({
  ...state.Curves,
  ...state.EnrichTimeTransform,
});

export default compose(
  connect(mapStateToProps, {
    getAllTimeTransformDataAction,
    openEditModal: openEditModalAction,
    closeEditModal: closeEditModalAction,
    updateModalRowAction,
    createTimeTransformAction,
    updateTimeTransformAction,
  }),
  withProps((props) => ({
    containsShift: any(equals(false))([
      props.modal.row.PositiveShift === "",
      props.modal.row.NegativeShift === "",
    ]),
  })),
  withHandlers({
    updateRow: (props) => (row) => props.updateModalRowAction(row),
    commitChanges: (props) => () =>
      equals(props.modal.type, "Add Transform")
        ? props.createTimeTransformAction(props.modal.row)
        : props.updateTimeTransformAction(props.modal.row),
    closeModal: (props) => () => props.closeEditModal(),
  }),
  withHandlers({
    applyBtnIsDisabled: (props) => () =>
      (props.modal.orig ? equals(props.modal.row, props.modal.orig) : true) ||
      props.modal.row.Name === "" ||
      props.modal.row.Period === "" ||
      !props.containsShift,
    GetDropDown: (props) => (type, cellValue = "") => {
      const objUpdater = compose(
        props.updateRow,
        assoc(type, __, props.modal.row)
      );
      const value =
        is(Object, cellValue) && equals(cellValue.overridden, true)
          ? cellValue.value
          : cellValue;
      switch (type) {
        case "Name":
          return (
            <div>
              <label htmlFor="select">
                <b>Name</b>
              </label>
              <InputTextBox
                value={removeInvalidChars(value)}
                onChange={objUpdater}
                placeholder="Please Enter an new TimeTransform Name"
              />
            </div>
          );
        case "Period":
          return (
            <div>
              <label htmlFor="select">
                <b>Minimum Granularity Applicable To MarketData</b>
              </label>
              <DropDown
                className="artesianDropdownBorderColor"
                selected={isEmpty(value) ? "No Value Selected" : value}
                dropDownValues={props.granularityValues}
                onClick={objUpdater}
                dropID="minGranularity"
              />
            </div>
          );
        case "PositiveShift":
          return (
            <div>
              <label htmlFor="select">
                <ShiftToolTip title="Positive Shift" value={value} />
              </label>
              <PeriodISOPicker
                isoDate={value}
                update={objUpdater}
                containsHour
              />
            </div>
          );
        case "NegativeShift":
          return (
            <div>
              <label htmlFor="select">
                <ShiftToolTip title="Negative Shift" value={value} />
              </label>
              <PeriodISOPicker
                isoDate={value}
                update={objUpdater}
                containsHour
              />
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withHandlers({
    GetDropDownGroups: (props) => () =>
      toPairs(props.modal.row).map(([type, value]) => (
        <div className={`form-group ${type}`} key={type}>
          {props.GetDropDown(type, value || "")}
        </div>
      )),
  })
)(EnrichTimeTransformModal);
