const CurveTableConfig = {
  curveTable: [
    {
      title: "MARKETDATA ID",
      dataField: "MarketDataId",
      width: "140px",
      hidden: false,
      relocate: true,
    },
    {
      title: "NAME",
      dataField: "MarketDataName",
      width: null,
      hidden: false,
      relocate: true,
    },
    {
      title: "AGGREGATION RULE",
      dataField: "AggregationRule",
      width: "170px",
      hidden: false,
      relocate: true,
    },
    {
      title: "PROVIDER",
      dataField: "ProviderName",
      width: "180px",
      hidden: false,
      relocate: true,
    },
    {
      title: "MARKETDATA TYPE",
      dataField: "Type",
      width: "200px",
      hidden: false,
      relocate: true,
    },
  ],
};

export default CurveTableConfig;
