import React from "react";
import { Redirect, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as R from "ramda";
import { permissionCheck } from "./helper/permissions";

const Protected = ({
  path,
  state,
  component: Comp,
  children,
  match,
  permission,
  fallback,
}) =>
  R.pipe(
    R.map((p) => permissionCheck({ permission: p, state })),
    R.any(R.equals(true)),
    R.ifElse(
      R.equals(true),
      () =>
        Comp ? (
          <Route path={path} component={Comp} />
        ) : (
          <Route>{() => children}</Route>
        ),
      () => <Redirect to={fallback || match.url} />
    )
  )(permission);

const ProtectedRoute = R.compose(
  withRouter,
  connect(R.objOf("state"))
)(Protected);
export default ProtectedRoute;
