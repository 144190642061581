import React from "react";
import { prepend, compose, concat, equals, contains, any } from "ramda";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

const startHelp = [
  {
    position: "center",
    content: (
      <div>
        <h3>Enrich Time Transform Tutorial</h3>
        Create, update and delete Time Transforms
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Create Time Transform
  {
    selector: '[class="btn createTimeTransformBtn marginBottom10 "]',
    position: "bottom",
    content: (
      <div>
        <h3>Create new Time Transform</h3>
        Here you can create a new custom time transform which can be applied
        when extracting market data.
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName("modal fade show d-block").length >= 1
      ) {
        return document.getElementsByClassName("btn modalClose")[0].click();
      }
      return null;
    },
    style: toolTipStyleTopMargin,
  },
];

const permissonHelp = [
  {
    selector: '[class="btn createTimeTransformBtn marginBottom10 "]',
    position: "bottom",
    content: (
      <div>
        <h3>Open Time Transform Modal</h3>
      </div>
    ),
    action: (node) => {
      if (
        document.getElementsByClassName("modal fade show d-block").length < 1
      ) {
        node.click();
      }
      return null;
    },
    style: toolTipStyleTopMargin,
  },
  // | - Modal - |
  // Enter name
  {
    selector: '[id="inputGroup"]',
    position: "bottom",
    content: (
      <div>
        <h3>Create Time Transform Name</h3>
        This is the unique identifier for you time transform.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Min granularity dropdown
  {
    selector: '[class="d-flex width100 noPadding minGranularity"]',
    position: "bottom",
    content: (
      <div>
        <h3>Set a minimum Granularity</h3>
        Set the minimum granurality that this time transform can be applied to
        when extracting
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Positive Shift
  {
    selector: '[class="form-group PositiveShift"]',
    position: "bottom",
    content: (
      <div>
        <h3>Apply Positive Shift Period</h3>
        Set the ISO_8601 Duration period, that will applied from today to the
        period in the future
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Negative Shift
  {
    selector: '[class="form-group NegativeShift"]',
    position: "bottom",
    content: (
      <div>
        <h3>Apply Negative Shift Period</h3>
        Set the ISO_8601 Duration period, that will applied from today to the
        period in the past
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Confirm Btn
  {
    selector: '[class="btn confirmClose btnDisabled"]',
    position: "bottom",
    content: (
      <div>
        <h3>Create New Time Transform</h3>
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName("modal fade show d-block").length < 1
      ) {
        return document
          .getElementsByClassName(
            "btn createTimeTransformBtn marginBottom10 "
          )[0]
          .click();
      }
      return null;
    },
    style: toolTipStyleTopMargin,
  },
];

const endHelp = [
  {
    selector:
      '[class="react-bs-table react-bs-table-bordered enrichTimeTransformTableContainer"]',
    position: "left",
    content: (
      <div>
        <h3>Time Transform Table</h3>
        Here all the time transforms that have been created are displayed.
        <br />
        Each tranform display shows the positive & negative shift, along with
        the minimum granurality applied to each transform.
      </div>
    ),
    action: () => document.getElementsByClassName("btn modalClose")[0].click(),
    style: toolTipStyleTopMargin,
  },
  // Back to enrich button
  {
    selector: '[class="btn  menuBtnItem Admin "]',
    position: "bottom",
    content: (
      <div>
        <h3>Go to Group Admin Page</h3>
        Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    action: (node) => node.click(),
    style: toolTipStyleTopMargin,
  },
  // On hover.
  {
    selector: '[id="MenuGroupAdmin"]',
    position: "right",
    content: (
      <div>
        <h3>Go to Group Admin Page</h3>
      </div>
    ),
    style: toolTipStyleLeftMargin,
    action: (node) => node.click()

  },
];

const metaDatahelp = {
  selector: '[class="btn  menuBtnItem Extract "]',
  action: (node) => node.click(),
  content: <div />,
};
const selectCurveshelp = {
  selector: '[class="btn  menuBtnItem Select "]',
  action: (node) => node.click(),
  content: <div />,
};

const permissonCheck = (permissions) =>
  !any(equals(true))([
    contains("Creator", permissions),
    contains("Owner", permissions),
  ]);

export const enrichTimeTransformTutorial = (goBackSelctor, permissions) => {
  const helperText = compose(
    (q) => concat(q, endHelp),
    (x) => (permissonCheck(permissions) ? concat(x, permissonHelp) : x)
  )(startHelp);
  return goBackSelctor > 0
    ? prepend(metaDatahelp, helperText)
    : prepend(selectCurveshelp, helperText);
};
export default enrichTimeTransformTutorial;
