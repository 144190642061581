import React from "react";
import { auth } from "../../../appContext"


const DefaultMenu = ({
  email,
  logout,
  displayCompanySwitch,
  redirectToApiKeys,
  openSwitchCompany,
}) => {
  return (
  <React.Fragment>
    <div className="loggedIn">
      Logged in as <span>{email}</span>
    </div>
    {displayCompanySwitch ? (
      <div className="headerItem" onClick={openSwitchCompany}>
        <i className="fas fa-sync-alt artesianIconNoHover" />
        Switch Company
      </div>
    ) : null}
    <div className="headerItem" id="APIKeyPanel" onClick={redirectToApiKeys}>
      <i className="fas fa-cog artesianIconNoHover" />
      Configure API Keys
    </div>
    <div className="headerItem signOut" onClick={() => {
      auth.logout()
      }}>
      <i className="fas fa-sign-out-alt artesianIconNoHover" />
      Sign Out
    </div>
  </React.Fragment>
)};

export default DefaultMenu;
