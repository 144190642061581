export const timezonesValues = [
  "None Selected",
  "CET",
  "WET",
  "EET",
  "UTC",
  "Europe/Istanbul",
  "Europe/Moscow"
];
export const aggregationRuleValues = [
  "None Selected",
  "Undefined",
  "SumAndDivide",
  "AverageAndReplicate"
];

export default timezonesValues;
