import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleLeftMargin,
  toolTipStyleTopMargin,
} from "./tooltipStyles";

export const extractionTutorial = [
  // End tutorial
  {
    position: "center",
    content: (
      <div>
        <h3>This ends the market data extraction tutorial.</h3>
        <div>
          To continue this tutorial with information on how to Enrich MarketData
          through Artesian, click -&gt;
        </div>
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Click on Enrich button
  {
    selector: '[class="btn  menuBtnItem Enrich "]',
    position: "bottom",
    content: <div><h3>Two enrichment options: Metadata and Time Transform. </h3><br/> Click &nbsp;
    <i className="fa fa-arrow-right" />
    &nbsp; to continue to the Enrich Metadata Page</div>,
    action: (node) => node.click(),
    style: toolTipStyleTopMargin,
  },
  // GOTO Enrich Metadata
  {
    selector: '[id="MenuEnrichMetaData"]',
    action: (node) => node.click(),
    content: <div />,
  },
];

export default extractionTutorial;
