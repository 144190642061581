import React, { useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import moment from "moment";
import { Tooltip } from "react-tippy";
import { Spinner } from "./spinner";
import AddAlertBody from "./modals/addAlertComponent";
import PaginationComponent from "../../components/tableComponents/tablePagination";
import {
  deleteAlertAction,
  addAlertAction,
  addMarketDataIds,
  openModal,
  Selectors,
} from "../../redux/modules/expectationMonitor";

const getStatusColour = (status) => {
  switch (status) {
    case "Ok":
    case "Solved":
      return <div className="sucess">{status}</div>;
    case "Waiting":
      return <div className="warning">{status}</div>;
    case "InLate":
      return <div className="failed">{status}</div>;
    case "NotSet":
      return <div />;
    default:
      return <div>{status}</div>;
  }
};

const DeleteAlertBody = (props) => {
  return (
  <div className="deleteAlertPopupModal">
    {R.pathOr("", ["info", "modal", "state"], props) !== "error" ? "Are you sure you want to delete Alert" : R.pathOr("", ["info", "modal", "errorMsg"], props)}
  </div>
)};
const AddAlertListBody = ({ selectedCurves }) => {
  const arr = selectedCurves || [];
  return selectedCurves.length === 0 ? (
    <div>
      <div>
        No market data Id's selected. Please Select the market data Id's you
        wish to add on the market data selection page
      </div>
    </div>
  ) : (
    <div className="alertHistoryPopup">
      <div>Add Selected MarketData to alert</div>
      <table className="artesianTable">
        <thead>
          <tr>
            <th>Market Data Name</th>
            <th>MarketDataID</th>
          </tr>
        </thead>
        <tbody>
          {arr.map((y, i) => (
            <tr key={i}>
              {/* todo: add remove function */}
              {/* <td><div className="tableCellIcon"><span className="fa fa-trash"></span></div></td> */}
              <td>{y.MarketDataName}</td>
              <td>{y.MarketDataId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AddHistoryBody = (props) => {
  const [expandedId, setExpandedId] = useState(null);
  return (
    <div className="alertHistoryPopup">
      {props.info.history.isFetching ? (
        <Spinner />
      ) : (
        <table className="artesianTable">
          <thead>
            <tr>
              <th />
              <th>Status</th>
              <th>Name</th>
              <th>Timezone</th>
              <th>Check Time</th>
              <th>Impacted Timezone</th>
              <th>Impacted From</th>
              <th>Impacted To</th>
            </tr>
          </thead>
          <tbody>
            {R.map((x) => (
              <React.Fragment key={x.CheckTime}>
                <tr>
                  <td>
                    <div className="tableCellIcon">
                      <i
                        onClick={() =>
                          setExpandedId(
                            x.CheckTime === expandedId ? null : x.CheckTime
                          )
                        }
                        className={`fa fa-chevron-${
                          x.ID === expandedId ? "down" : "right"
                        }`}
                      />
                    </div>
                  </td>
                  <td>{getStatusColour(x.Status)}</td>
                  <td>{x.ExpectationAlertName}</td>
                  <td>{x.CheckTimeZone}</td>
                  <td>{moment(x.CheckTime).format("DD-MM-YYYY HH:mm:ss")}</td>
                  <td>{x.ImpactedDataRangeTMZ}</td>
                  <td>
                    {moment(x.ImpactedTimeFrom).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  <td>
                    {moment(x.ImpactedTimeTo).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                </tr>
                {x.CheckTime === expandedId ? (
                  <tr>
                  </tr>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))(R.path(["info", "history", "data"], props) || [])}
          </tbody>
        </table>
      )}
    </div>
  );
};
const ExpectationMonitorModal = (props) => {
  const [timezone, setTimezone] = useState("CET");
  const [impactedDataRangeTimezone, setImpactedDataRangeTimezone] = useState(
    "CET"
  );
  const [cronExpression, setCronExpression] = useState("");
  const [alertName, setAlertName] = useState("");
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [isPeriodValid, setIsValid] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const resetAddModal = () => {
    setAlertName("");
    setCronExpression("");
    setTimezone("CET");
    setImpactedDataRangeTimezone("CET");
    setPageNo(1);
  };
  return (
    <div>
      <div
        className={`modal ScrollableModal fade  show ${
          props.info.modal.visible ? "d-block" : ""
        }`}
        id="errorModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={`modalOverlay ${props.info.modal.visible ? "fadeIn" : ""}`}
          onClick={() => props.openModal(false)}
        />
        <div
          className={`modal-dialog curvePreviewModal artesainModal ${
            props.info.modal.modalType === "addSelectedCurves" ||
            props.info.modal.modalType === "alertHistory"
              ? ""
              : ""
          }`}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h2 className="modal-title-expectation">{props.info.modal.title}</h2>
            </div>
            <div className="modal-body text-center ">
              <div
                className={`col ${
                  props.info.modal.modalType === "alertHistory" ||
                  props.info.modal.modalType === "addSelectedCurves"
                    ? "expectationHistoryBody"
                    : "expectationMonitorBody"
                }`}
              >
                <AlertBody
                  props={props}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  cronExpression={cronExpression}
                  setCronExpression={setCronExpression}
                  alertName={alertName}
                  setAlertName={setAlertName}
                  setImpactedDataRangeTimezone={setImpactedDataRangeTimezone}
                  impactedDataRangeTimezone={impactedDataRangeTimezone}
                  dateTo={dateTo}
                  dateFrom={dateFrom}
                  setIsValid={setIsValid}
                  setDateFrom={setDateFrom}
                  setDateTo={setDateTo}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn errorModalClose"
                disabled={R.pathOr("", ["info", "modal", "state"], props)==="fetching"}
                onClick={() => {
                  props.openModal(false);
                  resetAddModal();
                }}
              >
                Close
              </button>
              <AlertTooltip
                props={props}
                timezone={timezone}
                resetAddModal={resetAddModal}
                cronExpression={cronExpression}
                alertName={alertName}
                isPeriodValid={isPeriodValid}
                impactedDataRangeTimezone={impactedDataRangeTimezone}
                dateTo={dateTo}
                dateFrom={dateFrom}
                pageNo={pageNo}
                setPageNo={setPageNo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (s) => ({
    info: Selectors.ExpectationMonitor(s),
    selectedCurves: Selectors.getSelectedCurves(s),
  }),
  {
    openModal,
    addAlertAction,
    addMarketDataIds,
    deleteAlertAction,
  }
)(ExpectationMonitorModal);

const AlertBody = ({
  props,
  timezone,
  setTimezone,
  cronExpression,
  setCronExpression,
  alertName,
  setAlertName,
  setImpactedDataRangeTimezone,
  impactedDataRangeTimezone,
  dateTo,
  dateFrom,
  setIsValid,
  setDateFrom,
  setDateTo,
  pageNo,
  setPageNo
}) => {
  switch (props.info.modal.modalType) {
    case "addAlert":
      return (
        <AddAlertBody
          timezone={timezone}
          setTimezone={setTimezone}
          cronExpression={cronExpression}
          setCronExpression={setCronExpression}
          alertName={alertName}
          setAlertName={setAlertName}
          setImpactedDataRangeTimezone={setImpactedDataRangeTimezone}
          impactedDataRangeTimezone={impactedDataRangeTimezone}
          setIsValid={setIsValid}
          dateTo={dateTo}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      );
    case "editAlert":
      return <div>edit</div>;
    case "alertHistory":
      return <AddHistoryBody {...props} />;
    case "addSelectedCurves":
      return <AddAlertListBody selectedCurves={props.selectedCurves} />;
    case "deleteAlert":
      return DeleteAlertBody(props);
    default:
      return null;
  }
};

const AlertTooltip = ({
  props,
  timezone,
  resetAddModal,
  cronExpression,
  alertName,
  impactedDataRangeTimezone,
  isPeriodValid,
  dateTo,
  dateFrom,
  pageNo,
  setPageNo
}) => {
  switch (props.info.modal.modalType) {
    case "addAlert":
      return (
        <React.Fragment>
          {pageNo === 2 &&
          
            <i className="fa fa-chevron-left fa-stack-chevron-large" onClick={() => {setPageNo(1)}} />
          
          }
          <Tooltip
            arrow
            position="bottom"
            disabled={alertName !== "" && cronExpression !== "" && isPeriodValid }
            html={<span>{alertName === ""  ?  
              "Please enter an Alert Name" :
              "Create Alert"
            }</span>}
            theme="arkDefaultTooltip"
          >
            {pageNo == 1 ?
              <button
              type="button"
              className="btn errorModalClose"
              disabled={alertName  === ""}
              onClick={() => {setPageNo(2)}}
            >
              Next
            </button>
            :
              <button
                type="button"
                className="btn errorModalClose"
                disabled={alertName  === "" || cronExpression === "" || isPeriodValid }
                onClick={() => {
                  props.addAlertAction({
                    Name: alertName,
                    Schedule: {
                      CRONExpression: cronExpression,
                      CheckTimeZone: timezone,
                      ImpactedDataRangeTMZ: impactedDataRangeTimezone,
                      ImpactedPeriodFrom: dateFrom,
                      ImpactedPeriodTo: dateTo,
                    },
                  });
                  resetAddModal();
                }}
              >
                Create
              </button>
            }
          </Tooltip>
        </React.Fragment>
      );
    case "addSelectedCurves":
      return (
        <Tooltip
          arrow
          position="bottom"
          html={
            props.selectedCurves.length > 0 ? (
              "Add"
            ) : (
              <span>
                Please Select the market data Id's you wish to add on the market data
                selection page
              </span>
            )
          }
          theme="arkDefaultTooltip"
        >
          <button
            type="button"
            disabled={props.selectedCurves.length === 0}
            className="btn errorModalClose"
            onClick={() => {
              props.addMarketDataIds(
                props.info.modal.id,
                R.map((marketData) => marketData.MarketDataId)(
                  props.selectedCurves
                )
              );
              props.openModal(false);
            }}
          >
            Add
          </button>
        </Tooltip>
      );
    case "deleteAlert":{
      return (
        R.pathOr("", ["info", "modal", "state"], props) !== "error" ? <button
          type="button"
          className="btn errorModalClose"
          onClick={() => props.deleteAlertAction(props.info.modal.id)}
          disabled={R.pathOr("", ["info", "modal", "state"], props)==="fetching"}
        >
          {R.pathOr("", ["info", "modal", "state"], props)==="fetching" ? 
            <React.Fragment><span className="fa fa-spinner fa-spin"></span><span>&nbsp;&nbsp;</span></React.Fragment>: <React.Fragment></React.Fragment>}Delete
        </button>:<React.Fragment></React.Fragment>
      );}
    default:
      return null;
  }
};
