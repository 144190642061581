import React, { useEffect, useState } from "react";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import * as R from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import moment from "moment";
import { getStatusColour } from "../expectationAlertDashboard/helper";
import * as XLSX from "xlsx-populate/browser/xlsx-populate";
import { DateRangePicker } from "../containers/curveExtract/components";
import { todaysDate, lastWeekDate } from "../redux/modules/curvePreview/helper";

const expectationMonitorPage = "dashboard";
const expectationMonitorAlertsPage = "alerts";
const expectationMonitorHisoryDetailsPage = "historyDetails";

const XlsxPopulate = require("xlsx-populate");

const getWorkbook = () => {
  return XlsxPopulate.fromBlankAsync();
};

const generate = () => {
  return getWorkbook().then(function(workbook) {
    workbook
      .sheet(0)
      .cell("A1")
      .value(table_to_array("myTable"));
    return workbook.outputAsync();
  });
};

const generateBlob = () => {
  return generate()
    .then(function(blob) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "AlertHistory.xlsx");
      } else {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = "AlertHistory.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    })
    .catch(function(err) {
      alert(err.message || err);
      throw err;
    });
};

const table_to_array = (table_id) => {
  var myData = document.getElementById(table_id).rows;
  var tableArray = [];
  for (var i = 0; i < myData.length; i++) {
    var el = myData[i].children;
    var my_el = [];
    for (var j = 0; j < el.length; j++) {
      my_el.push(el[j].innerText);
    }
    tableArray.push(my_el);
  }
  return tableArray;
};

const HeaderButton = ({ title, onClick }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{title}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="filterBtn"
      onClick={() => onClick()}
      btnText={<span className="filterBtnText">{title}</span>}
    />
  </Tooltip>
);

export default function HistoryPage(props) {
  const expectationMonitorHisoryDetailsPage = "historyDetails";

  const [dateRangeStartDate, setDateRangeStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [dateRangeEndDate, setDateRangeEndDate] = useState(moment());

  const setDates = ([startDate, endDate]) => {
    setDateRangeStartDate(startDate);
    setDateRangeEndDate(endDate);
    props.parentProps.requestHistoryAction(props.selectedId);
  };

  useEffect(() => {
    props.parentProps.requestAction();
    const interval = setInterval(() => {
      props.parentProps.requestPollAction();
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  return (
    <React.Fragment>
      <div className="artesianTableContainer">
        <div className="tableFilter">
          <div>
            <HeaderButton
              onClick={() => {
                props.parentProps.setPageNoAction(1);
                props.parentProps.setPageSizeAction(50);
                props.setPage(expectationMonitorAlertsPage);
              }}
              title="Back"
            />
            <HeaderButton
              onClick={() => {
                props.parentProps.setPageNoAction(1);
                props.parentProps.setPageSizeAction(50);
                props.setPage(expectationMonitorPage);
              }}
              title="Back to dashboard"
            />
          </div>
          <div>
            <DateRangePicker
              startDate={dateRangeStartDate}
              endDate={dateRangeEndDate}
              onChange={setDates}
              defaultStartDate={moment().subtract(7, "days")}
              defaultEndDate={moment()}
            />
          </div>
          <div>
            <HeaderButton
              onClick={() => generateBlob()}
              title="Download Alert History"
            />
          </div>
        </div>

        <div hidden>
          <table className="artesianTable" id="myTable">
            <thead>
              <tr>
                <th>Name</th>
                <th />
                <th>Timezone</th>
                <th />
                <th>Impacted Timezone</th>
                <th />
                <th />
                <th />
              </tr>
            </thead>

            <thead>
              <tr>
                <th>
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "ExpectationAlertName",
                    ],
                    props
                  )}
                </th>
                <th />
                <th>
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "CheckTimeZone",
                    ],
                    props
                  )}
                </th>
                <th />
                <th>
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "ImpactedDataRangeTMZ",
                    ],
                    props
                  )}
                </th>
                <th />
              </tr>
            </thead>

            <tr>
              <th />
            </tr>

            <tbody>
              <tr>
                <th>Check Time</th>
                <th>Status</th>
                <th>Market Data Name</th>
                <th>Market Data Status</th>
                <th>Arrived At</th>
                <th>Impacted From</th>
                <th>Impacted To</th>
                <th>Provider Name</th>
              </tr>
              {R.map((x) => (
                <React.Fragment key={x.ID}>
                  {R.map((y) => {
                    return (
                      <React.Fragment key={y.ID}>
                        <tr>
                          <td>
                            {R.path(["CheckTime"])(x)
                              ? moment(R.path(["CheckTime"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>

                          <td>{getStatusColour(x.Status, true)}</td>

                          <td>{R.path(["MarketDataName"])(y) || ""}</td>

                          <td>{R.path(["Status"])(y) || ""}</td>

                          <td>{ R.path([
                            "ArrivedAt",
                          ])(y) 
                            ? moment(
                              R.path(["ArrivedAt"])(y)
                            ).format("DD-MM-YYYY HH:mm:ss")
                          : null}</td>

                          <td>
                            {R.path(["ImpactedTimeFrom"])(x)
                              ? moment(R.path(["ImpactedTimeFrom"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>

                          <td>
                            {R.path(["ImpactedTimeTo"])(x)
                              ? moment(R.path(["ImpactedTimeTo"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>

                          <td>{R.path(["ProviderName"])(y) || ""}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })(R.propOr([], "MarketDataList", x))}
                </React.Fragment>
              ))(
                R.pathOr(
                  [],
                  ["parentProps", "info", "history", "data"],
                  props
                ).filter(
                  (z) =>
                    moment(z.CheckTime) >= dateRangeStartDate &&
                    moment(z.CheckTime) <= dateRangeEndDate
                )
              )}
            </tbody>
          </table>
        </div>

        {
          <Scrollbars
            className="scrollFacets"
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            {R.pathOr(
              false,
              ["parentProps", "info", "data", "isFetching"],
              props
            ) ? (
              <Spinner />
            ) : (
              <React.Fragment>
                <div className="artesianTableTitleHeader">
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "ExpectationAlertName",
                    ],
                    props
                  )}{" "}
                  History, Timezone:{" "}
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "CheckTimeZone",
                    ],
                    props
                  )}
                  , Impacted Timezone:{" "}
                  {R.pathOr(
                    [],
                    [
                      "parentProps",
                      "info",
                      "history",
                      "data",
                      0,
                      "ImpactedDataRangeTMZ",
                    ],
                    props
                  )}
                </div>

                <table className="artesianTable">
                  <thead>
                    <tr>
                      <th />
                      <th>Status</th>
                      <th>Name</th>

                      <th>Check Time</th>

                      <th>Impacted From</th>
                      <th>Impacted To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {R.map((x) => (
                      <React.Fragment key={x.ID}>
                        <tr>
                          <td>
                            <div className="tableExpectationIcon">
                              <i
                                onClick={() => {
                                  props.setSelectedHistoryId(x.CheckTime);
                                  props.setPage(
                                    expectationMonitorHisoryDetailsPage
                                  );
                                }}
                                className={`fa fa-chevron-right`}
                              />
                            </div>
                          </td>

                          <td>{getStatusColour(x.Status, true)}</td>

                          <td>{x.ExpectationAlertName}</td>

                          <td>
                            {R.path(["CheckTime"])(x)
                              ? moment(R.path(["CheckTime"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>

                          <td>
                            {R.path(["ImpactedTimeFrom"])(x)
                              ? moment(R.path(["ImpactedTimeFrom"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>
                          <td>
                            {R.path(["ImpactedTimeTo"])(x)
                              ? moment(R.path(["ImpactedTimeTo"])(x)).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )
                              : null}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))(
                      R.pathOr(
                        [],
                        ["parentProps", "info", "history", "data"],
                        props
                      ).filter(
                        (z) =>
                          moment(z.CheckTime) >= dateRangeStartDate &&
                          moment(z.CheckTime) <= dateRangeEndDate
                      )
                    )}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </Scrollbars>
        }
      </div>

      <div className="historyFooterContainer">
        <div className="pageSizeSelect">
          <select
            defaultValue={R.pathOr(
              0,
              ["parentProps", "info", "data", "pageSize"],
              props
            )}
            onChange={(val) => {
              props.parentProps.setPageSizeAction(val.target.value);
              props.parentProps.requestHistoryAction(props.selectedId);
            }}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </select>
        </div>
        <PaginationComponent
          count={R.pathOr(
            null,
            ["parentProps", "info", "history", "count"],
            props
          )}
          pageSize={R.pathOr(
            0,
            ["parentProps", "info", "data", "pageSize"],
            props
          )}
          page={R.pathOr(0, ["parentProps", "info", "data", "page"], props)}
          onPageChange={(x) => {
            props.parentProps.setPageNoAction(x);
            props.parentProps.requestHistoryAction(props.selectedId);
          }}
        />
      </div>
    </React.Fragment>
  );
}
