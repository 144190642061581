import * as R from "ramda";

const { complement, isEmpty } = R;

const buildProductTypeString = (product) => {
  const offset = `+${product.offset}`;
  const gasYearString = product.gasYearChecked ? `GY${offset}` : "";
  const yearString = product.calYearChecked ? `Cal${offset}` : "";
  const separator = product.calYearChecked && product.gasYearChecked ? "," : "";

  switch (product.gridpoint) {
    case "Month":
      return `M${offset}`;
    case "Quarter":
      return `Q${offset}`;
    case "Year":
      return `${gasYearString}${separator}${yearString}`;
    case "Season":
      return `S${offset}`;
    case "Day":
      return `D${offset}`;
    case "Week":
      return `Wk${offset}`;
    case "Weekend":
      return `WkEnd${offset}`;
    default:
      return undefined;
  }
};

const buildAbsoluteProductTypeString = (product) =>
  product.productNames.map((item) => item.value).join(",");

const productToStringFn = (product) => {
  if (product.productType === "Relative") {
    return buildProductTypeString(product);
  }
  if (product.productType === "Absolute") {
    return buildAbsoluteProductTypeString(product);
  }
  return product.productType;
};

const productToString = (arr) =>
  arr
    .map(productToStringFn)
    .filter(complement(isEmpty))
    .map((x) => encodeURIComponent(x))
    .join("&p=");

const dateToString = (props, ds) => {
  switch (props.selectedRangeType) {
    case "Date Range":
      return `${ds.startDate.format("YYYY-MM-DD")}/${ds.endDate.format(
        "YYYY-MM-DD"
      )}`;
    case "Period From/To":
      return `${ds.selectPeriodFrom}/${ds.selectPeriodTo}`;
    case "Period":
      return `${ds.selectPeriod}`;
    default:
      return `${ds.range.replace(/ /g, "")}`;
  }
};

const getCustomFiller = R.pipe(
  R.toPairs,
  R.map(([k, v]) => `&${k}=${v}`),
  R.join("")
);

const buildQueryString = (props) => {
  let dateStringOptions = {
    startDate: props.startDate,
    endDate: props.endDate,
  };
  switch (props.selectedRangeType) {
    case "Relative":
      dateStringOptions = { range: props.selectedRelative };
      break;
    case "Period From/To":
      dateStringOptions = {
        selectPeriodFrom: props.selectPeriodFrom,
        selectPeriodTo: props.selectPeriodTo,
      };
      break;
    case "Period":
      dateStringOptions = {
        selectPeriod: props.selectPeriod,
      };
      break;
    default:
      break;
  }
  const dateSelect = dateToString(props, dateStringOptions);

  const curveIds = props.selectedCurves.curves
    .filter((curve) => curve.Type === "BidAsk")
    .map((curve) => curve.MarketDataId)
    .join(",");
  const dataTypeId = props.extractCustomFilterState
    ? `filterId=${props.selectedCustomFilter.id}`
    : `id=${curveIds}`;
  const products = productToString(props.productArray);

  const fillerType = `&fillerK=${props.selectedFiller}`;
  const customFillersDefault = R.isEmpty(props.fillerBAModal.row)
    ? props.fillerBAModal.default
    : props.fillerBAModal.row;
  const customFillers =
    props.selectedFiller === "CustomValue"
      ? getCustomFiller(customFillersDefault)
      : "";

  const timeZone = props.hasDailyCurves
      ? ""
      : `&tz=${props.selectedTimezone}`;

  return `${dateSelect}?p=${products}${timeZone}${fillerType}${customFillers}&${dataTypeId}`;
};

export const buildExtractionString = (props) =>
  `${props.api}/query/v1.0/ba/${buildQueryString(props)}`;

export default buildExtractionString;
