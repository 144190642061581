import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../../components";

const ClassificationDisplayLabel = (props) => (
  <div className="classificationTag" key={props.label}>
    <div
      className={`form-control label-default ${
        props.obj.curves.length === props.selectedCurveCount
          ? "allLabels"
          : "partialLabels"
      }`}
    >
      <span>{props.label || "Not Classified"}</span>
      <Button
        onClick={() => props.removeClassification(props.label, props.obj)}
        className="close close-default remove-box-shadow btn-circle pull-right"
        btnText={
          <Tooltip
            arrow
            position="bottom"
            html={<span>Remove Classification</span>}
            theme="arkDefaultTooltip"
          >
            <i className="fa fa-times" />
          </Tooltip>
        }
      />
    </div>
  </div>
);

export default ClassificationDisplayLabel;
