import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export const APIKeysList = (props) => (
  <BootstrapTable
    data={props.apiKeys}
    version="4"
    keyField="Id"
    pagination
    condensed
    options={props.tableSettings}
    containerClass="apiKeyContainer"
    tableContainerClass="apiKeyTableContainer"
    bodyContainerClass="apiKeyTableBody"
  >
    <TableHeaderColumn dataField="Id" hidden />
    <TableHeaderColumn dataField="Description" dataFormat={props.infoFormat}>
      Description
    </TableHeaderColumn>
    <TableHeaderColumn
      dataField="ExpiresAt"
      dataSort
      dataFormat={props.expiresAtFormat}
      width="140"
    >
      Expirtation
    </TableHeaderColumn>
    <TableHeaderColumn
      dataField="ExpiresAt"
      dataSort
      dataFormat={props.expiresAt}
      width="100"
    >
      Status
    </TableHeaderColumn>
    <TableHeaderColumn
      width="35"
      dataField="Id"
      dataFormat={props.dataFormat}
    />
  </BootstrapTable>
);

export default APIKeysList;
