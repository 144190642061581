import React from "react";
import { connect } from "react-redux";
import { compose, isNil, keys, equals, prop, contains, any } from "ramda";
import { withHandlers, withState, withProps } from "recompose";
import { CustomFiltersModal } from "../../curves";
import {
  closeCustomModalModalAction,
  updateCustomModalModalRowAction,
  updateCustomFilterAction,
  deleteCustomFilterAction,
  createCustomFilterAction,
  CurvesSelectors,
} from "../../redux/modules/curves";

const mapStateToProps = (state) => ({
  customFilterModal: CurvesSelectors.customFilterModal(state),
  filterPermission: prop("filter", state.Permissions.menuPermission) || [],
});

const createSelectedFilters = (labels) => {
  const labelKeys = keys(labels);
  return labelKeys.map((name) =>
    labels[name].map((val) => (
      <span
        key={`${name}:${val}`}
        className="customFilterLabel"
        aria-hidden="true"
      >
        {`${name}:${val}`}
      </span>
    ))
  );
};

export default compose(
  connect(mapStateToProps, {
    closeCustomModalModalAction,
    updateCustomModalModalRowAction,
    updateCustomFilterAction,
    deleteCustomFilterAction,
    createCustomFilterAction,
  }),
  withProps((props) => ({
    filterModalPermission: !any(equals(true))([
      contains("Creator", props.filterPermission),
      contains("Owner", props.filterPermission),
    ]),
  })),
  withState(
    "filterName",
    "updateFilterName",
    ({ customFilterModal }) => customFilterModal.row.filterName
  ),
  withState(
    "searchText",
    "updateSearchText",
    ({ customFilterModal }) => customFilterModal.row.searchText
  ),
  withState(
    "labels",
    "updateLabels",
    ({ customFilterModal }) => customFilterModal.row.labels
  ),
  withHandlers({
    updateRow: (props) => (row) => props.updateCustomModalModalRowAction(row),
    closeModal: (props) => () => props.closeCustomModalModalAction(),
    createCustomFilter: (props) => () =>
      props.createCustomFilterAction(
        props.customFilterModal.row,
        props.customFilterModal.id,
        props.customFilterModal.eTag
      ),
    updateCustomFilter: (props) => () =>
      props.updateCustomFilterAction(
        props.customFilterModal.row,
        props.customFilterModal.id,
        props.customFilterModal.eTag
      ),
    deleteCustomFilter: (props) => () =>
      props.deleteCustomFilterAction(
        props.customFilterModal.id,
        props.customFilterModal.filterName
      ),
  }),
  withProps((props) => ({
    selectedLabels: isNil(props.labels)
      ? null
      : createSelectedFilters(props.labels),
    disableUpdate:
      equals(props.customFilterModal.row, props.customFilterModal.orig) ||
      equals(props.customFilterModal.row.filterName, ""),
    disableCreate:
      equals(props.customFilterModal.row, props.customFilterModal.orig) ||
      equals(props.customFilterModal.row.filterName, "") ||
      equals(
        props.customFilterModal.row.filterName,
        props.customFilterModal.orig.filterName
      ),
  })),
  withHandlers({
    setFilterName: (props) => (val) => {
      props.updateFilterName(val);
      props.updateRow({
        ...props.customFilterModal.row,
        filterName: val,
      });
    },
  })
)(CustomFiltersModal);
