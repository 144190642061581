import React from "react";
import { Tooltip } from "react-tippy";
import moment from "moment";

const shiftTooltip = (title, value) => (
  <div className="shiftTooltip">
    <div className="shiftTooltipTitle">
      {`How the ${title} duration is applied to Todays date.`}
    </div>
    <table>
      <thead>
        <tr>
          <th colSpan="2">Today</th>
          <th>Shift</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{moment().format("YYYY-MM-DD HH:00:00")}</td>
          <td>
            <i className="fas fa-arrow-right" />
          </td>
          <td>
            {moment()
              .add(moment.duration(value))
              .format("YYYY-MM-DD HH:00:00")}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const ShiftTitle = ({ title, value }) => (
  <div>
    <b>{title}</b> -&nbsp;
    <Tooltip
      arrow
      position="top"
      html={shiftTooltip(title, value)}
      theme="shiftTooltip"
    >
      <i className="fas fa-info-circle shiftTooltipIcon" />
    </Tooltip>
  </div>
);

export default ShiftTitle;
