import { EMPTY, of } from "rxjs";
import { ofType } from "redux-observable";
import * as RxOp from "rxjs/operators";
import { LOCATION_CHANGE } from "react-router-redux";
import { combineAnalytics, setUserListener } from "./helper";
import { eventTracker } from "./eventTracker";
import { SET_AUTH } from "../authentication/index";

const { appInsights } = window;

const trackEvent = () => (action$, state$) =>
  action$.pipe(
    RxOp.exhaustMap((action) =>
      state$.pipe(
        RxOp.first(),
        RxOp.switchMap((state) => of(eventTracker({ action, state }))),
        RxOp.tap((event) =>
          event ? appInsights.trackEvent(event.name, event.properties) : event
        ),
        RxOp.switchMapTo(EMPTY)
      )
    )
  );

const trackPageView = () => (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    RxOp.exhaustMap((action) =>
      state$.pipe(
        RxOp.first(),
        RxOp.tap(() => appInsights.trackPageView(action.payload.url)),
        RxOp.switchMapTo(EMPTY)
      )
    )
  );

const setUser = setUserListener(SET_AUTH, appInsights);

const trackEventEpic = trackEvent();
const trackPageViewEpic = trackPageView();

export const epic = combineAnalytics([
  trackEventEpic,
  trackPageViewEpic,
  setUser,
]);

export default epic;
