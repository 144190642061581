import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { permissionCheck } from "./helper/permissions";

const Protected = ({
  state = {},
  component: Comp,
  permission,
  fallback: Fail = () => null,
  successProps = {},
  failProps = {},
}) =>
  R.pipe(
    R.map((p) => permissionCheck({ permission: p, state })),
    R.any(R.equals(true)),
    R.ifElse(
      R.equals(true),
      () => <Comp {...successProps} />,
      () => <Fail {...failProps} />
    )
  )(permission);

export default R.compose(connect(R.objOf("state")))(Protected);
