import React from "react";

const RenderSizePerPageDropDown = (pageSize, props) => (
  <div className="btn-group dropup artesianDropUpContainer">
    <button type="button dropUpToggle" className="btn">
      {pageSize}
    </button>
    <button
      type="button"
      className="btn dropdown-toggle artesianDropUpToggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    />
    <div className="dropdown-menu artesianDropUp">
      <span
        className="dropdown-item"
        onClick={() => props.setPageSize(50)}
        aria-hidden="true"
      >
        50
      </span>
      <span
        className="dropdown-item"
        onClick={() => props.setPageSize(100)}
        aria-hidden="true"
      >
        100
      </span>
      <span
        className="dropdown-item"
        onClick={() => props.setPageSize(250)}
        aria-hidden="true"
      >
        250
      </span>
    </div>
  </div>
);

export default RenderSizePerPageDropDown;
