export const DateOptions = [
  "Date Range",
  "Relative",
  "Period From/To",
  "Period",
];
export const PurposeOptions = ["Bid", "OFF"];
export const StatusOptions = ["ACC", "REJ", "INC", "REP", "REV", "SUB", "COM", "PCOM"];
export const ScopeOptions = [
  "NULL",
  "ACC",
  "AS",
  "CA",
  "GR1",
  "GR2",
  "GR3",
  "GR4",
  "RS",
];
export const MarketOptions = [
  "MGP",
  "MIA1", 
  "MIA2", 
  "MIA3",
  "MI1",
  "MI2",
  "MI3",
  "MI4",
  "MI5",
  "MI6",
  "MI7",
  "MSD",
  "MB",
  "MB2",
  "MB3",
  "MB4",
  "MB5",
  "MB6",
  "MBh",
  "MRR",
  "MIXBID",
  "AFRR"
];
export const BaTypeOptions = ["NULL", "NETT", "NREV", "REV"];
export const ZoneOptions = [
  "AUST",
  "BRNN",
  "CALA",
  "CNOR",
  "COAC",
  "CORS",
  "CSUD",
  "FOGN",
  "FRAN",
  "GREC",
  "MALT",
  "NORD",
  "PRGP",
  "ROSN",
  "SARD",
  "SICI",
  "SLOV",
  " SUD",
  "SVIZ",
];
export const UnitTypeOptions = ["UP", "UPV", "UC", "UCV"];
export const GenerationTypeOptions = [
  "UNKNOWN",
  "OTHER",
  "AUTOGENERATION",
  "BIOMASS",
  "COAL",
  "WIND",
  "PV",
  "GAS",
  "GASOIL",
  "THERMAL",
  "HYDRO",
  "MIXED",
  "OIL",
];
