import React from "react";
import {
  evolve,
  reject,
  any,
  propEq,
  concat,
  isEmpty,
  isNil,
  omit,
} from "ramda";
import { setErrorAction } from "../actions";

const adjustSection = (state, section, fn) =>
  evolve(
    {
      [section]: fn,
    },
    state
  );

export const addOrder = (state, section, curves) =>
  adjustSection(state, section, concat(curves));
export const removeOrder = (state, section, curves) =>
  adjustSection(
    state,
    section,
    reject((x) => any(propEq("MarketDataId", x.MarketDataId))(curves))
  );
export const updateArray = (originalArray, arrayUpdatesArray) =>
  originalArray.map(
    (originalItem) =>
      arrayUpdatesArray.find(
        (element) => element.MarketDataId === originalItem.MarketDataId
      ) || originalItem
  );

export const toggleLabelFn = (state, facet, label) => {
  const selectedLabelArray = state[facet] ? state[facet].concat() : [];
  if (selectedLabelArray.indexOf(label) > -1) {
    const res = {
      ...state,
      [facet]: selectedLabelArray.filter((val) => val !== label),
    };
    return isEmpty(res[facet]) ? omit([facet], res) : res;
  }
  return { ...state, [facet]: selectedLabelArray.concat([label]) };
};

export const dispatchNetworkError = (err) => {
  const hasCustomMsg =
    isNil(err.response) ||
    isNil(err.response.ErrorMessage) ||
    isEmpty(err.response.ErrorMessage);
  if (err.status >= 400 && err.status < 500) {
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact support for more information.
          </div>
        ),
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message: hasCustomMsg
        ? "Please refresh the page. If the issue persists, please contact support."
        : err.response.ErrorMessage,
    });
  }
  if (err.status > 0) {
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message:
        "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "An error has occurred. Please refresh the page. If the issue persists, please contact support.",
  });
};
