import React from "react";
import { compose, head, equals, map } from "ramda";
import { withHandlers, withState, withProps } from "recompose";
import {
  DropDown,
  Button,
  InputTextBox,
  MultiSelect,
} from "../../../components";
import { getPathDetails, SelectedPath3, SelectedPath2 } from "./helper";

const CurvesClassification = (props) => (
  <div className="paddingRight20 pathPermissions">
    <div>
      <h4 className="adminLabel">Add Path and Permissions</h4>
      <div className="row d-flex setPath">
        <div className="d-flex col-lg-2 col-md-12 col-sm-12 pathOption">
          <span className="adminLabel pathSplit">/</span>
          <DropDown
            selected={props.selectedPath1}
            dropDownValues={props.pathList1}
            onClick={props.setSelectedPath1}
            className="width100"
            dropID="pathDrop1"
          />
        </div>
        {props.path2Component}
        {props.path3Component}
        {props.selectedPath1 === "marketdata" &&
        props.selectedPath2 === "system" ? null : (
          <div className="d-flex col-lg-3 col-md-12 col-sm-12 pathOption">
            <span className="adminLabel pathSplit">/</span>
            <InputTextBox
              value={props.customPath}
              onChange={props.setCustomPath}
              className="customPathTextbox"
              placeholder="Custom Path"
              id="CustomPath"
            />
          </div>
        )}
      </div>
    </div>
    <div className="adminGroupSection">
      <div className="permissionsSection">
        <h5 className="adminLabel paddingRight">Permissions:</h5>
        {props.permissionCheckboxes}
      </div>
    </div>
    <div className="adminGroupSection">
      <Button
        permissionDisabled={props.canEditpathPermission}
        disabled={props.disableAddPermission}
        btnText="Add Path Permissions"
        className="addPermissionsBtn"
        onClick={props.setPermission}
      />
    </div>
  </div>
);

const enhancer = compose(
  withProps(() => ({
    onlyCustomPath1: ["marketdata"],
    onlyCustomPath2: ["system"],
  })),
  withState("userPermission", "updateUserPermission", []),
  withState("addPermission", "updatePermission", ""),
  withState("customPath", "updateCustomPath", ""),
  withState("selectedPath1", "updateSelectedPath1", ({ pathList1 }) =>
    head(pathList1)
  ),
  withState("selectedPath2", "updateSelectedPath2", ({ pathList2 }) =>
    head(pathList2)
  ),
  withState("selectedPath3", "updateSelectedPath3", ""),
  withState("userRole", "updateUserRoles", ({ userRolesList }) =>
    head(userRolesList)
  ),
  withHandlers({
    setDefault: (props) => () => {
      props.updateUserPermission([]);
      props.updateCustomPath("");
      props.updateSelectedPath1(head(props.pathList1));
      props.updateSelectedPath2(head(props.pathList2));
      props.updateSelectedPath3(head(props.allProviders));
      props.updateUserRoles(head(props.userRolesList));
    },
  }),
  withHandlers({
    setSelectedPath1: (props) => (val) => props.updateSelectedPath1(val),
    setSelectedPath2: (props) => (val) => props.updateSelectedPath2(val),
    setSelectedPath3: (props) => (val) => props.updateSelectedPath3(val),
    setCustomPath: (props) => (val) => props.updateCustomPath(val),
    setUserPermission: (props) => (permission) =>
      props.updateUserPermission(permission),
    setUserRoles: (props) => (val) => props.updateUserRoles(val),
    setPermission: (props) => () => getPathDetails(props),
  }),
  withProps((props) => ({
    userRolesOptions: map((x) => ({ value: x, label: x }), props.userRolesList),
  })),
  withProps((props) => ({
    path2Component: <SelectedPath2 {...props} />,
    path3Component: <SelectedPath3 {...props} />,
    disableAddPermission: equals(props.userPermission.length, 0),
    permissionCheckboxes: (
      <MultiSelect
        name="MultiSelect"
        value={props.userPermission}
        change={props.setUserPermission}
        options={props.userRolesOptions}
        noOptionsMessage={() => "All Permissions Selected"}
        closeOnSelect={false}
      />
    ),
  }))
);

export default enhancer(CurvesClassification);
