import React from "react";
import { equals } from "ramda";
import DatePicker from "react-date-picker";
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../components";

export const CreateAPIKeysContainers = (props) => (
  <div className="createApiContainer">
    <div className="header">
      <h3>Create a new API Key</h3>
    </div>
    <div className="inputDiv">
      <span className="inputLabels">Description: </span>
      <textarea
        maxLength="250"
        id="curveApiTextbox"
        className="form-control"
        value={props.description}
        onChange={props.setDescription}
      />
    </div>
    <div className="inputDiv" id="curveApiExpiration">
      <span className="inputLabels">Expiration Date</span>
      <div className="userInputSelection">
        <input
          type="checkbox"
          name="expireDate"
          checked={equals(props.expirationDateSelected, "expireDate")}
          onChange={() => props.updateExpirationDateSelected("expireDate")}
        />
        <DatePicker
          value={props.expirationDate.toDate()}
          onChange={props.setExpirationDate}
          className="calendarInput"
          format="dd-MM-yyyy"
          calendarClassName="calendarStyle"
          disabled={equals(props.expirationDateSelected, "expiryNever")}
          minDate={moment()
            .add(1, "days")
            .toDate()}
        />
      </div>
      <div>
        <label className="labelInput">
          <input
            type="checkbox"
            name="expiryNever"
            id="expiryNever"
            checked={equals(props.expirationDateSelected, "expiryNever")}
            onChange={() => props.updateExpirationDateSelected("expiryNever")}
          />
          Never Expires
        </label>
      </div>
    </div>
    <div className="inputDiv footer">
      <Button
        btnText="Create"
        id="curveApiConfirm"
        onClick={props.createNewKey}
        disabled={props.disableCreateBtn}
      />
    </div>
  </div>
);

export default CreateAPIKeysContainers;
