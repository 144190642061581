import { combineReducers } from "redux";
import { simpleSetReducerCreator } from "../../reducerCreators";

const SHOW_NAVIGATION_MODAL = "artesian-ui/menu/SHOW_NAVIGATION_MODAL";
const HIDE_NAVIGATION_MODAL = "artesian-ui/menu/HIDE_NAVIGATION_MODAL";
const MENU_PERMISSION = "artesian-ui/menu/MENU_PERMISSION";
export const SELECT_CURVES = "artesian-ui/menu/SELECT_CURVES";
export const DELIVERY_DASHBOARD = "artesian-ui/menu/DELIVERY_DASHBOARD";
export const REFINE_BASKET = "artesian-ui/menu/REFINE_BASKET";
export const CURVE_CLASSIFICATION = "artesian-ui/menu/CURVE_CLASSIFICATION";
export const EXTRACT_CURVES = "artesian-ui/menu/EXTRACT_CURVES";
export const ENRICH_METADATA = "artesian-ui/menu/ENRICH_METADATA";
export const ENRICH_TIME_TRANSFORM = "artesian-ui/menu/ENRICH_TIME_TRANSFORM";
export const ADMIN_GROUP = "artesian-ui/menu/ADMIN_GROUP";
export const ADMIN_USER = "artesian-ui/menu/ADMIN_USER";

const navigationModalDefaultState = {
  showModal: false,
  modalMessage: "",
};
const navigationModalState = (state = navigationModalDefaultState, action) => {
  switch (action.type) {
    case SHOW_NAVIGATION_MODAL:
      return {
        ...state,
        showModal: true,
        modalMessage: action.state.msg,
      };
    case HIDE_NAVIGATION_MODAL:
      return navigationModalDefaultState;
    default:
      return state;
  }
};

const selectedMenuItem = (state = "selectCurves", action) => {
  switch (action.type) {
    case SELECT_CURVES:
      return "selectCurves";
    case DELIVERY_DASHBOARD:
      return "deliveryDashboard";
    case REFINE_BASKET:
      return "refineBasket";
    case CURVE_CLASSIFICATION:
      return "classifyCurves";
    case EXTRACT_CURVES:
      return "extractCurves";
    case ENRICH_METADATA:
      return "enrichMetaData";
    case ENRICH_TIME_TRANSFORM:
      return "enrichTimeTransform";
    case ADMIN_GROUP:
      return "groupAdmin";
    case ADMIN_USER:
      return "userAdmin";
    default:
      return state;
  }
};

export default combineReducers({
  menuPermission: simpleSetReducerCreator({
    setAction: MENU_PERMISSION,
    prop: "permission",
    initialState: [],
  }),
  selectedMenuItem,
  navigationModalState,
});

const showNavigationModal = (state) => ({
  type: SHOW_NAVIGATION_MODAL,
  state,
});
const hideNavigationModal = () => ({
  type: HIDE_NAVIGATION_MODAL,
});

export const setSelectedMenuItemAction = (val) => {
  switch (val) {
    case "selectCurves":
      return {
        type: SELECT_CURVES,
        page: "Curves"
      };
    case "deliveryDashboard":
      return {
        type: DELIVERY_DASHBOARD,
        page: "Delivery_Dashboard"
    };
    case "refineBasket":
      return {
        type: REFINE_BASKET,
        page: "Refine_Basket"
      };
    case "classifyCurves":
      return {
        type: CURVE_CLASSIFICATION,
        page: "Curve_Classification"
      };
    case "extractCurves":
      return {
        type: EXTRACT_CURVES,
        page: "Extract_Curves"
      };
    case "enrichMetaData":
      return {
        type: ENRICH_METADATA,
        page: "Enrich_Metadata"
      };
    case "enrichTimeTransform":
      return {
        type: ENRICH_TIME_TRANSFORM,
        page: "Enrich_Time_Transform"
      };
    case "groupAdmin":
      return {
        type: ADMIN_GROUP,
        page: "Admin_Group"
      };
    case "userAdmin":
      return {
        type: ADMIN_USER,
        page: "User_Admin"
      };
    default:
      return {
        type: SELECT_CURVES,
        page: "Select_Curves"
      };
  }
};

export const showNavigationModalAction = (state) => [
  showNavigationModal(state),
];
export const closeNavigationModalAction = () => [hideNavigationModal()];
