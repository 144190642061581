import React from "react";

const TextNumberInput = (props) => (
  <input
    type="number"
    className={`form-control ${props.className}`}
    placeholder={props.placeholder}
    value={props.value}
    min={props.min}
    max={props.max}
    id="inputGroup"
    onChange={(val) => props.onChange(val.target.value)}
  />
);

export default TextNumberInput;
