import React from "react";
import { connect } from "react-redux";
import { compose, concat, equals, contains, any, prop } from "ramda";
import { lifecycle, withProps, withHandlers, withState } from "recompose";
import { EnrichTimeTransform } from "../../enrichTimeTransform";
import { RenderSizePerPageDropDown, Button } from "../../components";
import {
  getAllTimeTransformDataAction,
  openDeleteModalAction,
  openEditModalAction,
  closeEditModalAction,
} from "../../redux/modules/enrichTimeTransform";
import {
  tutorialOnAction,
  tutorialOffAction,
} from "../../redux/modules/tutorial";

const mapStateToProps = (state) => ({
  ...state.Curves,
  ...state.EnrichTimeTransform,
  ...state.tutorial,
  timetransformPermission:
    prop("timetransform", state.Permissions.menuPermission) || [],
});

export default compose(
  connect(mapStateToProps, {
    getAllTimeTransformDataAction,
    openEditModal: openEditModalAction,
    closeEditModal: closeEditModalAction,
    openDeleteModalAction,
    tutorialOn: tutorialOnAction,
    tutorialOff: tutorialOffAction,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAllTimeTransformDataAction();
    },
  }),
  withProps((props) => ({
    createTTPermission: !any(equals(true))([
      contains("Creator", props.timetransformPermission),
      contains("Owner", props.timetransformPermission),
    ]),
    canEditTTPermission: !any(equals(true))([
      contains("Contributor", props.timetransformPermission),
      contains("Editor", props.timetransformPermission),
      contains("Owner", props.timetransformPermission),
    ]),
    canDeleteTTPermission: !any(equals(true))([
      contains("Editor", props.timetransformPermission),
      contains("Owner", props.timetransformPermission),
    ]),
  })),
  withState("pageSize", "updatePageSize", 50),
  withProps((props) => ({
    transformValues: concat(
      props.systemTransformValues,
      props.userTransformValues
    ),
  })),
  withHandlers({
    setPageSize: (props) => (size) => props.updatePageSize(size),
    deleteRow: (props) => (row) => props.openDeleteModalAction(row.ID),
    openAddNewRow: (props) => () => {
      props.openEditModal({
        title: (
          <div className="timeTransformModalTitle">
            <span>Add Time Transform - </span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
            >
              What is a Period Duration?
            </a>
          </div>
        ),
        type: "Add Transform",
        row: {
          Name: "",
          Period: "",
          PositiveShift: "",
          NegativeShift: "",
        },
      });
    },
    openEditRow: (props) => (row) =>
      props.openEditModal({
        title: (
          <div className="timeTransformModalTitle">
            <span>Update Time Transform - </span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
            >
              What is a Period Duration?
            </a>
          </div>
        ),
        type: "Update Transform",
        row: {
          ID: row.ID,
          Name: row.Name,
          Period: row.Period,
          PositiveShift: row.PositiveShift,
          NegativeShift: row.NegativeShift,
          Type: row.Type,
          ETag: row.ETag,
          DefinedBy: row.DefinedBy,
        },
      }),
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(props.pageSize, props),
  }),
  withProps((props) => ({
    tableSettings: {
      sizePerPage: props.pageSize,
      sizePerPageDropDown: props.renderDropDown,
    },
    dataFormat: (cell, row) => (
      <div>
        <Button
          onClick={() => props.openEditRow(row)}
          className={`basicEditBtn ${
            row.mod === "Delete" || row.mod === "Add Mapping" ? " d-none" : ""
          }`}
          btnText={<i className="fas fa-pencil-alt" />}
          permissionDisabled={props.canEditTTPermission}
          disabled={equals(row.DefinedBy, "System")}
        />
        <Button
          onClick={() => props.deleteRow(row)}
          className={`basicEditBtn ${row.mod === undefined ? "" : " d-none"}`}
          btnText={<i className="fas fa-trash" />}
          permissionDisabled={props.canDeleteTTPermission}
          disabled={equals(row.DefinedBy, "System")}
        />
      </div>
    ),
  }))
)(EnrichTimeTransform);
