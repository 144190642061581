import React from "react";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import * as R from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import { getStatusColour } from "../expectationAlertDashboard/helper";
import moment from "moment";

const expectationMonitorPage = "dashboard";
const expectationMonitorHisoryPage = "history";

const HeaderButton = ({ title, onClick }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{title}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="filterBtn"
      onClick={() => onClick()}
      btnText={<span className="filterBtnText">{title}</span>}
    />
  </Tooltip>
);
function paginate(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export default function AlertDetails(props) {
  var titleName;
  R.map(
    (x) =>
      props.selectedId == R.path(["AlertState", "ID"])(x) &&
      (titleName = R.path(["AlertState", "LiveCheck", "ExpectationAlertName"])(
        x
      ))
  )(R.pathOr([], ["parentProps", "info", "data", "data"], props));

  const alertDetailsList = R.pipe(R.pathOr([], ["parentProps", "info", "data", "data"]), R.find(R.propEq( "ID",props.selectedId)), R.path(["AlertState", "LiveCheck", "MarketDataList"]))(props)
  return (
    <React.Fragment>
      <div className="artesianTableContainer">
        <div className="tableFilter">
          <HeaderButton
            onClick={() => props.setPage(expectationMonitorPage)}
            title="Back"
          />
          <div className="filterBtnContainer">
            <HeaderButton
              onClick={() => {
                props.parentProps.requestHistoryAction(props.selectedId);
                props.setPage(expectationMonitorHisoryPage);
              }}
              title="Alert History"
            />
            <HeaderButton
              onClick={() =>
                props.parentProps.openModal(
                  "Add Selected MarketData",
                  true,
                  props.selectedId,
                  "addSelectedCurves"
                )
              }
              title="Add Selected ID's"
            />
            <div></div>
          </div>
        </div>
        {
          <Scrollbars
            className="scrollFacets"
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            <div className="artesianTableTitleHeader">{titleName} Details</div>
            {alertDetailsList.length > 0 ? (
              <table className="artesianTable">
                <thead>
                  <tr>
                    <th />
                    <th>Status</th>
                    <th>MarketDataName</th>
                    <th>MarketDataID</th>
                    <th>Arrived At</th>
                  </tr>
                </thead>

                <tbody>
                  {R.map((x) => (
                    <React.Fragment key={x.ID}>
                      {R.map((y) => (
                        <React.Fragment>
                          {props.selectedId ==
                          R.path(["AlertState", "ID"])(x) ? (
                            <tr key={y.MarketDataID}>
                              <td>
                                <div className="tableExpectationIcon">
                                  <i
                                    onClick={() =>  props.parentProps.deleteMarketDataIdsAction(R.path(["AlertState", "ID"])(x), [
                                         y.MarketDataID,
                                       ])
                                    }
                                    className={`fa fa-trash`}
                                  />
                                </div>
                              </td>
                              <td>{getStatusColour(y.Status)}</td>
                              <td>{y.MarketDataName}</td>
                              <td>{y.MarketDataID}</td>
                              <td>
                                {R.path(["ArrivedAt"])(y)
                                  ? moment(R.path(["ArrivedAt"])(y)).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )
                                  : null}
                              </td>
                            </tr>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                      ))(
                        R.path(["AlertState", "LiveCheck", "MarketDataList"])(x)
                      )}
                    </React.Fragment>
                  ))(
                    R.pathOr([], ["parentProps", "info", "data", "data"], props)
                  )}
                </tbody>
              </table>
            ) : (
              <div className="scheduleContainer">
                <div className="centerAlertDetailText">
                  <div>No curves associated to alert</div>
                  <div>
                    Please navigate to Market Data Page and select curves to add
                  </div>
                  <div>
                    Once desired curves are selected navigate back here and
                    click
                  </div>
                  <div>
                    <HeaderButton
                      onClick={() =>
                        props.parentProps.openModal(
                          "Add Selected MarketData",
                          true,
                          props.selectedId,
                          "addSelectedCurves"
                        )
                      }
                      title="Add Selected ID's"
                    />
                  </div>
                </div>
              </div>
            )}
          </Scrollbars>
        }
      </div>
    </React.Fragment>
  );
}
