import React from "react";
import { Tooltip } from "react-tippy";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "../components";
import SortableList from "./components/sortableList";

const CurveTable = (props) => (
  <BootstrapTable
    printable
    key={props.dynamicTableKey}
    data={props.curveData}
    pagination
    fetchInfo={{ dataTotalSize: props.tablesOptions.curveCount }}
    options={props.tableSettings}
    selectRow={props.selectRowOptions}
    remote
    keyField="MarketDataId"
    version="4"
    containerClass="curveContainer"
    tableContainerClass="curveTableContainer"
    bodyContainerClass="curveTableBody"
  >
    <TableHeaderColumn
      dataField="infoCol"
      className="infoColClass"
      columnClassName="infoColClass"
      formatExtraData="infoCol"
      dataAlign="center"
      width="40px"
      dataFormat={props.dataFormat}
    >
      <Button
        onClick={() => props.toggleSettings()}
        className="curvesTableSettingsBtn"
        btnText={
          <Tooltip
            arrow
            trigger="manual"
            open={props.saveOpenSettings}
            onRequestClose={props.clearTableOrder}
            interactive
            duration={0}
            stickyDuration={0}
            hideOnClick
            touchHold
            html={
              <SortableList
                items={props.items}
                setItems={props.setItems}
                setHidden={props.setHidden}
                saveTableOrder={props.saveTableOrder}
                resetTableOrder={props.resetTableOrder}
                clearTableOrder={props.clearTableOrder}
                {...props}
              />
            }
            theme="tooltipIcon settings"
          >
            <i className="fas fa-bars" aria-hidden="true" />
          </Tooltip>
        }
      />
    </TableHeaderColumn>
    <TableHeaderColumn
      dataField="previewCol"
      keyField="previewCol"
      className="previewColClass"
      columnClassName="previewColClass"
      width="40px"
      dataAlign="center"
      dataFormat={props.curvePreview}
    >
      &nbsp;
    </TableHeaderColumn>
    {props.curveTableConfigSettings.curveTable.map((col) => (
      <TableHeaderColumn
        dataField={col.dataField}
        headerTitle={false}
        key={col.dataField}
        dataFormat={props.dataFormat}
        formatExtraData={col.dataField}
        width={col.width}
        hidden={col.hidden}
      >
        {col.title}
      </TableHeaderColumn>
    ))}
  </BootstrapTable>
);

export default CurveTable;
