import { createSelector } from "reselect";

export default (state) => state;

export const environmentSelector = createSelector(
  ({ environmentSettings }) => environmentSettings,
  (x) => x
);
export const authenticationSelector = createSelector(
  ({ authentication }) => authentication,
  (x) => x
);
