import React from "react";

const FacectTextBox = (props) => (
  <div className="input-group facetSearchContainer">
    <input
      type="text"
      className="form-control"
      placeholder="Search"
      id="inputGroup"
      value={props.filteredFacetText}
      onChange={(e) => {
        props.updateFacetFilterTextPlusFilter(e.target.value);
      }}
    />
    <span
      className="input-group-addon"
      onClick={props.facetsFilterClear}
      aria-hidden="true"
    >
      <i
        className={
          props.filteredFacetText ? "fa fa-times danger" : "fa fa-search"
        }
      />
    </span>
  </div>
);

export default FacectTextBox;
