import { combineReducers } from "redux";
import { compose, ap, of, mergeAll } from "ramda";
import * as RX from "rxjs";
import { tap, flatMap, map as RxMap, switchMap, toArray } from "rxjs/operators";
import {
  fetchingReducerCreator,
  simpleSetReducerCreator,
} from "../../reducerCreators";
import { auth } from "../../../appContext";
import { Selectors as TenantSelectors } from "../tenantSelection";
import { requestPALPermission } from "./request";
import { dispatchNetworkError } from "./helper";

const FETCHING = "artesian-ui/permissions/FETCHING";
const RECIEVED = "artesian-ui/permissions/RECIEVED";
const MENU_PERMISSION = "artesian-ui/permissions/MENU_PERMISSION";

export default combineReducers({
  fetchingMenuPermissions: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
  }),
  menuPermission: simpleSetReducerCreator({
    setAction: MENU_PERMISSION,
    prop: "permission",
    initialState: [],
  }),
});

const fetchingData = () => ({
  type: FETCHING,
});
const receivedData = () => ({
  type: RECIEVED,
});
const receivedPermission = (permission) => ({
  type: MENU_PERMISSION,
  permission: mergeAll(permission),
});

const tokenFromState = compose(auth.getToken, TenantSelectors.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: TenantSelectors.api(state),
    }))
  );

const dataRecieved = compose(ap([receivedPermission, receivedData]), of);
const handleNetworkError = compose(
  ap([dispatchNetworkError, receivedData]),
  of
);
const permissionInfo = [
  { name: "filter", url: "/filter/" },
  { name: "apiKey", url: "/apikey/" },
  { name: "groups", url: "/principal/group/" },
  { name: "timetransform", url: "/timetransform/" },
  { name: "expectation", url: "/expectation/" },
  { name: "gmepublicoffer", url: "/gmepublicoffer/" },
  { name: "globalPath", url: "/" },
];

export const getPermissionRequestAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingData)),
      flatMap(tokenApiFromState),
      switchMap(({ token, api }) =>
        RX.of(permissionInfo).pipe(
          flatMap((x) => x),
          flatMap(
            (permission) =>
              requestPALPermission({
                permission,
                token,
                api,
              }),
            2
          ),
          toArray()
        )
      )
    )
    .subscribe(
      compose(dispatch, dataRecieved),
      compose(dispatch, handleNetworkError)
    );
