import * as R from "ramda";

export const getTypeData = ({ data, type }) =>
  R.reduce((a, b) => (b.Type === type ? R.append(b, a) : a), [], data);

export const removeSelectedCurvesCheck = ({
  selectedMenuItem,
  extractCustomFilterState,
}) =>
  R.equals("groupAdmin", selectedMenuItem) ||
  R.equals("userAdmin", selectedMenuItem) ||
  R.equals("enrichTimeTransform", selectedMenuItem) ||
  (R.equals("extractCurves", selectedMenuItem) &&
    R.equals(extractCustomFilterState, true));

export const pageSelect = ({ count, dataCount }) =>
  R.equals(count, dataCount) ? "selectCurves" : "extractCurves";

export const updateSelectedDefault = {
  page: null,
  options: false,
  dataType: null,
};

export default getTypeData;
