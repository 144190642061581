import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { InputNumberTextBox } from "../../../components";

const ModalAlert = ({ row, defaultRow, showModal, confirm, close }) => {
  const [state, setState] = useState(R.isEmpty(row) ? defaultRow : row);

  useEffect(() => {
    setState(R.isEmpty(row) ? defaultRow : row);
  }, [showModal]);

  return (
    <div>
      <div
        className={`modal fade show ${showModal ? "d-block" : ""}`}
        id="errorModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={`modalOverlay ${showModal ? "fadeIn" : ""}`}
          id="errorModalOverlay"
          onClick={() => close()}
        />
        <div className="modal-dialog artesainModal" role="document">
          {showModal ? (
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title">Add Custom Fillers</h5>
              </div>
              <div className="modal-body text-center">
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Open</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVo}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVo: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Close</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVc}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVc: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>High</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVh}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVh: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Low</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVl}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVl: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Volume Paid</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVvp}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVvp: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Volume Given</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVvg}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVvg: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group `}>
                  <div>
                    <label htmlFor="select">
                      <b>Volume Total</b>
                    </label>
                    <InputNumberTextBox
                      className="artesianInputNumberTextBox lastInput"
                      value={state.fillerDVvt}
                      min={0}
                      onChange={(x) =>
                        setState({
                          ...state,
                          fillerDVvt: R.isEmpty(x) ? null : x,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn confirmClose"
                  onClick={() => confirm(state)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn modalClose"
                  onClick={() => close()}
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ModalAlert;
