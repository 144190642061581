import * as R from "ramda";

const stateToPermissions = R.pipe(
  R.pathOr("", ["Permissions", "menuPermission"]),
  R.filter((row) => !R.isEmpty(row)),
  R.keys
);

export const permissionCheck = ({ state, permission }) =>
  R.pipe(
    (x) => stateToPermissions(x),
    R.any(R.test(new RegExp(permission)))
  )(state);

export default { permissionCheck };
