import React, { useEffect } from "react";
import { Tooltip } from "react-tippy";
import ErrorsComp from "./components/errors";
import { Button } from "../../components";

const CurvesMarketAssessment = (props) => {
  useEffect(() => {
    props.setExtractionFilters({
      prop: "mas",
      filters: {},
    });
  }, []);
  return (
    <div className="card extractCurvesContainer">
      <div
        className="card-header"
        id="masTs"
        data-toggle="collapse"
        data-target="#masTsTarget"
        aria-expanded="true"
        aria-controls="masTsTarget"
        onClick={() => props.updateSelectedExtraction("masComponent")}
      >
        <i className={`fas fa-${props.selected ? "minus" : "plus"}-circle`} />
        Market Assessment
      </div>
      <div
        id="masTsTarget"
        className={`collapse ${props.isFirst ? "show" : ""}  masTsTarget`}
        aria-labelledby="masTs"
        data-parent="#extractionAccordian"
      >
        <div className="card-body extractCurvesBody">
          <div className="extractionContainer">
            <div className="extraction">
              <div className="selection">
                <div className="formLabel">
                  {props.selectedRangeType === "Date Range" ||
                  props.selectedRangeType === "Period From/To" ? (
                    <Tooltip
                      arrow
                      position="bottom"
                      html={
                        <span>"To" date is exclusive and not inclusive</span>
                      }
                      theme="arkDefaultTooltip"
                    >
                      Date&nbsp;Options&nbsp;
                      <i className="fas fa-info-circle dateInfoIcon" />
                    </Tooltip>
                  ) : (
                    <>Date&nbsp;Options</>
                  )}
                </div>
                <div className="flex-row VSTDateOptionsTypeComponent">
                  <div className="flex-col typeOption">
                    {props.timeSeriesDateTypeDropDown}
                  </div>
                  <div className="flex-col  type">
                    {props.selectedRangeTypeComponent}
                  </div>
                </div>
              </div>
              {props.hasDailyCurves ? null : (
                <div className="selection">
                  <div className="formLabel">Timezone</div>
                  <div className="noPadding typeOption" id="timezoneDropdown">
                    {props.timezoneDropDown}
                  </div>
                </div>
              )}

              <div className="selection">
                <div className="formLabel">Filler</div>
                <div className="flex-row VSTDateOptionsTypeComponent">
                  <div className="noPadding typeOption" id="fillerDropdown">
                    {props.fillerDropDown}
                  </div>
                  <div className="flex-col type fillerType">
                    {props.selectedFiller === "CustomValue"
                      ? props.customFillerComponent
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.product}
          <div className="copyToClipboard noselect">
            <input
              className="form-control noselect"
              value={props.urlString}
              readOnly
            />
            <Tooltip
              arrow
              open={props.copyClip}
              trigger="manual"
              duration={400}
              html={<span>Copied To Clipboard</span>}
              theme="arkDefaultTooltip"
            >
              <Button
                className="copyToClipboardBtn"
                id={`atsCopy${props.isVts ? "IsVts" : ""}`}
                onClick={props.copyURL}
                btnText={
                  <span>
                    <i className="fa fa-clipboard" /> &nbsp;
                  </span>
                }
              />
            </Tooltip>
          </div>

          <ErrorsComp errors={props.errors} />
          <div className="extractCurvesDownload no-Border">
            {/* {props.excelTypeDropDown} */}
            <div>
              <Button
                className="downloadBtn"
                onClick={props.downloadXLSX}
                id={`atsDownload${props.isVts ? "IsVts" : ""}`}
                disabled={
                  props.errors.granularity.length > 0 &&
                  props.errors.aggregationRule.length > 0
                }
                btnText={
                  <span>
                    <i className="far fa-file-excel" /> &nbsp;
                    <span className="d-inline">Download Excel</span>
                  </span>
                }
              />
            </div>
          </div>
          <div className="extractCurvesTutorial ">
            <Button
              className="artesianIconHover"
              onClick={props.openTutorial}
              btnText={
                <span>
                  <i className="fas fa-question" /> &nbsp;
                </span>
              }
              disabled={props.validCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurvesMarketAssessment;
