import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

export const CreateApiKeySuccess = ({ cancel, copy, keyString, copyClip }) => (
  <div className="statusApiKey">
    <div>
      <i className="fas fa-check sucess" />
    </div>
    <h4 className="text-center">New API Key has been created</h4>
    <h5 className="text-center note">
      Please ensure to copy your token now. You will not be able to view it
      again
    </h5>
    <input className="form-control keystring" value={keyString} />
    <div className="text-right">
      <Tooltip
        arrow
        open={copyClip}
        trigger="manual"
        duration={400}
        html={<span>Copied To Clipboard</span>}
        theme="arkDefaultTooltip"
      >
        <Button
          id="copyApiKey"
          onClick={copy}
          btnText={
            <span>
              <i className="fa fa-clipboard" /> &nbsp;
              <span className="d-inline">Copy Clipboard</span>
            </span>
          }
        />
      </Tooltip>
      <Button
        id="createNew"
        onClick={cancel}
        btnText={
          <span>
            <i className="fa fa-plus-square" /> &nbsp;
            <span className="d-inline">Create New Key</span>
          </span>
        }
      />
    </div>
  </div>
);
export default CreateApiKeySuccess;
