import { isEmpty, pathOr } from "ramda";
import rootSelector from "./rootSelector";
import { setOptions } from "../tenantSelection";

export const SET_AUTH = "ArkApp/authentication/SET_AUTH";
export const SET_B2C_AUTH = "ArkApp/authentication/SET_B2C_AUTH";
export const SET_EMAIL = "ArkApp/authentication/SET_EMAIL";
export const SET_NAME = "ArkApp/authentication/SET_NAME";

export function authenticationReducer(state = {
}, action) {
  switch (action.type) {
    case SET_AUTH:
      return action.payload;
    case SET_NAME:
      return { ...state, profile: { ...state.profile, name: action.name } };
    case SET_EMAIL:
      return { ...state, profile: { ...state.profile, email: action.email } };
    case SET_B2C_AUTH:{
      return { 
          profile: {
            name: pathOr("", ["authVal", "name"], action),
            email:  pathOr("", ["authVal", "idTokenClaims", "emails"], action),
          
        }}
      };
    default:
      return state;
  }
}

export const setAuth = auth => [
  { type: SET_AUTH, payload: auth },
  isEmpty(auth)
    ? null
    : setOptions(auth.profile["http://ark-energy.eu/claims/permissions"] || [])
];


export const setNameAction = name => ({ type: SET_NAME, name: name });
export const setEmailAction = email => ({ type: SET_EMAIL, email: email });

export const setB2CAuthAction = auth => ({ type: SET_B2C_AUTH, authVal: auth });


export const Selectors = {
  all: rootSelector
};

export default authenticationReducer;
