import React, {useEffect} from "react";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import * as R from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import moment from "moment";
import { getStatusColour } from "../expectationAlertDashboard/helper";

const HeaderButton = ({ title, onClick }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{title}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="filterBtn"
      onClick={onClick}
      btnText={<span className="filterBtnText">{title}</span>}
    />
  </Tooltip>
);

//********************************
export default function Dashboard(props) {
  const expectationMonitorAlertsPage = "alerts";
  useEffect(() => {
    props.parentProps.requestAction();
    const interval = setInterval(() => {
      props.parentProps.requestPollAction();
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  return (
    <React.Fragment>
      <div className="artesianTableContainer">
        <div className="tableFilter">
          <HeaderButton
            onClick={() =>
              props.parentProps.openModal("Configure Expectation", true, null, "addAlert")
            }
            title="Add Alert"
          />
          <div className="filterBtnContainer">
            {R.pathOr(
              false,
              ["parentProps", "info", "data", "isPolling"],
              props
            ) ? (
              <div className="fas fa-spinner fa-pulse pollingSpinner" />
            ) : (
              <div />
            )}
            <SearchTextBox
              filterText={R.pathOr(
                "",
                ["parentProps", "info", "data", "filter"],
                props
              )}
              updateFilterTextPlusFilter={(filter) =>
                props.parentProps.setExpectationDashboardFilterAction(filter)
              }
            />
          </div>
        </div>
        {R.pathOr(
          false,
          ["parentProps", "info", "data", "isFetching"],
          props
        ) ? (
          <Spinner />
        ) : (
          <Scrollbars
            className="scrollFacets"
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            <table className="artesianTable">
              <thead>
                <tr>
                  <th className="artesianTableTitleHeader">Alert List</th>
                </tr>
              </thead>
            </table>
            <table className="artesianTable">
              <thead>
                <tr>
                  <th />
                  <th>Current Status</th>
                  <th>Last Status</th>
                  <th>Alert Name</th>
                  <th>Timezone</th>
                  <th>Check Time</th>
                  <th>Impacted Timezone</th>
                  <th>Impacted From</th>
                  <th>Impacted To</th>
                </tr>
              </thead>
              <tbody>
                {R.map((x) =>(
                  <React.Fragment key={x.ID}>
                    <tr>
                      <td>
                        <div className="tableExpectationIcon">
                          <i
                            onClick={() => {
                                props.setSelectedId(x.ID);
                                props.setSelectedAlert(x);
                                props.setPage(expectationMonitorAlertsPage);
                            }}
                            className={`fa fa-chevron-right`}
                          />
                          <i
                            onClick={() =>
                              props.parentProps.openModal(
                                "Delete Alert",
                                true,
                                x.ID,
                                "deleteAlert"
                              )
                            }
                            className={`fa fa-trash`}
                          />
                        </div>
                      </td>
                      <td>{getStatusColour(x.MarketDataList.length>0 ? x.AlertState.LiveStatus: "NoCurves", true)}</td>
                      <td>{getStatusColour(x.AlertState.LastStatus)}</td>
                      <td>{x.Name}</td>
                      <td>
                        {R.path(["AlertState", "LiveCheck", "CheckTimeZone"])(
                          x
                        ) || ""}
                      </td>
                      <td>
                        {R.path(["AlertState", "LiveCheck", "CheckTime"])(x)
                          ? moment(
                              R.path(["AlertState", "LiveCheck", "CheckTime"])(
                                x
                              )
                            ).format("DD-MM-YYYY HH:mm:ss")
                          : null}
                      </td>
                      <td>
                        {R.path([
                          "AlertState",
                          "LiveCheck",
                          "ImpactedDataRangeTMZ",
                        ])(x)}
                      </td>
                      <td>
                        {R.path([
                          "AlertState",
                          "LiveCheck",
                          "ImpactedTimeFrom",
                        ])(x)
                          ? moment(
                              R.path([
                                "AlertState",
                                "LiveCheck",
                                "ImpactedTimeFrom",
                              ])(x)
                            ).format("DD-MM-YYYY HH:mm:ss")
                          : null}
                      </td>
                      <td>
                        {R.path(["AlertState", "LiveCheck", "ImpactedTimeTo"])(
                          x
                        )
                          ? moment(
                              R.path([
                                "AlertState",
                                "LiveCheck",
                                "ImpactedTimeTo",
                              ])(x)
                            ).format("DD-MM-YYYY HH:mm:ss")
                          : null}
                      </td>
                    </tr>
                  </React.Fragment>
                ))(
                  R.pathOr([], ["parentProps", "info", "data", "data"], props)
                )}
              </tbody>
            </table>
          </Scrollbars>
        )}
      </div>
      <div className="standardPaginationControls">
        <div>{/* {props.renderDropDown(props)} */}</div>
        <PaginationComponent
          count={R.pathOr(
            null,
            ["parentProps", "info", "data", "count"],
            props
          )}
          pageSize={10}
          page={R.pathOr(0, ["parentProps", "info", "data", "page"], props)}
          onPageChange={(x) => props.parentProps.setPageNoAction(x)}
        />
      </div>
    </React.Fragment>
  );
};
