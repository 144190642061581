import { map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import * as R from "ramda";
import platform from "platform";

export const requestPermission = ({ token, api }) =>
  ajax({
    url: `${api}/v2.1/acl/me?path=${encodeURIComponent("/")}`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` 
    },
  }).pipe(map((x) => x.response));

const getRole = R.unless(
  R.isEmpty,
  R.pipe(R.head, R.prop("Roles"), R.map(R.prop("Role")), R.uniq)
);

export const requestPALPermission = ({ permission, token, api }) => {
  const { name, url } = permission;
  return ajax({
    url: `${api}/v2.1/acl/me?path=${encodeURIComponent(url)}`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    },
  }).pipe(
    map((x) => x.response),
    map((x) => ({ [name]: getRole(x) }))
  );
};
