import React from "react";
import Tour from "reactour";
import Steps from "./excelTutorialSteps";

const ExcelTutorial = (props) => (
  <Tour
    action
    disableInteraction
    showNumber={false}
    showNavigation={false}
    steps={Steps}
    startAt={0}
    updateDelay={1}
    isOpen={props.excelTour}
    onAfterOpen={() => {
      window.artesianExcelTutorialOpen = true;
    }}
    closeWithMask={false}
    onRequestClose={() => {
      props.closeTutorial();
      window.artesianExcelTutorialOpen = false;
    }}
    onBeforeClose={() => {
      localStorage.setItem("finishedExcelTour", "true");
      if (
        document.getElementsByClassName("modal fade show d-block").length >= 1
      ) {
        document.getElementsByClassName("curvePreviewClose")[0].click();
      }
      setTimeout(() => {
        document.getElementsByClassName("fas fa-caret-down")[0].click();
        document.getElementsByClassName("fas fa-caret-down")[0].click();
      }, 10);
    }}
    maskClassName="excelTourMaskStyle"
    maskSpace={0}
    highlightedMaskClassName="excelTourMaskPortalStyle"
    lastStepNextButton={<div className="btn extractFilterBtn ">Done!</div>}
  />
);
export default ExcelTutorial;
