import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { InputTextBox, DropDownScroll } from "../components";

const AddUserModal = (props) => (
  <div>
    <div
      className={`modal fade show ${props.addUserModal.open ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${props.addUserModal.open ? "fadeIn" : ""}`}
        id="errorModalOverlay"
        onClick={props.closeModal}
        aria-hidden="true"
      />
      <div
        className="modal-dialog artesainModal adminAddUserModal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">Add User</h5>
          </div>
          <div className="modal-body">
            <div className="addUserModalDiv">
              <span className="addUserModalTitle">User Name: </span>
              <InputTextBox
                value={props.userName || ""}
                onChange={props.setUserName}
                id="UserName"
              />
            </div>
            <div className="addUserModalDiv">
              <span className="addUserModalTitle">Existing Groups: </span>
              <Scrollbars
                className="displayUserGroups"
                renderThumbHorizontal={(vals) => (
                  <div {...vals} className="thumb-horizontal" />
                )}
                renderThumbVertical={(vals) => (
                  <div {...vals} className="thumb-vertical" />
                )}
              >
                {props.displaySelectedGroups}
              </Scrollbars>
            </div>
            <div className="addUserModalDiv">
              <span className="addUserModalTitle">Add Group: </span>
              <DropDownScroll
                className="artesianDropdownBorderColor width100"
                selected={props.availbleGroupsTitle}
                dropDownValues={props.groupList}
                onClick={props.setSelectedGroup}
                dropID="addUserGroup"
              />
            </div>
          </div>
          <div className="modal-footer d-block">
            <div className="editModalButtons">
              <button
                type="button"
                className={`btn confirmClose ${
                  props.disableApplyBtn || props.updateGroupsIsEmpty
                    ? "btnDisabled"
                    : ""
                }`}
                onClick={props.commitChanges}
                disabled={props.disableApplyBtn || props.updateGroupsIsEmpty}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn modalClose"
                onClick={props.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddUserModal;
