import React from "react";
import { connect } from "react-redux";
import { withProps, withHandlers, lifecycle } from "recompose";
import {
  compose,
  length,
  equals,
  uniq,
  prop,
  map,
  without,
  contains,
  isEmpty,
} from "ramda";
import { MainMenu } from "../../menu";
import {
  removeAllSelectedCurvesAction,
  removeSelectedTypeAction,
  CurvesSelectors,
} from "../../redux/modules/curves";
import { setTutorialAction } from "../../redux/modules/tutorial";
import { showNavigationModalAction } from "../../redux/modules/menu";
import { getPermissionRequestAction } from "../../redux/modules/permissions";
import {
  getTypeData,
  removeSelectedCurvesCheck,
  pageSelect,
  updateSelectedDefault,
} from "./helper";

const mapStateToProps = (state) => ({
  selectedCurves: CurvesSelectors.selectedCurves(state),
  extractCustomFilterState: CurvesSelectors.extractCustomFilterState(state),
  ...state.Menu,
  principalGroupPermission:
    prop("groups", state.Permissions.menuPermission) || [],
  timetransformPermission:
    prop("timetransform", state.Permissions.menuPermission) || [],
  expectationMonitorPermission:
    prop("expectation", state.Permissions.menuPermission) || [],
  globalPathPermission:
    prop("globalPath", state.Permissions.menuPermission) || [],
});

export default compose(
  connect(mapStateToProps, {
    removeAllSelectedCurvesAction,
    removeSelectedTypeAction,
    showNavigationModalAction,
    getPermissionRequestAction,
    setTutorialAction,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPermissionRequestAction();
    },
  }),
  withProps((props) => ({
    marketAssessmentData: getTypeData({
      data: props.selectedCurves.curves,
      type: "MarketAssessment",
    }),
    versionedTimeSeriesData: getTypeData({
      data: props.selectedCurves.curves,
      type: "VersionedTimeSerie",
    }),
    actualTimeSeriesData: getTypeData({
      data: props.selectedCurves.curves,
      type: "ActualTimeSerie",
    }),
    auctionData: getTypeData({
      data: props.selectedCurves.curves,
      type: "Auction",
    }),
    bidAskData: getTypeData({
      data: props.selectedCurves.curves,
      type: "BidAsk",
    }),
    globalPathPermissionRes: contains("Owner", props.globalPathPermission),
  })),
  withProps((props) => ({
    displayAdminMenuItem: !(
      (contains("SecurityManager", props.principalGroupPermission) ||
        contains("Owner", props.principalGroupPermission)) &&
      length(props.principalGroupPermission) >= 2
    ),
    displayTimeTransMenuItem: isEmpty(props.timetransformPermission),
    displayExpectationMonitorMenuItem: !isEmpty(
      props.expectationMonitorPermission
    ),
    curveCount: length(props.selectedCurves.curves),
    marketAssessmentDataCount: length(props.marketAssessmentData),
    versionedTimeSeriesDataCount: length(props.versionedTimeSeriesData),
    actualTimeSeriesDataCount: length(props.actualTimeSeriesData),
    auctionDataCount: length(props.auctionData),
    bidAskDataCount: length(props.bidAskData),
  })),
  withProps((props) => ({
    totalCurveCount: props.curveCount,
    totalMarketAssessmentDataCount: props.marketAssessmentDataCount,
    totalVersionedTimeSeriesDataCount: props.versionedTimeSeriesDataCount,
    totalActualTimeSeriesDataCount: props.actualTimeSeriesDataCount,
    totalAuctionDataCount: props.auctionDataCount,
    totalBidAskDataCount: props.bidAskDataCount,
    activeHome: equals(props.selectedMenuItem, "selectCurves"),
    activeDashboard: equals(props.selectedMenuItem, "deliveryDashboard"),
    activeBasket: equals(props.selectedMenuItem, "refineBasket"),
    activeClassify: equals(props.selectedMenuItem, "classifyCurves"),
    activeEnrichMetaData: equals(props.selectedMenuItem, "enrichMetaData"),
    activeGroupAdmin: equals(props.selectedMenuItem, "groupAdmin"),
    activeUserAdmin: equals(props.selectedMenuItem, "userAdmin"),
    activeEnrichTimeTransform: equals(
      props.selectedMenuItem,
      "enrichTimeTransform"
    ),
    activeExtractCurves: equals(props.selectedMenuItem, "extractCurves"),
    duration: equals(props.curveCount, 0) ? 1000 : 375,
    navigationDisabled: equals(props.curveCount, 0),
    selectedCurveTypes: compose(
      uniq,
      map(prop("Type"))
    )(props.selectedCurves.curves),
  })),
  withHandlers({
    navCheck: (props) => (val) => {
      if (equals(props.mainIndex, 1) && equals(props.curveCount, val)) {
        props.showNavigationModal();
        props.home();
      }
    },
  }),
  withHandlers({
    removeAllSelectedCurves: (props) => () => {
      props.removeAllSelectedCurvesAction();
      if (
        equals("deliveryDashboard", props.selectedMenuItem) ||
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.navCheck(props.curveCount);
      props.setTutorialAction("selectCurves");
      return true;
    },
    toolTipTextHandler: (props) => (msg) =>
      equals(props.curveCount, 0) ? (
        <div>
          <i className="fa fa-exclamation-triangle red" /> No MarketData Selected
        </div>
      ) : (
        <div> {msg} </div>
      ),
    extratToolTipTextHandler: (props) => () => {
      if (props.extractCustomFilterState) {
        return (
          <div>
            <i className="fa fa-bolt" /> Filter Extraction
          </div>
        );
      }

      if (equals(props.curveCount, 0)) {
        return (
          <div>
            <i className="fa fa-exclamation-triangle red" /> No MarketData Selected
          </div>
        );
      }

      return <div> Extract </div>;
    },
    removeBidAskSelectedCurves: (props) => () => {
      props.removeSelectedTypeAction("BidAsk");
      if (
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.navCheck(props.bidAskDataCount);
      props.setTutorialAction(
        pageSelect({
          count: props.curveCount,
          dataCount: props.bidAskDataCount,
        }),
        without(["BidAsk"], props.selectedCurveTypes)
      );
      return true;
    },
    removeAuctionSelectedCurves: (props) => () => {
      props.removeSelectedTypeAction("Auction");
      if (
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.navCheck(props.auctionDataCount);
      props.setTutorialAction(
        pageSelect({
          count: props.curveCount,
          dataCount: props.auctionDataCount,
        }),
        without(["Auction"], props.selectedCurveTypes)
      );
      return true;
    },
    removeTSSelectedCurves: (props) => () => {
      props.removeSelectedTypeAction("ActualTimeSerie");
      if (
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.navCheck(props.actualTimeSeriesDataCount);
      props.setTutorialAction(
        pageSelect({
          count: props.curveCount,
          dataCount: props.actualTimeSeriesDataCount,
        }),
        without(["ActualTimeSerie"], props.selectedCurveTypes)
      );
      return true;
    },
    removeMASSelectedCurves: (props) => () => {
      props.removeSelectedTypeAction("MarketAssessment");
      if (
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.setTutorialAction(
        pageSelect({
          count: props.curveCount,
          dataCount: props.marketAssessmentDataCount,
        }),
        without(["MarketAssessment"], props.selectedCurveTypes)
      );
      return true;
    },
    removeVTSSelectedCurves: (props) => () => {
      props.removeSelectedTypeAction("VersionedTimeSerie");
      if (
        removeSelectedCurvesCheck({
          selectedMenuItem: props.selectedMenuItem,
          extractCustomFilterState: props.extractCustomFilterState,
        })
      ) {
        return true;
      }
      props.navCheck(props.versionedTimeSeriesDataCount);
      props.setTutorialAction(
        pageSelect({
          count: props.curveCount,
          dataCount: props.versionedTimeSeriesDataCount,
        }),
        without(["VersionedTimeSerie"], props.selectedCurveTypes)
      );
      return true;
    },
    openDashboard: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "deliveryDashboard",
      });
    },
    openBasket: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "refineBasket",
      });
    },
    openClassify: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "classifyCurves",
      });
    },
    openEnrichMetaData: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "enrichMetaData",
      });
    },
    openGroupAdmin: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "groupAdmin",
      });
    },
    openUserAdmin: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "userAdmin",
      });
    },
    openEnrichTimeTransform: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "enrichTimeTransform",
      });
    },
    openExtractCurves: (props) => () => {
      props.updateSelected({
        ...updateSelectedDefault,
        page: "extractCurves",
      });
    },
  })
)(MainMenu);
