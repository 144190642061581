import React from "react";
import { connect } from "react-redux";
import {
  compose,
  contains,
  prop,
  isEmpty,
  keys,
  propEq,
  find,
  isNil,
  equals,
  filter,
  toUpper,
  map,
  unnest,
} from "ramda";
import { lifecycle, withHandlers, withProps, withState, pure } from "recompose";
import { Curves } from "../../curves";
import { FacetPanel, SelectedFilter, FilterDropDownScroll } from "./components";
import {
  showCurveWarningModalAction,
  removeFacetLabelsAction,
  requestCurvesAction,
  setPageSizeAction,
  setPageSortColumnAction,
  setPageNoAction,
  setCustomFilterAction,
  setFilterTextAction,
  toggleTableRowSelectionAction,
  selectAllCurvesAction,
  toggleSelectLabelAction,
  removeAllSelectedLabelsAction,
  setToDefaultAction,
  firstLoadRequestAction,
  openCustomModalModalAction,
  CurvesSelectors,
} from "../../redux/modules/curves";
import {
  refSort,
  cardinalities,
  isLabelSelected,
  mapTypeNameFn,
  handleEmptyFacetFn,
  displaySelectedFacetsFn,
  sortLabelListFn,
  curveTableMapTypeNameFn,
  updateDropdown,
  removeInvalidChars,
  checkIfFilterChanged,
} from "./helper";
import {
  tutorialOnAction,
  tutorialOffAction,
} from "../../redux/modules/tutorial";
import { getPreviewTableAction } from "../../redux/modules/curvePreview";

const mapStateToProps = (state) => ({
  ...state.tutorial,
  filterPermission: prop("filter", state.Permissions.menuPermission) || [],
  tablesOptions: CurvesSelectors.tablesOptions(state),
  curveData: CurvesSelectors.curveData(state),
  facetData: CurvesSelectors.facetData(state),
  allCustomFilters: CurvesSelectors.allCustomFilters(state),
  selectedCustomFilter: CurvesSelectors.selectedCustomFilter(state),
  selectedlabels: CurvesSelectors.selectedlabels(state),
  selectedCurves: CurvesSelectors.selectedCurves(state),
});

const createSelectedFilters = (props) =>
  props.selectedFacetKeys.map((name) =>
    props.selectedlabels[name].map((val) => (
      <SelectedFilter
        key={`${val}Label`}
        type={name}
        value={val}
        remove={() => props.selectLabel(name, val)}
        label={`${name}: ${curveTableMapTypeNameFn(val) || "Not Classified"}`}
      />
    ))
  );

const defaultDropdonList = [
  "MarketData ID Asc",
  "MarketData ID Desc",
  "Name Asc",
  "Name Desc",
  "Provider Asc",
  "Provider Desc",
  "MarketData Type Asc",
  "MarketData Type Desc",
];

const getSortOption = [
  { sort: "Relevance", value: { sortName: "", sortOrder: "" } },
  {
    sort: "MarketData ID Asc",
    value: { sortName: "MarketDataId", sortOrder: "asc" },
  },
  {
    sort: "MarketData ID Desc",
    value: { sortName: "MarketDataId", sortOrder: "desc" },
  },
  { sort: "Name Asc", value: { sortName: "MarketDataName", sortOrder: "asc" } },
  {
    sort: "Name Desc",
    value: { sortName: "MarketDataName", sortOrder: "desc" },
  },
  {
    sort: "Provider Asc",
    value: { sortName: "ProviderName", sortOrder: "asc" },
  },
  {
    sort: "Provider Desc",
    value: { sortName: "ProviderName", sortOrder: "desc" },
  },
  { sort: "MarketData Type Asc", value: { sortName: "Type", sortOrder: "asc" } },
  { sort: "MarketData Type Desc", value: { sortName: "Type", sortOrder: "desc" } },
];

const mapSortOrder = (selectedSort) =>
  prop("value", find(propEq("sort", selectedSort))(getSortOption));

export default compose(
  pure,
  connect(mapStateToProps, {
    requestCurves: requestCurvesAction,
    selectAllCurvesAction,
    toggleTableRowSelectionAction,
    setPageSizeAction,
    setPageNoAction,
    setPageSortColumnAction,
    setFilterTextAction,
    setCustomFilterAction,
    toggleSelectLabelAction,
    removeFacetLabelsAction,
    firstLoadRequestAction,
    setToDefaultAction,
    openCustomModalModalAction,
    showCurveWarningModalAction,
    removeAllLabels: removeAllSelectedLabelsAction,
    tutorialOnAction,
    tutorialOff: tutorialOffAction,
    getPreviewTableAction,
  }),
  withState("filterText", "updateFilterText", ""),
  withState("filteredFacetText", "updateFacetFilterText", ""),
  withState("selectedSort", "updateSelectedSort", "MarketData ID Asc"),
  withState("sortValues", "updateSortValues", defaultDropdonList),
  lifecycle({
    componentDidMount() {
      this.subscription = this.props.firstLoadRequestAction();
    },
    componentWillUnmount() {
      this.subscription.unsubscribe();
    },
  }),
  withHandlers({
    openCustomModalModal: (props) => () => {
      const filterState = equals(props.selectedCustomFilter.name, "");
      const selected = props.filterState
        ? null
        : filter(
            (x) => equals(x.Name, props.selectedCustomFilter.name),
            props.allCustomFilters
          );
      props.openCustomModalModalAction({
        type: filterState ? "createFilter" : "createUpdateFilter",
        id: filterState ? 0 : selected[0].Id,
        eTag: filterState ? "" : selected[0].ETag,
        row: {
          filterName: props.selectedCustomFilter.name,
          labels: props.selectedlabels,
          searchText: props.tablesOptions.filterText,
        },
        orig: {
          filterName: filterState
            ? props.selectedCustomFilter.name
            : selected[0].Name,
          labels: filterState
            ? props.selectedlabels
            : selected[0].Filters.reduce(
                (state, { Key, Value }) => ({ ...state, [Key]: Value }),
                {}
              ),
          searchText: filterState
            ? props.tablesOptions.filterText
            : selected[0].SearchText,
        },
      });
    },
    tutorialOn: (props) => () => {
      props.setToDefaultAction();
      props.tutorialOnAction();
    },
    labelFilterCheck: (props) => (val) =>
      contains(props.filteredFacetText, prop("Value", val) || ""),
    facetValueFilter: (props) => (x) => [
      {
        FacetName: x.FacetName,
        Cardinality: x.Cardinality,
        SelectedCardinality: x.SelectedCardinality,
        FacetType: x.FacetType,
        Values: filter(
          (n) =>
            n.Value
              ? contains(toUpper(props.filteredFacetText), toUpper(n.Value))
              : null,
          x.Values
        ),
      },
    ],
    facetValuesIsEmpty: (val) => isEmpty(prop("Values", val)),
    setPageSortColumn: (props) => ({ sortName, sortOrder }) => {
      props.setPageSortColumnAction(sortName, sortOrder);
    },
  }),
  withProps((props) => ({
    hasFilterChanged: checkIfFilterChanged(
      props.selectedCustomFilter.id,
      props.allCustomFilters,
      props.selectedlabels,
      props.tablesOptions.filterText
    ),
    filterExtractBtnDisabled:
      isNil(props.selectedCustomFilter.id) || props.curveData.length < 1,
    filterOptions: map(prop("Name"), props.allCustomFilters),
    displayFilterDropdown: isEmpty(props.filterPermission),
  })),
  withHandlers({
    sortDropdownFn: (props) => (val) => {
      props.updateSelectedSort(val);
      props.setPageSortColumn(mapSortOrder(val));
    },
    setSortDropdownDefault: (props) => (val) => {
      props.updateSortValues(defaultDropdonList);
      props.updateSelectedSort(val);
      props.setPageSortColumn(mapSortOrder(val));
    },
  }),
  withHandlers({
    extractSelectedFilter: (props) => () => {
      props.extractCustomUserFilter();
    },
    updateFacetFilterTextPlusFilter: (props) => (val) =>
      props.updateFacetFilterText(removeInvalidChars(val)),
    updateFilterTextPlusFilter: (props) => (val) =>
      props.updateFilterText(removeInvalidChars(val)),
    selectLabel: (props) => (facet, label) =>
      props.toggleSelectLabelAction(facet, label),
    facetsFilterClear: (props) => props.updateFacetFilterText(""),
    clearAllFunction: (props) => () => {
      props.setSortDropdownDefault("MarketData ID Asc");
      props.updateFilterText("");
      props.updateFacetFilterText("");
      props.setFilterTextAction("");
      props.removeAllLabels();
    },
    applyCustomFilter: (props) => (val) => {
      props.updateFacetFilterText("");
      props.updateFilterText(val.SearchText || "");
      props.setCustomFilterAction(val);
    },
    removeFacetLabels: (props) => (facet) =>
      props.removeFacetLabelsAction(facet),
    setFilterText: (props) => () => {
      updateDropdown(props);
      props.setFilterTextAction(props.filterText);
    },
    mapTypeName: () => (obj) => mapTypeNameFn(obj),
    handleEmptyFacet: () => (val) => handleEmptyFacetFn(val),
    displaySelectedFacets: () => (val) => displaySelectedFacetsFn(val),
    sortLabelList: () => (label) => sortLabelListFn(label),
    labelSelected: (props) => (facet, label) =>
      isLabelSelected(prop(facet, props.selectedlabels), label),
    setPageSortColumn: (props) => ({ sortName, sortOrder }) => {
      props.setPageSortColumnAction(sortName, sortOrder);
    },
  }),
  withHandlers({
    handleShow: (props) => props.filteredFacetText.length,
    enterBtnPress: (props) => (event) =>
      event.key === "Enter" ? props.setFilterText(props.filterText) : null,
    searchBtn: (props) => () => props.setFilterText(props.filterText),
    containsSelectedFacet: (props) => (name) =>
      props.selectedlabels[name] !== undefined &&
      props.selectedlabels[name].length > 0,
    setSeletedFilter: (props) => (val) =>
      props.applyCustomFilter(
        find(propEq("Name", val))(props.allCustomFilters)
      ),
  }),
  withProps((props) => ({
    sortedFacets: refSort(
      ["TYPE", "PROVIDERNAME"],
      cardinalities(props.facetData, props.selectedlabels)
    ),
    selectedFacetKeys: keys(props.selectedlabels),
    curveCount: props.tablesOptions.curveCount,
  })),
  withProps((props) => ({
    selectedFilterComponents: !isEmpty(props.selectedlabels)
      ? createSelectedFilters(props)
      : "",
    facetPanels: filter(
      (n) => n.Values.length !== 0,
      unnest(map(props.facetValueFilter, props.sortedFacets))
    ).map((facet) => (
      <FacetPanel
        id={props.filteredFacetText + facet.FacetName}
        key={props.filteredFacetText + facet.FacetName}
        data={facet}
        {...props}
        showPanel={props.filteredFacetText.length !== 0}
      />
    )),
    customFilterDropDown: props.displayFilterDropdown ? null : (
      <FilterDropDownScroll
        className="artesianDropdownBorderColor width100"
        selected={props.selectedCustomFilter.name}
        dropDownValues={props.filterOptions || []}
        onClick={props.setSeletedFilter}
        openCustomModalModal={props.openCustomModalModal}
        hasSelectedlabels={isEmpty(props.selectedlabels)}
        hasFilterText={isEmpty(props.filterText)}
        hasFilterChanged={props.hasFilterChanged}
      />
    ),
  })),
  withProps((props) => ({
    selectAllCurves: () =>
      props.curveCount > 1000
        ? props.showCurveWarningModalAction({
            msg: `Are you sure you want to select all ${props.curveCount} curves?`,
          })
        : props.selectAllCurvesAction(),
  }))
)(Curves);
