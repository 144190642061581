import React from "react";
import * as R from "ramda";
import { Tooltip } from "react-tippy";

type PaginationComponentTypes = {
  count: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => any;
};

const PaginationComponent = ({
  count,
  pageSize,
  page,
  onPageChange,
}: PaginationComponentTypes) => {
  const totalPages = Math.ceil(R.divide(count, pageSize));
  const maxCountValue = 100000;
  const pageMinRange = 3;
  const pageMinRangeVal = R.gt(page, pageMinRange)
    ? R.min(
        R.subtract(page, pageMinRange),
        R.subtract(totalPages, pageMinRange)
      )
    : 0;

  const pageMaxRange = 2;
  const pageMaxSub = R.subtract(totalPages, pageMaxRange);
  const pageMaxRangeVal =
    page < pageMaxSub ? R.add(page, pageMaxRange) : totalPages;

  const pageRange = R.range(pageMinRangeVal, page < 3 ? 5 : pageMaxRangeVal);

  const disabledMessage =
    "Page is disabled as the current market data list is too large.<br>Please filter the market data list";
  return (
    <div className="customPagination">
      <div>
        <Tooltip arrow position="top" title={"First Page"} theme="transparent">
          <PageItem
            display={page > pageMinRange}
            onChange={() => onPageChange(1)}
            title="First Page"
            value={<i className="fa fa-angle-double-left" />}
          />
        </Tooltip>
      </div>
      <Tooltip arrow position="top" title={"Previous"} theme="transparent">
        <PageItem
          display={page > 1}
          onChange={() => onPageChange(R.subtract(page, 1))}
          value="Previous"
        />
      </Tooltip>
      {pageRange.map((p: any, i: number) => {
        const pVal = R.add(1, p);
        return (
          <Tooltip
            arrow
            position="top"
            key={i}
            title={
              pVal * pageSize > maxCountValue
                ? disabledMessage
                : R.toString(pVal)
            }
            theme="transparent"
          >
            <PageItem
              display={(pVal - 1) * pageSize < count}
              className={R.equals(pVal, page) ? "active" : ""}
              onChange={() => onPageChange(pVal)}
              value={pVal}
              disable={pVal * pageSize > maxCountValue}
            />
          </Tooltip>
        );
      })}
      <Tooltip
        arrow
        position="top"
        title={(page + 1) * pageSize > maxCountValue ? disabledMessage : "Next"}
        theme="transparent"
      >
        <PageItem
          disable={(page + 1) * pageSize > maxCountValue}
          display={page < totalPages}
          onChange={() => onPageChange(R.add(1, page))}
          value="Next"
        />
      </Tooltip>
      <Tooltip
        arrow
        position="top"
        title={count > maxCountValue ? disabledMessage : "Last Page"}
        theme="transparent"
      >
        <PageItem
          disable={count > maxCountValue}
          display={page < pageMaxSub}
          onChange={() => onPageChange(totalPages)}
          value={<i className="fa fa-angle-double-right" />}
        />
      </Tooltip>
    </div>
  );
};

export default PaginationComponent;

type PageItemsTypes = {
  display: boolean;
  onChange: any;
  title?: string;
  disable?: boolean;
  className?: string;
  value: string | number | JSX.Element;
};

const PageItem = ({
  display,
  onChange,
  title,
  disable,
  className = "",
  value,
}: PageItemsTypes) =>
  R.when(R.equals(true), () => (
    <div
      className={`pageNo ${className} ${disable ? "disabled" : ""}`}
      onClick={onChange}
      title={title}
    >
      {value}
    </div>
  ))(display);
