import * as RX from "rxjs";
import {
  flatMap,
  tap,
  map as RxMap,
  toArray,
  catchError,
} from "rxjs/operators";
import { combineReducers } from "redux";
import * as R from "ramda";
import {
  fetchingReducerCreator,
  simpleSetReducerCreator,
} from "../../reducerCreators";
import { dispatchNetworkError, getUpdatedGroups } from "./helper";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector } from "../tenantSelection";
import { getAllGroups, updateGroup, getAllOwnerGroups } from "./requests";

const {
  of,
  ap,
  compose,
  map,
  prop,
  flatten,
  uniq,
  reject,
  contains,
  split,
  filter,
  lensProp,
  over,
  equals,
  __,
} = R;

const FETCHING = "artesian-ui/adminUsers/FETCHING";
const RECIEVED = "artesian-ui/adminUsers/RECIEVED";
const CANCEL_FETCHING = "artesian-ui/adminUsers/CANCEL_FETCHING";
const ALL_GROUPS = "artesian-ui/adminUsers/ALL_GROUPS";
const ALL_USERS = "artesian-ui/adminUsers/ALL_USERS";
export const OPEN_ADD_USERS_MODAL =
  "artesian-ui/adminUsers/OPEN_ADD_USERS_MODAL";
export const CLOSE_ADD_USERS_MODAL =
  "artesian-ui/adminUsers/CLOSE_ADD_USERS_MODAL";
export const UPDATE_ADD_USERS_MODAL_ROW =
  "artesian-ui/adminUsers/UPDATE_ADD_USERS_MODAL_ROW";
const REQUEST_FAILED = "artesian-ui/adminUsers/REQUEST_FAILED";
const OPEN_USER_DELETE_MODAL = "artesian-ui/adminUsers/OPEN_USER_DELETE_MODAL";
const UPDATE_USER_DELETE_MODAL =
  "artesian-ui/adminUsers/UPDATE_USER_DELETE_MODAL";
const CLOSE_USER_DELETE_MODAL =
  "artesian-ui/adminUsers/CLOSE_USER_DELETE_MODAL";
const USER_GROUP_OWNERSHIP = "artesian-ui/adminUsers/USER_GROUP_OWNERSHIP";
export const CREATE_UPDATE_USER = "artesian-ui/adminUsers/CREATE_UPDATE_USER";
export const DELETE_USER = "artesian-ui/adminUsers/DELETE_USER";

const modalInitialState = { open: false, row: {}, type: "" };
const addUserModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_ADD_USERS_MODAL:
      return { ...action.edit, orig: action.edit.row, open: true };
    case UPDATE_ADD_USERS_MODAL_ROW:
      return { ...state, row: action.row };
    case CLOSE_ADD_USERS_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

const userDeleteModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_USER_DELETE_MODAL:
      return { ...action.edit, orig: action.edit.row, open: true };
    case UPDATE_USER_DELETE_MODAL:
      return { ...state, row: action.row };
    case CLOSE_USER_DELETE_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

const openAddUserModal = (edit) => ({ type: OPEN_ADD_USERS_MODAL, edit });
const closeAddUserModal = () => ({ type: CLOSE_ADD_USERS_MODAL });
const updateAddUserModalRow = (row) => ({
  type: UPDATE_ADD_USERS_MODAL_ROW,
  row,
});
const openUserDeleteModal = (edit) => ({ type: OPEN_USER_DELETE_MODAL, edit });
const updateUserDeleteModal = (row) => ({
  type: UPDATE_USER_DELETE_MODAL,
  row,
});
const closeUserDeleteModal = () => ({ type: CLOSE_USER_DELETE_MODAL });

export default combineReducers({
  adminUsersFetching: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
    cancelFetching: CANCEL_FETCHING,
  }),
  allGroups: simpleSetReducerCreator({
    setAction: ALL_GROUPS,
    prop: "groups",
    initialState: [],
  }),
  addUserModal,
  userDeleteModal,
  allUsers: simpleSetReducerCreator({
    setAction: ALL_USERS,
    prop: "users",
    initialState: [],
  }),
  failedRequest: simpleSetReducerCreator({
    setAction: REQUEST_FAILED,
    prop: "fail",
    initialState: [],
  }),
  userGroupOwnerShip: simpleSetReducerCreator({
    setAction: USER_GROUP_OWNERSHIP,
    prop: "groups",
    initialState: [],
  }),
});

const fetching = () => ({
  type: FETCHING,
});
const received = () => ({
  type: RECIEVED,
});
const createUpdateUserFunc = () => ({ type: CREATE_UPDATE_USER });
const deleteUserFunc = () => ({ type: DELETE_USER });
const setAllGroups = (groups) => ({
  type: ALL_GROUPS,
  groups,
});
const setAllUsers = (users) => ({
  type: ALL_USERS,
  users,
});
const setFailedRequests = (fail) => ({
  type: REQUEST_FAILED,
  fail,
});
const setUserGroupOwnership = (groups) => ({
  type: USER_GROUP_OWNERSHIP,
  groups,
});
const setFailedRequestsEmpty = () => ({
  type: REQUEST_FAILED,
  fail: [],
});

const handleNetworkError = compose(ap([dispatchNetworkError, received]), of);
const handleNetworkUserError = compose(
  ap([dispatchNetworkError, received, setFailedRequestsEmpty]),
  of
);

const tokenFromState = compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: TenantSelector.api(state),
      state,
    }))
  );
const removeClients = (user) => contains("clients", split("@", user));

export const getAdminGroupsAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetching)),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      tap(
        compose(
          dispatch,
          setAllUsers,
          reject(removeClients),
          uniq,
          flatten,
          map(prop("Users"))
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api, state }) =>
        getAllOwnerGroups({
          token,
          api,
          groups: state.AdminUsers.allGroups,
        })
      ),
      tap(compose(dispatch, setUserGroupOwnership))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkError)
    );

const removeUserGroups = (groups, user, userGroups, { token, api }) => {
  const allRemove = compose(
    map(prop("name")),
    filter((x) => equals(x.mod, "remove") && equals(x.orig, true))
  )(userGroups);

  return compose(
    map((data) => ({
      token,
      api,
      ID: data.ID,
      data,
    })),
    map((x) =>
      over(
        lensProp("Users"),
        reject((z) => equals(z, user), __),
        x
      )
    ),
    filter((x) => contains(x.Name, allRemove))
  )(groups);
};

const checkIfErrors = (res) =>
  RX.Observable.create((obs) => {
    const failures = filter((x) => x.type && equals(x.type, "fail"), res);
    if (failures.length > 0) {
      obs.error(res);
    } else {
      obs.next(res);
      obs.complete();
    }
  });

export const addNewUser = (data) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetching)),
      tap(compose(dispatch, createUpdateUserFunc)),
      RxMap((tokenData) =>
        getUpdatedGroups(
          getState().AdminUsers.allGroups,
          data.userName,
          data.userGroups,
          getState().AdminUsers.addUserModal.orig.userName,
          tokenData
        )
      ),
      tap(compose(dispatch, closeAddUserModal)),
      flatMap((x) => x),
      flatMap((permission) =>
        updateGroup(permission).pipe(
          catchError(() =>
            RX.of({
              type: "fail",
              permission,
            })
          )
        )
      ),
      toArray(),
      tap(compose(dispatch, setFailedRequests)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api, state }) =>
        getAllOwnerGroups({
          token,
          api,
          groups: state.AdminUsers.allGroups,
        })
      ),
      tap(compose(dispatch, setUserGroupOwnership)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      tap(
        compose(
          dispatch,
          setAllUsers,
          reject(removeClients),
          uniq,
          flatten,
          map(prop("Users"))
        )
      ),
      flatMap(() => checkIfErrors(getState().AdminUsers.failedRequest))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkUserError)
    );

export const removeUser = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetching)),
      tap(compose(dispatch, deleteUserFunc)),
      RxMap((tokenData) =>
        removeUserGroups(
          getState().AdminUsers.allGroups,
          getState().AdminUsers.userDeleteModal.row.userName,
          getState().AdminUsers.userDeleteModal.row.userGroups,
          tokenData
        )
      ),
      flatMap((x) => x),
      flatMap((permission) =>
        updateGroup(permission).pipe(
          catchError(() =>
            RX.of({
              type: "fail",
              permission,
            })
          )
        )
      ),
      toArray(),
      tap(compose(dispatch, setFailedRequests)),
      tap(compose(dispatch, closeUserDeleteModal)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api, state }) =>
        getAllOwnerGroups({
          token,
          api,
          groups: state.AdminUsers.allGroups,
        })
      ),
      tap(compose(dispatch, setUserGroupOwnership)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      tap(
        compose(
          dispatch,
          setAllUsers,
          reject(removeClients),
          uniq,
          flatten,
          map(prop("Users"))
        )
      ),
      flatMap(() => checkIfErrors(getState().AdminUsers.failedRequest))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkUserError, closeUserDeleteModal)
    );

export const emptyDataAction = () => [];

export const openAddUserModalAction = (row) => [openAddUserModal(row)];
export const closeAddUserModalAction = () => [closeAddUserModal()];
export const updateAddUserModalRowAction = (row) => [
  updateAddUserModalRow(row),
];
export const addNewUserAction = (data) => [addNewUser(data)];

export const openUserDeleteModalAction = (row) => [openUserDeleteModal(row)];
export const closeUserDeleteModalAction = () => [closeUserDeleteModal()];
export const updateUserDeleteModalAction = (row) => [
  updateUserDeleteModal(row),
];
export const removeUserAction = () => [removeUser()];

export const Selectors = {
  all: R.path(["AdminUsers"]),
};
