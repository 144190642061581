import React from "react";
import { Tooltip } from "react-tippy";

const SearchTextBox = ({
  enterBtnPress,
  searchBtn,
  filterText,
  updateFilterTextPlusFilter,
}) => (
  <div className="input-group curveSearchContainer">
    <input
      type="text"
      className="form-control"
      placeholder="Search MarketData"
      id="ArtesianSearchInput"
      onKeyPress={enterBtnPress}
      value={filterText}
      onChange={(e) => {
        updateFilterTextPlusFilter(e.target.value);
      }}
    />

    <span className="input-group-addon" onClick={searchBtn} aria-hidden="true">
      <Tooltip
        arrow
        position="bottom"
        html={<span>Click to Search</span>}
        theme="arkDefaultTooltip"
      >
        <i className="fa fa-search" />
      </Tooltip>
    </span>
    <span className="input-group-addon searchInfo" aria-hidden="true">
      <Tooltip
        arrow
        position="bottom"
        theme="seachInfo"
        html={
          <div>
            <table>
              <thead>
                <tr>
                  <th>Operator</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="orOperatorIcon">
                      <i className="fa fa-minus fa-rotate-270" />
                    </span>
                  </td>
                  <td>
                    Applies Logical OR
                    <br />
                    <b>E.g.</b> OfferedCapacity|AllocatedCapacity
                    <i className="fa fa-angle-double-right" /> Return 1 or both
                    market data.
                    <br />
                    Alternatively, using a blank space represents an OR.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="orOperatorIcon">
                      <i className="fa fa-plus" />
                    </span>
                  </td>
                  <td>
                    Applies Logical AND
                    <br />
                    <b>E.g.</b> Ita +Demand
                    <i className="fa fa-angle-double-right" /> Returns Italian
                    market data that contain demand
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="orOperatorIcon">
                      <i className="fa fa-minus" />
                    </span>
                  </td>
                  <td>
                    Applies Logical NOT
                    <br />
                    <b>E.g.</b> Forecast -Wind
                    <i className="fa fa-angle-double-right" /> Returns all
                    Forecast without wind market data
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="quoteOperatorIcon">
                      <i className="fa fa-quote-left" />
                      &nbsp;
                      <i className="fa fa-quote-right" />
                    </span>
                  </td>
                  <td>
                    Search explicit phrase
                    <br />
                    <b>E.g.</b> &quot;Baseload&quot;
                    <i className="fa fa-angle-double-right" /> Returns all
                    market data with Baseload associated with.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      >
        <i className="fa fa-info" />
      </Tooltip>
    </span>
  </div>
);

export default SearchTextBox;
