import React from "react";
import { connect } from "react-redux";
import {
  compose,
  find,
  __,
  prop,
  map,
  join,
  any,
  equals,
  contains,
  flatten,
  values,
  head,
  groupBy,
  filter,
} from "ramda";
import { lifecycle, withProps, withState, withHandlers } from "recompose";
import { Tooltip } from "react-tippy";
import { AdminUsers } from "../../adminUsers";
import { tutorialOnAction } from "../../redux/modules/tutorial";
import { RenderSizePerPageDropDown, Button } from "../../components";
import {
  getAdminGroupsAction,
  emptyDataAction,
  openUserDeleteModalAction,
  openAddUserModalAction,
} from "../../redux/modules/adminUsers";

const mapStateToProps = (state) => ({
  ...state.AdminUsers,
  principalGroupPermission:
    prop("groups", state.Permissions.menuPermission) || [],
});

const formatTableData = (users, groups) =>
  compose(
    flatten,
    map((user) =>
      compose(
        values,
        map((x) => ({
          user: compose(prop("user"), head)(x),
          data: x,
        })),
        groupBy(prop("user")),
        map((res) => ({ id: res.ID, name: res.Name, user })),
        filter((x) => find((r) => equals(r, user), x.Users))
      )(groups)
    )
  )(users);

export default compose(
  connect(mapStateToProps, {
    tutorialOn: tutorialOnAction,
    getAdminGroupsAction,
    emptyDataAction,
    openUserDeleteModalAction,
    openAddUserModalAction,
  }),
  lifecycle({
    componentDidMount() {
      this.subscription = this.props.getAdminGroupsAction();
    },
    componentWillUnmount() {
      this.props.emptyDataAction();
      this.subscription.unsubscribe();
    },
  }),
  withProps((props) => ({
    createUserPermission: !any(equals(true))([
      contains("Contributor", props.principalGroupPermission),
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
    ]),
    canEditUserPermission: !any(equals(true))([
      contains("Contributor", props.principalGroupPermission),
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
    ]),
    canDeleteUserPermission: !any(equals(true))([
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
    ]),
  })),
  withState("pageSize", "updatePageSize", 50),
  withHandlers({
    openAddUserModal: (props) => () =>
      props.openAddUserModalAction({
        actionType: "newUser",
        row: {
          userName: null,
          userGroups: [],
        },
      }),
    setPageSize: (props) => (size) => props.updatePageSize(size),
    openUserDeleteModal: (props) => (groups) =>
      props.openUserDeleteModalAction(groups),
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(props.pageSize, props),
    groupList: () => (cell, row) => {
      const val = compose(join(", ", __), map(prop("name")))(row.data);
      return (
        <Tooltip
          arrow
          position="bottom"
          html={<span>{val}</span>}
          theme="arkDefaultTooltip"
        >
          {val}
        </Tooltip>
      );
    },
  }),
  withProps((props) => ({
    tableData: formatTableData(props.allUsers, props.allGroups),
    tableSettings: {
      sizePerPage: props.pageSize,
      sizePerPageDropDown: props.renderDropDown,
    },
    infoFormat: (cell) => (
      <Tooltip
        arrow
        position="bottom"
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
    dataFormat: (cell, row) => (
      <div>
        <Button
          permissionDisabled={props.canEditUserPermission}
          onClick={() =>
            props.openAddUserModalAction({
              actionType: "edit",
              row: {
                userName: row.user,
                userGroups: map(
                  (x) => ({ name: x.name, orig: true }),
                  row.data
                ),
              },
            })
          }
          className="basicEditBtn"
          btnText={<i className="fas fa-pencil-alt" />}
        />
        <Button
          permissionDisabled={props.canDeleteUserPermission}
          onClick={() =>
            props.openUserDeleteModal({
              row: {
                userName: row.user,
                userGroups: map(
                  (x) => ({ name: x.name, orig: true, mod: "remove" }),
                  row.data
                ),
              },
            })
          }
          className="basicEditBtn"
          btnText={<i className="fas fa-trash" />}
        />
      </div>
    ),
  }))
)(AdminUsers);
