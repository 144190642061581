import React from "react";
import Button from "./button";
import { loginRequest } from "../authConfig";
import { auth } from "../appContext";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    return (
        <Button variant="secondary" btnText="Sign In" className="ml-auto loginBtn" onClick={() => auth.login()}></Button>
    );
}