import React from "react";
import * as R from "ramda";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { Tooltip } from "react-tippy";

export const DateRange = ({
  startDate,
  endDate,
  onChange,
  defaultStartDate,
  defaultEndDate,
}) => {
  return <Tooltip
  arrow
  html={
    "Click on calendar icon to change date range"
  }
  theme="arkDefaultTooltip"
>
  <DateRangePicker
    onChange={(date) =>
      onChange(getChangeValue({ date, defaultStartDate, defaultEndDate }))
    }
    value={[startDate.toDate(), endDate.toDate()]}
    format="dd-MM-yyyy"
    className="calendarInput"
    calendarClassName="calendarStyle"
  />
</Tooltip>
}


export default DateRange;

const getChangeValue = ({ date, defaultStartDate, defaultEndDate }) => {
  if (R.isNil(date)) return [defaultStartDate, defaultEndDate];

  const [startDate, endDate] = date;
  if (startDate && endDate) {
    return [moment(startDate), moment(endDate)];
  }

  if (startDate && endDate === null) {
    return [moment(startDate), moment(startDate).add(1, "days")];
  }

  if (endDate && startDate === null) {
    return [moment(endDate).subtract(1, "days"), moment(endDate)];
  }

  return [defaultStartDate, defaultEndDate];
};
