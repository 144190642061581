import React from "react";
import { toolTipStyleTopMargin } from "./tooltipStyles";

export const extractionTutorial = [
  {
    position: "bottom",
    content: (
      <div>
        <h3>Market Assessment Data</h3>
        Market assessment is a time series of 8-tuples indexed by market
        product. The 8-tuple consists of the following data items: Settlement,
        High, Low, Open, Close, VolumePaid, VolumeGiven, VolumeTotal and will
        pertain to a specific product.
        <br />
      </div>
    ),
    style: toolTipStyleTopMargin,
    action: () => {
      if (
        document.getElementsByClassName("masTsTarget collapse show").length < 1
      ) {
        document.getElementById("masTs").click();
      }
      return null;
    },
  },
  // Date Options
  {
    selector: '[class="d-flex width100 noPadding MASTimeSeriesDateType"]',
    position: "right",
    content: (
      <div>
        <h3>Date Options</h3>
        Here you can set the range of time over which you want your market data
        returned
        <br />
        Your options here are:
        <br />
        <b>Date Range</b> - A range of time spaning from the first date to the
        second date
        <br />
        <b>Relative</b> - A range spanning the chosen interval relative to
        todays date
        <br />
        <b>Period From/To</b> - A date range built in the ISO_8601 format,
        allows you to select a period of time from the past and a period of time
        into the future starting from todays date
        <br />
        <b>Period</b> - A date range built in the ISO_8601 format, Allow you to
        select a duration in the past or into the future starting from todays
        date
        <br />
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Timezone
  {
    selector: '[class="d-flex width100 noPadding MASTimezone"]',
    position: "bottom",
    content: (
      <div>
        <h3>TimeZone</h3>
        Select a TimeZone from the dropdown. The MarketData returned will match
        the datatime of the TimeZone selected.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  {
    selector: '[id="masDownload"]',
    position: "bottom",
    content: (
      <div>
        <h3>Download Excel</h3>
        Download your market data with your selected date ranges and types.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Copy to Clipboard
  {
    selector: '[id="masCopy"]',
    position: "bottom",
    content: (
      <div>
        <h3>Copy Clipboard</h3>
        Alternatively if you wish to extract the URL for use in a BI tool, you
        can copy it straight to your clipboard
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // End
  {
    position: "center",
    content: (
      <div>
        <h3>End of Versioned Time Series Tutorial</h3>
      </div>
    ),
    style: toolTipStyleTopMargin,
    action: () => {
      if (
        document.getElementsByClassName("masTsTarget collapse show").length < 1
      ) {
        document.getElementById("masTs").click();
      }
      return null;
    },
  },
];

export default extractionTutorial;
