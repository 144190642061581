import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import moment from "moment";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import {
  requestAction,
  requestPollAction,
  requestNewAction,
  setPageNoAction,
  setExpectationDashboardFilterAction,
  deleteAlertAction,
  addAlertAction,
  requestHistoryAction,
  addMarketDataIds,
  deleteMarketDataIdsAction,
  openModal,
  Selectors,
} from "../redux/modules/expectationMonitor";

const HeaderButton = ({ title, onClick }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{title}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="filterBtn"
      onClick={onClick}
      btnText={<span className="filterBtnText">{title}</span>}
    />
  </Tooltip>
);
const getStatusColour = (status) => {
  switch (status) {
    case "Ok":
    case "Solved":
      return <div className="sucess">{status}</div>;
    case "Waiting":
      return <div className="warning">{status}</div>;
    case "InLate":
      return <div className="failed">Late</div>;
    case "NotSet":
      return <div />;
    default:
      return <div>{status}</div>;
  }
};
function paginate(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
const ExpandedRow = ({ expandedRowData, deleteMarketDataIds }) => {
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);

  return (
    <div className="expandedRowContainer">
      <div className="expandedRowControls">
        {expandedRowData.MarketDataList.length === 0 ? (
          <div className="scheduleContainer">
            <div className="centerText">
              Please add Market Data Id's to List
            </div>
          </div>
        ) : (
          <table className="artesianTable">
            <thead>
              <tr>
                <th />
                <th>Status</th>
                <th>MarketDataName</th>
                <th>MarketDataID</th>
              </tr>
            </thead>
            <tbody>
              {R.map((y) => (
                <tr key={y.MarketDataID}>
                  <td>
                    <div className="tableCellIcon">
                      <span
                        onClick={() =>
                          deleteMarketDataIds(expandedRowData.ID, [
                            y.MarketDataID,
                          ])
                        }
                        className="fa fa-trash"
                      />
                    </div>
                  </td>
                  <td>{getStatusColour(y.Status)}</td>
                  <td>{y.MarketDataName}</td>
                  <td>{y.MarketDataID}</td>
                </tr>
              ))(
                paginate(
                  R.path(["AlertState", "LiveCheck", "MarketDataList"])(
                    expandedRowData
                  ) ||
                    R.map((x) => ({ MarketDataID: x }))(
                      expandedRowData.MarketDataList
                    ),
                  pageSize,
                  page
                )
              )}
            </tbody>
          </table>
        )}

        <div className="standardPaginationControls">
          <PaginationComponent
            count={
              R.path(["AlertState", "LiveCheck", "MarketDataList"])(
                expandedRowData
              ).length || expandedRowData.MarketDataList.length
            }
            pageSize={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
          />
        </div>
      </div>
      <div className="expandedRowControls">
        <CronView
          cronExpression={R.path(["Schedule", "CRONExpression"])(
            expandedRowData
          )}
        />
      </div>
    </div>
  );
};

const CronView = ({ cronExpression }) => {
  const splitCron = cronExpression
    .split(" ")
    .join(",")
    .split("/")
    .join(",")
    .split(",");
  const monthly =
    splitCron[4] === "*" &&
    splitCron[3] === "*" &&
    splitCron[2] !== "*" &&
    !R.contains("-")(splitCron[2]) &&
    !R.contains("/")(splitCron[2]) &&
    !R.contains(",")(splitCron[1]) &&
    splitCron[0] !== "*" &&
    splitCron[1] !== "*";

  const daily =
    splitCron[4] === "*" &&
    splitCron[2] === "*" &&
    splitCron[3] === "*" &&
    splitCron[0] !== "*" &&
    !R.contains("-")(splitCron[0]) &&
    !R.contains("/")(splitCron[0]) &&
    !R.contains(",")(splitCron[0]) &&
    splitCron[1] !== "*" &&
    !R.contains("-")(splitCron[1]) &&
    !R.contains("/")(splitCron[1]) &&
    !R.contains(",")(splitCron[1]);

  const monthlyQuery = monthly
    ? `Monthly on day ${splitCron[2]} of each month`
    : cronExpression;

  return (
    <div className="scheduleContainer">
      <div className="legend-cron">Schedule(Cron Expression)</div>
      <div className="fieldSet-cron">
        <div>
          {daily
            ? `Daily at ${splitCron[1].padStart(
                2,
                "0"
              )}:${splitCron[0].padStart(2, "0")}`
            : monthlyQuery}
        </div>
      </div>
    </div>
  );
};
const DataDeliveryDashboardView = (props) => {
  useEffect(() => {
    props.requestAction();
    const interval = setInterval(() => {
      props.requestPollAction();
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  const [expandedId, setExpandedId] = useState(null);
  return (
    <>
      <div className="deliveryDashboard">
        <div className="classify pageTitle">
          <span className="paddingLeftFive">
            <i className="fa fa-tachometer-alt marginRight10 artesianIconNoHover" />
          </span>
          Expectation Alert Dashboard
        </div>
        <div className="artesianTableContainer">
          <div className="tableFilter">
            <SearchTextBox
              filterText={props.info.data.filter}
              updateFilterTextPlusFilter={(filter) =>
                props.setExpectationDashboardFilterAction(filter)
              }
            />
            <div className="filterBtnContainer">
              {props.info.data.isPolling ? (
                <div className="fas fa-spinner fa-pulse pollingSpinner" />
              ) : (
                <div />
              )}
              <HeaderButton
                onClick={() =>
                  props.openModal("Add Alert", true, null, "addAlert")
                }
                title="Add Alert"
              />
            </div>
          </div>
          {props.info.data.isFetching ? (
            <Spinner />
          ) : (
            <Scrollbars
              className="scrollFacets"
              renderThumbHorizontal={(vals) => (
                <div {...vals} className="thumb-horizontal" />
              )}
              renderThumbVertical={(vals) => (
                <div {...vals} className="thumb-vertical" />
              )}
            >
              <table className="artesianTable">
                <thead>
                  <tr>
                    <th />
                    <th>Current Status</th>
                    <th>Last Status</th>
                    <th>Name</th>
                    <th>Timezone</th>
                    <th>Check Time</th>
                    <th>Impacted Timezone</th>
                    <th>Impacted From</th>
                    <th>Impacted To</th>
                  </tr>
                </thead>
                <tbody>
                  {R.map((x) => (
                    <React.Fragment key={x.ID}>
                      <tr>
                        <td>
                          <div className="tableCellIcon">
                            <i
                              onClick={() =>
                                setExpandedId(x.ID === expandedId ? null : x.ID)
                              }
                              className={`fa fa-chevron-${
                                x.ID === expandedId ? "down" : "right"
                              }`}
                            />
                          </div>
                        </td>
                        <td>{getStatusColour(x.AlertState.LiveStatus)}</td>
                        <td>{getStatusColour(x.AlertState.LastStatus)}</td>
                        <td>{x.Name}</td>
                        <td>
                          {R.path(["AlertState", "LiveCheck", "CheckTimeZone"])(
                            x
                          ) || ""}
                        </td>
                        <td>
                          {R.path(["AlertState", "LiveCheck", "CheckTime"])(x)
                            ? moment(
                                R.path([
                                  "AlertState",
                                  "LiveCheck",
                                  "CheckTime",
                                ])(x)
                              ).format("DD-MM-YYYY HH:mm:ss")
                            : null}
                        </td>
                        <td>
                          {R.path([
                            "AlertState",
                            "LiveCheck",
                            "ImpactedDataRangeTMZ",
                          ])(x)}
                        </td>
                        <td>
                          {R.path([
                            "AlertState",
                            "LiveCheck",
                            "ImpactedTimeFrom",
                          ])(x)
                            ? moment(
                                R.path([
                                  "AlertState",
                                  "LiveCheck",
                                  "ImpactedTimeFrom",
                                ])(x)
                              ).format("DD-MM-YYYY HH:mm:ss")
                            : null}
                        </td>
                        <td>
                          {R.path([
                            "AlertState",
                            "LiveCheck",
                            "ImpactedTimeTo",
                          ])(x)
                            ? moment(
                                R.path([
                                  "AlertState",
                                  "LiveCheck",
                                  "ImpactedTimeTo",
                                ])(x)
                              ).format("DD-MM-YYYY HH:mm:ss")
                            : null}
                        </td>
                      </tr>
                      {x.ID === expandedId ? (
                        <tr>
                          <td colSpan="9" className="expandedRow">
                            <div className="expandedRowButtons">
                              <HeaderButton
                                onClick={() => {
                                  props.requestHistoryAction(x.ID);
                                  props.openModal(
                                    "History",
                                    true,
                                    x.ID,
                                    "alertHistory"
                                  );
                                }}
                                title="Alert History"
                              />
                              <HeaderButton
                                onClick={() =>
                                  props.openModal(
                                    "Add Selected MarketData",
                                    true,
                                    x.ID,
                                    "addSelectedCurves"
                                  )
                                }
                                title="Add Selected ID's"
                              />
                              <HeaderButton
                                onClick={() =>
                                  props.openModal(
                                    "Delete Alert",
                                    true,
                                    x.ID,
                                    "deleteAlert"
                                  )
                                }
                                title="Delete"
                              />
                              {/* <HeaderButton  onClick={() => props.openModal("Edit MarketData", true, x.ID, "editAlert")} title='Edit Alert' /> */}
                            </div>
                            <ExpandedRow
                              deleteMarketDataIds={
                                props.deleteMarketDataIdsAction
                              }
                              expandedRowData={x}
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))(props.info.data.data || [])}
                </tbody>
              </table>
            </Scrollbars>
          )}
        </div>
        <div className="standardPaginationControls">
          <div>{/* {props.renderDropDown(props)} */}</div>
          <PaginationComponent
            count={props.info.data.count}
            pageSize={10}
            page={props.info.data.page}
            onPageChange={(x) => props.setPageNoAction(x)}
          />
        </div>
      </div>
    </>
  );
};

export default connect((s) => ({ info: Selectors.ExpectationMonitor(s) }), {
  requestAction,
  requestPollAction,
  requestNewAction,
  requestHistoryAction,
  setPageNoAction,
  deleteAlertAction,
  addAlertAction,
  openModal,
  addMarketDataIds,
  setExpectationDashboardFilterAction,
  deleteMarketDataIdsAction,
})(DataDeliveryDashboardView);
