import { mapObjIndexed, equals, identity } from "ramda";

export const regexTest = (str) =>
  /^[^,:;!?\s][^,:;!?\t\n]{0,49}?[^,:;!?\s]$/.test(str);
export const isCoreTag = (tag, coreTags) =>
  coreTags.indexOf(tag.toLowerCase()) > -1;

const curveSummary = (curve) => ({ ID: curve.MarketDataId, eTag: curve.ETag });

export const errorLabelHandler = (msg, props) => {
  props.setLabelName("");
  props.updateLabelNameValidation(false);
  props.updateLabelNameInvalidMsg(msg);
};

export const errorTagHandler = (msg, props) => {
  props.setTagName("");
  props.setTagValid(false);
  props.setTagNameInvalidMsg(msg);
};

export const createClassification = (props) => ({
  IDS: props.selectedCurves.curves.map(curveSummary),
  OperationList: [
    {
      Type: "EnableTag",
      Params: {
        TagKey: props.tagName,
        TagValue: props.labelName,
      },
    },
  ],
});

export const deleteClassification = (tag, label, obj) => ({
  IDS: obj.curves,
  OperationList: [
    {
      Type: "DisableTag",
      Params: {
        TagKey: tag,
        TagValue: label,
      },
    },
  ],
});

export const createClassificationTags = (props) => {
  const pageObj = {};
  props.selectedCurves.curves.forEach((curve) => {
    curve.Tags.forEach((tag) => {
      tag.Value.forEach((tagLabel) => {
        const classification = `${tag.Key}: ${tagLabel}`;
        if (pageObj[classification]) {
          pageObj[classification].curves.push(curveSummary(curve));
        } else {
          pageObj[classification] = { curves: [curveSummary(curve)] };
        }
      });
    });
  });
  mapObjIndexed(({ curves }) => {
    if (curves.length === props.selectedCurveCount) {
      props.incrementFullyClassifiedCount();
    }
  }, pageObj);

  return pageObj;
};

export const mapAllFacetsFn = (data) =>
  data.map((val) =>
    val.Values.map((label) => ({ tag: val.FacetName, label: label.Value }))
  );

export const filterTable = (data, filter) => {
  const keys = ["Type"];
  const filterArr = [];
  filter.map((val) => filterArr.push(val.value));
  return data.filter((curve) =>
    keys.every((val) => filterArr.includes(curve[val]))
  );
};

export const classifyTagNameHandler = (props) => {
  if (equals(regexTest(props.tagName), false)) {
    errorTagHandler("Invalid TAG Name", props);
    return false;
  }

  if (isCoreTag(props.tagName, props.coreTags)) {
    errorTagHandler("TAG Not Permitted", props);
    return false;
  }
  props.setTagValid(true);
  props.setTagNameInvalidMsg(null);
  return true;
};

export const classifyLabelNameHandler = (props) => {
  if (equals(regexTest(props.labelName), false)) {
    errorLabelHandler("Invalid LABEL Name", props);
    return false;
  }
  props.updateLabelNameValidation(true);
  props.updateLabelNameInvalidMsg(null);
  return true;
};

export const requestClassification = (props) => {
  props.classificationRequestAction(createClassification(props));
  props.setLabelName("");
  props.setTagName("");
};

export const deleteClassificationFn = (tags, obj, props) => {
  const [tag, label] = tags.split(": ");
  props.classificationRequestAction(deleteClassification(tag, label, obj));
};

export const applyClassifyFn = (props) => {
  const classifyTagName = classifyTagNameHandler(props);
  const classifyLabelName = classifyLabelNameHandler(props);
  return classifyTagName && classifyLabelName
    ? requestClassification(props)
    : identity;
};

const ranges = [
  "\ud83c[\udf00-\udfff]", // U+1F300 to U+1F3FF
  "\ud83d[\udc00-\ude4f]", // U+1F400 to U+1F64F
  "\ud83d[\ude80-\udeff]", // U+1F680 to U+1F6FF
];

export const removeInvalidChars = (str) =>
  str.replace(new RegExp(ranges.join("|"), "g"), "");
