import { SET_ERROR, CLEAR_ERROR } from "../actions";

const errorInitialState = { error: false, title: "", message: "" };

export default function errorHandlerReducer(state = errorInitialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: true,
        title: action.details.title,
        message: action.details.message,
      };
    case CLEAR_ERROR:
      return errorInitialState;
    default:
      return state;
  }
}

export const ClearError = () => ({ type: CLEAR_ERROR });
