import React from "react";
import { toolTipStyleNoMargin, toolTipStyleTopMargin } from "./tooltipStyles";

export const extractionTutorial = [
  // Intro
  {
    position: "center",
    content: (
      <div>
        <h3>Actual Time Series and Versioned Time Series as MUV</h3>
        Extract a combination of Actual and Version Time series as MUV
        <br />
      </div>
    ),
    style: toolTipStyleNoMargin,
    action: () => {
      if (
        document.getElementsByClassName("atvsTarget collapse show").length < 1
      ) {
        document.getElementById("atvs").click();
      }
      return null;
    },
  },
  // Date Options
  {
    selector: '[class="d-flex width100 noPadding ATSTimeSeriesDateTypeIsVts"]',
    position: "bottom",
    content: (
      <div>
        <h3>Date Options</h3>
        Here you can set the range of time over which you want your market data
        returned
        <br />
        Your options here are:
        <br />
        <b>Date Range</b> - A range of time spaning from the first date to the
        second date
        <br />
        <b>Relative</b> - A range spanning the chosen interval relative to
        todays date
        <br />
        <b>Period From/To</b> - A date range built in the ISO_8601 format,
        allows you to select a period of time from the past and a period of time
        into the future starting from todays date
        <br />
        <b>Period</b> - A date range built in the ISO_8601 format, Allow you to
        select a duration in the past or into the future starting from todays
        date
        <br />
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Granularity
  {
    selector: '[class="d-flex width100 noPadding ATSGranularityIsVts"]',
    position: "right",
    content: (
      <div>
        <h3>Granularity</h3>
        Select your desired Granularity from the dropdown. Returned data will be
        altered depending upon the chosen Granularity.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Time-zone
  {
    selector: '[class="d-flex width100 noPadding ATSTimezoneIsVts"]',
    position: "right",
    content: (
      <div>
        <h3>TimeZone</h3>
        Select a TimeZone from the dropdown. The MarketData returned will match
        the datatime of the TimeZone selected.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Transform
  {
    selector: '[class="d-flex width100 ATSTransformIsVts"',
    position: "left",
    content: (
      <div>
        <h3>Time Transform</h3>
        Select a given Time Transform from the dropdown. The Time Transform
        applies a positive and negative period shift on the market data.
        <br />
        New Time Transforms can be created in the Enrich Time Transform Page.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Excel Button
  {
    selector: '[id="atsDownloadIsVts"]',
    position: "bottom",
    content: (
      <div>
        <h3>Download Excel</h3>
        Download your market data with your selected date ranges and types.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Clipboard Button
  {
    selector: '[id="atsCopyIsVts"]',
    position: "bottom",
    content: (
      <div>
        <h3>Copy Clipboard</h3>
        Alternatively if you wish to extract the URL for use in a BI tool, you
        can copy it straight to your clipboard
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // End
  {
    position: "center",
    content: (
      <div>
        <h3>
          End of Actual Time Series and Versioned Time Series as MUV Tutorial
        </h3>
      </div>
    ),
    style: toolTipStyleTopMargin,
    action: () => {
      if (
        document.getElementsByClassName("atvsTarget collapse show").length < 1
      ) {
        document.getElementById("atvs").click();
      }
      return null;
    },
  },
];

export default extractionTutorial;
