import React from "react";
import { connect } from "react-redux";
import { compose, prop, isEmpty, equals, pipe } from "ramda";
import { withProps, withHandlers, withState } from "recompose";
import { Settings } from "../../settings";
import {
  toggleTableSettingsAction,
  showSettingsWarningModalAction,
  newApiKeyDefaultAction,
} from "../../redux/modules/settings";
import { ApiKeyList, CreateApiKeyList } from "./components";

const mapStateToProps = (state) => ({
  ...state.Settings,
  apiKeyPermission: prop("apiKey", state.Permissions.menuPermission) || [],
});

const MenuItem = ({ selectedSettings, settingsType, clickFn, title, id }) => (
  <div
    id={id}
    className={`menuItem ${
      equals(selectedSettings, settingsType) ? "selected" : ""
    }`}
    onClick={clickFn}
  >
    <span aria-hidden="true">{title}</span>
  </div>
);

const selectedComponent = (props) => {
  switch (props.selectedSettings) {
    case "apiKey":
      return <ApiKeyList />;
    case "createApiKey":
      return <CreateApiKeyList />;
    default:
      return null;
  }
};

export default compose(
  connect(mapStateToProps, {
    showSettingsWarningModalAction,
    toggleTableSettingsAction,
    newApiKeyDefaultAction,
  }),
  withState("selectedSettings", "updateSelectedSettings", "createApiKey"),
  withHandlers({
    updateSelectedSettings: (props) => (val) => {
      props.updateSelectedSettings(val);
      props.newApiKeyDefaultAction();
    },
  }),
  withHandlers({
    setApiKey: (props) => () => props.updateSelectedSettings("apiKey"),
    setCreateApiKey: (props) => () =>
      props.updateSelectedSettings("createApiKey"),
    toggleClose: (props) => () => props.toggleTableSettingsAction(),
  }),
  withHandlers({
    close: (props) => () =>
      pipe(
        () => props.updateSelectedSettings("createApiKey"),
        () => props.toggleClose()
      )([]),
  }),
  withProps((props) => ({
    selectedComponent: selectedComponent(props),
    displayAPI: isEmpty(props.apiKeyPermission),
  })),
  withProps((props) => ({
    createApiMenuItem: props.displayAPI ? null : (
      <MenuItem
        selectedSettings={props.selectedSettings}
        settingsType="createApiKey"
        id="CreateAPIKey"
        clickFn={props.setCreateApiKey}
        title="Create API Key"
      />
    ),
    displayKeysMenuItem: props.displayAPI ? null : (
      <MenuItem
        selectedSettings={props.selectedSettings}
        settingsType="apiKey"
        clickFn={props.setApiKey}
        title="Existing API Keys"
      />
    ),
  }))
)(Settings);
