import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import tutorial from "../styles/images/ExcelTutorialDataTab.png";
import tutorialAuthPic from "../styles/images/ExcelTutorialWebAccessContent.png";
import tutorialPic from "../styles/images/ExcelTutorialPrivacyLevels.png";

export const toolTipStyleBottomMargin = {
  backgroundColor: "#369dad",
  color: "white",
  marginBottom: "10px",
};
export const toolTipStyleImage = {
  backgroundColor: "#369dad",
  color: "white",
  maxWidth: "100%",
  maxHeight: "100%",
};

const Steps = [
  {
    content: (
      <div>
        <h3>Download Excel</h3> <br />
        This button downloads a live Excel.
        <br />
        This tutorial will show you how to connect to and use the excel.
        <br />
        <br />
        If you are using a version lower than 2016 you will need a power query
        add on: &nbsp;
        <a
          className="TourHyperLinkColour"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.microsoft.com/en-us/download/details.aspx?id=39379&CorrelationId=f18b983e-8ffd-42cd-be93-534bc2a709a7"
        >
          Click Here
        </a>
      </div>
    ),
    style: toolTipStyleBottomMargin,
  },
  {
    selector: '[id="userInfoPanel"]',
    content: (
      <div>
        <h3>Settings</h3> <br />
        Click into the user options to create an api key to use with your excel
        file.
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName(
          "dropdown-menu artesianDropdown userMenuHeaderDropdownMenu show"
        ).length < 1
      ) {
        return document.getElementsByClassName("fas fa-caret-down")[0].click();
      }
      return null;
    },
    style: toolTipStyleBottomMargin,
  },
  {
    selector: '[id="APIKeyPanel"]',
    position: "left",
    content: (
      <div>
        <h3>Configure API Keys</h3> <br />
        Click Configure API Keys.
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName("modal fade show d-block").length < 1
      ) {
        document
          .getElementsByClassName("fas fa-cog artesianIconNoHover")[0]
          .click();
      }
      return null;
    },
    style: toolTipStyleBottomMargin,
  },
  {
    selector: '[id="CreateAPIKey"]',
    position: "left",
    content: (
      <div>
        <h3>Create API Key</h3> <br />
        Click Create API Key.
      </div>
    ),
    observe: '[class="settingsMenuContainer"]',
    style: toolTipStyleBottomMargin,
  },
  {
    selector: '[class="settingsMenuContainer"]',
    content: (
      <div>
        <h3>Add API Key</h3> <br />
        <ol>
          <li>
            Add a description for the api key and select a desired expiration
            date.
          </li>
          <li> Click the create button.</li>
          <li> Once the key is created copy the token to your clipboard.</li>
        </ol>
      </div>
    ),
    action: () => {
      if (!document.getElementsByClassName("createApiContainer").length < 1) {
        document.getElementById("expiryNever").click();
      }
    },
    stepInteraction: true,
    observe: '[class="settingsMenuContainer"]',
    style: toolTipStyleBottomMargin,
  },
  {
    selector: '[class="settingsMenuContainer"]',
    content: (
      <div>
        <h3>Use key in excel</h3> <br />
        Once copied to clipboard open the downloaded excel file.
      </div>
    ),
    stepInteraction: true,
    style: toolTipStyleBottomMargin,
  },
  {
    content: (
      <div className="tutorialDiv">
        <h3>Loading the Table</h3> <br />
        <Scrollbars className="tutorialScroll">
          <ol>
            <li>Navigate to the data tab.</li>
            <li> Click the Refresh All button.</li>
          </ol>
          <img className="tutorialImage" src={tutorial} alt="Logo" />
        </Scrollbars>
      </div>
    ),
    style: toolTipStyleImage,
  },
  {
    content: (
      <div className="tutorialPaneDiv">
        <h3>Loading the Table</h3> <br />
        <Scrollbars className="tutorialScroll">
          <ol>
            <li>Navigate to the basic tab.</li>
            <li>Enter the copied api key in the user name textbox.</li>
            <li>Click Connect.</li>
          </ol>
          <img className="tutorialImage" src={tutorialAuthPic} alt="Logo" />
        </Scrollbars>
      </div>
    ),
    style: toolTipStyleImage,
  },
  {
    content: (
      <div className="tutorialPaneDiv">
        <h3>Loading the Table</h3> <br />
        <Scrollbars className="tutorialScroll ">
          <ol>
            <li>Set both dropdowns to Public.</li>
            <li> Click Save button.</li>
          </ol>
          <img className="tutorialImage" src={tutorialPic} alt="Logo" />
          &nbsp; <br />
          Once the api key has been saved to excel it will be usable with all
          future excel downloads.
        </Scrollbars>
      </div>
    ),
    style: toolTipStyleImage,
  },
];

export default Steps;
