import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Scrollbars } from "react-custom-scrollbars";

const MultiSelect = ({
  value = [],
  update,
  options,
  name,
  containsSelectAll = false,
  allString,
  containsFilter = false,
  selectAllTitle,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const [filter, setFilter] = useState(null);
  const opts = R.ifElse(
    (x) => R.isEmpty(x) || R.isNil(x),
    () => options,
    (x) =>
      R.filter((row) => R.contains(R.toLower(x), R.toLower(row.title)), options)
  )(filter);

  useEffect(() => {
    setSelected(value);
    R.when(R.equals(false), () => setFilter(null))(isOpen);
  }, [value, isOpen]);

  const updateFunc = R.unless((res) => R.equals(res, value), update);

  const onUpdate = (row) =>
    setSelected(
      R.ifElse(R.contains(row), R.reject(R.equals(row)), R.append(row))
    );

  const selectAll = () =>
    setSelected(selectedEquals(selected, options) ? [] : R.concat([], options));

  const blurHandler = () => {
    updateFunc(selected);
    setOpen(false);
  };
  const foucsHandler = () => setOpen(true);

  return (
    <div
      className="dropdown customMultiSelect"
      onBlur={unlessChild(blurHandler)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div className="toggle">
        <span>{displayResult(selected, options, name, allString)}</span>
        <i onClick={() => isOpen ? blurHandler() : foucsHandler()} className={`fa fa-caret-${isOpen ? "up" : "down"}`} />
      </div>
      {isOpen ? (
        <div className="dropdown-menu menuList">
          {containsFilter ? <Filter value={filter} update={setFilter} /> : null}
          <Scrollbars
            autoHeight
            autoHide
            className="autocomplete"
            renderTrackHorizontal={(p) => (
              <div
                {...p}
                style={{ display: "none" }}
                className="track-horizontal"
              />
            )}
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            {containsSelectAll ? (
              <SelectAll
                selected={selected}
                options={options}
                selectHandler={selectAll}
                title={selectAllTitle}
              />
            ) : null}
            {opts.map((row, i) => {
              const arr = R.map(R.prop("value"), selected);
              return (
                <div
                  className={`item ${
                    R.contains(row.value, arr) ? "selected" : ""
                  }`}
                  key={`${i}${row.title}`}
                  id={`${row.value}${row.title}`}
                  onClick={() => onUpdate(row)}
                >
                  <i
                    className={`checkbox fas fa-${
                      R.contains(row.value, arr) ? "check-square" : "square"
                    }`}
                  />
                  <label className="checkmark" />
                  <span>{row.title}</span>
                </div>
              );
            })}
          </Scrollbars>
        </div>
      ) : null}
    </div>
  );
};

const displayResult = (val, opts, name, allString = "All Selected") => {
  if (R.isEmpty(val) || R.equals(val.length, opts.length)) {
    return `${allString}`;
  }
  if (val.length > 1) {
    return `${val.length} ${name}`;
  }
  return val[0].title || "";
};

const unlessChild = (fn) =>
  R.unless((e) => e.target.contains(e.relatedTarget) || e.relatedTarget?.contains(e.target), fn);

const selectedEquals = (arr, list) =>
  // eslint-disable-next-line no-underscore-dangle
  R.equals(arr.length, list.length) && R.all(R.contains(R.__, arr), list);

const Filter = ({ value, update, placeholder = "Filter items..." }) => (
  <div className="dropdownFilter">
    <input
      value={value || ""}
      placeholder={placeholder}
      onChange={(e) => update(e.target.value)}
    />
  </div>
);

function SelectAll({ selectHandler, options, selected, title = "Select All" }) {
  return (
    <div
      className={`item selectAll ${
        selectedEquals(options, selected) ? "selected" : ""
      }`}
      onClick={selectHandler}
    >
      <i
        className={`checkbox fas fa-${
          selectedEquals(options, selected) ? "check-square" : "square"
        }`}
      />
      <label className="checkmark" />
      <span>{title}</span>
    </div>
  );
}

export default MultiSelect;
