import moment from "moment";
import { setErrorAction } from "../actions";

export const dispatchNetworkError = (err) => {
  if (err.status > 0) {
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message:
        "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message: "An error has occurred. Please contact support.",
  });
};

export const todaysDate = moment().format("YYYY-MM-DD");
export const yesterdaysDate = moment()
  .subtract(1, "days")
  .format("YYYY-MM-DD");
export const lastWeekDate = moment()
  .subtract(7, "days")
  .format("YYYY-MM-DD");

export default dispatchNetworkError;
