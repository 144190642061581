import React from "react";
import { connect } from "react-redux";
import {
  compose,
  length,
  map,
  pick,
  concat,
  prepend,
  equals,
  pipe,
  propEq,
  split,
  filter,
  unless,
  prop,
  when,
} from "ramda";
import moment from "moment";
import { Tooltip } from "react-tippy";
import { withHandlers, withProps, withState } from "recompose";
import { CurvesActualTimeSeries } from "../../../curveExtract/extractionComponents";
import {
  getAllTimeTransformDataAction,
  copyToClipboardAction,
  downloadXLSXAction,
  downloadIncrementalXLSXAction,
  setExtractionFilters
} from "../../../redux/modules/curveExtract";
import { DropDownScroll, TimeTransformDropdown } from "../../../components";
import { PeriodISOPicker, DateRangePicker } from "../components";
import { buildExtractionString } from "./helper";
import { Selectors } from "../../../redux/modules/tenantSelection";

const PeriodInfoTooltip = () => (
  <Tooltip
    arrow
    interactive
    position="top"
    html={
      <span className="periodInfoIconAnchor">
        What is a Period Duration? -&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
        >
          Click Here
        </a>
      </span>
    }
    theme="arkDefaultTooltip"
  >
    <i
      className="fa fa-info-circle tooltipIcon periodTooltipIcon"
      aria-hidden="true"
    />
  </Tooltip>
);
const excelNames = [
  { 
    name: "ActualTSExtraction", 
    excel: "ActualTSExtraction", 
    templateName: "GenericExtraction", 
    type: null },
  {
    name: "ActualTSExtraction Pivoted CurveName",
    excel: "ActualTSExtraction_Pivoted_CurveName",
    templateName: "GenericExtraction",
    type: "CurveName"
  },
  {
    name: "ActualTSExtraction Pivoted Time",
    excel: "ActualTSExtraction_Pivoted_Time",
    templateName: "GenericExtraction",
    type: "Time"
  }
];

const getExcelName = (type, name) =>
  pipe(filter(propEq("name", type)), map(prop(name)))(excelNames);

const mapStateToProps = (state) => ({
  ...state.CurveExtract,
  ...state.Curves,
  api: Selectors.api(state),
  defaultStartDate: moment().subtract(1, "days"),
  defaultEndDate: moment(),
});

export default compose(
  connect(mapStateToProps, {
    downloadXLSXAction,
    downloadIncrementalXLSXAction,
    copyToClipboardAction,
    getAllTimeTransformDataAction,
    setExtractionFilters
  }),
  withProps((props) => ({
    dropdownValues: props.relativeRangeValues,
    transformValues: concat(
      props.userTransformValues,
      props.systemTransformValues
    ),
    prodId: props.isVts ? "atvs" : "ats",
  })),
  withState(
    "startDate",
    "updateStartDate",
    ({ defaultStartDate }) => defaultStartDate
  ),
  withState("endDate", "updateEndDate", ({ defaultEndDate }) => defaultEndDate),
  withState("excelType", "updateExcelType", "ActualTSExtraction"),
  withState("copyClip", "updateCopyClip", false),
  withState("selectedRelative", "updateSelectedRelative", "Rolling Week"),
  withState("selectedGranularity", "updateSelectedGranularity", "Hour"),
  withState("selectedTimezone", "updateSelectedTimezone", "CET"),
  withState("selectedTransform", "updateSelectedTransform", "None"),
  withState("selectedRangeType", "updateSelectedRangeType", "Date Range"),
  withState("selectPeriod", "updateSelectedPeriod", "P0Y0M0D"),
  withState("selectPeriodFrom", "updateSelectedPeriodFrom", "P0Y0M0D"),
  withState("selectPeriodTo", "updateSelectedPeriodTo", "P0Y0M0D"),
  withState("selectedFiller", "updateSelectedFiller", "Null"),
  withState("customFillValue", "setCustomFillValue", 0),
  withHandlers({
    setStartDate: (props) => (start) => props.updateStartDate(start),
    setEndDate: (props) => (end) => props.updateEndDate(end),
    setExcelType: (props) => (val) => props.updateExcelType(val),
    selectDropdownItem: (props) => (item) => props.updateSelectedRelative(item),
    setSelectedRangeType: (props) => (end) => {
      if (
        !equals(end, "Date Range") &&
        equals(props.excelType, "ActualTSExtraction Incremental Update")
      ) {
        props.updateExcelType("ActualTSExtraction");
      }

      props.updateSelectedRangeType(end);
    },
    setSelectedGranularity: (props) => (gran) =>
      props.updateSelectedGranularity(gran),
    updateSelected: (props) => () =>
      props.updateSelectedExtraction(
        equals(props.selected, props.prodId) ? "" : props.prodId
      ),
    openTutorial: (props) => () => props.toggleTutorial(),
    setDates: (props) => ([startDate, endDate]) => {
      props.updateStartDate(startDate);
      props.updateEndDate(endDate);
    },
    selectFiller: (props) => (item) => {
      props.updateSelectedFiller(item);
    }
  }),
  withHandlers({
    setAsDateRange: (props) => () => props.setSelectedRangeType("Date Range"),
    setAsRelative: (props) => () =>
      props.setSelectedRangeType("relativeSelected"),
  }),
  withHandlers({
    chooseComponent: (props) => () => {
      switch (props.selectedRangeType) {
        case "Date Range":
          return (
            <div className="d-grid">
              <DateRangePicker
                startDate={props.startDate}
                endDate={props.endDate}
                onChange={props.setDates}
                defaultStartDate={props.defaultStartDate}
                defaultEndDate={props.defaultEndDate}
              />
            </div>
          );
        case "Relative":
          return (
            <div className="noPaddingLeft curveExtractDropdown">
              <DropDownScroll
                selected={props.selectedRelative}
                dropDownValues={props.relativeRangeValues}
                onClick={props.updateSelectedRelative}
              />
            </div>
          );
        case "Period From/To":
          return (
            <div className="periodFromTo" key="atsPeriodFromTo">
              <div className="periodFromPadding">
                <PeriodISOPicker
                  isoDate={props.selectPeriodFrom}
                  update={props.updateSelectedPeriodFrom}
                  label={<span>From&nbsp;-&nbsp;</span>}
                />
              </div>
              <div className="d-flex">
                <PeriodISOPicker
                  isoDate={props.selectPeriodTo}
                  update={props.updateSelectedPeriodTo}
                  label={
                    <span className="paddingRight15">To&nbsp;-&nbsp;</span>
                  }
                />
                <div className="periodInfoTooltipPadding">
                  <PeriodInfoTooltip />
                </div>
              </div>
            </div>
          );
        case "Period":
          return (
            <div className="d-flex" key="atsPeriod">
              <PeriodISOPicker
                isoDate={props.selectPeriod}
                update={props.updateSelectedPeriod}
                label=""
              />
              <div className="periodInfoTooltipPadding">
                <PeriodInfoTooltip />
              </div>
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withProps((props) => ({
    transformValuesOptions: compose(
      prepend({ Name: "None", ID: 0 }),
      map(pick(["Name", "ID"]))
    )(props.transformValues),
    actualDataCount: length(
      props.selectedCurves.curves.filter(
        (curve) => curve.Type === "ActualTimeSerie"
      )
    ),
    vtsAtsDataCount: length(
      props.selectedCurves.curves.filter(
        (curve) =>
          curve.Type === "ActualTimeSerie" ||
          curve.Type === "VersionedTimeSerie"
      )
    ),
  })),
  withProps((props) => ({
    dataCount: props.isVts ? props.vtsAtsDataCount : props.actualDataCount,
    selectedRangeTypeComponent: props.chooseComponent(props),
    excelTypeDropDown: (
      <DropDownScroll
        selected={props.excelType}
        dropDownValues={pipe(
          unless(
            () => equals("Date Range", props.selectedRangeType),
            filter(
              (row) => row.excel !== "ActualTSExtraction_IncrementalUpdate"
            )
          ),
          when(
            () => equals(props.extractCustomFilterState, true),
            filter(
              (row) => row.excel !== "ActualTSExtraction_IncrementalUpdate"
            )
          ),
          map(prop("name"))
        )(excelNames)}
        onClick={props.setExcelType}
        dropID="ATSexcelTypeDropDown"
      />
    ),
    granularityDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedGranularity}
        dropDownValues={props.granularityValues}
        onClick={props.updateSelectedGranularity}
        dropID={`ATSGranularity${props.isVts ? "IsVts" : ""}`}
      />
    ),
    urlString: buildExtractionString(props),
    timeSeriesDateTypeDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedRangeType}
        dropDownValues={props.timeSeriesDateTypeValues}
        onClick={props.setSelectedRangeType}
        dropID={`ATSTimeSeriesDateType${props.isVts ? "IsVts" : ""}`}
      />
    ),
    fillerDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedFiller}
        dropDownValues={props.fillerValues}
        onClick={props.selectFiller}
        dropID="ATSFiller"
      />
    ),
    timezoneDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedTimezone}
        dropDownValues={props.timezonesValues}
        onClick={props.updateSelectedTimezone}
        dropID={`ATSTimezone${props.isVts ? "IsVts" : ""}`}
      />
    ),
    transformDropDown: (
      <TimeTransformDropdown
        className="curveExtractDropdown"
        selected={props.selectedTransform}
        dropDownValues={props.transformValuesOptions}
        onClick={props.updateSelectedTransform}
        dropID={`ATSTransform${props.isVts ? "IsVts" : ""}`}
      />
    ),
  })),
  withHandlers({
    copyURL: (props) => () =>
      pipe(
        () => props.copyToClipboardAction(props.urlString),
        () => props.updateCopyClip(true),
        () => setTimeout(() => props.updateCopyClip(false), 800)
      )([]),
    downloadXLSX: (props) => () => {
      const info = {
        url: split("&id", props.urlString)[0],
        excelType: getExcelName(props.excelType, "excel"),
        templateName: getExcelName(props.excelType, "templateName"),
        type: getExcelName(props.excelType, "type"),
        isFilterId: props.extractCustomFilterState,
        curveList: props.selectedCurves.curves.filter((curve) =>
          props.isVts
            ? curve.Type === "ActualTimeSerie" ||
              curve.Type === "VersionedTimeSerie"
            : curve.Type === "ActualTimeSerie"
        ),
        startDate: props.startDate.format("YYYY-MM-DD"),
        endDate: props.endDate.format("YYYY-MM-DD"),
      };

      if (!localStorage.getItem("finishedExcelTour")) props.openTutorial();
      return equals(props.excelType, "ActualTSExtraction Incremental Update")
        ? props.downloadIncrementalXLSXAction(info)
        : props.downloadXLSXAction(info);
    },
  }),
  withProps((props) => ({
    componentTitle: props.isVts
      ? "Actual Time Series and Versioned Time Series as MUV"
      : "Actual Time Series",
  }))
)(CurvesActualTimeSeries);
