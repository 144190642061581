import React from "react";
import { setErrorAction } from "../actions";

export const dispatchNetworkError = (err) => {
  if (err.status > 0) {
    if (err.status === 400) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            Attempting to delete Time Transform
            <br />
            Associated with currently selected market data.
          </div>
        ),
      });
    }
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact admin for more information.
          </div>
        ),
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message:
        "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "Please refresh the page. If the issue persists, please contact support.",
  });
};

export default dispatchNetworkError;
