import React, { useState } from "react";
import * as R from "ramda";
import { NavLink } from "react-router-dom";
import Protected from "../containers/protectedComponent";

const MenuHeader = (props) => (
  <header id="header" className="col-12 noPadding">
    <div className="artesianLogo" />
    <UserMenu {...props} />
    <Protected
      permission={["gmepublicoffer"]}
      component={() => <MenuOptions {...props} />}
    />
  </header>
);

export default MenuHeader;

const MenuOptions = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="dropdown artesianDropdownContanier userMenuHeaderDropdown">
      <div className="btn-group width100">
        <div
          className="d-flex width100 noPadding"
          id="userInfoPanel"
          onClick={() => setOpen(!isOpen)}
        >
          <div className="userMenuHeader">
            <div className="userMenuHeaderInfo">
              <span className="location">
                {R.pipe(
                  R.filter(R.propEq("path", props.location.pathname)),
                  R.pathOr("", [0, "name"])
                )(Routes)}
              </span>
            </div>
          </div>
          <div className="split" onClick={() => setOpen(!isOpen)}>
            <i className="fas fa-caret-down" />
          </div>
        </div>
        <div
          className={`dropdown-menu artesianDropdown userMenuHeaderDropdownMenu ${
            isOpen ? "show" : ""
          }`}
        >
          <div className="container">
            <NavLink
              to="/"
              className="headerLinkItem"
              onClick={() => setOpen(false)}
            >
              <div id="homeLink">Time Series</div>
            </NavLink>
            <NavLink
              to="/GMEPublicOffer"
              className="headerLinkItem"
              onClick={() => setOpen(false)}
            >
              <div id="gmePublicOfferLink">GME Public Offer</div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const Routes = [
  { path: "/", name: "Time Series" },
  { path: "/GMEPublicOffer", name: "GME Public Offer" },
];

const UserMenu = (props) => (
  <div className="dropdown artesianDropdownContanier userMenuHeaderDropdown userMenuHeaderMain">
    <div className="btn-group width100">
      <div
        className="d-flex width100 noPadding"
        id="userInfoPanel"
        onClick={props.setUserMenuState}
      >
        <div className="userMenuHeader">
          <i className="fa fa-user fa-2x artesianIconNoHover" />
          <div className="userMenuHeaderInfo">
            <span className="name">{props.userName}</span>
            <span className="tenant">{props.companyName}</span>
          </div>
        </div>
        <div className="split" onClick={props.toggleDropdown}>
          <i className="fas fa-caret-down" />
        </div>
      </div>
      <div
        className={`dropdown-menu artesianDropdown userMenuHeaderDropdownMenu ${
          props.userMenuState || window.artesianExcelTutorialOpen ? "show" : ""
        }`}
      >
        <div className="container">{props.component}</div>
      </div>
    </div>
  </div>
);
