import React from "react";
import { compose, propOr, map, pathOr } from "ramda";
import { withState, withHandlers } from "recompose";
import { Scrollbars } from "react-custom-scrollbars";
import {
  CurvesTableContainer,
  CurvesContextMenuContainer,
} from "../containers/curves";
import CurvesTableFilter from "./curvesTableFilter";
import { Button, FacetSearchTextBox, DropDown } from "../components";
import { SelectAllBtn, ClearFilterBtn, ExtractFilterBtn } from "./components";
import * as XLSX from 'xlsx';
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";
import { setTutorialModalOpenAction, setVideoUrlAction } from '../redux/modules/tutorial';

const downloadSelectedCurvesExcel = (seledCurves) => {
var downloadData = map((curve)=>{
  return { 
    MarketDataId: propOr("", "MarketDataId", curve),
    MarketDataName: propOr("", "MarketDataName", curve),
    AggregationRule: propOr("", "AggregationRule", curve),
    Provider: propOr("", "ProviderName", curve),
    MarketdataType: propOr("", "Type", curve),
    Timetransform: propOr("", "Transform", curve)
  }
})(seledCurves)
  const ws = XLSX.utils.json_to_sheet(downloadData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, "selectedCurves" + '.xlsx');
};

const Curves = ({ setTutorialModalOpenAction, setVideoUrlAction, ...props }) => (
  <div className="curvesTable">
    <CurvesContextMenuContainer menuUpdate={props.menuUpdate} />
    <div className="pageTitle">
      <span>
        <i className="fa fa-mouse-pointer marginRight10 artesianIconNoHover" />
      </span>
      Select MarketData
      <span>  
        <i className="fas fa-question-circle artesianIconHover" onClick={() => {setVideoUrlAction("https://www.youtube.com/embed/WzBjP6Wxaj0?si=iAkE0HVuPRH7t7oi"); setTutorialModalOpenAction(true);}} />
      </span>
      {/***TODO: excelDownload****/}

      {pathOr([], ["selectedCurves","curves"], props).length>0?<span
          aria-hidden="true"
          disabled={true}
          className="excelDownload" 
          onClick={() => downloadSelectedCurvesExcel(pathOr([], ["selectedCurves","curves"], props))}
        >
        <Tooltip
          arrow
          position="bottom"
          theme="seachInfo"
          title="Download Metadata of selected curves"
        >
          <i className={"fas fa-solid fa-download artesianIconDownloadHover"}></i>
        </Tooltip> 
      </span>: <></>
      }
    </div>
    <div className="d-flex height100">
      <div
        className={`mainFacetsContainer transition ${
          props.facetVisible ? "facetsContainerOpen" : "facetsContainerClosed"
        }`}
      >
        <div
          className={`facetsContainer transition ${
            props.facetVisible ? "width90" : "width0"
          }`}
        >
          <div className="facets noPaddingTop">
            {props.customFilterDropDown}
            <FacetSearchTextBox {...props} />
          </div>
          <Scrollbars
            className="scrollFacets"
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            {props.facetPanels}
          </Scrollbars>
        </div>
        <Button
          onClick={props.setFacetVisibility}
          btnText={
            <i
              className={`fa ${
                props.facetVisible
                  ? "fa-angle-double-left"
                  : "fa-angle-double-right"
              }`}
            />
          }
          className="facetDisplayBtn"
        />
      </div>
      <div
        className={`curvesContainer transition ${
          props.facetVisible ? "curvesContainerOpen" : "curvesContainerClosed"
        }`}
      >
        <div className="d-flex flex-column height100">
          <div className="col static-flex noPaddingRight">
            <div className="row curveTableFilterContainer">
              <div className="col-lg-12 noPadding displaySortingContainer">
                <div className="col row facetButtonContainer">
                  <div className="option col-lg-2 col-md-6 col-sm-12 ">
                    <DropDown
                      selected={props.selectedSort}
                      dropDownValues={props.sortValues}
                      onClick={props.sortDropdownFn}
                      onPage="Curves"
                      dropID="Curves"
                    />
                  </div>
                  <div className="option col-lg-3 col-md-6 col-sm-12 ">
                    <CurvesTableFilter {...props} />
                  </div>
                  <div className="option col-lg-3 col-md-12 col-sm-12 ">
                    <SelectAllBtn
                      count={props.tablesOptions.curveCount}
                      selectAllFn={props.selectAllCurves}
                    />
                  </div>
                  <div className="option col-lg-2 col-md-6 col-sm-12 ">
                    <ClearFilterBtn clearAllFunction={props.clearAllFunction} />
                  </div>
                  <div className="option col-lg-2 col-md-6 col-sm-12 ">
                    <ExtractFilterBtn
                      extractFilterFunc={props.extractCustomUserFilter}
                      disabled={props.filterExtractBtnDisabled}
                      hasFilterChanged={props.hasFilterChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 d-sm-none d-xs-none d-md-block displaySelectedFilterContainer static-flex">
                {props.selectedFilterComponents}
              </div>
            </div>
          </div>
          <div className="row col curveTableMainContainer noPaddingRight">
            <CurvesTableContainer count={props.tablesOptions.curveCount} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction
};
const enhancer = compose(
  withState("facetVisible", "updateFacetVisibility", true),
  withHandlers({
    setFacetVisibility: (props) => () =>
      props.updateFacetVisibility(!props.facetVisible),
  }),
  connect(null, mapDispatchToProps)
);

export default enhancer(Curves);
