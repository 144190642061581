import React from "react";
import { head, __, values, unnest, replace, compose } from "ramda";
import { setErrorAction } from "../actions";

const getErrorMsg = compose(
  replace("Original Timezone", "Timezone", __),
  head,
  unnest,
  values
);

export const dispatchNetworkError = (err) => {
  if (err.status > 0) {
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact admin for more information.
          </div>
        ),
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message: err.response
        ? getErrorMsg(err.response)
        : "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "Please refresh the page. If the issue persists, please contact support.",
  });
};
export const dispatchNetworkErrorAddAlert = (err) => {
  if (err.status > 0) {
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact admin for more information.
          </div>
        ),
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message: err.response
        ? err.response.title
        : "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "Please refresh the page. If the issue persists, please contact support.",
  });
};
