import { merge } from "rxjs";
import { filter, first, tap, map } from "rxjs/operators";
import * as R from "ramda";
import propPath from "crocks/Maybe/propPath";
import either from "crocks/pointfree/either";

export const setUserListener = (evt, appInsights) => action$ =>
  action$.ofType(evt).pipe(
    first(),
    map(R.prop("payload")),
    tap(user => appInsights.setAuthenticatedUserContext(user.email))
  );

export const combineAnalytics = analytics => (...args) =>
  merge(...analytics.map(a => a(...args))).pipe(filter(R.F));

export const listen = (type, action) => action$ =>
  action$.ofType(type).pipe(tap(action));

export const ClassifyCurves = (appInsights, portalType) =>
  R.pipe(
    propPath(["OperationList", 0, "Params"]),
    either(R.identity, ev =>
      appInsights.trackEvent(`${portalType}_ClassifyCurves`, ev)
    )
  );

export const ToggleCurve = (appInsights, toggleType, portalType) =>
  R.pipe(
    propPath(["row", 0, "MarketDataId"]),
    R.map(curveId => ({
      curveId,
      toggleType
    })),
    either(R.identity, ev =>
      appInsights.trackEvent(`${portalType}_ToggleCurve`, ev)
    )
  );
