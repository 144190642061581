import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

export const classifyTutorial = [
  // GOTO previous page.
  {
    selector: '[class="btn  menuBtnItem Refine "]',
    action: (node) => node.click(),
    content: <div />,
  },
  // Title
  {
    position: "center",
    content: (
      <div>
        <h3>Classify MarketData</h3>
        This allows you to apply your own means of classification to selected
        market data in order to better facilitate future searching and retrieval of
        relevant data items.
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Assign Tag
  {
    selector: '[id="labelInput"]',
    position: "bottom",
    content: (
      <div>
        <h3>Add Classification Tag</h3>
        Adding a Classification Facet allows you to group selected market data within
        a new or existing Facet. Existing Facets can be selected from the
        dropdown, while new, user-defined Facets can be added via the text
        field.
      </div>
    ),
    // eslint-disable-next-line
    action: (node) => (node.value = "Country"),
    style: toolTipStyleTopMargin,
  },
  // Assign Label
  {
    selector: '[id="tagInput"]',
    position: "bottom",
    content: (
      <div>
        <h3>Add Classification Label</h3>
        To add a new label for a given Facet you can select a pre-existing Facet
        from the dropdown or create a new one.
      </div>
    ),
    // eslint-disable-next-line
    action: (node) => (node.value = "MarketAssessment"),
    style: toolTipStyleTopMargin,
  },
  // Classify Button
  {
    selector: '[class="btn btn applyClassifyBtn btnDisabled"]',
    position: "bottom",
    content: (
      <div>
        <h3>Add Classification Tag & Label</h3>
        When you have finished creating your facet and label you can add these
        classifications to your selected market data by clicking the Classify button.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Extract Button
  {
    selector: '[class="btn  menuBtnItem Extract "]',
    position: "right",
    content: (
      <div>
        <h3>Go to Extraction Page</h3>
        Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    style: toolTipStyleLeftMargin,
  },
  // GOTO next page
  {
    selector: '[class="btn  menuBtnItem Extract "]',
    action: (node) => node.click(),
    content: <div />,
  },
];

export default classifyTutorial;
