import React from "react";

const CompanyList = (props) => (
  <div className="companyList">
    <div className="titleList">
      <span onClick={props.close}>
        <i className="fas fa-caret-left" />
      </span>
      Switch Company
    </div>
    {props.tentantComponents}
  </div>
);

export default CompanyList;
