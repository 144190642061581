import React from "react";

const ArtesianTut = (props) => {
  // eslint-disable-next-line
  const TourEx = props.TourEx;
  return (
    <div>
      <TourEx {...props} />
    </div>
  );
};

export default ArtesianTut;
