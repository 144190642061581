import * as R from "ramda";
import { EMPTY, of, throwError } from "rxjs";
import * as RxOp from "rxjs/operators";
import platform from "platform";
import { ajax } from "rxjs/ajax";

const keyType = ({ key, data }) => R.pipe(R.prop(key), R.type)(data);

const objDataBuilder = ({ data, equator, join }) =>
  R.pipe(
    R.keys,
    R.map((key) => `${key} ${equator} '${R.prop(key, data)}'`),
    R.join(` ${join} `)
  )(data);

const arrDataBuilder = ({ key, data, equator, join }) => {
  switch (R.type(data[0])) {
    case "Object":
      return R.pipe(
        R.map(({ value }) => {
          const val = R.equals(equator, "eq") ? `'${value}'` : value;
          return `${key} ${equator} ${val}`;
        }),
        R.values,
        R.join(` ${join} `)
      )(data);
    case "Number":
    case "String":
      return R.pipe(
        R.map((value) => {
          const val = R.equals(equator, "eq") ? `'${value}'` : value;
          return `${key} ${equator} ${val}`;
        }),
        R.join(` ${join} `)
      )(data);
    default:
      return "";
  }
};

const builder = ({ key, data, equator, join }) => {
  switch (keyType({ key, data })) {
    case "Array":
      return arrDataBuilder({ key, data: R.prop(key, data), equator, join });
    case "Object":
      return objDataBuilder({ data: R.prop(key, data), equator, join });
    case "Number":
    case "String": {
      const val = R.prop(key, data);
      const res = R.equals(equator, "eq")
        ? `'${val}'`
        : encodeURIComponent(val);
      return R.isEmpty(val) ? "" : `${key} ${equator} ${res}`;
    }
    default:
      return "";
  }
};

export const queryStringBuilder = ({ filters }) =>
  R.pipe(
    R.keys,
    R.map((key) => builder({ key, data: filters, equator: "eq", join: "or" })),
    R.filter((x) => x),
    R.join(" and "),
    encodeURIComponent,
    R.unless(R.isEmpty, (x) => `&$filter=${x}`)
  )(filters);

export const searchBuilder = ({ filters }) =>
  R.pipe(
    R.keys,
    R.map((key) => builder({ key, data: filters, equator: "=", join: "&" })),
    R.filter((x) => x),
    R.join("&"),
    R.unless(R.isEmpty, (x) => `&${x}`),
    R.unless(R.isEmpty, (x) => R.replace(/ /g, "", x))
  )(filters);

export const getRequest = ({
  api,
  page = 1,
  pageSize = 999,
  token,
  filters,
}) => {
  const queryString = searchBuilder({ filters: { ...filters } });

  return of(`${api}?page=${page}&pageSize=${pageSize}${queryString}`).pipe(
    RxOp.switchMap((url) =>
      ajax({
        url,
        responseType: "json",
        headers: { Authorization: `Bearer ${token}`,  'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
      }).pipe(
        RxOp.map((x) => x.response),
        RxOp.catchError(throwError)
      )
    )
  );
};

export const getAllRequests = ({ api, filters, token }) =>
  getRequest({
    api,
    filters,
    token,
  }).pipe(
    RxOp.expand((x) =>
      x.isCountPartial || x.PageSize * x.Page < x.Count
        ? getRequest({
            filters,
            api,
            token,
            page: R.add(1, x.Page),
          })
        : EMPTY
    ),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
