import React from "react";
import { toolTipStyleNoMargin, toolTipStyleTopMargin } from "./tooltipStyles";

export const userAdminPageTutorial = [
  {
    selector: '[class="btn  menuBtnItem Extract "]',
    action: (node) => node.click(),
    content: <div />,
  },
  {
    position: "center",
    content: (
      <div>
        <h3>User Management</h3>
        Create, update and delete users for your organistation
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Select a Group
  {
    selector: '[class="btn addGroupBtn addUserBtn "]',
    position: "bottom",
    content: (
      <div>
        <h3>Add a new User</h3>
        Create and add a new user to the system. The user must be associated to
        1 group to be able to view data.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Table
  {
    selector:
      '[class="react-bs-table react-bs-table-bordered usersGroupsTableContainer"]',
    position: "bottom",
    content: (
      <div>
        <h3>All User</h3>
        Displays all all users associated to each group in the system. Admins
        can remove/ add users from existings groups or delete the user enitirely
        from the system.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // End tutorial
  {
    position: "center",
    content: (
      <div>
        <h3>This ends the market data enrichment tutorial.</h3>
        <div>Press &apos;x&apos; to exit the tutorial.</div>
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
];

export default userAdminPageTutorial;
