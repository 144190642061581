import React from "react";

const TextBox = (props) => (
  <input
    type="text"
    className={`form-control textInput ${props.className}`}
    placeholder={props.placeholder}
    value={props.value}
    disabled={props.disabled}
    id={props.id || "inputGroup"}
    onChange={(val) => props.onChange(val.target.value)}
  />
);

export default TextBox;
