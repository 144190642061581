import * as RX from "rxjs";
import { ajax } from "rxjs/ajax";
import * as RxOp from "rxjs/operators";
import platform from "platform";
import * as R from "ramda";

export const updateGroup = ({ token, api, data, ID }) =>
  ajax({
    url: `${api}/v2.1/group/${ID}`,
    method: "PUT",
    responseType: "json",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

const getGroups = ({ api, token, page, pageSize }) =>
  ajax({
    url: `${api}/v2.1/group?page=${page}&pageSize=${pageSize}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllGroups = ({ token, api }) => {
  const info = {
    token,
    api,
    page: 1,
    pageSize: 500,
  };
  return getGroups(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getGroups({
            ...info,
            page: val.Page + 1,
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};

const getPathPermission = ({ api, token, id }) =>
  ajax({
    url: `${api}/v2.1/acl/me?path=${encodeURIComponent(
      `/principal/group/${id}`
    )}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`},
  }).pipe(RxOp.map((x) => x.response));

const checkOwner = ({ data, id }) =>
  R.pipe(
    R.head,
    R.prop("Roles"),
    R.reduce(
      (a, b) =>
        (R.equals(b.InheritedFrom, `/principal/group/${id}`) ||
          R.equals(b.InheritedFrom, `/`)) &&
        b.Role === "Owner"
          ? R.append(id, a)
          : a,
      []
    ),
    R.uniq
  )(data);

export const getAllOwnerGroups = ({ api, token, groups }) =>
  RX.of(groups).pipe(
    RxOp.flatMap((x) => x),
    RxOp.flatMap((x) =>
      getPathPermission({
        api,
        token,
        id: x.ID,
      }).pipe(RxOp.map((res) => checkOwner({ data: res, id: x.ID })))
    ),
    RxOp.toArray(),
    RxOp.map(R.flatten)
  );
