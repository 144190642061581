import * as RX from "rxjs";
import { ajax } from "rxjs/ajax";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import platform from "platform";

export const deleteApiKeyRequest = ({ token, api, keyId }) => ajax({
    url: `${api}/v2.1/apikey/entity/${keyId}`,
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map(R.prop("response")))

export const createApiKeyRequest = ({ token, api, data }) =>
  ajax({
    url: `${api}/v2.1/apikey/entity`,
    method: "POST",
    responseType: "json",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map(R.prop("response")), RxOp.map(R.prop("Key")));

const getUserApiKeys = ({ api, token, email, page, pageSize }) =>
  ajax({
    url: `${api}/v2.1/apikey/entity?page=${page}&pageSize=${pageSize}&userId=${encodeURIComponent(
      email
    )}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllUsersApiKeys = ({ state, token, api }) => {
  const info = {
    token,
    api,
    email: state.Authentication.profile.email,
    page: 1,
    pageSize: 500,
  };
  return getUserApiKeys(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getUserApiKeys({
            ...info,
            page: val.Page + 1,
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};
