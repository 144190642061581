import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

const MenuItem = ({
  toolTipText,
  btnFn,
  btnClass,
  btnText,
  duration,
  navigationDisabled,
  itemNameId,
}) => (
  <div className="menuIcons" id={itemNameId}>
    <Tooltip
      arrow
      touchHold
      position="left"
      html={toolTipText}
      duration={duration || 375}
      theme="arkDefaultTooltip"
    >
      <Button
        onClick={btnFn}
        className={`${btnClass} ${itemNameId}`}
        btnText={btnText}
        disabled={navigationDisabled}
      />
    </Tooltip>
  </div>
);

export default MenuItem;
