import { connect } from "react-redux";
import { compose, contains, prop, map, pipe } from "ramda";
import { withHandlers } from "recompose";
import { CurvesContextMenu } from "../../curves";
import {
  toggleTableRowSelectionAction,
  CurvesSelectors,
} from "../../redux/modules/curves";
import { getPreviewTableAction } from "../../redux/modules/curvePreview";

const mapStateToProps = (state) => ({
  selectedCurves: CurvesSelectors.selectedCurves(state),
});

export default compose(
  connect(mapStateToProps, {
    toggleTableRowSelectionAction,
    getPreviewTableAction,
  }),
  withHandlers({
    curveContectMenuHandler: (props) => (page, row) => {
      const hasBeenSelected = pipe(map(prop("MarketDataId")), (x) =>
        contains(row.MarketDataId, x)
      )(props.selectedCurves.curves);

      if (!hasBeenSelected) {
        props.toggleTableRowSelectionAction([row], true);
      }
      props.menuUpdate({ page, dataType: row.Type, override: true });
      return true;
    },
  }),
  withHandlers({
    contextCurvePreview: (props) => (e, data) =>
      props.getPreviewTableAction(data.row),
    contextClassify: (props) => (e, data) =>
      props.curveContectMenuHandler("classifyCurves", data.row),
    contextExtraction: (props) => (e, data) =>
      props.curveContectMenuHandler("extractCurves", data.row),
    contextEnrichMeta: (props) => (e, data) =>
      props.curveContectMenuHandler("enrichMetaData", data.row),
  })
)(CurvesContextMenu);
