import React from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";

const Contextmenu = (props) => (
  <ContextMenu id="curveTableContextMenu">
    <MenuItem onClick={props.contextCurvePreview}>
      <i className="fa fa-table" /> MarketData Preview
    </MenuItem>
    <MenuItem divider />
    <MenuItem onClick={props.contextClassify}>
      <i className="fa fa-tag" /> Classify MarketData
    </MenuItem>
    <MenuItem onClick={props.contextExtraction}>
      <span className="fa-stack">
        <i className="fa fa-folder-open fa-stack-folder" />
        <i className="fa fa-arrow-down fa-stack-arrow" />
      </span>
      Extract MarketData
    </MenuItem>
    <MenuItem onClick={props.contextEnrichMeta}>
      <i className="fa fa-edit" /> Enrich MarketData MetaData
    </MenuItem>
  </ContextMenu>
);

export default Contextmenu;
