import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../../components";

const RemoveAllSelectedOrder = (props) => (
  <Button
    className="close close-default remove-box-shadow btn-circle removeSelectedCurve"
    onClick={() => props.removeAllSelectedCurves()}
    btnText={
      <Tooltip
        arrow
        position="bottom"
        html={<span>Delete All</span>}
        theme="arkDefaultTooltip"
      >
        <i className="fa fa-times icon" />
      </Tooltip>
    }
  />
);

export default RemoveAllSelectedOrder;
