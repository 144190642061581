import { combineReducers } from "redux";
import * as R from "ramda";
import { of } from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineEpics } from "redux-observable";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector } from "../tenantSelection";
import { getRequest, getAllRequests } from "../helper";
import { createDemandOfferExcel } from "./download";
import { dispatchNetworkError } from "./helper";

export const EMPTY_DATA = "demandOffer/EMPTY_DATA";
export const REQUEST_ENUM_OPERATORS = "demandOffer/REQUEST_ENUM_OPERATORS";
export const SET_ENUM_OPERATORS = "demandOffer/SET_ENUM_OPERATORS";
export const REQUEST_ENUM_UNITS = "demandOffer/REQUEST_ENUM_UNITS";
export const SET_ENUM_UNITS = "demandOffer/SET_ENUM_UNITS";

export const SET_DEMAND_OFFER_PAGE = "demandOffer/SET_DEMAND_OFFER_PAGE";
export const SET_DEMAND_OFFER_PAGE_SIZE =
  "demandOffer/SET_DEMAND_OFFER_PAGE_SIZE";
export const CLEAR_DEMAND_OFFER_FILERS =
  "demandOffer/CLEAR_DEMAND_OFFER_FILERS";
export const CLEAR_ALL_DEMAND_OFFER_FILERS =
  "demandOffer/CLEAR_ALL_DEMAND_OFFER_FILERS";
export const SET_DEMAND_OFFER_FILERS = "demandOffer/SET_DEMAND_OFFER_FILERS";
export const SET_DEMAND_OFFER_DATA = "demandOffer/SET_DEMAND_OFFER_DATA";
export const DOWNLOAD_DEMAND_OFFER = "demandOffer/DOWNLOAD_DEMAND_OFFER";
export const COMPLETE_DEMAND_OFFER = "demandOffer/COMPLETE_DEMAND_OFFER";
export const PREVIEW_DEMAND_OFFER = "demandOffer/PREVIEW_DEMAND_OFFER";
export const SET_PREVIEW_DEMAND_OFFER_DATA =
  "demandOffer/SET_PREVIEW_DEMAND_OFFER_DATA";
export const SET_PREVIEW_DEMAND_OFFER_PAGE =
  "demandOffer/SET_PREVIEW_DEMAND_OFFER_PAGE";
export const PREVIEW_DEMAND_OFFER_CLOSE =
  "demandOffer/PREVIEW_DEMAND_OFFER_CLOSE";
export const SET_ERROR = "demandOffer/SET_ERROR";
export const CLOSE_ERROR = "demandOffer/CLOSE_ERROR";
export const CANCEL = "demandOffer/CANCEL";

const demandOfferState = {
  isFetching: false,
  filters: {
    date: null,
    purpose: null,
    status: null,
    operators: null,
    unit: null,
    market: null,
    scope: null,
    baType: null,
    zone: null,
    unitType: null,
    generationType: null,
  },
};

const demandOffer = (state = demandOfferState, action) => {
  switch (action.type) {
    case SET_DEMAND_OFFER_FILERS:
      return { ...state, filters: action.filters };
    case CLEAR_ALL_DEMAND_OFFER_FILERS:
      return { ...state, filters: demandOfferState.filters };
    case CLEAR_DEMAND_OFFER_FILERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...R.omit(["date", "purpose", "status"], demandOfferState.filters),
        },
      };
    case DOWNLOAD_DEMAND_OFFER:
      return { ...state, isFetching: true };
    case COMPLETE_DEMAND_OFFER:
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const previewDemandOfferState = {
  page: 1,
  pageSize: 100,
  isFetching: false,
  open: false,
  data: [],
};

const previewDemandOfferReducer = (state = previewDemandOfferState, action) => {
  switch (action.type) {
    case PREVIEW_DEMAND_OFFER:
      return { ...state, isFetching: true };
    case SET_PREVIEW_DEMAND_OFFER_DATA:
      return { ...state, isFetching: false, open: true, data: action.data };
    case PREVIEW_DEMAND_OFFER_CLOSE:
      return { ...state, open: false };
    case SET_PREVIEW_DEMAND_OFFER_PAGE:
      return { ...state, page: action.page };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const operatorEnumState = {
  isFetching: false,
  data: [],
};
const operatorEnumReducer = (state = operatorEnumState, action) => {
  switch (action.type) {
    case REQUEST_ENUM_OPERATORS:
      return { ...state, isFetching: true };
    case SET_ENUM_OPERATORS:
      return { ...state, isFetching: false, data: action.data };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const unitEnumState = {
  isFetching: false,
  data: [],
};
const unitEnumReducer = (state = unitEnumState, action) => {
  switch (action.type) {
    case REQUEST_ENUM_UNITS:
      return { ...state, isFetching: true };
    case SET_ENUM_UNITS:
      return { ...state, isFetching: false, data: action.data };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
const errorInitialState = { error: false, title: "", message: "" };
export default function errorHandlerReducer(state = errorInitialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: true,
        title: action.details.title,
        message: action.details.message,
      };
    case CLOSE_ERROR:
      return errorInitialState;
    default:
      return state;
  }
}

export const reducer = combineReducers({
  data: demandOffer,
  previewData: previewDemandOfferReducer,
  operatorEnum: operatorEnumReducer,
  unitEnum: unitEnumReducer,
  errorHandler: errorHandlerReducer,
});

const tokenFromState = R.compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxOp.map((token) => ({
      token,
      api: TenantSelector.api(state),
      state,
    }))
  );

export const setError = (details) => ({
  type: SET_ERROR,
  details,
});
export const closeError = () => ({
  type: CLOSE_ERROR,
});
export const cancel = () => ({
  type: CANCEL,
});
export const clearData = () => ({
  type: EMPTY_DATA,
});

export const setDemandOfferFilters = (filters) => ({
  type: SET_DEMAND_OFFER_FILERS,
  filters,
});
export const clearDemandOfferFilters = () => ({
  type: CLEAR_DEMAND_OFFER_FILERS,
});
export const clearAllDemandOfferFilters = () => ({
  type: CLEAR_ALL_DEMAND_OFFER_FILERS,
});
export const startDownlaodDemandOffer = () => ({
  type: DOWNLOAD_DEMAND_OFFER,
});
const demandOfferComplete = () => ({
  type: COMPLETE_DEMAND_OFFER,
});

export const downloadDemandOffer = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startDownlaodDemandOffer())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api, state }) => {
        const info = Selectors.all(state);
        const { filters } = info.data;
        return getAllRequests({
          api: `${api}/gmepublicoffer/v2.0/extract/${filters.date}/${filters.purpose}/${filters.status}`,
          token,
          filters: R.omit(["date", "purpose", "status"], filters),
        });
      }),
      RxOp.map((data) => createDemandOfferExcel({ data }))
    )
    .subscribe(
      R.compose(dispatch, demandOfferComplete),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

export const startPreviewDemandOffer = () => ({
  type: PREVIEW_DEMAND_OFFER,
});
export const previewDemandOfferClose = () => ({
  type: PREVIEW_DEMAND_OFFER_CLOSE,
});
export const setPreviewDemandOfferPage = (page) => ({
  type: SET_PREVIEW_DEMAND_OFFER_PAGE,
  page,
});
const setPreviewDemandOfferData = (data) => ({
  type: SET_PREVIEW_DEMAND_OFFER_DATA,
  data,
});

export const previewDemandOffer = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startPreviewDemandOffer())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api, state }) => {
        const info = Selectors.all(state);
        const { filters } = info.data;
        const preview = info.previewData;
        return getRequest({
          api: `${api}/gmepublicoffer/v2.0/extract/${filters.date}/${filters.purpose}/${filters.status}`,
          token,
          page: preview.page,
          pageSize: preview.pageSize,
          filters: R.omit(["date", "purpose", "status"], filters),
        });
      })
    )
    .subscribe(
      R.compose(dispatch, (x) => setPreviewDemandOfferData(x.Data)),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

const startOperatorEnum = () => ({
  type: REQUEST_ENUM_OPERATORS,
});
const setOperatorEnum = (data) => ({
  type: SET_ENUM_OPERATORS,
  data,
});

export const requestOperatorEnum = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startOperatorEnum())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api }) =>
        getAllRequests({
          api: `${api}/gmepublicoffer/v2.0/enums/operators`,
          token,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, setOperatorEnum),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

const setUnitsEnum = (data) => ({
  type: SET_ENUM_UNITS,
  data,
});

export const requestUnitsEnum = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startOperatorEnum())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api }) =>
        getAllRequests({
          api: `${api}/gmepublicoffer/v2.0/enums/units`,
          token,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, setUnitsEnum),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

export const epic = combineEpics();

export const Selectors = {
  all: R.path(["DemandOffer"]),
};
