import { combineReducers } from "redux";
import {
  always,
  compose,
  of,
  ap,
  prop,
  pick,
  curry,
  assoc,
  map,
  reduce,
  equals,
  keys,
  without,
} from "ramda";
import * as RX from "rxjs";
import { flatMap, tap, map as RxMap, delay } from "rxjs/operators";
import {
  simpleSetReducerCreator,
  fetchingReducerCreator,
} from "../../reducerCreators";
import { dispatchNetworkError } from "./helper";
import {
  requestUserDefinedData,
  requestSystemDefinedData,
  enrichCurvesRequest,
} from "./request";
import { updateSelectedCurvesList, requestCurvesAction } from "../curves";
import { timezonesValues, aggregationRuleValues } from "./constants";
import { auth } from "../../../appContext";
import { Selectors as TenantSelectors } from "../tenantSelection";

const RECIEVED_FACETS = "artesian-ui/curveEnrich/RECIEVED_FACETS";
const FETCHINGDATA = "artesian-ui/curveEnrich/FETCHINGDATA";
const RECIEVEDDATA = "artesian-ui/curveEnrich/RECIEVEDDATA";
const RECIEVED_SYSTEM_TRANSFORM_DATA =
  "artesian-ui/curveEnrich/RECIEVED_SYSTEM_TRANSFORM_DATA";
const RECIEVED_USER_TRANSFORM_DATA =
  "artesian-ui/curveEnrich/RECIEVED_USER_TRANSFORM_DATA";
const SHOW_SEND_SELCTED_ENRICH_WARNING_MODAL =
  "artesian-ui/curveEnrich/SHOW_SEND_SELCTED_ENRICH_WARNING_MODAL";
const HIDE_SEND_SELCTED_ENRICH_WARNING_MODAL =
  "artesian-ui/curveEnrich/HIDE_SEND_SELCTED_ENRICH_WARNING_MODAL";
const SET_ENRICH_TIMEZONE = "artesian-ui/curveEnrich/SET_ENRICH_TIMEZONE";
const SET_ENRICH_TRANSFORM = "artesian-ui/curveEnrich/SET_ENRICH_TRANSFORM";
const SET_ENRICH_AGGREGATION_RULE =
  "artesian-ui/curveEnrich/SET_ENRICH_AGGREGATION_RULE";
const SET_ENRICH_DESCRIPTION = "artesian-ui/curveEnrich/SET_ENRICH_DESCRIPTION";
export const CREATE_NEW_METADATA =
  "artesian-ui/curveEnrich/CREATE_NEW_METADATA";

const sendCurveEnrichWarningModalDefaultState = {
  showModal: false,
  modalMessage: "",
};

const curveEnrichWarningModalState = (
  state = sendCurveEnrichWarningModalDefaultState,
  action
) => {
  switch (action.type) {
    case SHOW_SEND_SELCTED_ENRICH_WARNING_MODAL:
      return {
        ...state,
        showModal: true,
        modalMessage: action.msg,
      };
    case HIDE_SEND_SELCTED_ENRICH_WARNING_MODAL:
      return sendCurveEnrichWarningModalDefaultState;
    default:
      return state;
  }
};

export default combineReducers({
  timezonesValues: always(timezonesValues),
  aggregationRuleValues: always(aggregationRuleValues),
  enrichFetching: fetchingReducerCreator({
    fetching: FETCHINGDATA,
    received: RECIEVEDDATA,
  }),
  systemTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_SYSTEM_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  userTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_USER_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  curveEnrichWarningModalState,
  enrichTimezone: simpleSetReducerCreator({
    setAction: SET_ENRICH_TIMEZONE,
    prop: "timezone",
    initialState: "None Selected",
  }),
  enrichTransform: simpleSetReducerCreator({
    setAction: SET_ENRICH_TRANSFORM,
    prop: "transform",
    initialState: "None Selected",
  }),
  enrichAggregationRule: simpleSetReducerCreator({
    setAction: SET_ENRICH_AGGREGATION_RULE,
    prop: "aggregationRule",
    initialState: "None Selected",
  }),
  enrichDescription: simpleSetReducerCreator({
    setAction: SET_ENRICH_DESCRIPTION,
    prop: "description",
    initialState: "",
  }),
  facetData: simpleSetReducerCreator({
    setAction: RECIEVED_FACETS,
    prop: "facets",
    initialState: [],
  }),
});

const fetchingData = () => ({ type: FETCHINGDATA });
const receivedData = () => ({ type: RECIEVEDDATA });
const createNewMetaData = (data) => ({ type: CREATE_NEW_METADATA, data });
const receivedSystemTransformData = (data) => ({
  type: RECIEVED_SYSTEM_TRANSFORM_DATA,
  data,
});
const receivedUserTransformData = (data) => ({
  type: RECIEVED_USER_TRANSFORM_DATA,
  data,
});
const showSendSelectedCurveEnrichWarningModal = (msg) => ({
  type: SHOW_SEND_SELCTED_ENRICH_WARNING_MODAL,
  msg,
});
const hideSendSelectedCurveEnrichWarningModal = () => ({
  type: HIDE_SEND_SELCTED_ENRICH_WARNING_MODAL,
});
const setEnrichTimezone = (timezone) => ({
  type: SET_ENRICH_TIMEZONE,
  timezone,
});
const setEnrichTransform = (transform) => ({
  type: SET_ENRICH_TRANSFORM,
  transform,
});
const setEnrichAggregationRule = (aggregationRule) => ({
  type: SET_ENRICH_AGGREGATION_RULE,
  aggregationRule,
});
const setEnrichDescription = (description) => ({
  type: SET_ENRICH_DESCRIPTION,
  description,
});

const handleNetworkError = compose(
  ap([dispatchNetworkError, receivedData]),
  of
);
const resultsSystemRecieved = compose(
  ap([compose(receivedSystemTransformData, prop("Data")), receivedData]),
  of
);
const resultsUserRecieved = compose(
  ap([compose(receivedUserTransformData, prop("Data")), receivedData]),
  of
);
const emptyEnrichSettings = compose(
  ap([
    setEnrichTimezone("None Selected"),
    setEnrichTransform("None Selected"),
    setEnrichAggregationRule("None Selected"),
    setEnrichDescription(""),
  ]),
  of
);
const tokenFromState = compose(auth.getToken, TenantSelectors.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: TenantSelectors.api(state),
    }))
  );
const getSystemDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingData)),
      delay(400),
      flatMap(tokenApiFromState),
      flatMap(requestSystemDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsSystemRecieved),
      compose(dispatch, handleNetworkError)
    );

const getUserDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingData)),
      delay(400),
      flatMap(tokenApiFromState),
      flatMap(requestUserDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsUserRecieved),
      compose(dispatch, handleNetworkError)
    );

const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

const templateOperationList = (Type, Value) => ({
  Type,
  Params: {
    Value,
  },
});

const buildOperationList = (state) => {
  const TransformID = !state.enrichTransform
    ? null
    : templateOperationList(
        "UpdateTimeTransformID",
        equals(state.enrichTransform, 9999) ? null : state.enrichTransform
      );
  const OriginalTimezone = equals(state.enrichTimezone, "None Selected")
    ? null
    : templateOperationList("UpdateOriginalTimeZone", state.enrichTimezone);
  const AggregationRule = equals(state.enrichAggregationRule, "None Selected")
    ? null
    : templateOperationList(
        "UpdateAggregationRule",
        state.enrichAggregationRule
      );
  const ProviderDescription = equals(state.enrichDescription, "")
    ? null
    : templateOperationList(
        "UpdateProviderDescription",
        state.enrichDescription
      );

  const arr = [
    TransformID,
    OriginalTimezone,
    AggregationRule,
    ProviderDescription,
  ];
  return without([null], arr);
};

const sendCurveEnrichment = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingData)),
      tap(
        compose(dispatch, () =>
          createNewMetaData({
            IDS: compose(
              map(renameKeys({ MarketDataId: "ID" })),
              map(pick(["MarketDataId"]))
            )(getState().Curves.selectedCurves.curves),
            OperationList: buildOperationList(getState().CurveEnrich),
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) => {
        return enrichCurvesRequest({
          token,
          api,
          data: {
            IDS: compose(
              map(renameKeys({ MarketDataId: "ID" })),
              map(pick(["MarketDataId", "ETag"]))
            )(getState().Curves.selectedCurves.curves),
            OperationList: buildOperationList(getState().CurveEnrich),
          },
        });
      }),
      RxMap((x) => x.response)
    )
    .subscribe(
      compose(dispatch, receivedData, dispatch, updateSelectedCurvesList),
      compose(dispatch, handleNetworkError),
      compose(dispatch, requestCurvesAction)
    );

export const getAllTimeTransformDataAction = () => [
  getSystemDefinedTransformData(),
  getUserDefinedTransformData(),
];

export const showSendSelectedCurveEnrichWarningModalAction = (state) => [
  showSendSelectedCurveEnrichWarningModal(state.msg),
  setEnrichTimezone(state.timezone),
  setEnrichTransform(state.transform),
  setEnrichAggregationRule(state.aggregationRule),
  setEnrichDescription(state.description),
];
export const closeSendSelectedCurveEnrichWarningModalAction = () => [
  hideSendSelectedCurveEnrichWarningModal(),
  emptyEnrichSettings(),
];

export const confirmSendSelectedCurveEnrichWarningModalAction = () => [
  hideSendSelectedCurveEnrichWarningModal(),
  sendCurveEnrichment(),
  emptyEnrichSettings(),
];
