import React from "react";
import { chain, map, always, ifElse, isEmpty } from "ramda";
import { Tooltip } from "react-tippy";
import moment from "moment";

const emptyClassification = always(
  <tr className="noMargin noPadding">
    <td colSpan={3}>No Classifications</td>
  </tr>
);
const toClassification = (obj) => (
  <tr className="noMargin noPadding" key={obj.classification}>
    <td className="classificationLabel">{obj.tag}</td>
    <td>:</td>
    <td>{obj.label}</td>
  </tr>
);
const classifyList = ifElse(
  isEmpty,
  emptyClassification,
  map(toClassification)
);
const mapClassification = (tag) =>
  map((label) => ({ classification: `${tag}:${label}`, tag, label }));
const classifyFromTags = chain((tag) => mapClassification(tag.Key)(tag.Value));
const table = (row) => (
  <div className="row tooltipMainContainer">
    <div className="row col-12 description">
      <div className="col-3 text-right">
        <b>LAST UPDATED:</b>
      </div>
      <div className="col-9 descriptionText">
        {row.DataLastWritedAt
          ? moment(row.DataLastWritedAt).format("YYYY-MM-DD HH:mm:ss")
          : "No Data"}
      </div>
    </div>
    <div className="row col-12 description">
      <div className="col-3 text-right">
        <b>DATE START:</b>
      </div>
      <div className="col-9 descriptionText">
        {row.DataRangeStart || "No Data"}
      </div>
    </div>
    <div className="row col-12 description">
      <div className="col-3 text-right">
        <b>DATE END:</b>
      </div>
      <div className="col-9 descriptionText">
        {row.DataRangeEnd || "No Data"}
      </div>
    </div>
    <div className="row col-12 description">
      <div className="col-3 text-right">
        <b>DESCRIPTION:</b>
      </div>
      <div className="col-9 descriptionText">
        {row.ProviderDescription || "No Description"}
      </div>
    </div>
    <div className="row col-12 classification">
      <div className="col-3 text-black classificationText">
        <b>CLASSIFICATION:</b>
      </div>
      <div className="col-9">
        <table className="table table-condensed">
          <tbody>{classifyList(classifyFromTags(row.Tags))}</tbody>
        </table>
      </div>
    </div>
  </div>
);

const TooltipIcon = (cell, row) => (
  <Tooltip arrow position="right" html={table(row)} interactive>
    <i className="fa fa-info-circle tooltipIcon" aria-hidden="true" />
  </Tooltip>
);

export default TooltipIcon;
