import React from "react";

const PortalSpinner = (props) => (
  <div
    className={`overlay spinnerOverlay ${props.spinnerState ? "fadeIn" : ""}`}
    id="overlay"
  >
    <div className="loader">
      <div className="box" />
      <div className="box" />
      <div className="box" />
      <div className="box" />
    </div>
  </div>
);

export default PortalSpinner;
