import React from "react";
import { Button } from "../components";

const CurvePreviewModal = (props) => (
  <div>
    <div
      className={`modal fade show ${props.curvePreviewState ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${props.curvePreviewState ? "fadeIn" : ""} `}
        onClick={props.toggleCurvePreview}
      />
      <div
        className="modal-dialog modal-dialog-centered curvePreviewModal artesainModal"
        role="document"
      >
        <div className="modal-content">
          <div className="refineCurvesHeader">
            <div className="col curvePreviewText">
              <div className="mainHeaderPreviewText">
                {props.curveTableMapTypeName}
                <Button
                  className="btn close close-default remove-box-shadow btn-circle curvePreviewClose"
                  onClick={props.toggleCurvePreview}
                  btnText="&times;"
                />
              </div>
              <div className="subHeaderPreviewText">
                MarketData ID: {props.curveIDHeader}
              </div>
            </div>
            <div className="col curvePreviewBody">{props.drawPreviewTable}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CurvePreviewModal;
