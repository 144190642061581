import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "../components";
import ExcelTutorial from "../tutorial/excelTutorial";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";
import { connect } from "react-redux";

const CurveExtract = ({
  setTutorialModalOpenAction,
  setVideoUrlAction,
  ...props
}) => (
  <div className="extractionContainer">
    <div className="extract pageTitle">
      <span className="fa-stack-large marginRight10">
        <i className="fa fa-folder-open fa-stack-folder-large artesianIconNoHover" />
        <i className="fa fa-arrow-down fa-stack-arrow-large artesianIconNoHover" />
      </span>
      {props.extractTitle}
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/HaDlV1ZMwSs?si=QimB4EDYhFvR31Cu"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="selectedFunction">
      <div
        className={`effectedCurves ${
          props.extractCustomFilterState ? "d-none" : ""
        } ${
          props.effectedCurves
            ? "effectedCurveContainerOpen"
            : "effectedCurveContainerClosed"
        }`}
      >
        <div
          className={`effectedCurvesContainer ${
            props.effectedCurves ? "open" : "closed"
          }`}
          id="tutEffCurvesContainer"
        >
          {props.effectedCurvesTable}
        </div>
        <ExcelTutorial
          closeTutorial={props.closeTutorial}
          excelTour={props.excelTourIsOpen}
        />
        <Button
          onClick={props.toggleEffectedCurves}
          btnText={
            <i
              className={`fa ${
                props.effectedCurves
                  ? "fa-angle-double-left"
                  : "fa-angle-double-right"
              }`}
            />
          }
          className="effectedCurvesDisplayBtn"
        />
      </div>

      <Scrollbars
        className={`accordion ${
          props.effectedCurves && !props.extractCustomFilterState
            ? "componentOpen"
            : "componentClosed"
        }`}
        id="extractionAccordian"
        renderThumbHorizontal={(vals) => (
          <div {...vals} className="thumb-horizontal" />
        )}
        renderThumbVertical={(vals) => (
          <div {...vals} className="thumb-vertical" />
        )}
      >
        {props.tsvAsTsComponent}
        {props.tsvComponent}
        {props.tsComponent}
        {props.masComponent}
        {props.auctionExtractComponent}
        {props.bidAskExtractComponent}
      </Scrollbars>
    </div>
  </div>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction,
};
export default connect(null, mapDispatchToProps)(CurveExtract);
