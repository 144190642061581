import React from "react";
import { Tooltip } from "react-tippy";

const MenuSelectCountType = ({ title, count, removeSelectType }) => (
  <div className="menuSelectCountType">
    <div className={`titleCount ${count > 0 ? "hasItems" : ""}`}>
      <span className="title">{title}</span>
      <span>{count}</span>
    </div>
    <div className={`countSymbols ${count > 0 ? "" : "hide"}`}>
      <span onClick={removeSelectType} aria-hidden="true">
        <Tooltip
          arrow
          position="left"
          html={<span>Remove Selected MarketData</span>}
          theme="arkDefaultTooltip"
        >
          <i className="fa fa-times menuClearBtn" />
        </Tooltip>
      </span>
    </div>
  </div>
);

export default MenuSelectCountType;
