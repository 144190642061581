import React from "react";
import { compose, equals } from "ramda";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { withHandlers } from "recompose";
import { Tooltip } from "react-tippy";

const ClassificationTable = (props) => (
  <div className="height100 paddingLeft5">
    <div className="pageTitle">Selected MarketData</div>
    <BootstrapTable
      data={props.data}
      condensed
      printable
      pagination
      version="4"
      options={props.settings}
      keyField="MarketDataId"
      containerClass="effectedCurvesContainerClass paddingRight"
      tableContainerClass="effectedCurvesTableContainer"
      bodyContainerClass="effectedCurvesTableBody effectedNested"
    >
      <TableHeaderColumn
        dataField="MarketDataId"
        dataFormat={props.selectedCol}
        width="25"
      />
      <TableHeaderColumn
        dataField="MarketDataId"
        width="80"
        hidden={!props.showID}
        dataSort
      >
        MarketData ID
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="MarketDataName"
        dataSort
        dataFormat={props.curveNameFormat}
      >
        NAME
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="ProviderName"
        dataSort
        width="130"
        columnClassName="classifactionCol"
      >
        PROVIDER
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="Type"
        dataSort
        width="140"
        columnClassName="classifactionCol"
      >
        MarketData TYPE
      </TableHeaderColumn>
    </BootstrapTable>
  </div>
);

const getFilterName = (name) => {
  switch (name) {
    case "tsvComponent":
      return "VersionedTimeSerie";
    case "tsComponent":
      return "ActualTimeSerie";
    case "masComponent":
      return "MarketAssessment";
    case "auctionExtractComponent":
      return "Auction";
    case "bidAskExtractComponent":
      return "BidAsk";
    case "tsvAsTsComponent":
      return "both";
    default:
      return "";
  }
};

const enhancer = compose(
  withHandlers({
    curveNameFormat: () => (cell) => (
      <Tooltip
        arrow
        position="top"
        interactive
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
    selectedCol: (props) => (cell, row) => {
      const filterName = getFilterName(props.selected);
      if (equals(filterName, "both")) {
        return equals(row.Type, "VersionedTimeSerie") ||
          equals(row.Type, "ActualTimeSerie") ? (
          // eslint-disable-next-line
          <i className="fas fa-check effectCurvesTick" />
        ) : null;
      }
      return equals(row.Type, filterName) ? (
        <i className="fas fa-check effectCurvesTick" />
      ) : null;
    },
  })
);

export default enhancer(ClassificationTable);
