import React from "react";
import {
  compose,
  map,
  prop,
  uniq,
  reject,
  contains,
  filter,
  append,
  lensProp,
  isEmpty,
  over,
  equals,
  isNil,
  concat,
  __,
} from "ramda";
import { setErrorAction } from "../actions";

export const dispatchNetworkError = (err) => {
  if (err.status > 0) {
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact admin for more information.
          </div>
        ),
      });
    }
    if (err.status === 400) {
      const hasCustomMsg =
        isNil(err.response) ||
        isNil(err.response.ErrorMessage) ||
        isEmpty(err.response.ErrorMessage);
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: hasCustomMsg
          ? "Please refresh the page. If the issue persists, please contact support."
          : err.response.ErrorMessage,
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message:
        "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  if (err.length > 0) {
    return setErrorAction({
      title: `Error: ${err.length} Group failed to be added to User`,
      message: (
        <div>
          <div>The following Groups failed:</div>
          <ul className="errorList">
            {map(
              (x) => (
                <ol key={x.permission.data.ID}>{x.permission.data.Name}</ol>
              ),
              err
            )}
          </ul>
        </div>
      ),
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "Please refresh the page. If the issue persists, please contact support.",
  });
};

const missingNameChange = (
  updateGroups,
  updatedRemovedGroups,
  updatedAddGroups
) =>
  filter(
    (x) =>
      !contains(
        x.Name,
        map(prop("Name"), concat(updatedRemovedGroups, updatedAddGroups))
      )
  )(updateGroups);

export const getUpdatedGroups = (
  groups,
  user,
  userGroups,
  origName,
  { token, api }
) => {
  const allAdds = compose(
    map(prop("name")),
    filter((x) => equals(x.mod, "add") && equals(x.orig, false))
  )(userGroups);

  const allRemove = compose(
    map(prop("name")),
    filter((x) => equals(x.mod, "remove") && equals(x.orig, true))
  )(userGroups);

  const updateGroups = equals(user, origName)
    ? groups
    : compose(
        map((x) => over(lensProp("Users"), append(user, __), x)),
        map((x) =>
          over(
            lensProp("Users"),
            reject((z) => equals(z, origName), __),
            x
          )
        ),
        filter((x) => contains(x.Name, map(prop("name"), userGroups)))
      )(groups);

  if (isEmpty(allAdds) && isEmpty(allRemove) && !equals(user, origName)) {
    return compose(
      map((data) => ({
        token,
        api,
        ID: data.ID,
        data,
      }))
    )(updateGroups);
  }

  const updatedAddGroups = compose(
    map((x) => over(lensProp("Users"), uniq, x)),
    map((x) => over(lensProp("Users"), append(user, __), x)),
    filter((x) => contains(x.Name, allAdds))
  )(updateGroups);

  const updatedRemovedGroups = compose(
    map((x) =>
      over(
        lensProp("Users"),
        reject((z) => equals(z, user), __),
        x
      )
    ),
    filter((x) => contains(x.Name, allRemove))
  )(updateGroups);

  const modifiedGroups = equals(user, origName)
    ? concat(updatedRemovedGroups, updatedAddGroups)
    : compose(
        concat(updatedRemovedGroups),
        concat(updatedAddGroups)
      )(
        missingNameChange(updateGroups, updatedRemovedGroups, updatedAddGroups)
      );

  return compose(
    map((data) => ({
      token,
      api,
      ID: data.ID,
      data,
    }))
  )(modifiedGroups);
};

export default dispatchNetworkError;
