import React from "react";
import { Tooltip } from "react-tippy";
import { compose } from "ramda";
import { withHandlers } from "recompose";
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = (props) => (
  <div
    className={`dropdown artesianDropdownContanier ${props.className || ""}`}
    id={props.id}
  >
    <div className="btn-group width100">
      <div
        className={`d-flex width100 ${props.dropID ||
          props.tutExtractID ||
          props.tutEnrichCurveId ||
          ""}`}
        data-toggle="dropdown"
      >
        <button
          type="button"
          className="btn dropdown-width"
          onClick={props.toggleDropdown}
        >
          <Tooltip
            arrow
            position="bottom"
            html={<span>{props.selected}</span>}
            theme="arkDefaultTooltip"
          >
            {props.selected}
          </Tooltip>
        </button>
        <button
          type="button"
          className="btn dropdown-toggle dropdown-toggle-split artesianDropdownToggle"
          onClick={props.toggleDropdown}
        >
          <span className="sr-only">Toggle Dropdown</span>
        </button>
      </div>
      <div className="dropdown-menu artesianDropdown timeTransformDropdown">
        {props.dropDownValues.map((val) => (
          <span
            key={val.ID}
            value={val.Name}
            className={`dropdown-item ${props.dropdownItemClass}`}
            onClick={() => props.clickFn(val.Name)}
            aria-hidden="true"
          >
            {val.Name}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const enhancer = compose(
  withHandlers({
    clickFn: (props) => (val) => props.onClick(val),
  })
);
export default enhancer(Dropdown);
