import React from "react";
import { toolTipStyleNoMargin, toolTipStyleTopMargin } from "./tooltipStyles";

export const extractionTutorial = [
  // Intro
  {
    position: "center",
    content: (
      <div>
        <h3>Versioned Time Series</h3>
        Versioned time series is itself a time series, additionally indexed by
        the time co-ordinates of its version, for example meteorological
        forecasts for a specific geographical area for a specific date.
        <br />
      </div>
    ),
    style: toolTipStyleNoMargin,
    action: () => {
      if (
        document.getElementsByClassName("versionTsTarget collapse show")
          .length < 1
      ) {
        document.getElementById("versionTs").click();
      }
      return null;
    },
  },
  // Date Options
  {
    selector: '[class="d-flex width100 noPadding VTSTimeSeriesDateType"]',
    position: "bottom",
    content: (
      <div>
        <h3>Date Options</h3>
        Here you can set the range of time over which you want your market data
        returned
        <br />
        Your options here are:
        <br />
        <b>Date Range</b> - A range of time spaning from the first date to the
        second date
        <br />
        <b>Relative</b> - A range spanning the chosen interval relative to
        todays date
        <br />
        <b>Period From/To</b> - A date range built in the ISO_8601 format,
        allows you to select a period of time from the past and a period of time
        into the future starting from todays date
        <br />
        <b>Period</b> - A date range built in the ISO_8601 format, Allow you to
        select a duration in the past or into the future starting from todays
        date
        <br />
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Version
  {
    selector: '[class="d-flex width100 noPadding VTSVersionOptions"]',
    position: "bottom",
    content: (
      <div>
        <h3>Version</h3>
        Here we set the time range in which you would like the market data
        returned.
        <br />
        Your options here are:
        <br />
        <b>Last of Months</b> - Retrive the last version of each month given the
        version date range
        <br />
        <b>Last of Days</b> - Retrieve the last version of each day given the
        version date range
        <br />
        <b>Last</b> - This option returns the last &apos;x&apos; most recent
        versions of the selected market data where &apos;x&apos; is the number you
        enter
        <br />
        <b>MUV</b> - Return the most updated version of the selcted market data
        <br />
        <b>Version</b> - Enter the specfic version you would like to retrieve
        <br />
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Granularity
  {
    selector: '[class="d-flex width100 noPadding VTSGranularity"]',
    position: "bottom",
    content: (
      <div>
        <h3>Granularity</h3>
        Select your desired Granularity from the dropdown. Returned data will be
        altered depending upon the chosen Granularity.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Timezone
  {
    selector: '[class="d-flex width100 noPadding VTSTimezone"]',
    position: "bottom",
    content: (
      <div>
        <h3>TimeZone</h3>
        Select a TimeZone from the dropdown. The MarketData returned will match
        the datatime of the TimeZone selected.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Time Transform
  {
    selector: '[class="d-flex width100 VTSTransform"]',
    position: "bottom",
    content: (
      <div>
        <h3>Time Transform</h3>
        Select a given Time Transform from the dropdown. The Time Transform
        applies a positive and negative period shift on the market data.
        <br />
        New Time Transforms can be created in the Enrich Time Transform Page.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Download Excel Button
  {
    selector: '[id="vtsDownload"]',
    position: "bottom",
    content: (
      <div>
        <h3>Download Excel</h3>
        Download your market data with your selected date ranges and types.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Clipboard
  {
    selector: '[id="vtsCopy"]',
    position: "bottom",
    content: (
      <div>
        <h3>Copy Clipboard</h3>
        Alternatively if you wish to extract the URL for use in a BI tool, you
        can copy it straight to your clipboard
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // End
  {
    position: "center",
    content: (
      <div>
        <h3>End of Versioned Time Series Tutorial</h3>
      </div>
    ),
    style: toolTipStyleTopMargin,
    action: () => {
      if (
        document.getElementsByClassName("versionTsTarget collapse show")
          .length < 1
      ) {
        document.getElementById("versionTs").click();
      }
      return null;
    },
  },
];

export default extractionTutorial;
