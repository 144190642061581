import { combineReducers } from "redux";
import { ap, compose, of, isEmpty } from "ramda";
import * as RX from "rxjs";
import { flatMap, tap, map as RxMap, mapTo } from "rxjs/operators";
import { simpleSetReducerCreator } from "../../reducerCreators";
import { requestData } from "./request";
import { dispatchNetworkError } from "./helper";
import { auth } from "../../../appContext";
import { Selectors } from "../tenantSelection";

export const TOGGLE_CURVE_PREVIEW =
  "artesian-ui/curvePreview/TOGGLE_CURVE_PREVIEW";
const EMPTY_CURVE_PREVIEW = "artesian-ui/curvePreview/EMPTY_CURVE_PREVIEW";
export const CURVE_PRIVEIEW_REQUEST =
  "artesian-ui/curvePreview/CURVE_PRIVEIEW_REQUEST";
export const CURVE_PRIVEIEW_RECIEVED =
  "artesian-ui/curvePreview/CURVE_PRIVEIEW_RECIEVED";
const RECIEVED_CURVE_TYPE = "artesian-ui/curvePreview/RECIEVED_CURVE_TYPE";
const RECIEVED_CURVE_ID = "artesian-ui/curvePreview/RECIEVED_CURVE_ID";
const FETCHINGDATA = "artesian-ui/curvePreview/FETCHINGDATA";
const FETCHINGFAILED = "artesian-ui/curvePreview/FETCHINGFAILED";
const RECIEVEDDATA = "artesian-ui/curvePreview/RECIEVEDDATA";
const RECIEVEDNODATA = "artesian-ui/curvePreview/RECIEVEDNODATA";

const curvePreviewState = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CURVE_PREVIEW:
      return !state;
    default:
      return state;
  }
};
const curvePreviewTable = (state = [], action) => {
  switch (action.type) {
    case CURVE_PRIVEIEW_RECIEVED:
      return action.data;
    case EMPTY_CURVE_PREVIEW:
      return [];
    default:
      return state;
  }
};
const requestState = (state = "NoData", action) => {
  switch (action.type) {
    case FETCHINGDATA:
      return "FETCHINGDATA";
    case FETCHINGFAILED:
      return "FETCHINGFAILED";
    case RECIEVEDDATA:
      return "RECIEVEDDATA";
    case RECIEVEDNODATA:
      return "RECIEVEDNODATA";
    default:
      return state;
  }
};

export default combineReducers({
  curvePreviewState,
  curvePreviewTable,
  curveType: simpleSetReducerCreator({
    setAction: RECIEVED_CURVE_TYPE,
    prop: "Type",
    initialState: "",
  }),
  curveID: simpleSetReducerCreator({
    setAction: RECIEVED_CURVE_ID,
    prop: "id",
    initialState: "",
  }),
  requestState,
});

const receivedCurveData = (data) => ({
  type: RECIEVED_CURVE_TYPE,
  Type: data.Type,
});
const emptyCurveType = () => ({ type: RECIEVED_CURVE_TYPE, Type: "" });
const receivedCurveID = (data) => ({
  type: RECIEVED_CURVE_ID,
  id: data.MarketDataId,
});
const emptyCurveID = () => ({ type: RECIEVED_CURVE_ID, id: "" });
const receivedCurvePreview = (data) => ({
  type: CURVE_PRIVEIEW_RECIEVED,
  data,
});
const requestCurvePreview = (data) => ({
  type: CURVE_PRIVEIEW_REQUEST,
  data,
});
const toggleCurvePreview = () => ({ type: TOGGLE_CURVE_PREVIEW });
const emptyCurvePreviewTable = () => ({ type: EMPTY_CURVE_PREVIEW });
const fetchingData = () => ({ type: FETCHINGDATA });
const fetchingFailed = () => ({ type: FETCHINGFAILED });
const receivedData = () => ({ type: RECIEVEDDATA });
const receivedNoData = () => ({ type: RECIEVEDNODATA });

const handleNetworkError = compose(
  ap([dispatchNetworkError, fetchingFailed]),
  of
);

const receivedCurvePreviewData = compose(
  ap([
    receivedCurveData,
    (x) => (isEmpty(x) ? receivedNoData() : receivedData()),
  ]),
  of
);

export const getCurvePreview = (row) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, () => requestCurvePreview(row))),
      RxMap(() => Selectors.tenant(getState())),
      flatMap(auth.getToken),
      tap(compose(dispatch, fetchingData)),
      flatMap((token) => requestData(getState(), token, row)),
      RxMap(compose(dispatch, receivedCurvePreview)),
      mapTo(row),
      RxMap(compose(dispatch, receivedCurveID)),
      mapTo(row)
    )
    .subscribe(
      compose(dispatch, receivedCurvePreviewData),
      compose(dispatch, handleNetworkError)
    );

export const toggleCurvePreviewAction = () => [
  emptyCurvePreviewTable(),
  emptyCurveType(),
  emptyCurveID(),
  toggleCurvePreview(),
  receivedNoData(),
];
export const getPreviewTableAction = (row) => [
  getCurvePreview(row),
  toggleCurvePreview(),
];
