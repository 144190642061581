import { combineReducers } from "redux";
import { always, contains, equals, concat, length, compose } from "ramda";
import * as RX from "rxjs";
import { map, tap } from "rxjs/operators";
import { simpleSetReducerCreator } from "../../reducerCreators";
import {
  curvesTutorial,
  refineTutorial,
  classifyTutorial,
  enrichMetadataTutorial,
  enrichTimeTransformTutorial,
  extractATS,
  extractEnd,
  extractMAS,
  extractStart,
  extractVtsAsAts,
  extractVTS,
  goupAdminPage,
  userAdminPage,
} from "./tutorialSteps";

const TUTORIAL_OFF = "artesian-ui/tutorial/TUTORIAL_OFF";
export const TUTORIAL_ON = "artesian-ui/tutorial/TUTORIAL_ON";
const SELECTED_TUTORIAL = "artesian-ui/tutorial/SELECTED_TUTORIAL";
const SELECTED_TUTORIAL_PAGE = "artesian-ui/tutorial/SELECTED_TUTORIAL_PAGE";

const tutorialState = (state = false, action) => {
  switch (action.type) {
    case TUTORIAL_OFF:
      return false;
    case TUTORIAL_ON:
      return true;
    default:
      return state;
  }
};

// Add new action types
const SET_MODAL_OPEN = "artesian-ui/tutorial/SET_MODAL_OPEN";
const SET_VIDEO_URL = "artesian-ui/tutorial/SET_VIDEO_URL";

// Add new reducers
const isModalOpen = (state = false, action) => {
  switch (action.type) {
    case SET_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
};

const videoUrl = (state = "", action) => {
  switch (action.type) {
    case SET_VIDEO_URL:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  tutorialState,
  tutorialSteps: always(curvesTutorial),
  selectedTutotial: simpleSetReducerCreator({
    setAction: SELECTED_TUTORIAL,
    prop: "tut",
    initialState: curvesTutorial,
  }),
  selectedTutotialPage: simpleSetReducerCreator({
    setAction: SELECTED_TUTORIAL_PAGE,
    prop: "page",
    initialState: "selectCurves",
  }),
  isModalOpen, // Add new reducer
  videoUrl, // Add new reducer
});

const tutorialOn = () => ({
  type: TUTORIAL_ON,
});
const tutorialOff = () => ({
  type: TUTORIAL_OFF,
});
const setTutorailPage = (page) => ({
  type: SELECTED_TUTORIAL_PAGE,
  page,
});

// action creators
const setModalOpen = (isOpen) => ({
  type: SET_MODAL_OPEN,
  payload: isOpen,
});

const setVideoUrl = (url) => ({
  type: SET_VIDEO_URL,
  payload: url,
});

// Export action creators
export const setTutorialModalOpenAction = (isOpen) => [setModalOpen(isOpen)];
export const setVideoUrlAction = (url) => [setVideoUrl(url)];

const componentDisplayHelper = (filterState, list, result, helpFile) => {
  if (
    equals(filterState, true) &&
    contains("VersionedTimeSerie", list) &&
    contains("ActualTimeSerie", list)
  ) {
    return result;
  }

  return concat(result, helpFile);
};

const setCurrentTutorialStepsFunc = ({ page, list, filterState, state }) => {
  const permissions = state.Permissions.menuPermission;
  const curveCount = length(state.Curves.selectedCurves.curves);
  let tut = curvesTutorial;
  switch (page) {
    case "selectCurves":
      tut = curvesTutorial;
      break;
    case "refineBasket":
      tut = refineTutorial;
      break;
    case "classifyCurves":
      tut = classifyTutorial;
      break;
    case "expectation":
      tut = refineTutorial;
      break;
    case "enrichMetaData":
      tut = enrichMetadataTutorial;
      break;
    case "groupAdmin":
      tut = goupAdminPage;
      break;
    case "userAdmin":
      tut = userAdminPage;
      break;
    case "extractCurves": {
      let result = extractStart;
      if (
        contains("VersionedTimeSerie", list) &&
        contains("ActualTimeSerie", list)
      ) {
        result = concat(result, extractVtsAsAts);
      }
      if (contains("VersionedTimeSerie", list)) {
        result = componentDisplayHelper(filterState, list, result, extractVTS);
      }
      if (contains("ActualTimeSerie", list)) {
        result = componentDisplayHelper(filterState, list, result, extractATS);
      }
      if (contains("MarketAssessment", list)) {
        result = concat(result, extractMAS);
      }
      result = concat(result, extractEnd);
      tut = result;
      break;
    }
    case "enrichTimeTransform":
      tut = enrichTimeTransformTutorial(curveCount, permissions);
      break;
    default:
      break;
  }

  return {
    type: SELECTED_TUTORIAL,
    tut,
  };
};

const setCurrentTutorialSteps = (page, list, filterState) => (
  dispatch,
  getState
) =>
  RX.of(getState())
    .pipe(
      map((state) => ({ page, list, filterState, state })),
      tap(compose(dispatch, setCurrentTutorialStepsFunc))
    )
    .subscribe();

export const tutorialOnAction = () => [tutorialOn()];
export const tutorialOffAction = () => [tutorialOff()];
export const setTutorialAction = (page, list, filterState) => [
  setTutorailPage(page),
  setCurrentTutorialSteps(page, list, filterState),
];
