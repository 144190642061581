import * as RX from "rxjs";
import { flatMap, tap, map as RxMap, toArray } from "rxjs/operators";
import { combineReducers } from "redux";
import * as R from "ramda";
import {
  fetchingReducerCreator,
  simpleSetReducerCreator,
} from "../../reducerCreators";
import { dispatchNetworkError } from "./helper";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector} from "../tenantSelection";
import {
  getAllGroups,
  getAllUsersGroupPaths,
  createGroup,
  deleteGroup,
  editOrDeletePathGroup,
  addPathGroup,
  deletePathGroup,
  checkSelectedGroupPermission,
} from "./requests";

const {
  of,
  ap,
  compose,
  map,
  prop,
  filter,
  isEmpty,
  equals,
  head,
  merge,
  propEq,
  find,
  always,
} = R;

const userRoles = ["Editor", "Creator", "Reader", "Owner", "Contributor", "SecurityManager"];
const pathList1 = [
  "apikey",
  "expectation",
  "filter",
  "gmepublicoffer",
  "marketdata",
  "principal",
  "timetransform",
];
const pathList2 = ["system", "private"];

const FETCHING = "artesian-ui/adminGroups/FETCHING";
const RECIEVED = "artesian-ui/adminGroups/RECIEVED";
const CANCEL_FETCHING = "artesian-ui/adminGroups/CANCEL_FETCHING";
const ALL_GROUPS = "artesian-ui/adminGroups/ALL_GROUPS";
const SELECTED_GROUP = "artesian-ui/adminGroups/SELECTED_GROUP";
const GROUP_PATHS = "artesian-ui/adminGroups/GROUP_PATHS";
const OPEN_GROUP_MODAL = "artesian-ui/adminGroups/OPEN_GROUP_MODAL";
const CLOSE_GROUP_MODAL = "artesian-ui/adminGroups/CLOSE_GROUP_MODAL";
const UPDATE_GROUP_MODAL_ROW = "artesian-ui/adminGroups/UPDATE_GROUP_MODAL_ROW";
const OPEN_DELETE_GROUP_MODAL =
  "artesian-ui/adminGroups/OPEN_DELETE_GROUP_MODAL";
const CLOSE_DELETE_GROUP_MODAL =
  "artesian-ui/adminGroups/CLOSE_DELETE_GROUP_MODAL";
const UPDATE_DELETE_GROUP_MODAL_ROW =
  "artesian-ui/adminGroups/UPDATE_DELETE_GROUP_MODAL_ROW";
const OPEN_EDIT_PATH_MODAL = "artesian-ui/adminGroups/OPEN_EDIT_PATH_MODAL";
const CLOSE_EDIT_PATH_MODAL = "artesian-ui/adminGroups/CLOSE_EDIT_PATH_MODAL";
const UPDATE_EDIT_PATH_MODAL_ROW =
  "artesian-ui/adminGroups/UPDATE_EDIT_PATH_MODAL_ROW";
 const OPEN_DELETE_PATH_MODAL = "artesian-ui/adminGroups/OPEN_DELETE_PATH_MODAL";
 const CLOSE_DELETE_PATH_MODAL =
  "artesian-ui/adminGroups/CLOSE_DELETE_PATH_MODAL";
const UPDATE_DELETE_PATH_MODAL_ROW =
  "artesian-ui/adminGroups/UPDATE_DELETE_PATH_MODAL_ROW";
const SELCTED_GROUP_PERMISSION =
  "artesian-ui/adminGroups/SELCTED_GROUP_PERMISSION";
export const CREATE_NEW_GROUP = "artesian-ui/adminGroups/CREATE_NEW_GROUP";
export const DELTE_NEW_GROUP = "artesian-ui/adminGroups/DELTE_NEW_GROUP";
export const CREATE_NEW_PATH = "artesian-ui/adminGroups/CREATE_NEW_PATH";
export const EDIT_PATH = "artesian-ui/adminGroups/EDIT_PATH";
export const DELETE_PATH = "artesian-ui/adminGroups/DELETE_PATH";

const modalInitialState = {
  open: false,
  row: {},
  type: "",
};
const deletePathModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_DELETE_PATH_MODAL:
      return {
        ...action.edit,
        orig: action.edit.row,
        open: true,
      };
    case UPDATE_DELETE_PATH_MODAL_ROW:
      return {
        ...state,
        row: action.row,
      };
    case CLOSE_DELETE_PATH_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};
const editPathModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_EDIT_PATH_MODAL:
      return {
        ...action.edit,
        orig: action.edit.row,
        open: true,
      };
    case UPDATE_EDIT_PATH_MODAL_ROW:
      return {
        ...state,
        row: action.row,
      };
    case CLOSE_EDIT_PATH_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};
const deleteModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_DELETE_GROUP_MODAL:
      return {
        ...action.edit,
        orig: action.edit.row,
        open: true,
      };
    case UPDATE_DELETE_GROUP_MODAL_ROW:
      return {
        ...state,
        row: action.row,
      };
    case CLOSE_DELETE_GROUP_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};
const modal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_GROUP_MODAL:
      return {
        ...action.edit,
        orig: action.edit.row,
        open: true,
      };
    case UPDATE_GROUP_MODAL_ROW:
      return {
        ...state,
        row: action.row,
      };
    case CLOSE_GROUP_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

export default combineReducers({
  adminGroupFetching: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
    cancelFetching: CANCEL_FETCHING,
  }),
  allGroups: simpleSetReducerCreator({
    setAction: ALL_GROUPS,
    prop: "groups",
    initialState: [],
  }),
  selectedGroup: simpleSetReducerCreator({
    setAction: SELECTED_GROUP,
    prop: "selected",
    initialState: "",
  }),
  selectedGroupPaths: simpleSetReducerCreator({
    setAction: GROUP_PATHS,
    prop: "paths",
    initialState: "",
  }),
  selectedGroupPermission: simpleSetReducerCreator({
    setAction: SELCTED_GROUP_PERMISSION,
    prop: "permission",
    initialState: [],
  }),
  modal,
  deleteModal,
  editPathModal,
  deletePathModal,
  userRolesList: always(userRoles),
  pathList1: always(pathList1),
  pathList2: always(pathList2),
});

const fetching = () => ({
  type: FETCHING,
});
const received = () => ({
  type: RECIEVED,
});
const createNewGroupFunc = (data) => ({ type: CREATE_NEW_GROUP, data });
const deleteGroupFunc = (data) => ({ type: DELTE_NEW_GROUP, data });
const createNewPathFunc = (data) => ({ type: CREATE_NEW_PATH, data });
const editPathFunc = (data) => ({ type: EDIT_PATH, data });
const deletePathFunc = (data) => ({ type: DELETE_PATH, data });
const setAllGroups = (groups) => ({
  type: ALL_GROUPS,
  groups,
});
const setSelectedGroups = (selected) => ({
  type: SELECTED_GROUP,
  selected,
});
const setSelectedGroupPaths = (paths) => ({
  type: GROUP_PATHS,
  paths,
});
const openGroupModal = (edit) => ({
  type: OPEN_GROUP_MODAL,
  edit,
});
const closeGroupModal = () => ({
  type: CLOSE_GROUP_MODAL,
});
const updateGroupModalRow = (row) => ({
  type: UPDATE_GROUP_MODAL_ROW,
  row,
});
const openDeleteGroupModal = (edit) => ({
  type: OPEN_DELETE_GROUP_MODAL,
  edit,
});
const closeDeleteGroupModal = () => ({
  type: CLOSE_DELETE_GROUP_MODAL,
});
const updateDeleteGroupModalRow = (row) => ({
  type: UPDATE_DELETE_GROUP_MODAL_ROW,
  row,
});
const openEditPathModal = (edit) => ({
  type: OPEN_EDIT_PATH_MODAL,
  edit,
});
const closeEditPathModal = () => ({
  type: CLOSE_EDIT_PATH_MODAL,
});
const updateEditPathModalRow = (row) => ({
  type: UPDATE_EDIT_PATH_MODAL_ROW,
  row,
});
const openDeletePathModal = (edit) => ({
  type: OPEN_DELETE_PATH_MODAL,
  edit,
});
const closeDeletePathModal = () => ({
  type: CLOSE_DELETE_PATH_MODAL,
});
const updateDeletePathModalRow = (row) => ({
  type: UPDATE_DELETE_PATH_MODAL_ROW,
  row,
});
const setSelectedGroupPermission = (permission) => ({
  type: SELCTED_GROUP_PERMISSION,
  permission,
});

const handleNetworkError = compose(ap([dispatchNetworkError, received]), of);
const networkErrorNewGroup = compose(
  ap([dispatchNetworkError, received, closeGroupModal]),
  of
);

const tokenFromState = compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: TenantSelector.api(state),
    }))
  );

const createNewGroup = (group) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, closeGroupModal)),
      tap(compose(dispatch, () => createNewGroupFunc(group))),
      tap(compose(dispatch, fetching)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((data) =>
        createGroup(
          merge(data, {
            group,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      RxMap(find(propEq("Name", group.Name))),
      tap(compose(dispatch, setSelectedGroups)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) =>
        checkSelectedGroupPermission({
          token,
          api,
          groupId: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPermission)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, networkErrorNewGroup)
    );

const deleteNewGroup = (group) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, closeDeleteGroupModal)),
      tap(compose(dispatch, () => deleteGroupFunc(group))),
      tap(compose(dispatch, fetching)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) =>
        checkSelectedGroupPermission({
          token,
          api,
          groupId: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPermission)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((data) =>
        deleteGroup(
          merge(data, {
            groupID: group.ID,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      RxMap(head),
      tap(compose(dispatch, setSelectedGroups)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) =>
        checkSelectedGroupPermission({
          token,
          api,
          groupId: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPermission)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, networkErrorNewGroup)
    );

const formRole = (Role, PrincipalId) => ({
  Principal: {
    PrincipalType: "Group",
    PrincipalId: `${PrincipalId}`,
  },
  Role,
});

const getUpdatedGroups = (
  allPaths,
  pathToUse,
  selectedUserRoles,
  principalId,
  { token, api }
) => {
  const allAdds = compose(
    map((r) => formRole(r, principalId)),
    map(prop("role")),
    filter((x) => equals(x.mod, "add") && equals(x.orig, false))
  )(selectedUserRoles);

  const allRemove = compose(
    map((r) => formRole(r, principalId)),
    map(prop("role")),
    filter((x) => equals(x.mod, "remove") && equals(x.orig, true))
  )(selectedUserRoles);

  return [
    isEmpty(allAdds)
      ? null
      : {
          token,
          api,
          pathVal: {
            Path: pathToUse,
            Roles: allAdds,
          },
          id: principalId,
          type: "add",
        },
    isEmpty(allRemove)
      ? null
      : {
          token,
          api,
          pathVal: {
            Path: pathToUse,
            Roles: allRemove,
          },
          id: principalId,
          type: "remove",
        },
  ];
};

const editPath = (data) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, closeEditPathModal)),
      tap(compose(dispatch, () => editPathFunc(data))),
      tap(compose(dispatch, fetching)),
      flatMap(() => tokenApiFromState(getState())),
      RxMap((tokenData) =>
        getUpdatedGroups(
          getState().AdminGroups.selectedGroupPaths,
          data.path,
          data.editRoles,
          data.principalId,
          tokenData
        )
      ),
      RxMap((res) => filter((x) => x, res)),
      flatMap((x) => x),
      flatMap(editOrDeletePathGroup),
      toArray(),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, networkErrorNewGroup)
    );

const addPath = (newRole) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, closeEditPathModal)),
      tap(compose(dispatch, () => createNewPathFunc(newRole))),
      tap(compose(dispatch, fetching)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((data) =>
        addPathGroup(
          merge(data, {
            newRole,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, networkErrorNewGroup)
    );

const deletePath = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, () => deletePathFunc())),
      tap(compose(dispatch, fetching)),
      flatMap(tokenApiFromState),
      flatMap((data) =>
        deletePathGroup(
          merge(data, {
            rolePath: getState().AdminGroups.deletePathModal.row,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, closeDeletePathModal)),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, networkErrorNewGroup, closeDeletePathModal)
    );

const getSelectedGroup = (group) => (dispatch, getState) =>
  RX.of(group)
    .pipe(
      tap(compose(dispatch, fetching)),
      tap(compose(dispatch, setSelectedGroups)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) =>
        checkSelectedGroupPermission({
          token,
          api,
          groupId: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPermission)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkError)
    );

export const getAdminGroupsAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetching)),
      flatMap(tokenApiFromState),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllGroups),
      tap(compose(dispatch, setAllGroups)),
      RxMap(head),
      tap(compose(dispatch, setSelectedGroups)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(({ token, api }) =>
        checkSelectedGroupPermission({
          token,
          api,
          groupId: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPermission)),
      tap(compose(dispatch, closeDeletePathModal)),
      flatMap(() => tokenApiFromState(getState())),
      flatMap((info) =>
        getAllUsersGroupPaths({
          ...info,
          id: getState().AdminGroups.selectedGroup.ID,
        })
      ),
      tap(compose(dispatch, setSelectedGroupPaths))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkError, closeDeletePathModal)
    );

export const emptyDataAction = () => [
  setSelectedGroups([]),
  setSelectedGroups(""),
];

export const setSelectedGroupsAction = (group) => [getSelectedGroup(group)];

export const openGroupModalAction = (edit) => [openGroupModal(edit)];
export const closeGroupModalAction = () => [closeGroupModal()];
export const updateGroupModalRowAction = (row) => [updateGroupModalRow(row)];
export const createNewGroupAction = (row) => [createNewGroup(row)];

export const openDeleteGroupModalAction = (edit) => [
  openDeleteGroupModal(edit),
];
export const closeDeleteGroupModalAction = () => [closeDeleteGroupModal()];
export const updateDeleteGroupModalRowAction = (row) => [
  updateDeleteGroupModalRow(row),
];
export const deleteNewGroupAction = (row) => [deleteNewGroup(row)];

export const openEditPathModalAction = (edit) => [openEditPathModal(edit)];
export const closeEditPathModalAction = () => [closeEditPathModal()];
export const updateEditPathModalRowAction = (row) => [
  updateEditPathModalRow(row),
];
export const editPathAction = (row) => [editPath(row)];
export const addPathAction = (row) => [addPath(row)];

export const openDeletePathModalAction = (edit) => [openDeletePathModal(edit)];
export const closeDeletePathModalAction = () => [closeDeletePathModal()];
export const updateDeletePathModalRowAction = (row) => [
  updateDeletePathModalRow(row),
];
export const deletePathAction = () => [deletePath()];

export const Selectors = {
  all: R.path(["AdminGroups"]),
};