import React, { useEffect } from "react";
import * as R from "ramda";
import { Tooltip } from "react-tippy";
import { Button, InputNumberTextBox } from "../../components";
import ErrorsComp from "./components/errors";

const CurvesActualTimeSeries = (props) => {
  useEffect(() => {
    props.setExtractionFilters({
      prop: props.isVts ? "tsvAsTs" : "ats",
      filters: {
        granularity: props.selectedGranularity,
        timezone: props.selectedTimezone,
        transform: props.selectedTransform,
      },
    });
  }, [
    props.selectedGranularity,
    props.selectedTimezone,
    props.selectedTransform,
  ]);
  return (
    <div className="card extractCurvesContainer">
      <div
        className="card-header"
        id={`${props.isVts ? "atvs" : "ats"}`}
        data-toggle="collapse"
        data-target={`#${props.isVts ? "atvs" : "ats"}Target`}
        aria-expanded="true"
        aria-controls={`${props.isVts ? "atvs" : "ats"}Target`}
        onClick={() =>
          props.updateSelectedExtraction(
            props.isVts ? "tsvAsTsComponent" : "tsComponent"
          )
        }
      >
        <i className={`fas fa-${props.selected ? "minus" : "plus"}-circle`} />
        {props.componentTitle}
      </div>
      <div
        id={`${props.isVts ? "atvs" : "ats"}Target`}
        className={`collapse ${props.isFirst ? "show" : ""} ${
          props.isVts ? "atvs" : "ats"
        }Target`}
        aria-labelledby={`${props.isVts ? "atvs" : "ats"}`}
        data-parent="#extractionAccordian"
      >
        <div className="card-body extractCurvesBody">
          <div className="extractionContainer">
            <div className="extraction">
              <div className="selection">
                <div className="formLabel">
                  {props.selectedRangeType === "Date Range" ||
                  props.selectedRangeType === "Period From/To" ? (
                    <Tooltip
                      arrow
                      position="bottom"
                      html={
                        <span>"To" date is exclusive and not inclusive</span>
                      }
                      theme="arkDefaultTooltip"
                    >
                      Date&nbsp;Options&nbsp;
                      <i className="fas fa-info-circle dateInfoIcon" />
                    </Tooltip>
                  ) : (
                    <>Date&nbsp;Options</>
                  )}
                </div>
                <div className="flex-row VSTDateOptionsTypeComponent">
                  <div className="flex-col typeOption">
                    {props.timeSeriesDateTypeDropDown}
                  </div>
                  <div className="flex-col type">
                    {props.selectedRangeTypeComponent}
                  </div>
                </div>
              </div>
              <div className="selection">
                <div className="formLabel">Granularity</div>
                <div className="noPadding typeOption">
                  {props.granularityDropDown}
                </div>
              </div>
              <div className="selection">
                <div className="formLabel">Timezone</div>
                <div className="noPadding typeOption">
                  {props.timezoneDropDown}
                </div>
              </div>
              <div className="selection">
                <div className="formLabel">Time&nbsp;Transform</div>
                <div className="noPadding typeOption">
                  {props.transformDropDown}
                </div>
              </div>
              <div className="selection">
                <div className="formLabel">Filler</div>
                <div className="flex-row">
                  <div className="noPadding typeOption" id="fillerDropdown">
                    {props.fillerDropDown}
                  </div>
                  <div className="flex-col type fillerType">
                    {props.selectedFiller === "CustomValue"
                      ? <InputNumberTextBox
                      className="artesianInputNumberTextBox"
                      value={props.customFillValue}
                      onChange={props.setCustomFillValue}
                    />
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyToClipboard noselect">
            <input
              className="form-control noselect"
              value={props.urlString}
              readOnly
            />
            <Tooltip
              arrow
              open={props.copyClip}
              trigger="manual"
              duration={400}
              html={<span>Copied To Clipboard</span>}
              theme="arkDefaultTooltip"
            >
              <Button
                className="copyToClipboardBtn"
                id={`atsCopy${props.isVts ? "IsVts" : ""}`}
                onClick={props.copyURL}
                btnText={
                  <span>
                    <i className="fa fa-clipboard" /> &nbsp;
                  </span>
                }
              />
            </Tooltip>
          </div>
          <ErrorsComp errors={props.errors} />
          <div className="extractCurvesDownload">
            {props.excelTypeDropDown}
            <div>
              <Button
                className="downloadBtn"
                onClick={props.downloadXLSX}
                id={`atsDownload${props.isVts ? "IsVts" : ""}`}
                disabled={
                  props.errors.granularity.length > 0 &&
                  props.errors.aggregationRule.length > 0
                }
                btnText={
                  <span>
                    <i className="far fa-file-excel" /> &nbsp;
                    <span className="d-inline">Download Excel</span>
                  </span>
                }
              />
            </div>
          </div>
          <div className="extractCurvesTutorial ">
            <Button
              className="artesianIconHover"
              onClick={props.openTutorial}
              btnText={
                <span>
                  <i className="fas fa-question" /> &nbsp;
                </span>
              }
              disabled={props.validCount}
            />

            {R.equals(
              props.excelType,
              "ActualTSExtraction Incremental Update"
            ) ? (
              <span className="beta">
                ActualTSExtraction Incremental Update is in BETA
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurvesActualTimeSeries;
