import React from "react";
import { compose, equals } from "ramda";
import { withState, withHandlers } from "recompose";
import { InputNumberTextBox } from "../../../components";

const Picker = ({ value, func, granularity, min, max }) => (
  <div className="isoPicker">
    <span className="periodText">{granularity}</span>
    <InputNumberTextBox
      className="artesianInputNumberTextBox"
      value={value}
      onChange={func}
      min={min}
      max={max}
    />
  </div>
);
const PeriodISOPicker = (props) => (
  <div>
    <div className="d-flex">
      <Picker value={props.year} func={props.setYear} granularity="Year" />
      <Picker value={props.month} func={props.setMonth} granularity="Month" />
      <Picker value={props.day} func={props.setDay} granularity="Day" />
      {props.containsHour ? (
        <Picker value={props.hour} func={props.setHour} granularity="Hour" />
      ) : null}
    </div>
  </div>
);

const format = (date, regex) => {
  const val = date.match(regex);
  return val ? val.toString().slice(0, -1) : 0;
};

const enhancer = compose(
  withState("year", "updateYear", ({ isoDate }) => format(isoDate, /-?\d+Y/)),
  withState("month", "updateMonth", ({ isoDate }) => format(isoDate, /-?\d+M/)),
  withState("day", "updateDay", ({ isoDate }) => format(isoDate, /-?\d+D/)),
  withState("hour", "updateHour", ({ isoDate }) => format(isoDate, /-?\d+H/)),
  withHandlers({
    setYear: (props) => (val) => {
      props.updateYear(val);
      const dateString = `P${equals(val, 0) ? "" : `${val}Y`}${
        equals(props.month, 0) ? "" : `${props.month}M`
      }${equals(props.day, 0) ? "" : `${props.day}D`}${
        equals(props.hour, 0) ? "" : `T${props.hour}H`
      }`;
      props.update(dateString);
    },
    setMonth: (props) => (val) => {
      props.updateMonth(val);
      const dateString = `P${equals(props.year, 0) ? "" : `${props.year}Y`}${
        equals(val, 0) ? "" : `${val}M`
      }${equals(props.day, 0) ? "" : `${props.day}D`}${
        equals(props.hour, 0) ? "" : `T${props.hour}H`
      }`;
      props.update(dateString);
    },
    setDay: (props) => (val) => {
      props.updateDay(val);
      const dateString = `P${equals(props.year, 0) ? "" : `${props.year}Y`}${
        equals(props.month, 0) ? "" : `${props.month}M`
      }${equals(val, 0) ? "" : `${val}D`}${
        equals(props.hour, 0) ? "" : `T${props.hour}H`
      }`;
      props.update(dateString);
    },
    setHour: (props) => (val) => {
      props.updateHour(val);
      const dateString = `P${equals(props.year, 0) ? "" : `${props.year}Y`}${
        equals(props.month, 0) ? "" : `${props.month}M`
      }${equals(props.day, 0) ? "" : `${props.day}D`}${
        equals(val, 0) ? "" : `T${val}H`
      }`;
      props.update(dateString);
    },
  })
);

export default enhancer(PeriodISOPicker);
