import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const TimeSeriesPreviewTable = ({ data, dataFormatFn }) => (
  <BootstrapTable
    data={data}
    condensed
    keyField="ID"
    version="4"
    containerClass="refineCurvesContainer curvePreviewContainer"
    tableContainerClass="refineCurvesTableContainer"
    bodyContainerClass="refineCurvesTableBody"
  >
    <TableHeaderColumn width="10%" dataField="ID" dataAlign="center" hidden>
      TSID
    </TableHeaderColumn>
    <TableHeaderColumn width="20%" dataField="P" dataAlign="center">
      ProviderName
    </TableHeaderColumn>
    <TableHeaderColumn
      width="30%"
      dataField="C"
      dataAlign="center"
      dataFormat={dataFormatFn}
      className="previewColOverflow"
    >
      MarketDataName
    </TableHeaderColumn>
    <TableHeaderColumn width="20%" dataField="T" dataAlign="center">
      DateTime
    </TableHeaderColumn>
    <TableHeaderColumn width="20%" dataField="D" dataAlign="center">
      Value
    </TableHeaderColumn>
  </BootstrapTable>
);

export default TimeSeriesPreviewTable;
