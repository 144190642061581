import React from "react";
import * as R from "ramda";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { withHandlers } from "recompose";
import { Tooltip } from "react-tippy";

const { compose, equals, path, propOr, propEq, pipe, find, concat } = R;
const ExtractionTable = (props) => (
  <div className="height100 paddingLeft5">
    <div className="pageTitle">Selected MarketData</div>
    <BootstrapTable
      data={props.data}
      condensed
      printable
      height="100%"
      width="100%"
      pagination
      version="4"
      options={props.settings}
      keyField="MarketDataId"
      containerClass="effectedCurvesContainerClass paddingRight"
      tableContainerClass="effectedCurvesExtractionTableContainer"
      bodyContainerClass="effectedCurvesTableBody effectedNested"
    >
      <TableHeaderColumn
        dataField="MarketDataId"
        dataFormat={props.selectedCol}
        width="25"
      />
      <TableHeaderColumn
        dataField="MarketDataId"
        dataFormat={props.errorCol}
        width="40"
        hidden={R.isEmpty(props.errors)}
      >
        Error
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="MarketDataId"
        width="80"
        hidden={!props.showID}
        dataSort
      >
        MarketData ID
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="MarketDataName"
        dataSort
        dataFormat={props.curveNameFormat}
      >
        NAME
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="ProviderName"
        dataSort
        width="130"
        columnClassName="classifactionCol"
      >
        PROVIDER
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="Type"
        dataSort
        width="140"
        columnClassName="classifactionCol"
      >
        MarketData TYPE
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="AggregationRule"
        dataSort
        width="140"
        columnClassName="AggregationRule"
      >
        AGGREGATION RULE
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="Transform"
        dataSort
        width="140"
        dataFormat={(cell) =>
          pipe(
            find(propEq("ID", path(["ID"], cell))),
            propOr(null, "Name")
          )(
            concat(
              props.systemTransformValues || [],
              props.userTransformValues || []
            )
          )
        }
        columnClassName="Transform"
      >
        TIME TRANSFORM
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="OriginalGranularity"
        dataSort
        width="140"
        columnClassName="OriginalGranularity"
      >
        ORIGINAL GRANULARITY
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="OriginalTimezone"
        dataSort
        width="140"
        columnClassName="OriginalTimezone"
      >
        ORIGINAL TIMEZONE
      </TableHeaderColumn>
    </BootstrapTable>
  </div>
);
const getFilterName = (name) => {
  switch (name) {
    case "tsvComponent":
      return "VersionedTimeSerie";
    case "tsComponent":
      return "ActualTimeSerie";
    case "masComponent":
      return "MarketAssessment";
    case "auctionExtractComponent":
      return "Auction";
    case "bidAskExtractComponent":
      return "BidAsk";
    case "tsvAsTsComponent":
      return "both";
    default:
      return "";
  }
};

const enhancer = compose(
  withHandlers({
    curveNameFormat: () => (cell) => (
      <Tooltip
        arrow
        position="top"
        interactive
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
    selectedCol: (props) => (cell, row) => {
      const filterName = getFilterName(props.selected);
      if (equals(filterName, "both")) {
        return equals(row.Type, "VersionedTimeSerie") ||
          equals(row.Type, "ActualTimeSerie") ? (
          // eslint-disable-next-line
          <i className="fas fa-check effectCurvesTick" />
        ) : null;
      }
      return equals(row.Type, filterName) ? (
        <i className="fas fa-check effectCurvesTick" />
      ) : null;
    },
    errorCol: (props) => (cell, row) =>
      R.contains(row.MarketDataId, props.errors) ? (
        <div className="colErrorWarning">
          <i className="fa fa-exclamation-circle" />
        </div>
      ) : null,
  })
);

export default enhancer(ExtractionTable);
