import React from "react";
import { Tooltip } from "react-tippy";
import { compose, isNil, isEmpty, any, equals } from "ramda";
import { withHandlers, withProps } from "recompose";
import { Scrollbars } from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = (props) => (
  <div
    className={`dropdown artesianDropdownContanier ${props.className ||
      ""} customFilterDiv`}
  >
    <div className="btn-group width100" id="customFilterDropDown">
      <div className="d-flex width100 noPadding">
        <button
          type="button"
          className={`btn dropdown-width text-left ${
            props.hasFilterChanged ? "" : "italic"
          }`}
          onClick={props.toggleDropdown}
          data-toggle="dropdown"
          data-target="#customFilterDropDown"
        >
          <Tooltip
            arrow
            position="bottom"
            html={
              <span>
                {isNil(props.selected) || isEmpty(props.selected)
                  ? "Select a Custom Filter"
                  : props.selected}
              </span>
            }
            theme="arkDefaultTooltip"
          >
            {isNil(props.selected) || isEmpty(props.selected) ? (
              <span className="defaultFiltertext">Custom Filters</span>
            ) : (
              `${props.selected}${props.hasFilterChanged ? "" : "*"}`
            )}
          </Tooltip>
        </button>
        <button
          disabled={!props.createDisabled}
          type="button"
          className={`btn artesianDropdownToggle ${
            !props.createDisabled ? "btnDisabled" : ""
          }`}
          onClick={props.openCustomModalModal}
        >
          <Tooltip
            arrow
            position="top"
            html={
              <span>
                {!props.createDisabled
                  ? "No Selection Created"
                  : "Create/Update Custom Filter"}
              </span>
            }
            theme="arkDefaultTooltip"
          >
            <span aria-hidden="true">
              <i className="fas fa-star" />
            </span>
          </Tooltip>
        </button>
      </div>
      <div
        className={`dropdown-menu artesianDropdown ${props.dropID ||
          props.tutExtractID ||
          ""}`}
      >
        <Scrollbars
          autoHeight
          autoHide
          className="autocomplete"
          renderTrackHorizontal={(p) => (
            <div
              {...p}
              style={{ display: "none" }}
              className="track-horizontal"
            />
          )}
          renderThumbHorizontal={(vals) => (
            <div {...vals} className="thumb-horizontal" />
          )}
          renderThumbVertical={(vals) => (
            <div {...vals} className="thumb-vertical" />
          )}
        >
          {props.dropDownValues.length > 0 ? (
            props.dropDownValues.map((val) => (
              <div className="customFilterItem" key={val}>
                <span
                  value={val}
                  className={`dropdown-item ${props.dropdownItemClass}`}
                  onClick={() => props.clickFn(val)}
                  aria-hidden="true"
                >
                  {val}
                </span>
              </div>
            ))
          ) : (
            <div className="customFilterItem">
              <span
                className={`dropdown-item ${props.dropdownItemClass}`}
                aria-hidden="true"
              >
                No Filters Available
              </span>
            </div>
          )}
        </Scrollbars>
      </div>
    </div>
  </div>
);

const enhancer = compose(
  withHandlers({
    clickFn: (props) => (val) => props.onClick(val),
  }),
  withProps((props) => ({
    createDisabled: any(equals(false))([
      props.hasSelectedlabels,
      props.hasFilterText,
    ]),
  }))
);
export default enhancer(Dropdown);
