import React from "react";
import { connect } from "react-redux";
import { setTutorialModalOpenAction } from "../../redux/modules/tutorial";

const VideoComponent = ({
  videoUrl,
  isModalOpen,
  setTutorialModalOpenAction,
}) =>
  isModalOpen ? (
    <div>
      <div
        className={`modal fade show ${isModalOpen ? "d-block" : ""}`}
        id="errorModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={`modalOverlay ${isModalOpen ? "fadeIn" : ""}`}
          id="errorModalOverlay"
          onClick={() => setTutorialModalOpenAction(false)}
        />
        <div className="video modal-dialog artesainModal" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title">Tutorial</h5>
            </div>
            <div className="modal-body text-center">
              <div className="video-container">
                <iframe
                  className="tutorial-iframe"
                  src={videoUrl + "&autoplay=1"}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modalClose"
                onClick={() => setTutorialModalOpenAction(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

const mapStateToProps = (state) => ({
  videoUrl: state.Tutorial.videoUrl,
  isModalOpen: state.Tutorial.isModalOpen,
});
const mapDispatchToProps = {
  setTutorialModalOpenAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoComponent);
