import React from "react";
import { connect } from "react-redux";
import {
  compose,
  remove,
  adjust,
  split,
  merge,
  __,
  curryN,
  length,
  equals,
  pipe,
  propEq,
  filter,
  prop,
  map,
} from "ramda";
import moment from "moment";
import { Tooltip } from "react-tippy";
import { withHandlers, withProps, withState, lifecycle } from "recompose";
import { AuctionExtract } from "../../../curveExtract/extractionComponents";
import {
  copyToClipboardAction,
  downloadXLSXAction,
  setExtractionFilters,
} from "../../../redux/modules/curveExtract";
import { DropDownScroll } from "../../../components";
import { DateRangePicker, Product, PeriodISOPicker } from "../components";
import { buildExtractionString } from "./helper";
import { Selectors } from "../../../redux/modules/tenantSelection";

const createDefault = () => ({
  productType: "Relative",
  gridpoint: "Month",
  offset: 1,
  gasYearChecked: true,
  calYearChecked: true,
  yearOffset: moment().format("YYYY"),
  absoluteDayDate: moment(),
  relative: "Rolling Week",
  productNames: [],
});

const PeriodInfoTooltip = () => (
  <Tooltip
    arrow
    interactive
    position="top"
    html={
      <span className="periodInfoIconAnchor">
        What is a Period Duration? -&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
        >
          Click Here
        </a>
      </span>
    }
    theme="arkDefaultTooltip"
  >
    <i
      className="fa fa-info-circle tooltipIcon periodTooltipIcon"
      aria-hidden="true"
    />
  </Tooltip>
);

const mapStateToProps = (state) => ({
  ...state.CurveExtract,
  ...state.Curves,
  api: Selectors.api(state),
  defaultStartDate: moment().subtract(1, "days"),
  defaultEndDate: moment(),
});

const excelNames = [
  { name: "AuctionExtraction", excel: "AuctionExtraction", templateName: "GenericExtraction", type: null },
  {
    name: "AuctionExtraction Pivoted CurveName",
    excel: "AuctionExtraction_Pivoted_CurveName",
    templateName: "GenericExtraction",
    type: "CurveName"
  },
  {
    name: "AuctionExtraction Pivoted Time",
    excel: "AuctionExtraction_Pivoted_Time",
    templateName: "GenericExtraction",
    type: "Time"
  },
];

const getExcelName = (type, name) =>
  pipe(filter(propEq("name", type)), map(prop(name)))(excelNames);
  
export default compose(
  connect(mapStateToProps, {
    downloadXLSXAction,
    copyToClipboardAction,
    setExtractionFilters
  }),
  withProps((props) => ({
    dropdownValues: props.relativeRangeValues,
  })),
  withState(
    "startDate",
    "updateStartDate",
    ({ defaultStartDate }) => defaultStartDate
  ),
  withState("endDate", "updateEndDate", ({ defaultEndDate }) => defaultEndDate),
  withState("copyClip", "updateCopyClip", false),
  withState("excelType", "updateExcelType", "AuctionExtraction"),
  withState("selectedRelative", "updateSelectedRelative", "Rolling Week"),
  withState("selectedRangeType", "updateSelectedRangeType", "Date Range"),
  withState("selectedTimezone", "updateSelectedTimezone", "CET"),
  withState("productArray", "updateProductArray", [createDefault()]),
  withState("urlString", "updateUrlString", ""),
  withState("selectPeriod", "updateSelectedPeriod", "P0Y0M0D"),
  withState("selectPeriodFrom", "updateSelectedPeriodFrom", "P0Y0M0D"),
  withState("selectPeriodTo", "updateSelectedPeriodTo", "P0Y0M0D"),
  withHandlers({
    setStartDate: (props) => (start) => props.updateStartDate(start),
    setEndDate: (props) => (end) => props.updateEndDate(end),
    selectDropdownItem: (props) => (item) => props.updateSelectedRelative(item),
    selectTimezone: (props) => (item) => props.updateSelectedTimezone(item),
    setSelectedRangeType: (props) => (end) =>
      props.updateSelectedRangeType(end),
    updateSelected: (props) => () =>
      props.updateSelectedExtraction(
        equals(props.selected, props.prodId) ? "" : "auctionExtractComponent"
      ),
  }),
  withHandlers({
    setAsDateRange: (props) => () => props.setSelectedRangeType("Date Range"),
    setExcelType: (props) => (val) => props.updateExcelType(val),
    setAsRelative: (props) => () =>
      props.setSelectedRangeType("relativeSelected"),
    addProduct: (props) => () => {
      if (props.productArray.length < 9) {
        props.updateProductArray([...props.productArray, createDefault()]);
        props.updateUrlString(buildExtractionString(props));
      }
    },
    removeProduct: (props) => (index) => {
      if (props.productArray.length >= 2) {
        props.updateProductArray(remove(index, 1, props.productArray));
        props.updateUrlString(buildExtractionString(props));
      }
    },
    updateProduct: (props) => (index, obj) => {
      curryN(
        2,
        props.updateProductArray(
          adjust(index, merge(__, obj), props.productArray)
        )
      );
      props.updateUrlString(buildExtractionString(props));
    },
    openTutorial: (props) => () => props.toggleTutorial(),
    setDates: (props) => ([startDate, endDate]) => {
      props.updateStartDate(startDate);
      props.updateEndDate(endDate);
    },
  }),
  withHandlers({
    chooseComponent: (props) => () => {
      switch (props.selectedRangeType) {
        case "Date Range":
          return (
            <div className="d-grid">
              <DateRangePicker
                startDate={props.startDate}
                endDate={props.endDate}
                onChange={props.setDates}
                defaultStartDate={props.defaultStartDate}
                defaultEndDate={props.defaultEndDate}
              />
            </div>
          );
        case "Relative":
          return (
            <div className="noPaddingLeft curveExtractDropdown">
              <DropDownScroll
                selected={props.selectedRelative}
                dropDownValues={props.relativeRangeValues}
                onClick={props.updateSelectedRelative}
              />
            </div>
          );
        case "Period From/To":
          return (
            <div className="periodFromTo">
              <div className="periodFromPadding">
                <PeriodISOPicker
                  isoDate={props.selectPeriodFrom}
                  update={props.updateSelectedPeriodFrom}
                  label={<span>From&nbsp;-&nbsp;</span>}
                />
              </div>
              <div className="d-flex">
                <PeriodISOPicker
                  isoDate={props.selectPeriodTo}
                  update={props.updateSelectedPeriodTo}
                  label={
                    <span className="paddingRight15">To&nbsp;-&nbsp;</span>
                  }
                />
                <div className="periodInfoTooltipPadding">
                  <PeriodInfoTooltip />
                </div>
              </div>
            </div>
          );
        case "Period":
          return (
            <div className="d-flex" key="atsPeriod">
              <PeriodISOPicker
                isoDate={props.selectPeriod}
                update={props.updateSelectedPeriod}
                label=""
              />
              <div className="periodInfoTooltipPadding">
                <PeriodInfoTooltip />
              </div>
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withProps((props) => ({
    dataCount: length(
      props.selectedCurves.curves.filter((curve) => curve.Type === "Auction")
    ),
    selectedRangeTypeComponent: props.chooseComponent(props),
    excelTypeDropDown: (
      <DropDownScroll
        selected={props.excelType}
        dropDownValues={map(prop("name"), excelNames)}
        onClick={props.setExcelType}
        dropID="MasExcelTypeDropDown"
      />
    ),
    product: props.productArray.map((val, i) => (
      <Product
        {...val}
        {...props}
        key={i}
        value={i}
        removeItem={() => props.removeProduct(i)}
        addProduct={() => props.addProduct()}
        productCount={props.productArray.length}
      />
    )),
    timeSeriesDateTypeDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedRangeType}
        dropDownValues={["Date Range", "Period From/To", "Period"]}
        onClick={props.updateSelectedRangeType}
        dropID="MASTimeSeriesDateType"
      />
    ),
    timezoneDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedTimezone}
        dropDownValues={props.timezonesValues}
        onClick={props.updateSelectedTimezone}
        dropID="MASTimezone"
      />
    ),
    urlString: buildExtractionString(props),
  })),
  withHandlers({
    copyURL: (props) => () =>
      pipe(
        () => props.copyToClipboardAction(props.urlString),
        () => props.updateCopyClip(true),
        () => setTimeout(() => props.updateCopyClip(false), 800)
      )([]),
    downloadXLSX: (props) => () => {
      props.downloadXLSXAction({
        url: split("&id", props.urlString)[0],
        excelType: getExcelName(props.excelType, "excel"),
        templateName: getExcelName(props.excelType, "templateName"),
        type: getExcelName(props.excelType, "type"),
        isFilterId: props.extractCustomFilterState,
        curveList: props.selectedCurves.curves.filter(
          (curve) => curve.Type === "Auction"
        ),
      });
      if (!localStorage.getItem("finishedExcelTour")) props.openTutorial();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateUrlString(buildExtractionString(this.props));
    },
  })
)(AuctionExtract);
