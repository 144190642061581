import * as RX from "rxjs";
import { ajax } from "rxjs/ajax";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import platform from "platform";

export const getProviders = ({ token, api }) =>
  ajax({
    url: `${api}/v2.1/marketdata/searchfacet?page=1&pageSize=1`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,  'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`
    },
  }).pipe(RxOp.map((x) => x.response));

export const checkSelectedGroupPermission = ({ token, api, groupId }) =>
  ajax({
    url: `${api}/v2.1/acl/me?path=${encodeURIComponent(
      `/principal/group/${groupId}`
    )}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(
    RxOp.map((x) => x.response),
    RxOp.map(
      R.pipe(
        R.head,
        R.prop("Roles"),
        R.filter(R.propEq("InheritedFrom", `/principal/group/${groupId}`)),
        R.map(R.prop("Role"))
      )
    )
  );

export const createGroup = ({ token, api, group }) =>
  ajax({
    url: `${api}/v2.1/group`,
    method: "POST",
    responseType: "json",
    body: JSON.stringify(group),
    headers: {
      Authorization: `Bearer ${token}`, 
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

export const deleteGroup = ({ token, api, groupID }) =>
  ajax({
    url: `${api}/v2.1/group/${groupID}`,
    method: "DELETE",
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,  
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

export const editOrDeletePathGroup = ({ token, api, pathVal, type }) =>
  ajax({
    url: `${api}/v2.1/acl/roles`,
    method: `${R.equals(type, "add") ? "POST" : "DELETE"}`,
    responseType: "json",
    body: JSON.stringify(pathVal),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

export const addPathGroup = ({ token, api, newRole }) =>
  ajax({
    url: `${api}/v2.1/acl/roles`,
    method: "POST",
    responseType: "json",
    body: JSON.stringify(newRole),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

export const deletePathGroup = ({ token, api, rolePath }) =>
  ajax({
    url: `${api}/v2.1/acl/roles`,
    method: "DELETE",
    body: JSON.stringify(rolePath),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(RxOp.map((x) => x.response));

const getGroups = ({ api, token, page, pageSize }) =>
  ajax({
    url: `${api}/v2.1/group?page=${page}&pageSize=${pageSize}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllGroups = ({ token, api }) => {
  const info = {
    token,
    api,
    page: 1,
    pageSize: 500,
  };
  return getGroups(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getGroups({
            ...info,
            page: val.Page + 1,
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};

const getUserGroupPaths = ({ api, token, id, page, pageSize }) =>
  ajax({
    url: `${api}/v2.1/acl?page=${page}&pageSize=${pageSize}&principalIds=${encodeURIComponent(
      `g:${id}`
    )}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllUsersGroupPaths = ({ token, api, id }) => {
  const info = {
    token,
    api,
    id,
    page: 1,
    pageSize: 500,
  };
  return getUserGroupPaths(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getUserGroupPaths({
            ...info,
            page: val.Page + 1,
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};
