import React, { useState, useEffect } from "react";
import { InputNumberTextBox } from "../../../components";

const ImpactedPeriodComponent = ({ setPeriodFrom, setPeriodTo, setIsValid }) => {
  const [dayTo, setDayTo] = useState(0);
  const [monthTo, setMonthTo] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [dayFrom, setDayFrom] = useState(0);
  const [monthFrom, setMonthFrom] = useState(0);
  const [yearFrom, setYearFrom] = useState(0);

  useEffect(() => {
    setIsValid(true);
    if (dayFrom || monthFrom || yearFrom) {
      setPeriodFrom(`P${yearFrom}Y${monthFrom}M${dayFrom}D`);
      setIsValid(yearFrom+((monthFrom/12)+(dayFrom/365)) >= yearTo+((monthTo/12)+(dayTo/365)));
    }
    if (yearTo || monthTo || dayTo) {
      setPeriodTo(`P${yearTo}Y${monthTo}M${dayTo}D`);
      setIsValid(yearFrom+((monthFrom/12)+(dayFrom/365)) >= yearTo+((monthTo/12)+(dayTo/365)));
    }
  }, [dayFrom, monthFrom, yearFrom, yearTo, monthTo, dayTo]);
  
  return (
    <div className="groupBox">
      <div className="periodComponentLeft">
        <div>Impacted Period From</div>

        <div className="periodRow">
          <div className = "periodRowDateSelector">
            Y
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={yearFrom}
              onChange={(val) => {
                setYearFrom(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the YEAR starting from "0" as the current one, "-1" as last year, and "+1" as next year.
          </div>
        </div>
        
        <div className="periodRow">
          <div className = "periodRowDateSelector">
            M
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={monthFrom}
              onChange={(val) => {
                setMonthFrom(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the MONTH starting from "0" as the current one, "-1" as last month, and "+1" as next month.
          </div>
        </div>

        <div className="periodRow">
          <div className = "periodRowDateSelector">
            D
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={dayFrom}
              onChange={(val) => {
                setDayFrom(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the DAY starting from "0" as yesterday, "1" as today, and "2" as tomorrow.
          </div>
        </div>
        <br />
      </div>
      <br />
      
      
      <div className="periodComponentRight">
        <div>Impacted Period To (Exclusive)</div>

        <div className="periodRow">
          <div className = "periodRowDateSelector">
            Y
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={yearTo}
              onChange={(val) => {
                setYearTo(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the YEAR starting from "0" as the current one, "-1" as last year, and "+1" as next year.
          </div>
        </div>

        <div className="periodRow">
          <div className = "periodRowDateSelector">
            M
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={monthTo}
              onChange={(val) => {
                setMonthTo(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the MONTH starting from "0" as the current one, "-1" as last month, and "+1" as next month.
          </div>
        </div>

        <div className="periodRow">
          <div className = "periodRowDateSelector">
            D
            <InputNumberTextBox
              className="artesianInputNumberTextBox periodTextBox"
              value={dayTo}
              onChange={(val) => {
                setDayTo(val);
              }}
            />
          </div>
          <div className="periodRowText">
            Select the DAY starting from "0" as yesterday, "1" as today, and "2" as tomorrow.
          </div>
        </div>

        <div>
          {`${
            yearFrom + (monthFrom / 12 + dayFrom / 365) >=
            yearTo + (monthTo / 12 + dayTo / 365)
              ? "Impacted Period To offset needs to be higher than Impacted Period From"
              : ""
          }
                      `}
        </div>
      </div>
      <br />
      
    </div>
  );
};

export default ImpactedPeriodComponent;
