import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import platform from "platform";

export const requestData = ({ pageNo, pageSize, filter, token, api }) =>
  ajax({
    url: `${api}/v2.1/expectationAlert?pageSize=${pageSize}&page=${pageNo}${
      filter ? `&name=${filter}` : ""
    }`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` 
    },
  }).pipe(map((x) => x.response));

export const requestHistoricalDataRequest = ({
  id,
  pageNo,
  pageSize,
  token,
  api,
}) =>
  ajax({
    url: `${api}/v2.1/expectationAlert/${id}/pastChecks?pageSize=${pageSize}&page=${pageNo}`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    },
  }).pipe(map((x) => x.response));

export const deleteAlertRequest = ({ id, token, api }) =>
  ajax({
    url: `${api}/v2.1/expectationAlert/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    },
  }).pipe(map((x) => x.response));

export const addAlertRequest = ({ alertDetails, token, api }) =>
  ajax({
    url: `${api}/v2.1/expectationAlert`,
    method: "PUT",
    body: JSON.stringify(alertDetails),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(map((x) => x.response));

export const addMarketDataIdsRequest = ({ id, marketDataIdList, token, api }) =>
  ajax({
    url: `${api}/v2.1/expectationAlert/${id}/marketDataList`,
    method: "PUT",
    body: JSON.stringify(marketDataIdList),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(map((x) => x.response));

export const deleteMarketDataIdsRequest = ({
  id,
  marketDataIdList,
  token,
  api,
}) =>
  ajax({
    url: `${api}/v2.1/expectationAlert/${id}/marketDataList`,
    method: "DELETE",
    body: JSON.stringify(marketDataIdList),
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
  }).pipe(map((x) => x.response));
