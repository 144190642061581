import { combineReducers } from "redux";
import * as R from "ramda";
import * as RX from "rxjs";
import { flatMap, tap, map as RxMap, delay } from "rxjs/operators";
import copyToClipboard from "copy-to-clipboard";
import XLSX from "xlsx-populate/browser/xlsx-populate";
import { createSelector } from "reselect";
import {
  simpleSetReducerCreator,
  fetchingReducerCreator,
} from "../../reducerCreators";
import { dispatchNetworkError } from "./helper";
import {
  requestUserDefinedData,
  requestSystemDefinedData,
  requestExcelBlob,
  getAllCurveVersions,
} from "./request";
import {
  timeSeriesDateTypeValues,
  relativeRangeValues,
  granularityValues,
  timezonesValues,
  fillerValues,
  versionValues,
  gridPointValues,
  productTypesValues,
} from "./constants";
import { auth } from "../../../appContext";
import { Selectors } from "../tenantSelection";

const { always, compose, of, ap, prop, head } = R;

const FETCHINGDATA = "artesian-ui/curveExtract/FETCHINGDATA";
const RECIEVEDDATA = "artesian-ui/curveExtract/RECIEVEDDATA";

const FETCHINGAVAILABLEVERSIONDATA = "artesian-ui/curveExtract/FETCHINGAVAILABLEVERSIONDATA";
const RECIEVEDAVAILABLEVERSIONDATA = "artesian-ui/curveExtract/RECIEVEDAVAILABLEVERSIONDATA";

const RECIEVED_CURVE_VERSIONED =
  "artesian-ui/curveExtract/RECIEVED_CURVE_VERSIONED";
const RECIEVED_SYSTEM_TRANSFORM_DATA =
  "artesian-ui/curveExtract/RECIEVED_SYSTEM_TRANSFORM_DATA";
const RECIEVED_USER_TRANSFORM_DATA =
  "artesian-ui/curveExtract/RECIEVED_USER_TRANSFORM_DATA";
export const COPY_TO_CLIPBOARD = "artesian-ui/curveExtract/COPY_TO_CLIPBOARD";
export const DOWNLOADXLSX = "artesian-ui/curveExtract/DOWNLOADXLSX";
const DISPLAY_CURVETABLE = "artesian-ui/curveExtract/DISPLAY_CURVETABLE";
const RESET_MAS_FILLER_MODAL =
  "artesian-ui/curveExtract/RESET_MAS_FILLER_MODAL";
const OPEN_MAS_FILLER_MODAL = "artesian-ui/curveExtract/OPEN_MAS_FILLER_MODAL";
const UPDATE_MAS_FILLER_MODAL =
  "artesian-ui/curveExtract/UPDATE_MAS_FILLER_MODAL";
const CLOSE_MAS_FILLER_MODAL =
  "artesian-ui/curveExtract/CLOSE_MAS_FILLER_MODAL";
const RESET_BA_FILLER_MODAL = "artesian-ui/curveExtract/RESET_BA_FILLER_MODAL";
const OPEN_BA_FILLER_MODAL = "artesian-ui/curveExtract/OPEN_BA_FILLER_MODAL";
const UPDATE_BA_FILLER_MODAL =
  "artesian-ui/curveExtract/UPDATE_BA_FILLER_MODAL";
const CLOSE_BA_FILLER_MODAL = "artesian-ui/curveExtract/CLOSE_BA_FILLER_MODAL";
const SET_EXTRACTION_FITLERS =
  "artesian-ui/curveExtract/SET_EXTRACTION_FITLERS";

const fillerMASModalDefault = {
  open: false,
  row: {},
  default: {
    fillerDVo: null,
    fillerDVc: null,
    fillerDVh: null,
    fillerDVl: null,
    fillerDVvp: null,
    fillerDVvg: null,
    fillerDVvt: null,
  },
};

const fillerMASModalReducer = (state = fillerMASModalDefault, action) => {
  switch (action.type) {
    case OPEN_MAS_FILLER_MODAL:
      return { ...state, open: true };
    case UPDATE_MAS_FILLER_MODAL:
      return { ...state, open: false, row: action.row };
    case CLOSE_MAS_FILLER_MODAL:
      return { ...state, open: false };
    case "artesian-ui/curves/EXTRACT_CUSOM_FILTER":
    case RESET_MAS_FILLER_MODAL:
      return fillerMASModalDefault;
    default:
      return state;
  }
};

const fillerBAModalDefault = {
  open: false,
  row: {},
  default: {
    fillerDVbbp: null,
    fillerDVbap: null,
    fillerDVbbq: null,
    fillerDVbaq: null,
    fillerDVlp: null,
    fillerDVlq: null,
  },
};
const fillerBAModalReducer = (state = fillerBAModalDefault, action) => {
  switch (action.type) {
    case OPEN_BA_FILLER_MODAL:
      return { ...state, open: true };
    case UPDATE_BA_FILLER_MODAL:
      return { ...state, open: false, row: action.row };
    case CLOSE_BA_FILLER_MODAL:
      return { ...state, open: false };
    case "artesian-ui/curves/EXTRACT_CUSOM_FILTER":
    case RESET_BA_FILLER_MODAL:
      return fillerBAModalDefault;
    default:
      return state;
  }
};

const extractionFiltersDefault = {
  auction: {},
  bidAsk: {},
  ats: {},
  mas: {},
  vts: {},
  tsvAsTs: {},
};
const extractionFiltersReducer = (state = extractionFiltersDefault, action) => {
  switch (action.type) {
    case SET_EXTRACTION_FITLERS:
      return {
        ...state,
        [action.filterData.prop]: action.filterData.filters,
      };
    default:
      return state;
  }
};

export default combineReducers({
  timeSeriesDateTypeValues: always(timeSeriesDateTypeValues),
  relativeRangeValues: always(relativeRangeValues),
  granularityValues: always(granularityValues),
  timezonesValues: always(timezonesValues),
  fillerValues: always(fillerValues),
  versionValues: always(versionValues),
  productTypesValues: always(productTypesValues),
  gridPointValues: always(gridPointValues),
  extractionFetching: fetchingReducerCreator({
    fetching: FETCHINGDATA,
    received: RECIEVEDDATA,
  }),
  availableVersionFetching: fetchingReducerCreator({
    fetching: FETCHINGAVAILABLEVERSIONDATA,
    received: RECIEVEDAVAILABLEVERSIONDATA,
  }),
  systemTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_SYSTEM_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  displayCurveTableState: simpleSetReducerCreator({
    setAction: DISPLAY_CURVETABLE,
    prop: "state",
    initialState: "displaySpinner",
  }),
  userTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_USER_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  curveVersion: simpleSetReducerCreator({
    setAction: RECIEVED_CURVE_VERSIONED,
    prop: "data",
    initialState: [],
  }),
  fillerMASModal: fillerMASModalReducer,
  fillerBAModal: fillerBAModalReducer,
  extractionFilters: extractionFiltersReducer,
});

const fetchingData = () => ({ type: FETCHINGDATA });
const receivedData = () => ({ type: RECIEVEDDATA });

const fetchingAvailableVersionData = () => ({ type: FETCHINGAVAILABLEVERSIONDATA });
const receivedAvailableVersionData = () => ({ type: RECIEVEDAVAILABLEVERSIONDATA });

const receivedSystemTransformData = (data) => ({
  type: RECIEVED_SYSTEM_TRANSFORM_DATA,
  data,
});
const receivedCurveVersionData = (data) => ({
  type: RECIEVED_CURVE_VERSIONED,
  data,
});
const receivedUserTransformData = (data) => ({
  type: RECIEVED_USER_TRANSFORM_DATA,
  data,
});
const handleNetworkError = compose(
  ap([dispatchNetworkError, receivedData]),
  of
);
const displayCurveTableData = (state) => ({
  type: DISPLAY_CURVETABLE,
  state,
});

const resultsSystemRecieved = compose(
  ap([compose(receivedSystemTransformData, prop("Data")), receivedData]),
  of
);
const resultsUserRecieved = compose(
  ap([compose(receivedUserTransformData, prop("Data")), receivedData]),
  of
);

const copyToClipboarFn = ap(
  (text) => always({ type: COPY_TO_CLIPBOARD, text }),
  copyToClipboard
);

const tokenFromState = compose(auth.getToken, Selectors.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: Selectors.api(state),
    }))
  );
const getSystemDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingData)),
      flatMap(requestSystemDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsSystemRecieved),
      compose(dispatch, handleNetworkError)
    );

const getUserDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingData)),
      flatMap(requestUserDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsUserRecieved),
      compose(dispatch, handleNetworkError)
    );

const processFile = ({ url, excelType, type, isFilterId, curveList }) => (response) =>
  XLSX.fromDataAsync(response)
    .then((wk) => {
      const queries = wk.sheet("Queries");
      queries
      .row(2)
      .cell(3)
      .value(`${type}`);
      if (isFilterId) {
        queries
          .row(2)
          .cell(1)
          .value(url);
      } else {
        const urlList = R.pipe(
          R.map(R.prop("MarketDataId")),
          R.splitEvery(25000000000),
          R.map(R.prepend("")),
          R.map(R.join("&id="))
        )(curveList);

        const rowStart = 2;
        urlList.map((ids, i) =>
          queries
            .row(rowStart + i)
            .cell(1)
            .value(`${url}${ids}`)
        );
      }
      return wk;
    })
    .then((wk) => wk.outputAsync("arraybuffer"))
    .then((wbout) => {
      const blob = new Blob([wbout], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${excelType+"Extraction"+type}.xlsx`);
      } else {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${excelType+ type}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });

const setRowsValues = ({ queries, excelType, type, row, url, startDate, endDate }) => {
  queries
    .row(row)
    .cell(1)
    .value(url);
  queries
    .row(row)
    .cell(2)
    .value(startDate);
  queries
    .row(row)
    .cell(3)
    .value(endDate);
};

const processIncrementalFile = ({
  url,
  excelType,
  type,
  isFilterId,
  curveList,
  startDate,
  endDate,
}) => (response) =>
  XLSX.fromDataAsync(response)
    .then((wk) => {
      const queries = wk.sheet("Queries");

      const query = R.pipe(
        R.replace(startDate, "XXXXX"),
        R.replace(endDate, "YYYYY")
      )(url);

      if (isFilterId) {
        setRowsValues({
          queries,
          row: 2,
          url: query,
          startDate,
          endDate,
        });
      } else {
        const urlList = R.pipe(
          R.map(R.prop("MarketDataId")),
          R.splitEvery(25000000000),
          R.map(R.prepend("")),
          R.map(R.join("&id="))
        )(curveList);

        const rowStart = 2;
        urlList.map((ids, i) =>
          setRowsValues({
            queries,
            row: rowStart + i,
            url: `${query}${ids}`,
            startDate,
            endDate,
          })
        );
      }

      return wk;
    })
    .then((wk) => wk.outputAsync("arraybuffer"))
    .then((wbout) => {
      const blob = new Blob([wbout], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${excelType+"Extraction"+type}.xlsx`);
      } else {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${excelType+type}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });

const downloadXLSX = ({ url, excelType, templateName, type, isFilterId, curveList }) => (
  dispatch,
  getState
) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch,()=>  createDownloadxlsxFunc(url))),
      tap(compose(dispatch, fetchingData)),
      flatMap(() =>
        requestExcelBlob({
          templateName,
          type,
          excelType: isFilterId ? `Filter_${excelType}` : excelType
        })
      )
    )
    .subscribe(
      compose(
        dispatch,
        receivedData,
        processFile({
          url,
          excelType,
          type,
          isFilterId,
          curveList,
        })
      ),
      compose(dispatch, handleNetworkError)
    );

const downloadIncrementalXLSX = ({
  url,
  excelType,
  templateName,
  type,
  isFilterId,
  curveList,
  startDate,
  endDate,
}) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, always({ type: DOWNLOADXLSX }))),
      tap(compose(dispatch, fetchingData)),
      flatMap(() => requestExcelBlob({ excelType, fileType: "xlsx" }))
    )
    .subscribe(
      compose(
        dispatch,
        receivedData,
        processIncrementalFile({
          url,
          excelType,
          type,
          isFilterId,
          curveList,
          startDate,
          endDate,
        })
      ),
      compose(dispatch, handleNetworkError)
    );

const getFirstCurveID = compose(prop("MarketDataId"), head);

const getCurveIDVersion = (versionFrom, versionTo) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingAvailableVersionData)),
      delay(800),
      flatMap(({ token, api }) =>
        getAllCurveVersions({
          token,
          api,
          id: getFirstCurveID(getState().Curves.selectedCurves.curves),
          versionFrom, 
          versionTo
        })
      )
    )
    .subscribe(
      compose(dispatch, receivedCurveVersionData),
      compose(dispatch, handleNetworkError),
      compose(dispatch, receivedAvailableVersionData)
    );

const loadCurvesTable = (val) => (dispatch) =>
  RX.of(val).subscribe(compose(dispatch, displayCurveTableData));

export const loadCurvesTableAction = (val) => [loadCurvesTable(val)];
export const getAllTimeTransformDataAction = () => [
  getSystemDefinedTransformData(),
  getUserDefinedTransformData(),
];

export const copyToClipboardAction = (text) => [copyToClipboarFn(text)];
export const getCurveIDVersionAction = (versionFrom, versionTo) => [getCurveIDVersion(versionFrom, versionTo)];

export const downloadXLSXAction = ({
  url,
  excelType,
  templateName,
  type,
  isFilterId,
  curveList,
}) => [
  downloadXLSX({
    url,
    excelType,
    templateName,
    type,
    isFilterId,
    curveList,
  }),
];

export const downloadIncrementalXLSXAction = ({
  url,
  excelType,
  templateName,
  type,
  isFilterId,
  curveList,
  startDate,
  endDate,
}) => [
  downloadIncrementalXLSX({
    url,
    excelType,
    templateName,
    type,
    isFilterId,
    curveList,
    startDate,
    endDate,
  }),
];
export const CurveExtractSelectors = {
  displayCurveTableSelector: createSelector(
    prop("CurveExtract"),
    prop("displayCurveTableState")
  ),
};

const createDownloadxlsxFunc = (url) => ({ type: DOWNLOADXLSX, url });
export const resetMASFillerModal = () => ({ type: RESET_MAS_FILLER_MODAL });
export const openMASFillerModal = () => ({ type: OPEN_MAS_FILLER_MODAL });
export const closeMASFillerModal = () => ({ type: CLOSE_MAS_FILLER_MODAL });
export const updateMASFillerModal = (row) => ({
  type: UPDATE_MAS_FILLER_MODAL,
  row,
});

export const resetBAFillerModal = () => ({ type: RESET_BA_FILLER_MODAL });
export const openBAFillerModal = () => ({ type: OPEN_BA_FILLER_MODAL });
export const closeBAFillerModal = () => ({ type: CLOSE_BA_FILLER_MODAL });
export const updateBAFillerModal = (row) => ({
  type: UPDATE_BA_FILLER_MODAL,
  row,
});
export const setExtractionFilters = (filterData) => ({
  type: SET_EXTRACTION_FITLERS,
  filterData,
});
