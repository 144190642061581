import React from "react";
import * as R from "ramda";


export const getStatusColour = (status, hasTrafficLight) => {
    const trafficLight = hasTrafficLight ? (
      <div className="fas fa-circle trafficLight" />
    ) : (
      <React.Fragment></React.Fragment>
    );
  
    switch (status) {
      case "Ok":
      case "Solved":
        return (
          <div className="sucess">
            {trafficLight}
            {status}
          </div>
        );
      case "Waiting":
        return (
          <div className="warning">
            {trafficLight}
            {status}
          </div>
        );
      case "InLate":
        return <div className="failed">{trafficLight}Late</div>;
      case "NoCurves":
        return <div className="failed">{trafficLight}No curves</div>;
      case "NotSet":
        return <div />;
      default:
        return <div>{status}</div>;
    }
  };