import "rxjs";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { combineEpics } from "redux-observable";
import Authentication from "./authentication";
import Menu from "./menu";
import Curves from "./curves";
import ErrorHandler from "./errorHandler";
import Settings from "./settings";
import RefineCurves from "./refineCurves";
import CurveEnrich from "./curveEnrich";
import CurveClassification from "./curveClassification";
import EnrichTimeTransform from "./enrichTimeTransform";
import { epic as AnalyticsEpic } from "./analytics/index";
import CurvePreview from "./curvePreview";
import CurveExtract from "./curveExtract";
import tenant from "./tenantSelection";
import Tutorial from "./tutorial";
import AdminGroups from "./adminGroups";
import Permissions from "./permissions";
import AdminUsers from "./adminUsers";
import {
  reducer as DemandOfferReducer,
  epic as DemandOfferEpic,
} from "./demandOffer";
import {
  reducer as GmePublicOffersReducer,
  epic as GmePublicOffersEpic,
} from "./gmePublicOffers";
import {
  reducer as ExpectationMonitorReducer,
  epic as ExpectationMonitorEpic,
} from "./expectationMonitor";

const environmentSettingsReducer = (state = {}) => state;

export const rootReducer = combineReducers({
  environmentSettings: environmentSettingsReducer,
  router: routerReducer,
  Authentication,
  Menu,
  Curves,
  ErrorHandler,
  RefineCurves,
  CurveEnrich,
  Settings,
  EnrichTimeTransform,
  CurveClassification,
  CurvePreview,
  CurveExtract,
  Permissions,
  tenant,
  Tutorial,
  AdminGroups,
  AdminUsers,
  DemandOffer: DemandOfferReducer,
  GmePublicOffers: GmePublicOffersReducer,
  ExpectationMonitor: ExpectationMonitorReducer,
});

export const rootEpic = combineEpics(
  AnalyticsEpic,
  DemandOfferEpic,
  GmePublicOffersEpic,
  ExpectationMonitorEpic
);
