/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import { format, subDays } from "date-fns";
import copyToClipboard from "copy-to-clipboard";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  DropDownScroll,
  PortalSpinner,
  ErrorModal,
  CustomMulti,
} from "../components";
import {
  StatusOptions,
  PurposeOptions,
  ScopeOptions,
  MarketOptions,
  BaTypeOptions,
  ZoneOptions,
  UnitTypeOptions,
  GenerationTypeOptions,
} from "./options";
import {
  Selectors,
  setGmePublicOfferFilters,
  clearGmePublicOfferFilters,
  clearAllGmePublicOfferFilters,
  previewGmePublicOffer,
  previewGmePublicOfferClose,
  downloadGmePublicOffer,
  requestOperatorEnum,
  requestUnitsEnum,
  closeError,
  getGmePublicOfferApi,
} from "../redux/modules/gmePublicOffers";
import Modal from "./components/modal";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";

const GmePublicOfferView = ({
  info,
  setGmePublicOfferFilters,
  clearGmePublicOfferFilters,
  clearAllGmePublicOfferFilters,
  previewGmePublicOffer,
  previewGmePublicOfferClose,
  downloadGmePublicOffer,
  requestOperatorEnum,
  requestUnitsEnum,
  closeError,
  getGmePublicOfferApi,
  setTutorialModalOpenAction,
  setVideoUrlAction,
}) => {
  const { data, previewData, operatorEnum, unitEnum, errorHandler } = info;

  useEffect(() => {
    requestOperatorEnum();
    requestUnitsEnum();
    getGmePublicOfferApi();

    return () => clearAllGmePublicOfferFilters();
  }, []);
  return (
    <>
      <div className="GmePublicOfferContainer">
        <GmePublicOfferMain
          info={data}
          updateFilters={setGmePublicOfferFilters}
          clearAddtionalFilters={clearGmePublicOfferFilters}
          previewGmePublicOffer={previewGmePublicOffer}
          downloadGmePublicOffer={downloadGmePublicOffer}
          setTutorialModalOpenAction={setTutorialModalOpenAction}
          setVideoUrlAction={setVideoUrlAction}
          enums={{
            operatorEnum: operatorEnum.data,
            unitEnum: unitEnum.data,
          }}
          url={`${info.data.api}${info.data.query}`}
        />
      </div>
      <Modal
        show={previewData.open}
        title="Preview Data"
        close={previewGmePublicOfferClose}
        body={<PreviewTable data={previewData.data} />}
      />
      <PortalSpinner
        spinnerState={
          data.isFetching ||
          previewData.isFetching ||
          operatorEnum.isFetching ||
          unitEnum.isFetching
        }
      />
      <ErrorModal showError={errorHandler} closeModal={closeError} />
    </>
  );
};

export default connect((s) => ({ info: Selectors.all(s) }), {
  setGmePublicOfferFilters,
  previewGmePublicOffer,
  clearGmePublicOfferFilters,
  clearAllGmePublicOfferFilters,
  previewGmePublicOfferClose,
  downloadGmePublicOffer,
  requestOperatorEnum,
  requestUnitsEnum,
  closeError,
  getGmePublicOfferApi,
  setVideoUrlAction,
  setTutorialModalOpenAction,
})(GmePublicOfferView);

const GmePublicOfferMain = ({
  info,
  updateFilters,
  clearAddtionalFilters,
  previewGmePublicOffer,
  downloadGmePublicOffer,
  setTutorialModalOpenAction,
  setVideoUrlAction,
  enums,
  url,
}) => {
  const { filters } = info;

  const update = ({ val, prop }) =>
    updateFilters({
      ...filters,
      [prop]: val,
    });

  const date = R.pathOr(null, ["date"], filters);
  return (
    <>
      <div className="gmePublicOfferMain">
      <div className="pageTitle">
      GME Public Offer
        <span aria-hidden="true">
        <i
            className="fas fa-question-circle artesianIconHover"
            onClick={() => {
              setVideoUrlAction(
                "https://www.youtube.com/embed/uFC8wKDQ28M?si=LUqRWNa7VVqqZjxN"
              );
              setTutorialModalOpenAction(true);
            }}
          />
        </span>
        </div>
        <DateOption
          title="Date Options"
          value={R.isNil(date) ? null : moment(date)}
          maxDate={subDays(new Date(), 7)}
          update={(val) =>
            update({
              val: R.isNil(val) ? val : format(new Date(val), "yyyy-MM-dd"),
              prop: "date",
            })
          }
        />
        <DropOption
          title="Purpose"
          value={R.pathOr("", ["purpose"], filters)}
          options={PurposeOptions}
          update={(val) => update({ val, prop: "purpose" })}
        />
        <DropOption
          title="Status"
          value={R.pathOr("", ["status"], filters)}
          options={StatusOptions}
          update={(val) => update({ val, prop: "status" })}
        />

        <GmePublicOfferFilters
          filters={filters}
          enums={enums}
          clearAddtionalFilters={clearAddtionalFilters}
          update={update}
        />
        <GmePublicOfferFuncs
          previewGmePublicOffer={previewGmePublicOffer}
          downloadGmePublicOffer={downloadGmePublicOffer}
          filters={filters}
          url={url}
        />
      </div>
    </>
  );
};
const GmePublicOfferFilters = ({
  filters,
  enums,
  clearAddtionalFilters,
  update,
}) => {
  const fils = R.pipe(
    R.omit(["date", "purpose", "status"]),
    R.values,
    R.all((x) => R.isNil(x) || R.isEmpty(x))
  )(filters);

  return (
    <>
      <fieldset className="gmePublicOfferFieldset">
        <legend>
          <div>
            <i className="fa fa-filter" />
            Optional Filter &nbsp;
            {fils ? null : (
              <span onClick={() => clearAddtionalFilters()}>
                <i className="fa fa-times clearFilters" />
              </span>
            )}
          </div>
        </legend>

        <div className="gmePublicOfferOptional">
          {R.ifElse(
            R.isEmpty,
            () => null,
            () => (
              <MultiDropOption
                title="Operators"
                containsFilter
                value={R.pathOr([], ["operators"], filters)}
                options={R.map(R.prop("Operator"), enums.operatorEnum)}
                update={(val) => update({ val, prop: "operators" })}
              />
            )
          )(enums.operatorEnum)}
          {R.ifElse(
            R.isEmpty,
            () => null,
            () => (
              <MultiDropOption
                title="Unit"
                containsFilter
                value={R.pathOr([], ["unit"], filters)}
                options={R.map(R.prop("Unit"), enums.unitEnum)}
                update={(val) => update({ val, prop: "unit" })}
              />
            )
          )(enums.unitEnum)}
          <MultiDropOption
            title="Market"
            value={R.pathOr([], ["market"], filters)}
            options={MarketOptions}
            update={(val) => update({ val, prop: "market" })}
          />
          <MultiDropOption
            title="Scope"
            value={R.pathOr([], ["scope"], filters)}
            options={ScopeOptions}
            update={(val) => update({ val, prop: "scope" })}
          />
          <MultiDropOption
            title="BA Type"
            value={R.pathOr([], ["baType"], filters)}
            options={BaTypeOptions}
            update={(val) => update({ val, prop: "baType" })}
          />
          <MultiDropOption
            title="Zone"
            value={R.pathOr([], ["zone"], filters)}
            options={ZoneOptions}
            update={(val) => update({ val, prop: "zone" })}
          />
          <MultiDropOption
            title="Unit Type"
            value={R.pathOr([], ["unitType"], filters)}
            options={UnitTypeOptions}
            update={(val) => update({ val, prop: "unitType" })}
          />
          <MultiDropOption
            title="GenerationType"
            value={R.pathOr([], ["generationType"], filters)}
            options={GenerationTypeOptions}
            update={(val) => update({ val, prop: "generationType" })}
          />
        </div>
      </fieldset>
    </>
  );
};

const GmePublicOfferFuncs = ({
  previewGmePublicOffer,
  downloadGmePublicOffer,
  filters,
  url,
}) => {
  const required = R.pipe(
    R.pick(["date", "purpose", "status"]),
    R.values,
    R.any(R.isNil)
  )(filters);

  const [copy, setCopy] = useState(false);

  return (
    <>
      <div className="copyToClipboard noselect">
        <input className="form-control noselect" value={url} readOnly />
        <Tooltip
          arrow
          open={copy}
          trigger="manual"
          duration={400}
          html={<span>Copied To Clipboard</span>}
          theme="arkDefaultTooltip"
        >
          <button
            type="button"
            className="btn copyToClipboardBtn"
            disabled={required}
            onClick={() =>
              R.pipe(
                () => copyToClipboard(url),
                () => setCopy(true),
                () => setTimeout(() => setCopy(false), 800)
              )([])
            }
          >
            <span>
              <i className="fa fa-clipboard" /> &nbsp;
            </span>
          </button>
        </Tooltip>
      </div>
      <div className="gmePublicOfferFuncs">
        <button
          type="button"
          className="btn gmePublicOfferBtn"
          disabled={required}
          onClick={() => downloadGmePublicOffer({ url })}
        >
          Download Excel
        </button>
        <button
          type="button"
          className="btn gmePublicOfferBtn"
          disabled={required}
          onClick={() => previewGmePublicOffer()}
        >
          Preview
        </button>
      </div>
    </>
  );
};

const DropOption = ({
  title,
  value,
  containsFilter = false,
  options,
  update,
}) => (
  <div className="offerOption">
    <div className="offerTitle">{title}</div>
    <div className="offerTask">
      <DropDownScroll
        className=""
        selected={value}
        containsFilter={containsFilter}
        dropDownValues={options}
        onClick={update}
      />
    </div>
  </div>
);
const MultiDropOption = ({
  title,
  value,
  containsFilter = false,
  options,
  update,
}) => (
  <div className="offerOption">
    <div className="offerTitle">{title}</div>
    <div className="offerTask">
      <CustomMulti
        value={
          R.isEmpty(value)
            ? []
            : value.map((val) => ({ value: val, title: val }))
        }
        update={(row) => update(row.map((x) => x.value))}
        options={options.map((val) => ({ value: val, title: val }))}
        name={title}
        containsFilter={containsFilter}
      />
    </div>
  </div>
);
const DateOption = ({
  title,
  value,
  update,
  maxDate = null,
  minDate = null,
}) => (
  <div className="offerOption">
    <div className="offerTitle">{title}</div>
    <div className="offerTask">
      <DatePicker
        className="datePicker"
        selected={value}
        dateFormat="YYYY-MM-DD"
        onChange={update}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  </div>
);

const PreviewTable = ({ data }) => (
  <>
    <Scrollbars
      className="scrollFacets"
      renderThumbHorizontal={(vals) => (
        <div {...vals} className="thumb-horizontal" />
      )}
      renderThumbVertical={(vals) => (
        <div {...vals} className="thumb-vertical" />
      )}
    >
      <table className="previewTable">
        <thead>
          <tr>
            <th>Status</th>
            <th>BAType</th>
            <th>Scope</th>
            <th>Date</th>
            <th>Hour</th>
            <th>Market</th>
            <th>Purpose</th>
            <th>Zone</th>
            <th>UnitType</th>
            <th>GenerationType</th>
            <th>Unit</th>
            <th>Operator</th>
            <th>Quantity</th>
            <th>AwardedQuantity</th>
            <th>EnergyPrice</th>
            <th>MeritOrder</th>
            <th>PartialQuantityAccepted</th>
            <th>ADJQuantity</th>
            <th>ADJEnergyPrice</th>
          </tr>
        </thead>

        <tbody>
          {R.isEmpty(data) ? (
            <>
              <tr>
                <td colSpan={19}>No Data To Display</td>
              </tr>
            </>
          ) : (
            data.map((row, i) => (
              <tr key={i}>
                <TableTD title={row.Status} />
                <TableTD title={row.BAType} />
                <TableTD title={row.Scope} />
                <TableTD title={row.Date} />
                <TableTD title={row.Hour} />
                <TableTD title={row.Market} />
                <TableTD title={row.Purpose} />
                <TableTD title={row.Zone} />
                <TableTD title={row.UnitType} />
                <TableTD title={row.GenerationType} />
                <TableTD title={row.Unit} />
                <TableTD title={row.Operator} />
                <TableTD title={row.Quantity} />
                <TableTD title={row.AwardedQuantity} />
                <TableTD title={row.EnergyPrice} />
                <TableTD title={row.MeritOrder} />
                <TableTD title={row.PartialQuantityAccepted} />
                <TableTD title={row.ADJQuantity} />
                <TableTD title={row.ADJEnergyPrice} />
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Scrollbars>
  </>
);

const TableTD = ({ title }) => (
  <td>
    <Tooltip
      arrow
      touchHold
      position="bottom"
      html={<span>{title}</span>}
      theme="arkDefaultTooltip"
    >
      {R.isNil(title) ? null : R.toString(title)}
    </Tooltip>
  </td>
);
