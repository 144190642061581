import React from "react";
import { connect } from "react-redux";
import { compose, equals, is, __, assoc, toPairs } from "ramda";
import { withHandlers } from "recompose";
import { InputTextBox } from "../../components";
import { AdminGroupsModal } from "../../adminGroups";
import {
  closeGroupModalAction,
  updateGroupModalRowAction,
  createNewGroupAction,
} from "../../redux/modules/adminGroups";

const mapStateToProps = (state) => ({
  ...state.AdminGroups,
});

export default compose(
  connect(mapStateToProps, {
    closeGroupModalAction,
    updateGroupModalRowAction,
    createNewGroupAction,
  }),
  withHandlers({
    updateRow: (props) => (row) => props.updateGroupModalRowAction(row),
    commitChanges: (props) => () => props.createNewGroupAction(props.modal.row),
    closeModal: (props) => () => props.closeGroupModalAction(),
  }),
  withHandlers({
    applyBtnIsDisabled: (props) => () =>
      (props.modal.orig ? equals(props.modal.row, props.modal.orig) : true) ||
      props.modal.row.Name === "",
    GetDropDown: (props) => (type, cellValue = "") => {
      const objUpdater = compose(
        props.updateRow,
        assoc(type, __, props.modal.row)
      );
      const value =
        is(Object, cellValue) && equals(cellValue.overridden, true)
          ? cellValue.value
          : cellValue;
      switch (type) {
        case "Name":
          return (
            <div>
              <label htmlFor="select">
                <b>Group Name</b>
              </label>
              <InputTextBox
                value={value}
                onChange={objUpdater}
                id="AddGroupName"
              />
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withHandlers({
    GetDropDownGroups: (props) => () =>
      toPairs(props.modal.row).map(([type, value]) => (
        <div className={`form-group ${type}`} key={type}>
          {props.GetDropDown(type, value || "")}
        </div>
      )),
  })
)(AdminGroupsModal);
