import React, { useState } from "react";
import { DropDown, InputTextBox } from "../../../components";
import ImpactedPeriodComponent from "./impactedPeriodComponent";
import CronSelection from "../cronSelection/cronSelection";

const AddAlertBody = ({
  timezone,
  setTimezone,
  impactedDataRangeTimezone,
  setImpactedDataRangeTimezone,
  cronExpression,
  setCronExpression,
  alertName,
  setAlertName,
  dateTo,
  setIsValid,
  setDateTo,
  dateFrom,
  setDateFrom,
  type,
}) => {
  const timeZones = ["CET", "GMT", "UTC"];
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const [
    toggleImpactedDataRangeTimezoneDropdown,
    setImpactedDataRangeTimezoneDropdown,
  ] = useState(true);
  return (
    <div className="addAlert">
      <div className="inlineComponentsContainer">
        <div className="inlineComponent">
          <div className="textLeft">Alert Name</div>
          <InputTextBox
            value={alertName}
            disabled={type === "edit"}
            onChange={(x) => setAlertName(x)}
            placeholder="Add Alert Name"
          />
        </div>
        <br />
        <div className="inlineComponent">
          <div className="textLeft">Timezone</div>
          <DropDown
            onClick={(x) => setTimezone(x)}
            selected={timezone}
            dropDownValues={timeZones}
            toggleDropdown={() => setToggleDropdown(!toggleDropdown)}
          />
        </div>
        <br />
        <div className="inlineComponent">
          <div className="textLeft">Impacted Data Range Timezone</div>
          <DropDown
            onClick={(x) => setImpactedDataRangeTimezone(x)}
            selected={impactedDataRangeTimezone}
            dropDownValues={timeZones}
            toggleDropdown={() =>
              setImpactedDataRangeTimezoneDropdown(
                !toggleImpactedDataRangeTimezoneDropdown
              )
            }
          />
        </div>
      </div>
      <br />

      <div className="inlineComponentsContainer">
        <CronSelection
          cronExpression={cronExpression || ""}
          onChange={setCronExpression}
        />
        <ImpactedPeriodComponent
          periodFrom={dateFrom}
          periodTo={dateTo}
          setIsValid={setIsValid}
          setPeriodFrom={setDateFrom}
          setPeriodTo={setDateTo}
        />
      </div>
    </div>
  );
};

export default AddAlertBody;
