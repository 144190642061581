import React from "react";
import { Button } from "../../../components";

const RemoveSelectedOrder = (props) => (
  <Button
    className="close close-default remove-box-shadow btn-circle removeSelectedCurve small fa fa-times"
    onClick={() => props.removeAndCheckNavigation([props.row])}
    btnText=""
  />
);

export default RemoveSelectedOrder;
