import React from "react";
import { connect } from "react-redux";
import {
  compose,
  find,
  propEq,
  isEmpty,
  prop,
  map,
  join,
  any,
  equals,
  contains,
} from "ramda";
import { lifecycle, withProps, withHandlers, withState } from "recompose";
import { Tooltip } from "react-tippy";
import { AdminGroups } from "../../adminGroups";
import { tutorialOnAction } from "../../redux/modules/tutorial";
import {
  getAdminGroupsAction,
  emptyDataAction,
  openGroupModalAction,
  setSelectedGroupsAction,
  openDeleteGroupModalAction,
  addPathAction,
  openEditPathModalAction,
  deletePathAction,
  openDeletePathModalAction,
} from "../../redux/modules/adminGroups";
import { CurvesSelectors } from "../../redux/modules/curves";
import { RenderSizePerPageDropDown, Button } from "../../components";
import Permissions from "./components/permissions";

const mapStateToProps = (state) => ({
  ...state.AdminGroups,
  allProviders: CurvesSelectors.allProviders(state),
  principalGroupPermission:
    prop("groups", state.Permissions.menuPermission) || [],
});

export default compose(
  connect(mapStateToProps, {
    tutorialOn: tutorialOnAction,
    getAdminGroupsAction,
    emptyDataAction,
    openGroupModalAction,
    openDeleteGroupModalAction,
    openDeletePathModalAction,
    setSelectedGroupsAction,
    openEditPathModalAction,
    addPathAction,
    deletePathAction,
  }),
  lifecycle({
    componentDidMount() {
      this.subscription = this.props.getAdminGroupsAction();
    },
    componentWillUnmount() {
      this.props.emptyDataAction();
      this.subscription.unsubscribe();
    },
  }),
  withProps((props) => ({
    createGroupPermission: !any(equals(true))([
      contains("Creator", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
    ]),
    removeGroupPermission: !any(equals(true))([
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
      contains("Owner", props.selectedGroupPermission),
    ]),
    canEditpathPermission: !any(equals(true))([
      contains("Contributor", props.principalGroupPermission),
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
      contains("Owner", props.selectedGroupPermission),
    ]),
    canDeletePathPermission: !any(equals(true))([
      contains("Editor", props.principalGroupPermission),
      contains("Owner", props.principalGroupPermission),
      contains("Owner", props.selectedGroupPermission),
    ]),
  })),
  withState("pageSize", "updatePageSize", 50),
  withHandlers({
    setPageSize: (props) => (size) => props.updatePageSize(size),
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(props.pageSize, props),
    deletePath: (props) => (val) =>
      props.openDeletePathModalAction({
        row: {
          Path: val.Path,
          Roles: val.Orig.Roles,
        },
      }),
  }),
  withProps((props) => ({
    tableData: map(
      (x) => ({
        Path: x.Path,
        Role: compose(join(",  "), map(prop("Role")))(x.Roles),
        editRoles: compose(map((z) => ({ role: z.Role, orig: true })))(x.Roles),
        PrincipalId: props.selectedGroup.ID,
        Orig: x,
      }),
      props.selectedGroupPaths
    ),
    selectedGroupName: props.selectedGroup.Name,
    selectedGroupOption: isEmpty(props.allGroups)
      ? []
      : map(prop("Name"), props.allGroups),
    tableSettings: {
      sizePerPage: props.pageSize,
      sizePerPageDropDown: props.renderDropDown,
    },
    dataFormat: (cell, row) => (
      <div>
        <Button
          onClick={() =>
            props.openEditPathModalAction({
              row: {
                path: row.Path,
                principalId: row.PrincipalId,
                editRoles: row.editRoles,
              },
            })
          }
          permissionDisabled={props.canEditpathPermission}
          className="basicEditBtn"
          btnText={<i className="fas fa-pencil-alt" />}
        />
        <Button
          onClick={() => props.deletePath(row)}
          permissionDisabled={props.canDeletePathPermission}
          className="basicEditBtn"
          btnText={<i className="fas fa-trash" />}
        />
      </div>
    ),
    PermissionFormat: (cell) => (
      <Tooltip
        arrow
        position="bottom"
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
  })),
  withHandlers({
    setSelectedGroup: (props) => (group) =>
      props.setSelectedGroupsAction(
        find(propEq("Name", group))(props.allGroups)
      ),
    openGroupModal: (props) => () =>
      props.openGroupModalAction({
        title: "Create a new group",
        row: {
          ID: 0,
          ETag: null,
          Name: null,
          Users: [],
        },
      }),
    openDeleteGroupModal: (props) => () =>
      props.openDeleteGroupModalAction({
        title: "Delete Group",
        row: props.selectedGroup,
      }),
  }),
  withProps((props) => ({
    addPermissions: <Permissions {...props} />,
  }))
)(AdminGroups);
