import React from "react";
import * as R from "ramda";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

export const GroupLabel = ({ group, removeGroup }) => (
  <div className="groupTag" key={group.name}>
    <div className="form-control group-default allLabels">
      <span>{group.name}</span>
      <Button
        onClick={() => removeGroup(group.name)}
        className="close close-default remove-box-shadow btn-circle pull-right"
        btnText={
          <Tooltip
            arrow
            position="bottom"
            html={<span>Remove Group</span>}
            theme="arkDefaultTooltip"
          >
            <i className="fa fa-times" />
          </Tooltip>
        }
      />
    </div>
  </div>
);

export const addGroupToUser = (val, userGroups) => {
  const rowIndex = R.findIndex(R.propEq("name", val))(userGroups);
  return R.contains(val, R.map(R.prop("name"), userGroups))
    ? R.adjust(rowIndex, (x) => ({ ...x, mod: "add" }), userGroups)
    : R.append({ name: val, mod: "add", orig: false }, userGroups);
};

export const removeGroupFromUser = (val, userGroups) => {
  const rowIndex = R.findIndex(R.propEq("name", val))(userGroups);
  return R.adjust(rowIndex, (x) => ({ ...x, mod: "remove" }), userGroups);
};

export const getAvailableGroups = (allGroups, userGroups) => {
  const allArr = R.map(R.prop("Name"), allGroups);
  const selectedArr = R.compose(
    R.map(R.prop("name")),
    R.filter((x) => x.mod !== "remove")
  )(userGroups);

  return R.reject((x) => R.contains(x, selectedArr), allArr);
};
