import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

export const goupAdminPageTutorial = [
  {
    selector: '[class="btn  menuBtnItem Extract "]',
    action: (node) => node.click(),
    content: <div />,
  },
  {
    position: "center",
    content: (
      <div>
        <h3>Group Management</h3>
        Create, update and delete groups for your organistation
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Select a Group
  {
    selector: '[class="d-flex width100 noPadding selectedGroup"]',
    position: "bottom",
    content: (
      <div>
        <h3>Select a Group</h3>
        Choose any of the groups the has been created.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Create a new Group
  {
    selector: '[id="AddNewGroup"]',
    position: "bottom",
    content: (
      <div>
        <h3>Create a new Group</h3>
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Delete a Group
  {
    selector: '[id="DeleteGroup"]',
    position: "bottom",
    content: (
      <div>
        <h3>Delete a Group</h3>
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Table
  {
    selector:
      '[class="react-bs-table react-bs-table-bordered adminGroupsTableContainer"]',
    position: "bottom",
    content: (
      <div>
        <h3>Groups Associated Path</h3>
        Displays all associated paths to the groups and each permission the path
        has. <br /> Admins can edit path permissions or can remove the path
        entirely from the group
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Add new path
  {
    selector: '[class="paddingRight20 pathPermissions"]',
    position: "bottom",
    content: (
      <div>
        <h3>Create a new Path Permission</h3>
        Create a new path to add to the groups exisiting permissions.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  {
    selector: '[class="btn active menuBtnItem Admin "]',
    position: "bottom",
    content: (
      <div>
        <h3>Go to User Admin Page</h3>
        Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    action: (node) => node.click(),
    style: toolTipStyleTopMargin,
  },
  // On hover.
  {
    selector: '[id="MenuUserAdmin"]',
    position: "right",
    content: (
      <div>
        <h3>Go to User Admin Page</h3>
      </div>
    ),
    style: toolTipStyleLeftMargin,
    action: (node) => node.click()
  }
];

export default goupAdminPageTutorial;
