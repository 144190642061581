import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import {
  compose,
  equals,
  reject,
  __,
  map,
  prop,
  contains,
  adjust,
  dissoc,
  isEmpty,
  merge,
  filter,
  isNil,
  findIndex,
  append,
  propEq,
} from "ramda";
import { withHandlers, withState, withProps } from "recompose";
import { AdminEditGroupsModal } from "../../adminGroups";
import {
  closeEditPathModalAction,
  updateEditPathModalRowAction,
  editPathAction,
} from "../../redux/modules/adminGroups";
import { Button } from "../../components";

const mapStateToProps = (state) => ({
  ...state.AdminGroups,
});

const GroupLabel = ({ group, removeGroup }) => (
  <div className="groupTag" key={group.role}>
    <div className="form-control group-default allLabels">
      <span>{group.role}</span>
      <Button
        onClick={() => removeGroup(group.role)}
        className="close close-default remove-box-shadow btn-circle pull-right"
        btnText={
          <Tooltip
            arrow
            position="bottom"
            html={<span>Remove Group</span>}
            theme="arkDefaultTooltip"
          >
            <i className="fa fa-times" />
          </Tooltip>
        }
      />
    </div>
  </div>
);

const addGroupToUser = (val, userGroups) =>
  contains(val, map(prop("role"), userGroups))
    ? adjust(
        findIndex(propEq("role", val))(userGroups),
        merge(__, { mod: "add" }),
        userGroups
      )
    : append({ role: val, mod: "add", orig: false }, userGroups);

const removeGroupFromUser = (val, userGroups) =>
  adjust(
    findIndex(propEq("role", val))(userGroups),
    merge(__, { mod: "remove" }),
    userGroups
  );

const getAvailableGroups = (allRoles, userGroups) => {
  const selectedArr = compose(
    map(prop("role")),
    filter((x) => x.mod !== "remove")
  )(userGroups);

  return reject((x) => contains(x, selectedArr), allRoles);
};

export default compose(
  connect(mapStateToProps, {
    closeEditPathModalAction,
    updateEditPathModalRowAction,
    editPathAction,
  }),
  withHandlers({
    updateRow: (props) => (row) => props.updateEditPathModalRowAction(row),
    commitChanges: (props) => () =>
      props.editPathAction(props.editPathModal.row),
    closeModal: (props) => () => props.closeEditPathModalAction(),
  }),
  withState(
    "pathPermissions",
    "updatePathPermissions",
    ({ editPathModal }) => editPathModal.row.editRoles
  ),
  withHandlers({
    setPathPermissions: (props) => (val) => {
      props.updatePathPermissions(val);
      props.updateRow({
        ...props.editPathModal.row,
        editRoles: val,
      });
    },
  }),
  withHandlers({
    setSelectedGroup: (props) => (val) =>
      props.setPathPermissions(addGroupToUser(val, props.pathPermissions)),
    removeGroup: (props) => (group) =>
      props.setPathPermissions(
        removeGroupFromUser(group, props.pathPermissions)
      ),
  }),
  withProps((props) => ({
    disableApplyBtn:
      equals(
        compose(
          map((x) =>
            equals(x.orig, true) && equals(x.mod, "add") ? dissoc("mod", x) : x
          ),
          reject((x) => equals(x.orig, false) && equals(x.mod, "remove"))
        )(props.editPathModal.row.editRoles),
        props.editPathModal.orig.editRoles
      ) ||
      isEmpty(
        reject(
          (x) => equals(x.mod, "remove"),
          props.editPathModal.row.editRoles
        )
      ),
    roleList: getAvailableGroups(
      props.userRolesList || [],
      props.pathPermissions || []
    ),
    displaySelectedGroups: isNil(props.pathPermissions)
      ? null
      : props.pathPermissions.map((group) =>
          group.mod && equals(group.mod, "remove") ? null : (
            <GroupLabel
              key={group.role}
              group={group}
              removeGroup={props.removeGroup}
            />
          )
        ),
  })),
  withProps((props) => ({
    availbleRolesTitle: equals(props.roleList.length, 0)
      ? "All Roles Selected"
      : "Please select a Role",
  }))
)(AdminEditGroupsModal);
