import React from "react";
import Select, { components } from "react-select";
import { Scrollbars } from "react-custom-scrollbars";

const Menu = (props) => (
  <components.Menu {...props}>
    <Scrollbars
      autoHeight
      autoHide
      className="autocomplete"
      renderTrackHorizontal={(p) => (
        <div {...p} style={{ display: "none" }} className="track-horizontal" />
      )}
      renderThumbHorizontal={(vals) => (
        <div {...vals} className="thumb-horizontal" />
      )}
      renderThumbVertical={(vals) => (
        <div {...vals} className="thumb-vertical" />
      )}
    >
      {props.children}
    </Scrollbars>
  </components.Menu>
);

const RemoveSelectedOrder = ({
  value,
  change,
  options,
  closeOnSelect,
  name,
  disabled,
  noOptionsMessage,
  className,
  isSearchable = false,
}) => (
  <Select
    isMulti
    closeMenuOnSelect={closeOnSelect}
    className={`multiSelect ${className}`}
    classNamePrefix="multiSelect"
    name={name}
    value={value}
    onChange={change}
    options={options}
    menuPlacement="auto"
    isSearchable={isSearchable}
    isClearable={false}
    isDisabled={disabled}
    noOptionsMessage={noOptionsMessage}
    components={{ Menu }}
  />
);

export default RemoveSelectedOrder;
