import React from "react";
import { connect } from "react-redux";
import {
  compose,
  equals,
  reject,
  isNil,
  isEmpty,
  dissoc,
  map,
  contains,
  pathOr
} from "ramda";
import { withHandlers, withState, withProps } from "recompose";
import { AdminAddUserModal } from "../../adminUsers";
import {
  closeAddUserModalAction,
  updateAddUserModalRowAction,
  addNewUserAction,
} from "../../redux/modules/adminUsers";

import {
  GroupLabel,
  addGroupToUser,
  removeGroupFromUser,
  getAvailableGroups,
} from "./helper";

const mapStateToProps = (state) => ({
  ...state.AdminUsers,
  ...state.Authentication,
});

export default compose(
  connect(mapStateToProps, {
    closeAddUserModalAction,
    updateAddUserModalRowAction,
    addNewUserAction,
  }),
  withState(
    "userName",
    "updateUserName",
    ({ addUserModal }) => addUserModal.row.userName
  ),
  withState(
    "userGroups",
    "updateUserGroups",
    ({ addUserModal }) => addUserModal.row.userGroups
  ),
  withState("selectedGroup", "updateSelectedGroup", ""),
  withHandlers({
    updateRow: (props) => (row) => props.updateAddUserModalRowAction(row),
    commitChanges: (props) => () =>
      props.addNewUserAction(props.addUserModal.row),
    closeModal: (props) => () => props.closeAddUserModalAction(),
  }),
  withHandlers({
    setUserName: (props) => (val) => {
      props.updateUserName(val);
      props.updateRow({
        ...props.addUserModal.row,
        userName: val,
      });
    },
    setUserGroups: (props) => (val) => {
      props.updateUserGroups(val);
      props.updateRow({
        ...props.addUserModal.row,
        userGroups: val,
      });
    },
  }),
  withHandlers({
    setSelectedGroup: (props) => (val) =>
      props.setUserGroups(addGroupToUser(val, props.userGroups)),
    removeGroup: (props) => (group) =>
      props.setUserGroups(removeGroupFromUser(group, props.userGroups)),
  }),
  withProps((props) => ({
    allUsersGroups: props.allGroups.filter(
      (x) =>
        contains(pathOr("", ["profile","email"],props), x.Users) ||
        contains(x.ID, props.userGroupOwnerShip)
    ),
  })),
  withProps((props) => ({
    updateGroupsIsEmpty: isEmpty(
      reject((x) => equals(x.mod, "remove"), props.addUserModal.row.userGroups)
    ),
    disableApplyBtn:
      (equals(
        compose(
          map((x) =>
            equals(x.orig, true) && equals(x.mod, "add") ? dissoc("mod", x) : x
          ),
          reject((x) => equals(x.orig, false) && equals(x.mod, "remove"))
        )(props.addUserModal.row.userGroups),
        props.addUserModal.orig.userGroups
      ) &&
        equals(
          props.addUserModal.orig.userName,
          props.addUserModal.row.userName
        )) ||
      equals("", props.addUserModal.row.userName) ||
      equals(null, props.addUserModal.row.userName),
    groupList: getAvailableGroups(
      props.allUsersGroups || [],
      props.userGroups || []
    ),
    displaySelectedGroups: isNil(props.userGroups)
      ? null
      : props.userGroups.map((group) =>
          group.mod && equals(group.mod, "remove") ? null : (
            <GroupLabel
              key={group.name}
              group={group}
              removeGroup={props.removeGroup}
            />
          )
        ),
  })),
  withProps((props) => ({
    availbleGroupsTitle: equals(props.groupList.length, 0)
      ? "All Groups Selected"
      : "Please select a Group",
  }))
)(AdminAddUserModal);
