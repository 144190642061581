export const curveTableMapTypeNameFn = (type) => {
  if (type === "VersionedTimeSerie") {
    return "Versioned Time Series Preview";
  } else if (type === "ActualTimeSerie") {
    return "Actual Time Series Preview";
  } else if (type === "MarketAssessment") {
    return "Market Assessment Preview";
  }
  return type || "";
};

export default curveTableMapTypeNameFn;
