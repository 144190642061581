import React from "react";
import { Button } from "../components";

const Settings = (props) => (
  <div>
    <div
      className={`modal fade show ${
        props.tableSettingsModalState ? "d-block" : ""
      }`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${
          props.tableSettingsModalState ? "fadeIn" : ""
        }`}
        onClick={props.close}
      />
      <div className="modal-dialog artesainModal settingsModal" role="document">
        <div className="modal-content settings">
          <div className="modal-header pageTitle">
            API Key Configuration
            <Button
              className="btn close close-default remove-box-shadow btn-circle curvePreviewClose"
              onClick={props.close}
              btnText="&times;"
            />
          </div>
          <div className="modal-body settingsBody d-flex">
            <div className="settingsMenuList">
              {props.tableSettingsMenuItem}
              {props.createApiMenuItem}
              {props.displayKeysMenuItem}
            </div>
            <div className="settingsMenuContainer">
              {props.selectedComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Settings;
