import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import moment from "moment";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import Dashboard from "./dashboard";
import AlertDetails from "./alertDetails";
import HistoryPage from "./historyPage";
import HistoryDetailsPage from "./historyDetailsPage";
import {
  requestAction,
  requestPollAction,
  requestNewAction,
  setPageNoAction,
  setPageSizeAction,
  setExpectationDashboardFilterAction,
  deleteAlertAction,
  addAlertAction,
  requestHistoryAction,
  addMarketDataIds,
  deleteMarketDataIdsAction,
  openModal,
  Selectors,
} from "../redux/modules/expectationMonitor";
import { setTutorialModalOpenAction, setVideoUrlAction } from '../redux/modules/tutorial';

//TODO: move to helper********
const expectationMonitorPage = "dashboard";
const expectationMonitorAlertsPage = "alerts";
const expectationMonitorHisoryPage = "history";
const expectationMonitorHisoryDetailsPage = "historyDetails";
//********************************

const ExpectationAlertDashboard = (props) => {
  const [currentPage, setCurrentPage] = useState(expectationMonitorPage);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState(0);
  const [selectedHistoryId, setSelectedHistoryId] = useState(0);

  const renderSwitch = (param) => {
    switch (param) {
      case expectationMonitorPage:
        return (
          <Dashboard
            setSelectedId={setSelectedId}
            setSelectedAlert={setSelectedAlert}
            setPage={setCurrentPage}
            parentProps={props}
          ></Dashboard>
        );
      case expectationMonitorAlertsPage:
        return (
          <AlertDetails
            selectedId={selectedId}
            selectedAlert={selectedAlert}
            setPage={setCurrentPage}
            parentProps={props}
          ></AlertDetails>
        );
      case expectationMonitorHisoryPage:
        return (
          <HistoryPage
            selectedId={selectedId}
            setSelectedHistoryId={setSelectedHistoryId}
            setPage={setCurrentPage}
            parentProps={props}
          ></HistoryPage>
        );
      case expectationMonitorHisoryDetailsPage:
        return (
          <HistoryDetailsPage
            selectedId={selectedId}
            selectedHistoryId={selectedHistoryId}
            setPage={setCurrentPage}
            selectedAlert={selectedAlert}
            parentProps={props}
          ></HistoryDetailsPage>
        );
      default:
        return <div></div>;
    }
  };
  return (
    <>
      <div className="deliveryDashboard">
        <div className="classify pageTitle">
          <span className="paddingLeftFive">
            <i className="fa fa-tachometer-alt marginRight10 artesianIconNoHover" />
          </span>
          Expectation Alert Dashboard
          <span aria-hidden="true">
            <i
              className="fas fa-question-circle artesianIconHover"
              onClick={() => {
                props.setVideoUrlAction(
                  "https://www.youtube.com/embed/KUs2zykZBsk?si=P8RJQvl9CVeSDXiK"
                );
                props.setTutorialModalOpenAction(true);
              }}
            />
          </span>
        </div>
        {renderSwitch(currentPage)}
      </div>
    </>
  );
};

export default connect((s) => ({ info: Selectors.ExpectationMonitor(s) }), {
  requestAction,
  requestPollAction,
  requestNewAction,
  requestHistoryAction,
  setPageNoAction,
  setPageSizeAction,
  deleteAlertAction,
  addAlertAction,
  openModal,
  addMarketDataIds,
  setExpectationDashboardFilterAction,
  deleteMarketDataIdsAction,
  setTutorialModalOpenAction,
  setVideoUrlAction
})(ExpectationAlertDashboard);
