import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as R from "ramda";
import { permissionCheck } from "./helper/permissions";

const Protected = ({ routes, state }) => (
  <Redirect
    to={R.pipe(
      R.toPairs,
      R.find(
        R.pipe(
          R.nth(1),
          R.map((p) => permissionCheck({ permission: p, state })),
          R.any(R.equals(true))
        )
      ),
      R.head
    )(routes)}
  />
);

const ProtectedRoute = R.compose(
  withRouter,
  connect(R.objOf("state"))
)(Protected);
export default ProtectedRoute;
