import React, { useEffect } from "react";
import { connect } from "react-redux";
import isMobile from "ismobilejs";
import { withHandlers, withState, withProps, lifecycle } from "recompose";
import {
  compose,
  append,
  equals,
  length,
  gt,
  prop,
  map,
  uniq,
  isEmpty,
  contains,
} from "ramda";
import { Switch, Route } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { CurvesContainer } from "./curves";
import { RefineCurvesContainer } from "./refineCurves";
import { SettingsContainer } from "./settings";
import { CurvesClassificationContainer } from "./curvesClassification";
import { DataDeliveryDashboard } from "../dataDeliveryDashboard";
import { ExpectationAlertDashboard } from "../expectationAlertDashboard";
import { CurvePreviewContainer } from "./curvePreview";
import { MenuHeaderContainer, MainMenuContainer } from "./menu";
import { ClearError } from "../redux/modules/errorHandler";
import { PortalSpinner } from "../components";
import { CurveExtractContainer } from "./curveExtract";
import { CurveEnrichContainer } from "./curveEnrich";
import { EnrichTimeTransformContainer } from "./enrichTimeTransform";
import { AdminGroupsContainer } from "./adminGroup";
import { AdminUsersContainer } from "./adminUsers";
import DisplayModals from "./displayModals";
import NonOptimizedDisplay from "./nonOptimizedDidplay";
import {
  setSelectedMenuItemAction,
  closeNavigationModalAction,
  showNavigationModalAction,
} from "../redux/modules/menu";
import {
  setExtractCustomFilterStateAction,
  CurvesSelectors,
} from "../redux/modules/curves";
import { removeTableSettingsAction } from "../redux/modules/refineCurves";
import { setTutorialAction } from "../redux/modules/tutorial";
import { TutorialContainer } from "./tutorial";
import GmePublicOffer from "../gmePublicOffers/view";
import Protected from "./protectedRoute";
import PermissionsAwareRedirect from "./protectedRedirect";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Login } from "../components";

import { setAuth, setB2CAuthAction, setNameAction, setEmailAction } from "../redux/modules/authentication";
import { setTenant, setOptions } from "../redux/modules/tenantSelection";
import { msalConfig, loginRequest } from "../authConfig";
import { msalInstance, auth } from "../appContext";
import EnvironmentSettings from "../environment";
import { taggedSum } from "daggy";
import VideoComponent from "./tutorial/tutorialPopupVid";


const { Loading } = taggedSum("Auth", {
  Loading: []
});

const DisplaySelectedFunction = ({
  selectedIndex,
  renderSelectedFunction,
  location,
}) => (
  <SwipeableViews
    disabled
    className={`swipe ${location}`}
    index={selectedIndex}
  >
    {renderSelectedFunction}
  </SwipeableViews>
);

const Artesian = ({
  mainIndex,
  displaySelectedFunction,
  extractCustomUserFilter,
  updateSelected,
}) => (
  <SwipeableViews disabled className="swipe" index={mainIndex}>
    <CurvesContainer
      extractCustomUserFilter={extractCustomUserFilter}
      menuUpdate={updateSelected}
    />
    {displaySelectedFunction}
  </SwipeableViews>
);

const MainContainer = ({
  mainIndex,
  selectedMenuItem,
  home,
  updateSelected,
  displaySelectedFunction,
  showNavigationModal,
  extractCustomUserFilter,
  updateUserMenuState,
  setMaslToken,
  setOptions
}) => {
  const mainPermissions = [
    "filter",
    "apikey",
    "groups",
    "timetransform",
    "expectation",
    "globalPath",
  ];
  const gmepublicofferPermissions = ["gmepublicoffer"];
  React.useEffect(() => {
    hasTennant = true
    // Runs after the first render() lifecycle
    msalInstance.handleRedirectPromise().then(authResult => {
      const account = msalInstance.getAllAccounts();
      hasTennant = auth.retrieveTenantsList(account[0].idTokenClaims).length > 0
      if (account.length === 0) {
        auth.loginRedirect(msalConfig);
      }
      else {
        setMaslToken(account[0])
        setOptions(auth.retrieveTenantsList(account[0].idTokenClaims))
      }
    });
  }, []);
  var logoutCheck = setTimeout(()=>hasTennant? {}:setTimeout(()=>auth.logout(), 3000))
  return (
    <Switch>
      <Protected
        permission={gmepublicofferPermissions}
        path="/GMEPublicOffer"
        component={GmePublicOffer}
      />
      <Route path="">
      {hasTennant ? <div
          className="mainBody d-inline-flex"
          onClick={() => updateUserMenuState(false)}
        >
          <div className="userMenu transition">
            <div className="displayMenu">
              <MainMenuContainer
                mainIndex={mainIndex}
                selectedMenuItem={selectedMenuItem}
                home={home}
                updateSelected={updateSelected}
                showNavigationModal={showNavigationModal}
                displaySelectedFunction={displaySelectedFunction}
              />
            </div>
          </div>
          <div className="mainContainer transition">
            <Artesian
              mainIndex={mainIndex}
              updateSelected={updateSelected}
              extractCustomUserFilter={extractCustomUserFilter}
              displaySelectedFunction={displaySelectedFunction}
            />
          </div>
        </div>:
        <div className="reloadUserPanel">
          <div>Account has been updated</div>
          <div>Logging out to reload user</div>
          <i className="fa fa-solid fa-spinner fa-spin"></i>
        </div>
      }
      </Route>

      <PermissionsAwareRedirect
        routes={{
          [``]: mainPermissions,
          [`/GMEPublicOffer`]: gmepublicofferPermissions,
        }}
      />
    </Switch>
  );
};

const Main = ({
  isfetching,
  optimizedDevice,
  optimizedDeviceDisplay,
  extractionFetching,
  adminGroupFetching,
  fetchingMenuPermissions,
  adminUsersFetching,
  enrichFetching,
  selectedMenuItem,
  home,
  enrichTimeTransformFetching,
  tenant,
  userMenuState,
  updateUserMenuState,
}) => {
  return (
    <div className="main">
      <AuthenticatedTemplate>
        <MenuHeaderContainer
          home={home}
          optimizedDevice={optimizedDevice}
          userMenuState={userMenuState}
          updateUserMenuState={updateUserMenuState}
        />
      </AuthenticatedTemplate>
      <VideoComponent/>
      <PortalSpinner
        spinnerState={
          isfetching ||
          extractionFetching ||
          enrichFetching ||
          enrichTimeTransformFetching ||
          adminGroupFetching ||
          fetchingMenuPermissions ||
          adminUsersFetching
        }
      />

      <SettingsContainer />
      <CurvePreviewContainer />
      <DisplayModals />
      <TutorialContainer selectedMenuItem={selectedMenuItem} />
      {optimizedDeviceDisplay}
    </div>
  );
}

const selectedFunctionRenderer = (
  props,
  page,
  filterExtractionState,
  override
) => {
  let component = <div />;
  switch (page) {
    case "refineBasket":
      component = (
        <RefineCurvesContainer
          showNavigationModal={props.showNavigationModal}
          navHome={props.home}
        />
      );
      break;
    case "deliveryDashboard":
      //component = <DataDeliveryDashboard />;
      component = <ExpectationAlertDashboard />;
      break;
    case "classifyCurves":
      component = <CurvesClassificationContainer />;
      break;
    case "enrichMetaData":
      component = <CurveEnrichContainer />;
      break;
    case "extractCurves":
      component = <CurveExtractContainer />;
      break;
    case "enrichTimeTransform":
      component = <EnrichTimeTransformContainer />;
      break;
    case "groupAdmin":
      component = <AdminGroupsContainer />;
      break;
    case "userAdmin":
      component = <AdminUsersContainer />;
      break;
    default:
      break;
  }

  if (
    !equals(page, props.selectedMenuItem) &&
    (equals("groupAdmin", page) ||
      equals("userAdmin", page) ||
      equals("deliveryDashboard", page) ||
      equals("enrichTimeTransform", page) ||
      (equals("extractCurves", page) && filterExtractionState))
  ) {
    props.updateMainIndex(1);
    props.updatePreviousSelectedIndex(props.selectedIndex);
    props.updateSelectedIndex(props.selectedIndex + 1);
    return append(component, props.selectedFunction);
  }

  if (
    (!equals(page, props.selectedMenuItem) &&
      gt(length(props.selectedCurves.curves), 0)) ||
    override
  ) {
    props.updateMainIndex(1);
    props.updatePreviousSelectedIndex(props.selectedIndex);
    props.updateSelectedIndex(props.selectedIndex + 1);
    return append(component, props.selectedFunction);
  }
  return props.selectedFunction;
};

const tutorialHelper = (arr, menuOverride, dataType) => {
  if (isEmpty(arr) && menuOverride) {
    return [dataType];
  }

  return !contains(dataType, arr) ? append(dataType, arr) : arr;
};

const mapStateToProps = (state) => ({
  errorHandler: state.ErrorHandler,
  ...state.CurveEnrich,
  ...state.AdminGroups,
  ...state.AdminUsers,
  ...state.CurveExtract,
  ...state.Permissions,
  ...state.EnrichTimeTransform,
  ...state.Menu,
  ...state.tenant,
  availableFacets: CurvesSelectors.availableFacets(state),
  selectedCurves: CurvesSelectors.selectedCurves(state),
  isfetching: CurvesSelectors.curvesFetching(state),
});
let hasTennant = false
export default compose(
  connect(mapStateToProps, {
    setError: ClearError,
    setSelectedMenuItemAction,
    setTutorialAction,
    closeNavigationModalAction,
    showNavigationModalAction,
    removeTableSettingsAction,
    setExtractCustomFilterStateAction,
    setReduxAuth: setAuth,
    setMaslToken: setB2CAuthAction,
    setName: setNameAction,
    setEmail: setEmailAction,
    setTenant: setTenant,
    setOptions: setOptions
  }),
  withState("mainIndex", "updateMainIndex", 0),
  withState("selectedIndex", "updateSelectedIndex", 0),
  withState("previousSelectedIndex", "updatePreviousSelectedIndex", null),
  withState("selectedFunction", "updateselectedFunction", [<div />]),
  withState("userMenuState", "updateUserMenuState", false),
  withProps((props) => ({
    showNavigationModal: () => {
      props.showNavigationModalAction({
        msg: `No market data available. Navigating to Select MarketData.`,
      });
      setTimeout(() => props.closeNavigationModalAction(), 2000);
    },
    selectedCurveTypes: compose(
      uniq,
      map(prop("Type"))
    )(props.selectedCurves.curves),
  })),
  withHandlers({
    home: (props) => () => {
      props.updateMainIndex(0);
      props.setExtractCustomFilterStateAction(false);
      props.updateselectedFunction([<div />]);
      props.updatePreviousSelectedIndex(null);
      props.updateSelectedIndex(0);
      props.setSelectedMenuItemAction("selectCurves");
      props.setTutorialAction("selectCurves", [], false);
      props.removeTableSettingsAction();
    },
    deliveryDashboard: (props) => () => {
      props.updateMainIndex(0);
      props.setTutorialAction("deliveryDashboard", props.availableFacets, true);
      props.setExtractCustomFilterStateAction(true);
      props.setSelectedMenuItemAction("deliveryDashboard");
      props.updateselectedFunction(
        selectedFunctionRenderer(props, "deliveryDashboard", true)
      );
      props.removeTableSettingsAction();
    },
  }),
  withHandlers({

    extractCustomUserFilter: (props) => () => {
      props.setTutorialAction("extractCurves", props.availableFacets, true);
      props.setExtractCustomFilterStateAction(true);
      props.setSelectedMenuItemAction("extractCurves");
      props.updateselectedFunction(
        selectedFunctionRenderer(props, "extractCurves", true)
      );
      props.removeTableSettingsAction();
    },
    updateSelected: (props) => ({ page, override, dataType }) => {
      props.setExtractCustomFilterStateAction(false);
      props.setSelectedMenuItemAction(page);
      props.setTutorialAction(
        page,
        tutorialHelper(props.selectedCurveTypes, override, dataType),
        false
      );
      props.updateselectedFunction(
        selectedFunctionRenderer(props, page, false, override)
      );
      props.removeTableSettingsAction();
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.home();
    },
  }),
  withProps((props) => ({
    renderSelectedFunction: props.selectedFunction.map((val, i) => (
      <div className="swipeDiv" key={i}>
        {props.previousSelectedIndex === i || props.selectedIndex === i
          ? val
          : null}
      </div>
    )),
  })),
  withProps((props) => ({
    displaySelectedFunction: (
      <DisplaySelectedFunction
        selectedIndex={props.selectedIndex}
        renderSelectedFunction={props.renderSelectedFunction}
        location={props.selectedMenuItem}
      />
    ),
    optimizedDevice: isMobile.phone || isMobile.tablet,
    isIE:
      navigator.userAgent.indexOf("MSIE ") > -1 ||
      navigator.userAgent.indexOf("Trident/") > -1,
  })),
  withProps((props) => ({
    optimizedDeviceDisplay:
      props.optimizedDevice || props.isIE ? (
        <NonOptimizedDisplay isIE={props.isIE} />
      ) : (
        <>
          <AuthenticatedTemplate>
            <MainContainer
              mainIndex={props.mainIndex}
              selectedMenuItem={props.selectedMenuItem}
              home={props.home}
              updateSelected={props.updateSelected}
              displaySelectedFunction={props.displaySelectedFunction}
              showNavigationModal={props.showNavigationModal}
              extractCustomUserFilter={props.extractCustomUserFilter}
              updateUserMenuState={props.updateUserMenuState}
              setMaslToken={props.setMaslToken}
              setOptions={props.setOptions}
            />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login {...props} />
          </UnauthenticatedTemplate>
        </>

      ),
  }))
)(Main);
