export const toolTipStyleNoMargin = {
  backgroundColor: "#369dad",
  color: "white",
};
export const toolTipStyleTopMargin = {
  backgroundColor: "#369dad",
  color: "white",
  marginTop: "10px",
};
export const toolTipStyleBottomMargin = {
  backgroundColor: "#369dad",
  color: "white",
  marginBottom: "10px",
};
export const toolTipStyleLeftMargin = {
  backgroundColor: "#369dad",
  color: "white",
  marginLeft: "10px",
};
export const toolTipStyleRightMargin = {
  backgroundColor: "#369dad",
  color: "white",
  marginRight: "10px",
};
