import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

const ClearFilterBtn = ({ clearAllFunction }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>Clear Filters</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="extractFilterBtn"
      onClick={clearAllFunction}
      btnText={
        <span>
          <i className="fa fa-eraser" /> Clear
          <span className="filterBtnText"> Filters</span>
        </span>
      }
    />
  </Tooltip>
);

export default ClearFilterBtn;
