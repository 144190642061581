import { equals, prop, map, filter, compose, last } from "ramda";
import EnvironmentSettings from "../../../environment";

const dateToString = (props, ds) => {
  switch (props.selectedRangeType) {
    case "Date Range":
      return `${ds.startDate.format("YYYY-MM-DD")}/${ds.endDate.format(
        "YYYY-MM-DD"
      )}`;
    case "Period From/To":
      return `${ds.selectPeriodFrom}/${ds.selectPeriodTo}`;
    case "Period":
      return `${ds.selectPeriod}`;
    default:
      return `${ds.range.replace(/ /g, "")}`;
  }
};

const getTrId = (arr, val) =>
  equals(val, "None")
    ? ""
    : `&tr=${compose(
        last,
        map(prop("ID")),
        filter((x) => x.Name === val)
      )(arr)}`;

const buildQuery = (props, curveIds) => {
  let dateStringOptions = {
    startDate: props.startDate,
    endDate: props.endDate,
  };
  switch (props.selectedRangeType) {
    case "Relative":
      dateStringOptions = {
        range: props.selectedRelative,
      };
      break;
    case "Period From/To":
      dateStringOptions = {
        selectPeriodFrom: props.selectPeriodFrom,
        selectPeriodTo: props.selectPeriodTo,
      };
      break;
    case "Period":
      dateStringOptions = {
        selectPeriod: props.selectPeriod,
      };
      break;
    default:
      break;
  }
  const dataTypeId = props.extractCustomFilterState
    ? `filterId=${props.selectedCustomFilter.id}`
    : `id=${curveIds}`;
  const period = dateToString(props, dateStringOptions);

  const fillerType = `&fillerK=${props.selectedFiller}`;
  const customFillers =
    props.selectedFiller === "CustomValue"
      ? `&fillerDV=${props.customFillValue}`
      : "";

  return `${props.selectedGranularity}/${period}?tz=${
    props.selectedTimezone
  }${getTrId(props.transformValues, props.selectedTransform)}${fillerType}${customFillers}&${dataTypeId}`;
};

export const buildExtractionString = (props) => {
  const curveIds = props.selectedCurves.curves
    .filter((curve) =>
      props.isVts
        ? curve.Type === "ActualTimeSerie" ||
          curve.Type === "VersionedTimeSerie"
        : curve.Type === "ActualTimeSerie"
    )
    .map((curve) => curve.MarketDataId)
    .join(",");
  return (
    props.api +
    EnvironmentSettings["UI.tsExtractionEndpoint"] +
    buildQuery(props, curveIds)
  );
};

export default buildExtractionString;
