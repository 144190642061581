import React from "react";
import { Tooltip } from "react-tippy";
import { compose, equals, length, concat } from "ramda";
import { withState, withHandlers, withProps } from "recompose";

const Panel = (props) => (
  <div className="facets" id={props.id}>
    <div className=" noPadding facetPanelTab">
      <div
        id={concat(props.id, "Btn")}
        onClick={() => props.setLabelsVisibility(props.labelsVisible)}
        className={`col-1 facetIcons fa ${props.iconClass} ${props.id}`}
        aria-hidden="true"
      />
      <div
        className={`col-9 noPadding facetName ${
          props.containsSelectedFacet(props.data.FacetName)
            ? "selectedFacet"
            : ""
        }`}
        onClick={() => props.setLabelsVisibility(props.labelsVisible)}
        aria-hidden="true"
      >
        {props.data.FacetName}
      </div>
      <Tooltip
        arrow
        touchHold
        className="d-flex"
        position="bottom"
        html={
          <span>
            Remove all <b>{props.data.FacetName}</b> Selected Facets
          </span>
        }
        theme="arkDefaultTooltip"
      >
        <div
          onClick={() => props.removeFacetLabels(props.data.FacetName)}
          className="col-1 pull-right facetIcons fa fa-undo selectedFacet"
          aria-hidden="true"
          hidden={!props.containsSelectedFacet(props.data.FacetName)}
        />
      </Tooltip>
    </div>
    <div className={` collapse facetPanel  ${props.facetClass}`}>
      <div className="facetList">
        <ul className="list-group">
          {props.labelData.slice(0, props.itemsToShow).map((label) => (
            <li
              id={concat(props.id, label.Value)}
              key={label.Value}
              className={`list-group-item labelData ${
                props.labelSelected(props.data.FacetName, label.Value)
                  ? "active"
                  : ""
              } ${equals(label.Count, 0) ? "emptylabelSet" : ""}`}
              onClick={() =>
                label.nonSelectable
                  ? null
                  : props.selectLabel(props.data.FacetName, label.Value)
              }
              aria-hidden="true"
            >
              <span>
                <Tooltip
                  arrow
                  touchHold
                  delay={350}
                  position="bottom"
                  html={<span>{label.DisplayName}</span>}
                  theme="arkDefaultTooltip"
                >
                  <span className="col-9 labelName">{label.DisplayName}</span>
                </Tooltip>
              </span>
              <span className="badge facetBadge col-3">{label.Count}</span>
            </li>
          ))}
          {props.labelCount >= props.itemsToShow ? (
            <div
              className="list-group-item labelData facetButton"
              onClick={props.showMore}
              aria-hidden="true"
            >
              <span className="facet-button-Span">
                {props.expanded ? "Show less" : "Show more"}
              </span>
            </div>
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  </div>
);

const buildLabelItemsFn = (props) => {
  let sortedLabels = props.handleEmptyFacet(props);
  sortedLabels = props.displaySelectedFacets(props);
  sortedLabels = props.data.Values.map((labelObj) =>
    props.mapTypeName(labelObj)
  );
  sortedLabels = props.sortLabelList(sortedLabels);
  return sortedLabels;
};

const showMoreCheck = (props) => () => {
  if (props.itemsToShow === 10) {
    props.updateShowMoreState(true);
    props.updateItemsToShow(length(props.data.Values));
  } else {
    props.updateShowMoreState(false);
    props.updateItemsToShow(10);
  }
};

const showHideClass = (props, showClass, hideClass) => {
  if (props.showPanel) {
    return props.labelsVisible ? hideClass : showClass;
  }
  return props.labelsVisible ? showClass : hideClass;
};

const enhancer = compose(
  withState("labelsVisible", "updateLabelsVisibility", false),
  withState("itemsToShow", "updateItemsToShow", 10),
  withState("expanded", "updateShowMoreState", false),
  withHandlers({
    setLabelsVisibility: (props) => (val) => props.updateLabelsVisibility(!val),
    buildLabelItems: (props) => () => buildLabelItemsFn(props),
    updateItemsToShow: (val) => val,
    updateShowMoreState: (val) => val,
    showMore: showMoreCheck,
  }),
  withProps((props) => ({
    iconClass: showHideClass(
      props,
      "facetIconsOpen fa-minus-square",
      "facetIconsClosed fa-plus-square"
    ),
    facetClass: showHideClass(props, "displayPanel", "closePanel"),
    labelData: props.buildLabelItems(),
    labelCount: length(props.data.Values),
  }))
);

export default enhancer(Panel);
