import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "../components";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";
import { connect } from "react-redux";

const Enrich = ({
  setTutorialModalOpenAction,
  setVideoUrlAction,
  ...props
}) => (
  <div className="enrichTimetransformConatiner">
    <div className="pageTitle">
      <span>
        <i className="fas fa-clock marginRight10 artesianIconNoHover paddingLeftFive" />
      </span>
      <span className="paddingLeftFive">Enrich TimeTransform</span>
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/PMiZsLmxA_g?si=Czc9wYHjhM7vLKDc"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>

    <Button
      onClick={props.openAddNewRow}
      btnText="Add New Time Transform"
      permissionDisabled={props.createTTPermission}
      className="createTimeTransformBtn marginBottom10"
    />
    <div className="enrichTimetransformBody">
      <BootstrapTable
        data={props.transformValues}
        version="4"
        pagination
        options={props.tableSettings}
        trClassName={props.rowClassMapper}
        containerClass="enrichTimeTransformContainer"
        tableContainerClass="enrichTimeTransformTableContainer"
        bodyContainerClass="enrichTimeTransformTableBody"
      >
        <TableHeaderColumn
          width="80"
          dataFormat={props.dataFormat}
          hidden={props.disableEdit}
        />
        <TableHeaderColumn dataSort dataField="ID" width="60" isKey>
          ID
        </TableHeaderColumn>
        <TableHeaderColumn dataSort dataField="Name">
          NAME
        </TableHeaderColumn>
        <TableHeaderColumn dataSort dataField="Period">
          GRANULARITY
        </TableHeaderColumn>
        <TableHeaderColumn dataSort dataField="PositiveShift">
          POSITIVESHIFT
        </TableHeaderColumn>
        <TableHeaderColumn dataSort dataField="NegativeShift">
          NEGATIVESHIFT
        </TableHeaderColumn>
        <TableHeaderColumn dataSort dataField="DefinedBy">
          DEFINEDBY
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  </div>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction,
};
export default connect(null, mapDispatchToProps)(Enrich);
