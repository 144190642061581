import { filter, pathOr, mergeDeepLeft } from "ramda";
import { createBrowserHistory } from "history";
import { concat, EMPTY, defer, from } from "rxjs";
import { switchMap, delay, repeat } from "rxjs/operators";
import { msalConfig, loginRequest } from "./authConfig";
import EnvironmentSettings from "./environment";
import * as msal from "@azure/msal-browser";

export const msalInstance = new msal.PublicClientApplication(msalConfig);

const createEnhancedHistory = () => {
  let history;
  const getUserConfirmation = (mes, cb) => history.getUserConfirmation(mes, cb);
  history = createBrowserHistory({ getUserConfirmation });
  history.getUserConfirmation = (mess, cb) => cb(window.confirm(mess)); // eslint-disable-line
  return history;
};
export const history = createEnhancedHistory();

export const auth = {
  logout: () => msalInstance.logoutRedirect(),
  login: (extraParams) => { 
     extraParams = extraParams ?? {};
     msalInstance.loginRedirect(mergeDeepLeft(loginRequest,extraParams))
  },
  loginRedirect: (cfg) => msalInstance.loginRedirect(cfg),
  handleRedirect: () => msalInstance.handleRedirectPromise(),
  getToken: () => {
    const request = EnvironmentSettings["UI.authConnection"] === "K4View" ?
      {
        scopes: loginRequest.scopes,
        account: msalInstance.getAllAccounts()[0],
        extraQueryParameters: { domain_hint: 'k4view' }
      } : {
        scopes: loginRequest.scopes,
        account: msalInstance.getAllAccounts()[0],
      };
    return from(msalInstance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        ensureTenant(tokenResponse.accessToken, request);
        return tokenResponse.accessToken || "";
      })
      .catch(async (error) => {
        return msalInstance.acquireTokenRedirect(request);
      }))
  },
  retrieveTenantsList: (tokenClaims) => {
    let permissions = pathOr("", ["extension_permissions"], tokenClaims)
    let splitPermissions = permissions.split(" ");
    let tenants = filter(n => n.startsWith('tenant'), splitPermissions)
    return tenants
  }
};

/*
For the first login of the user the tenant is unknown

Grab a default tenant as first one in the permissions list and
Rediect to azure getting a token for the tenant and skipping the login
with {prompt: "none"}

Cache the tenant for future login flows
*/
  
function ensureTenant(accessToken, request) {

  if (window.localStorage.tenant) return accessToken;

  let parsedToken = JSON.parse(atob(accessToken.split('.')[1]))

  let permissions = pathOr("", ["extension_permissions"], parsedToken)
  let splitPermissions = permissions.split(" ");
  let tenants = filter(n => n.startsWith('tenant'), splitPermissions)

  window.localStorage.tenant = tenants[0]

  if (!window.localStorage.tenant) return accessToken;

  window.location.reload();
}
