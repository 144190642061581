import React from "react";
import {
  MenuItem,
  MenuSelectCountType,
  MenuItemEnrichMenu,
  MenuItemAdminMenu,
} from "./components";

const MainMenu = (props) => (
  <div className="userMenuContainer">
    <div className="typeCountContainer">
      <MenuSelectCountType
        title="TSV"
        count={props.totalVersionedTimeSeriesDataCount}
        removeSelectType={props.removeVTSSelectedCurves}
      />
      <MenuSelectCountType
        title="TS"
        count={props.totalActualTimeSeriesDataCount}
        removeSelectType={props.removeTSSelectedCurves}
      />
      <MenuSelectCountType
        title="MAS"
        count={props.totalMarketAssessmentDataCount}
        removeSelectType={props.removeMASSelectedCurves}
      />
      <MenuSelectCountType
        title="Auction"
        count={props.totalAuctionDataCount}
        removeSelectType={props.removeAuctionSelectedCurves}
      />
      <MenuSelectCountType
        title="BidAsk"
        count={props.totalBidAskDataCount}
        removeSelectType={props.removeBidAskSelectedCurves}
      />
      <MenuSelectCountType
        title="#MarketData"
        total
        count={props.totalCurveCount}
        removeSelectType={props.removeAllSelectedCurves}
      />
    </div>
    <div className="menuItems">
      <MenuItem
        toolTipText="Select MarketData"
        btnFn={props.home}
        btnClass={`${props.activeHome ? "active" : ""} menuBtnItem`}
        btnText={<i className="fa fa-mouse-pointer" />}
        itemNameId="Select"
      />
      {props.displayExpectationMonitorMenuItem ? (
        <MenuItem
          toolTipText="Data Delivery Dashboard"
          btnFn={props.openDashboard}
          btnClass={`${props.activeDashboard ? "active" : ""} menuBtnItem`}
          btnText={<i className="fa fa-tachometer-alt" />}
          itemNameId="Select"
        />
      ) : (
        ""
      )}
      <MenuItem
        toolTipText={props.toolTipTextHandler("Refine Selection")}
        btnFn={props.openBasket}
        btnClass={`${props.activeBasket ? "active" : ""} menuBtnItem`}
        btnText={<i className="fa fa-table" />}
        duration={props.duration}
        navigationDisabled={props.navigationDisabled}
        itemNameId="Refine"
      />
      <MenuItem
        toolTipText={props.toolTipTextHandler("Classify")}
        btnFn={props.openClassify}
        btnClass={`${props.activeClassify ? "active" : ""} menuBtnItem`}
        btnText={<i className="fa fa-tag" />}
        duration={props.duration}
        navigationDisabled={props.navigationDisabled}
        itemNameId="Classify"
      />
      <MenuItem
        toolTipText={props.extratToolTipTextHandler("Extract")}
        btnFn={props.openExtractCurves}
        btnClass={`${props.activeExtractCurves ? "active" : ""} menuBtnItem`}
        btnText={
          <span className="fa-stack">
            <i className="fa fa-folder-open fa-stack-folder" />
            <i className="fa fa-arrow-down fa-stack-arrow" />
          </span>
        }
        duration={props.duration}
        navigationDisabled={props.navigationDisabled}
        itemNameId="Extract"
      />
      <MenuItemEnrichMenu
        btnFnEnrichMetadata={props.openEnrichMetaData}
        btnFnEnrichTimeTransform={props.openEnrichTimeTransform}
        activeEnrichMetaData={props.activeEnrichMetaData}
        activeEnrichTimeTransform={props.activeEnrichTimeTransform}
        btnClass={`${
          props.activeEnrichTimeTransform || props.activeEnrichMetaData
            ? "active"
            : ""
        } menuBtnItem`}
        btnText={<i className="fas fa-edit" />}
        duration={props.duration}
        navigationDisabled={props.navigationDisabled}
        itemNameId="Enrich"
        ttPermissionAccess={props.displayTimeTransMenuItem}
        globalAdmin={props.globalPathPermissionRes}
      />
      <MenuItemAdminMenu
        btnFnGroupAdmin={props.openGroupAdmin}
        activeGroupAdmin={props.activeGroupAdmin}
        btnFnUserAdmin={props.openUserAdmin}
        activeUserAdmin={props.activeUserAdmin}
        btnClass={`${
          props.activeGroupAdmin || props.activeUserAdmin ? "active" : ""
        } menuBtnItem`}
        btnText={<i className="fas fa-cogs" />}
        duration={props.duration}
        itemNameId="Admin"
        permissionAccess={props.displayAdminMenuItem}
        globalAdmin={props.globalPathPermissionRes}
      />
    </div>
  </div>
);

export default MainMenu;
