import React from "react";
import { connect } from "react-redux";
import { withHandlers, withProps } from "recompose";
import { compose } from "ramda";
import {
  tutorialOnAction,
  tutorialOffAction,
} from "../../redux/modules/tutorial";
import { ArtesianTut } from "../../tutorial";
import { TutorialTour } from "./components";

const test = (props) => {
  switch (props.selectedTutotialPage) {
    case "selectCurves":
      return (
        <TutorialTour
          steps={props.selectedTutotial}
          name={props.selectedTutotialPage}
          state={props.tutorialState}
          close={props.tutorialOff}
        />
      );
    case "refineBasket":
      return (
        <TutorialTour
          steps={props.selectedTutotial}
          name={props.selectedTutotialPage}
          state={props.tutorialState}
          close={props.tutorialOff}
          startAtStep={1}
        />
      );
    case "classifyCurves":
      return (
        <TutorialTour
          steps={props.selectedTutotial}
          name={props.selectedTutotialPage}
          state={props.tutorialState}
          close={props.tutorialOff}
          startAtStep={1}
        />
      );

    case "extractCurves":
      return (
        <TutorialTour
          steps={props.selectedTutotial}
          name={props.selectedTutotialPage}
          state={props.tutorialState}
          close={props.tutorialOff}
          navBtns={false}
          startAtStep={1}
        />
      );
    default:
      return (
        <TutorialTour
          steps={props.selectedTutotial}
          name={props.selectedTutotialPage}
          state={props.tutorialState}
          close={props.tutorialOff}
          startAtStep={1}
        />
      );
  }
};

const mapStateToProps = (state) => ({
  ...state.Tutorial,
});
export default compose(
  connect(mapStateToProps, {
    tutorialOnAction,
    tutorialOffAction,
  }),
  withHandlers({
    tutorialOff: (props) => () => props.tutorialOffAction(),
    tutorialOn: (props) => () => props.tutorialOn(),
  }),
  withProps(() => ({
    TourEx: test,
  }))
)(ArtesianTut);
