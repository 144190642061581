import { setErrorAction } from "../actions";

export const dispatchNetworkError = (err) => {
  if (err.status > 0) {
    if (err.status >= 400 && err.status < 500) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message:
          "One or more market data selected has no aggregation rule defined, the selected granularity is not available",
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message:
        "Please refresh the page. If the issue persists, please contact support.",
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message: "An error has occurred. Please contact support.",
  });
};

export default dispatchNetworkError;
