import React from "react";
import { Button } from "../components";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";
import { connect } from "react-redux";

const Enrich = ({
  setTutorialModalOpenAction,
  setVideoUrlAction,
  ...props
}) => (
  <div className="enrichConatiner">
    <div className="pageTitle">
      <span>
        <i className="fa fa-wrench artesianIconNoHover marginRight10 paddingLeftFive" />
      </span>
      <span className="paddingLeftFive">Enrich MetaData</span>
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/V4e6aLTeNW0?si=-ju9LPuQRFYr5V8S"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="selectedFunction">
      <div
        className={`effectedCurves ${
          props.effectedCurves
            ? "effectedCurveContainerOpen"
            : "effectedCurveContainerClosed"
        }`}
      >
        <div
          className={`effectedCurvesContainer ${
            props.effectedCurves ? "open" : "closed"
          }`}
        >
          {props.effectedCurvesTable}
        </div>
        <Button
          onClick={props.toggleEffectedCurves}
          btnText={
            <i
              className={`fa ${
                props.effectedCurves
                  ? "fa-angle-double-left"
                  : "fa-angle-double-right"
              }`}
            />
          }
          className="effectedCurvesDisplayBtn"
        />
      </div>
      <div
        className={`paddingLeftFive enrichBody ${
          props.effectedCurves ? "componentOpen" : "componentClosed"
        }`}
      >
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 separator">
            <div className="form-group">
              <label
                className={`${
                  props.isNoneSelected(props.selectedTimezone)
                    ? null
                    : "enrichLabel"
                }`}
              >
                Set Timezone
              </label>
              {props.timezoneDropDown}
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 separator">
            <div className="form-group">
              <label
                className={`${
                  props.isNoneSelected(props.selectedTransform)
                    ? null
                    : "enrichLabel"
                }`}
              >
                Set Time&nbsp;Transform
              </label>
              {props.transformDropDown}
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 separator">
            <div className="form-group">
              <label
                className={`${
                  props.isNoneSelected(props.selectedAggregationRule)
                    ? null
                    : "enrichLabel"
                }`}
              >
                Set Aggregation&nbsp;
              </label>
              {props.aggregationRuleDropDown}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 separator">
            <div className="form-group">
              <label
                className={`${props.descriptionText ? "enrichLabel" : null}`}
              >
                Set Description
              </label>
              <textarea
                maxLength="1500"
                className="form-control artesianInputTextBox"
                value={props.descriptionText}
                onChange={props.setDescriptionText}
              />
            </div>
          </div>
        </div>
        <div className="d-flex applyEnrichmentBtn">
          <Button
            onClick={props.applyEnrichment}
            disabled={props.applyDisabled()}
            id="applyEnrichmentBtn"
            btnText={
              <span>
                <i className="fa fa-check-circle" /> &nbsp;
                <span className="d-inline">Apply</span>
              </span>
            }
          />
        </div>
      </div>
    </div>
  </div>
);
const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction,
};
export default connect(null, mapDispatchToProps)(Enrich);
