import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, MultiSelect } from "../components";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";
import { connect } from "react-redux";

const RefineCurves = ({
  setTutorialModalOpenAction,
  setVideoUrlAction,
  ...props
}) => (
  <div className="refineCurves">
    <div className="pageTitle">
      <span className="paddingLeftFive">
        <i className="fa fa-table marginRight10 artesianIconNoHover" />
      </span>
      Refine Selection
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/CJ_UCKjvPNc?si=6INKjLeWhq-Noi5l"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="refineCurvesBody">
      <div className="refineCurvesContainer">
        <div className="refineCurvesOptions">
          <span className="filterTitle">Filter Selected MarketData:</span>
          <MultiSelect
            value={props.filterVal}
            change={props.selectedFilters}
            options={props.filterDropdownOptions}
            name="RefineMulti"
            noOptionsMessage={() => "All Filters Selected"}
            closeOnSelect
          />

          <Button
            onClick={props.checkAllPaths}
            disabled={props.disableCheckAllCurvesBtn}
            className="checkAllPermissions"
            btnText="Check All Permissions"
          />

          <Button
            disabled={props.containsPermissions}
            onClick={props.removeNonEditable}
            className="checkAllPermissions"
            btnText="Remove Read Only MarketData"
            id="RemoveReadOnly"
          />
        </div>
        <BootstrapTable
          data={props.selectedCurveData}
          condensed
          pagination
          printable
          fetchInfo={{ dataTotalSize: props.selectedCurveCount }}
          version="4"
          keyField="MarketDataId"
          options={props.tableSettings}
          containerClass="refineCurvesContainer"
          tableContainerClass="refineCurvesTableContainer"
          bodyContainerClass="refineCurvesTableBody"
        >
          <TableHeaderColumn
            width="40"
            dataField="Remove"
            dataAlign="center"
            dataFormat={props.removeSelectedOrderBtn}
          >
            {props.removeAllSelectedOrderBtn}
          </TableHeaderColumn>
          {props.curveTableConfigSettings.curveTable.map((col) => (
            <TableHeaderColumn
              dataField={col.dataField}
              key={col.dataField}
              headerTitle={false}
              dataFormat={(cell, row) =>
                props.dataFormat(col.dataField, cell, row)
              }
              dataSort
              width={col.width}
              hidden={col.hidden}
            >
              {col.title}
            </TableHeaderColumn>
          ))}
          <TableHeaderColumn
            width="60"
            dataField="Permissions"
            dataAlign="center"
            hidden={props.containsPermissions}
            dataFormat={(cell) => props.permssionFormat(cell || [], "Reader")}
          >
            Reader
          </TableHeaderColumn>
          <TableHeaderColumn
            width="60"
            dataField="Permissions"
            dataAlign="center"
            hidden={props.containsPermissions}
            dataFormat={(cell) => props.permssionFormat(cell || [], "Owner")}
          >
            Owner
          </TableHeaderColumn>
          <TableHeaderColumn
            width="80"
            dataField="Permissions"
            dataAlign="center"
            hidden={props.containsPermissions}
            dataFormat={(cell) => props.permssionFormat(cell || [], "Creator")}
          >
            Creator
          </TableHeaderColumn>
          <TableHeaderColumn
            width="70"
            dataField="Permissions"
            dataAlign="center"
            hidden={props.containsPermissions}
            dataFormat={(cell) =>
              props.permssionFormat(cell || [], "Contributor")
            }
          >
            Contributor
          </TableHeaderColumn>
          <TableHeaderColumn
            width="60"
            dataField="Permissions"
            dataAlign="center"
            hidden={props.containsPermissions}
            dataFormat={(cell) => props.permssionFormat(cell || [], "Editor")}
          >
            Editor
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction,
};
export default connect(null, mapDispatchToProps)(RefineCurves);
