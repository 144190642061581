import React from "react";

const ModalAlert = (props) => (
  <div>
    <div
      className={`modal fade show ${props.showModal ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${props.showModal ? "fadeIn" : ""}`}
        id="errorModalOverlay"
        onClick={() => {
          props.closeModal();
        }}
      />
      <div className="modal-dialog artesainModal" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">{props.modalTitle}</h5>
          </div>
          <div className="modal-body text-center">
            <span>
              <h4>{props.modalMessage}</h4>
            </span>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn confirmClose"
              onClick={() => {
                props.confirm();
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn modalClose"
              onClick={() => {
                props.closeModal();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalAlert;
