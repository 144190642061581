import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import platform from "platform";

const request = ({ token, api, userDefined }) =>
  ajax({
    url: `${api}/v2.1/timeTransform/entity?page=1&pageSize=500&userDefined=${userDefined}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`},
  }).pipe(map((x) => x.response));

export const requestSystemDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "false" });

export const requestUserDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "true" });

export const createTimeTransformRequest = ({ token, api, data }) =>
  ajax.post(`${api}/v2.1/timeTransform/entity`, data, {
    Authorization: `Bearer ${token}`,
    'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    "Content-Type": "application/json",
  });

export const updateTimeTransformRequest = ({ token, api, id, data }) =>
  ajax.put(`${api}/v2.1/timeTransform/entity/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    "Content-Type": "application/json",
  });

export const deleteTimeTransform = ({ token, api, id }) =>
  ajax.delete(`${api}/v2.1/timeTransform/entity/${id}`, {
    Authorization: `Bearer ${token}`,
    'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    "Content-Type": "application/json",
  });
