import { combineReducers } from "redux";
import * as R from "ramda";
import * as RX from "rxjs";
import { flatMap, tap, toArray, map as RxMap, delay } from "rxjs/operators";
import { createSelector } from "reselect";
import {
  fetchingReducerCreator,
  tablesOptionsReducerCreator,
  simpleSetReducerCreator,
} from "../../reducerCreators";
import {
  addOrder,
  removeOrder,
  updateArray,
  toggleLabelFn,
  dispatchNetworkError,
} from "./helper";
import {
  requestData,
  classificationRequest,
  createCustomFilterRequest,
  getAllCustomFilter,
  deleteCustomFilterRequest,
  updateCustomFilterRequest,
  requestAllCurvesData,
  requestAclMeAllPathsPermission,
} from "./requests";
import { auth } from "../../../appContext";
import { Selectors } from "../tenantSelection";

const {
  compose,
  prop,
  ap,
  of,
  pipe,
  ifElse,
  path,
  always,
  omit,
  objOf,
  merge,
  assoc,
  keys,
  isEmpty,
  find,
  propEq,
  map,
} = R;

const FETCHING = "artesian-ui/curves/FETCHING";
const RECIEVED = "artesian-ui/curves/RECIEVED";
const RECIEVED_CURVES = "artesian-ui/curves/RECIEVED_CURVES";
const RECIEVED_FACETS = "artesian-ui/curves/RECIEVED_FACETS";
const RECIEVED_FACETS_STATIC = "artesian-ui/curves/RECIEVED_FACETS_STATIC";
const SET_CURVES_PAGE_SIZE = "artesian-ui/curves/SET_CURVES_PAGE_SIZE";
const SET_CURVES_PAGE_NO = "artesian-ui/curves/SET_CURVES_PAGE_NO";
export const SET_CURVES_PAGE_SORT_COLUMN =
  "artesian-ui/curves/SET_CURVES_PAGE_SORT_COLUMN";
export const SELECT_ALL_CURVES = "artesian-ui/curves/SELECT_ALL_CURVES";
export const CLEAR_FILTERS = "artesian-ui/curves/CLEAR_FILTERS";
const SET_CURVES_COUNT = "artesian-ui/curves/SET_CURVES_COUNT";
export const REMOVE_ALL_SELECTED_CURVE =
  "artesian-ui/curves/REMOVE_ALL_SELECTED_CURVE";
const REFRESH_SELECTED_CURVE = "artesian-ui/curves/REFRESH_SELECTED_CURVE";
const REMOVE_TABLE_SETTINGS = "artesian-ui/curves/REMOVE_TABLE_SETTINGS";
const EMPTY_SELECTED_CURVE = "artesian-ui/curves/EMPTY_SELECTED_CURVE";
const REMOVE_ALL_SELECTED_LABELS =
  "artesian-ui/curves/REMOVE_ALL_SELECTED_LABELS";
const SHOW_CURVE_WARNING_MODAL = "artesian-ui/curves/SHOW_CURVE_WARNING_MODAL";
const HIDE_CURVE_WARNING_MODAL = "artesian-ui/curves/HIDE_CURVE_WARNING_MODAL";
export const UPDATE_SELECTED_CURVES =
  "artesian-ui/curves/UPDATE_SELECTED_CURVES";
const OPEN_CUSTOM_FILTER_MODAL = "artesian-ui/curves/OPEN_CUSTOM_FILTER_MODAL";
const CLOSE_CUSTOM_FILTER_MODAL =
  "artesian-ui/curves/CLOSE_CUSTOM_FILTER_MODAL";
const UPDATE_CUSTOM_FILTER_MODAL_ROW =
  "artesian-ui/curves/UPDATE_CUSTOM_FILTER_MODAL_ROW";
const SET_CUSTOM_FILTER = "artesian-ui/curves/SET_CUSTOM_FILTER";
export const SET_CURVES_FILTER_TEXT =
  "artesian-ui/curves/SET_CURVES_FILTER_TEXT";
const ALL_CUSTOM_FILTERS = "artesian-ui/curves/ALL_CUSTOM_FILTERS";
export const CUSTOM_FILTER_FACETS = "artesian-ui/curves/CUSTOM_FILTER_FACETS";
export const EXTRACT_CUSOM_FILTER = "artesian-ui/curves/EXTRACT_CUSOM_FILTER";
const OPEN_PERMISSION_CHECK_MODAL =
  "artesian-ui/curves/OPEN_PERMISSION_CHECK_MODAL";
const CLOSE_PERMISSION_CHECK_MODAL =
  "artesian-ui/curves/CLOSE_PERMISSION_CHECK_MODAL";
export const ADD_SELECTED_CURVE = "artesian-ui/curves/ADD_SELECTED_CURVE";
export const REMOVE_SELECTED_CURVE = "artesian-ui/curves/REMOVE_SELECTED_CURVE";
export const TOGGLE_SELECTED_CURVE = "artesian-ui/curves/TOGGLE_SELECTED_CURVE";
export const CLASSIFY_CURVES = "artesian-ui/curves/CLASSIFY_CURVES";
export const UPDATE_CUSTOM_FILTER = "artesian-ui/curves/UPDATE_CUSTOM_FILTER";
export const DELETE_CUSTOM_FILTER = "artesian-ui/curves/DELETE_CUSTOM_FILTER";
export const CREATE_CUSTOM_FILTER = "artesian-ui/curves/CREATE_CUSTOM_FILTER";
export const REMOVE_ALL_CURVE_TYPES =
  "artesian-ui/curves/REMOVE_ALL_CURVE_TYPES";
export const CHECK_All_SELECTED_CURVES_PERMISSIONS =
  "artesian-ui/curves/CHECK_All_SELECTED_CURVES_PERMISSIONS";
export const REMOVE_SELECTED_CURVES =
  "artesian-ui/curves/REMOVE_SELECTED_CURVES";

const selectedCurves = (
  state = {
    curves: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_SELECTED_CURVE:
      return addOrder(state, "curves", action.row);
    case REMOVE_SELECTED_CURVE:
      return removeOrder(state, "curves", action.row);
    case REFRESH_SELECTED_CURVE:
      return {
        ...state,
        curves: updateArray(state.curves, action.curves),
      };
    case UPDATE_SELECTED_CURVES:
      return {
        ...state,
        curves: action.arr,
      };
    case REMOVE_SELECTED_CURVES:
      return {
        ...state,
        curves: action.curves,
      };
    case REMOVE_ALL_SELECTED_CURVE:
      return {
        ...state,
        curves: [],
      };
    default:
      return state;
  }
};

const selectedlabels = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_SELECTED_CURVE:
      return toggleLabelFn(state, action.facet, action.label);
    case EMPTY_SELECTED_CURVE:
      return omit([action.facet], state);
    case CUSTOM_FILTER_FACETS:
      return action.result;
    case REMOVE_ALL_SELECTED_LABELS:
      return {};
    default:
      return state;
  }
};

const curveWarningModalDefaultState = {
  showModal: false,
  modalMessage: "",
};
const curveWarningModalState = (
  state = curveWarningModalDefaultState,
  action
) => {
  switch (action.type) {
    case SHOW_CURVE_WARNING_MODAL:
      return {
        ...state,
        showModal: true,
        modalMessage: action.state.msg,
      };
    case HIDE_CURVE_WARNING_MODAL:
      return curveWarningModalDefaultState;
    default:
      return state;
  }
};

const modalInitialState = {
  open: false,
  row: {},
  type: "",
};
const customFilterModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_CUSTOM_FILTER_MODAL:
      return {
        ...action.edit,
        orig: action.edit.orig,
        open: true,
      };
    case UPDATE_CUSTOM_FILTER_MODAL_ROW:
      return {
        ...state,
        row: action.row,
      };
    case CLOSE_CUSTOM_FILTER_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

const userPermissionCheckModal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_PERMISSION_CHECK_MODAL:
      return { ...action.edit, open: true };
    case CLOSE_PERMISSION_CHECK_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

const tableOptionsSettings = {
  setFilterText: SET_CURVES_FILTER_TEXT,
  setPageSize: SET_CURVES_PAGE_SIZE,
  setPageNo: SET_CURVES_PAGE_NO,
  setSortColumn: SET_CURVES_PAGE_SORT_COLUMN,
  setCurveCount: SET_CURVES_COUNT,
  removeTableSettings: REMOVE_TABLE_SETTINGS,
};

export default combineReducers({
  isfetching: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
  }),
  tablesOptions: tablesOptionsReducerCreator(tableOptionsSettings),
  curveData: simpleSetReducerCreator({
    setAction: RECIEVED_CURVES,
    prop: "curves",
    initialState: [],
  }),
  facetData: simpleSetReducerCreator({
    setAction: RECIEVED_FACETS,
    prop: "facets",
    initialState: [],
  }),
  facetDataStatic: simpleSetReducerCreator({
    setAction: RECIEVED_FACETS_STATIC,
    prop: "facets",
    initialState: [],
  }),
  allCustomFilters: simpleSetReducerCreator({
    setAction: ALL_CUSTOM_FILTERS,
    prop: "customFilters",
    initialState: [],
  }),
  selectedCustomFilter: simpleSetReducerCreator({
    setAction: SET_CUSTOM_FILTER,
    prop: "customFilters",
    initialState: {
      name: "",
      id: null,
    },
  }),
  extractCustomFilterState: simpleSetReducerCreator({
    setAction: EXTRACT_CUSOM_FILTER,
    prop: "res",
    initialState: false,
  }),
  customFilterModal,
  selectedCurves,
  selectedlabels,
  userPermissionCheckModal,
  curveWarningModalState,
});

const fetchingCurves = () => ({
  type: FETCHING,
});
const receivedData = () => ({
  type: RECIEVED,
});
const receivedCurves = (curves) => ({
  type: RECIEVED_CURVES,
  curves,
});
const receivedFacets = (facets) => ({
  type: RECIEVED_FACETS,
  facets,
});
const receivedFacetsStatic = (facets) => ({
  type: RECIEVED_FACETS_STATIC,
  facets,
});
const receivedCount = (count) => ({
  type: SET_CURVES_COUNT,
  count,
});
const removeSelectedCurve = ({ curveType, curves }) => ({
  type: REMOVE_SELECTED_CURVES,
  curveType,
  curves,
});
const setPageSize = (pageSize) => ({
  type: SET_CURVES_PAGE_SIZE,
  pageSize,
});
const setPageNo = (pageNo) => ({
  type: SET_CURVES_PAGE_NO,
  pageNo,
});
const setFilterText = (filterText) => ({
  type: SET_CURVES_FILTER_TEXT,
  filterText,
});
const setPageSortColumn = ({ sortColumn, sortDirection }) => ({
  type: SET_CURVES_PAGE_SORT_COLUMN,
  sortColumn,
  sortDirection,
});
export const updateSelectedCurvesList = (arr) => ({
  type: UPDATE_SELECTED_CURVES,
  arr,
});
const refreshSelectedCurves = (curves) => ({
  type: REFRESH_SELECTED_CURVE,
  curves,
});
const showCurveWarningModal = (state) => ({
  type: SHOW_CURVE_WARNING_MODAL,
  state,
});
const setCustomFilterFacets = (result) => ({
  type: CUSTOM_FILTER_FACETS,
  result,
});
const setExtractCustomFilterState = (res) => ({
  type: EXTRACT_CUSOM_FILTER,
  res,
});
const hideCurveWarningModal = () => ({
  type: HIDE_CURVE_WARNING_MODAL,
});
const openPermissionCheckModal = () => ({
  type: OPEN_PERMISSION_CHECK_MODAL,
});
const closePermissionCheckModal = () => ({
  type: CLOSE_PERMISSION_CHECK_MODAL,
});
const selectAllCurvesBtnAction = () => ({
  type: SELECT_ALL_CURVES,
});
const clearFiltersBtnAction = () => ({
  type: CLEAR_FILTERS,
});
const customFiltersUpdatedBtnAction = () => ({
  type: UPDATE_CUSTOM_FILTER,
});
const customFiltersDeletedBtnAction = () => ({
  type: DELETE_CUSTOM_FILTER,
});
const customFiltersCreatedBtnAction = () => ({
  type: CREATE_CUSTOM_FILTER,
});
const checkAllSelectedCurvesPrsmiions = () => ({
  type: CHECK_All_SELECTED_CURVES_PERMISSIONS,
});

const toggleTableRowSelectionRaw = pipe(
  ifElse(
    prop("isSelected"),
    always(ADD_SELECTED_CURVE),
    always(REMOVE_SELECTED_CURVE)
  ),
  objOf("type")
);
const toggleTableRowSelectionRawAction = (row, isSelected) => (dispatch) =>
  of({})
    .map(() => ({
      row,
      isSelected,
    }))
    .map(ap(merge, toggleTableRowSelectionRaw))
    .map(dispatch);

const toggleLabel = (facet, label) => ({
  type: TOGGLE_SELECTED_CURVE,
  facet,
  label,
});
const removeFacetLabels = (facet) => ({
  type: EMPTY_SELECTED_CURVE,
  facet,
});
const removeAllSelectedCurves = () => ({
  type: REMOVE_ALL_SELECTED_CURVE,
});
const removeAllSelectedLabels = () => ({
  type: REMOVE_ALL_SELECTED_LABELS,
});
const openCustomModalModal = (edit) => ({
  type: OPEN_CUSTOM_FILTER_MODAL,
  edit,
});
const closeCustomModalModal = () => ({
  type: CLOSE_CUSTOM_FILTER_MODAL,
});
const updateCustomModalModalRow = (row) => ({
  type: UPDATE_CUSTOM_FILTER_MODAL_ROW,
  row,
});
const setAllCustomFilters = (customFilters) => ({
  type: ALL_CUSTOM_FILTERS,
  customFilters,
});

const setSelectedCustomFilters = (customFilters) => ({
  type: SET_CUSTOM_FILTER,
  customFilters,
});

const dataRecieved = compose(
  ap([
    compose(receivedFacets, prop("Facets")),
    compose(receivedCurves, prop("Results")),
    compose(receivedCount, prop("CountResults")),
    receivedData,
  ]),
  of
);
const dataRecievedFirstLoad = compose(
  ap([
    compose(receivedFacets, prop("Facets")),
    compose(receivedFacetsStatic, prop("Facets")),
    compose(receivedCurves, prop("Results")),
    compose(receivedCount, prop("CountResults")),
    receivedData,
  ]),
  of
);

const allResultsRecieved = compose(
  ap([(rows) => toggleTableRowSelectionRawAction(rows, true)]),
  of
);

const updateSelectedCurves = compose(
  ap([compose(refreshSelectedCurves, prop("response"))]),
  of
);
const handleNetworkError = compose(
  ap([dispatchNetworkError, receivedData]),
  of
);
const handleNetworkErrorCustomFilter = compose(
  ap([dispatchNetworkError, receivedData, closeCustomModalModal]),
  of
);

const sortFilter = (col, dir) =>
  col ? `&sorts=${encodeURIComponent(col)} ${encodeURIComponent(dir)}` : "";
const sortTextFilter = (text) =>
  text ? `&searchText=${encodeURIComponent(text)}` : "";
const selectedFacet = (filters) => {
  let odataLabelFilter = [];
  Object.keys(filters).forEach((key) => {
    odataLabelFilter = odataLabelFilter.concat(
      filters[key].map(
        (label) => `filters=${encodeURIComponent(`${key}:${label || ""}`)}`
      )
    );
  });
  odataLabelFilter =
    odataLabelFilter.length > 0 ? `&${odataLabelFilter.join("&")}` : "";
  return odataLabelFilter;
};

const tokenFromState = compose(auth.getToken, Selectors.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: Selectors.api(state),
    }))
  );
export const firstLoadRequestAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingCurves)),
      flatMap(getAllCustomFilter),
      tap(compose(dispatch, setAllCustomFilters)),
      flatMap(() => tokenApiFromState(getState())),
      tap(
        dispatch(
          setPageSortColumn({
            sortColumn: "MarketDataId",
            sortDirection: "asc",
          })
        )
      ),
      flatMap(({ token, api }) =>
        requestData({
          pageNo: getState().Curves.tablesOptions.pageNo,
          pageSize: getState().Curves.tablesOptions.pageSize,
          sort: sortFilter(
            getState().Curves.tablesOptions.sortColumn,
            getState().Curves.tablesOptions.sortDirection
          ),
          filterText: sortTextFilter(
            getState().Curves.tablesOptions.filterText
          ),
          labels: selectedFacet(getState().Curves.selectedlabels),
          token,
          api,
        })
      )
    )
    .subscribe(
      compose(dispatch, dataRecievedFirstLoad),
      compose(dispatch, handleNetworkError)
    );

export const requestCurvesAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingCurves)),
      delay(100),
      flatMap(({ token, api }) =>
        requestData({
          pageNo: getState().Curves.tablesOptions.pageNo,
          pageSize: getState().Curves.tablesOptions.pageSize,
          sort: sortFilter(
            getState().Curves.tablesOptions.sortColumn,
            getState().Curves.tablesOptions.sortDirection
          ),
          filterText: sortTextFilter(
            getState().Curves.tablesOptions.filterText
          ),
          labels: selectedFacet(getState().Curves.selectedlabels),
          token,
          api,
        })
      )
    )
    .subscribe(
      compose(dispatch, dataRecieved),
      compose(dispatch, handleNetworkError)
    );

export const selectAllCurvesAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingCurves)),
      tap(compose(dispatch, selectAllCurvesBtnAction)),
      tap(compose(dispatch, removeAllSelectedCurves)),
      flatMap(({ token }) =>
        requestAllCurvesData({
          token,
          tenant: Selectors.tenant(getState()),
          countSize: getState().Curves.tablesOptions.curveCount,
          sort: sortFilter(
            getState().Curves.tablesOptions.sortColumn,
            getState().Curves.tablesOptions.sortDirection
          ),
          filterText: sortTextFilter(
            getState().Curves.tablesOptions.filterText
          ),
          labels: selectedFacet(getState().Curves.selectedlabels),
        })
      )
    )
    .subscribe(
      compose(dispatch, allResultsRecieved),
      compose(dispatch, handleNetworkError),
      compose(dispatch, receivedData)
    );

export const classificationRequestAction = (bodyData) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(
        compose(
          dispatch,
          always({
            type: CLASSIFY_CURVES,
            ...bodyData,
          })
        )
      ),
      tap(compose(dispatch, fetchingCurves)),
      flatMap((tokenApi) =>
        classificationRequest({
          ...tokenApi,
          bodyData,
        })
      )
    )
    .subscribe(
      compose(dispatch, updateSelectedCurves),
      compose(dispatch, handleNetworkError),
      compose(dispatch, requestCurvesAction)
    );

export const removeSelectedTypeAction = (type) => (dispatch, getState) =>
  RX.of({})
    .pipe(
      tap(compose(dispatch, fetchingCurves)),
      tap(() =>
        dispatch(
          removeSelectedCurve({
            curveType: type,
            curves: R.reduce(
              (a, b) => (b.Type !== type ? R.append(b, a) : a),
              [],
              CurvesSelectors.selectedCurves(getState()).curves
            ),
          })
        )
      )
    )
    .subscribe(compose(dispatch, receivedData));

const getFilterLabels = (labels) => {
  const labelsKeys = keys(labels);

  return labelsKeys.map((lab) => ({
    Key: lab,
    Value: labels[lab],
  }));
};

const getFilterObj = ({ filterName, labels, searchText }, id, etag) => ({
  Id: id,
  Name: filterName,
  SearchText: searchText,
  Filters: getFilterLabels(labels),
  ETag: etag,
});

export const createCustomFilter = (row, id, etag) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, customFiltersCreatedBtnAction)),
      flatMap(tokenApiFromState),
      flatMap(({ token, api }) =>
        createCustomFilterRequest({
          token,
          api,
          filterData: getFilterObj(row, id, etag),
        })
      ),
      tap(compose(dispatch, closeCustomModalModal)),
      tap((x) =>
        dispatch(
          setSelectedCustomFilters({
            name: x.Name,
            id: x.Id,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllCustomFilter),
      tap(compose(dispatch, setAllCustomFilters))
    )
    .subscribe(
      compose(dispatch, receivedData),
      compose(dispatch, handleNetworkErrorCustomFilter)
    );

export const setCustomFilter = (row) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(dispatch(setFilterText(row.SearchText))),
      RxMap(() =>
        row.Filters.reduce(
          (state, { Key, Value }) => ({
            ...state,
            [Key]: Value,
          }),
          {}
        )
      ),
      tap(compose(dispatch, setCustomFilterFacets)),
      tap(
        dispatch(
          setSelectedCustomFilters({
            name: row.Name,
            id: row.Id,
          })
        )
      )
    )
    .subscribe();

export const deleteCustomFilter = (filterId) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingCurves)),
      tap(compose(dispatch, customFiltersDeletedBtnAction)),
      flatMap(tokenApiFromState),
      flatMap(({ token, api }) =>
        deleteCustomFilterRequest({
          token,
          api,
          filterId,
        })
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllCustomFilter),
      tap(compose(dispatch, setAllCustomFilters))
    )
    .subscribe(
      dispatch(
        setSelectedCustomFilters({
          name: "",
          id: null,
        })
      ),
      compose(dispatch, handleNetworkErrorCustomFilter)
    );

export const updateCustomFilter = (row, filterId, etag) => (
  dispatch,
  getState
) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, fetchingCurves)),
      tap(compose(dispatch, customFiltersUpdatedBtnAction)),
      flatMap(tokenApiFromState),
      flatMap(({ token, api }) =>
        updateCustomFilterRequest({
          token,
          api,
          filterId,
          filterData: getFilterObj(row, filterId, etag),
        })
      ),
      tap((x) =>
        dispatch(
          setSelectedCustomFilters({
            name: x.Name,
            id: x.Id,
          })
        )
      ),
      flatMap(() => tokenApiFromState(getState())),
      flatMap(getAllCustomFilter)
    )
    .subscribe(
      compose(dispatch, setAllCustomFilters),
      compose(dispatch, handleNetworkErrorCustomFilter)
    );

export const aclMeAllPathsPermission = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, checkAllSelectedCurvesPrsmiions)),
      tap(compose(dispatch, fetchingCurves)),
      flatMap(tokenApiFromState),
      flatMap(({ token }) =>
        requestAclMeAllPathsPermission({
          token,
          tenant: Selectors.tenant(getState()),
          curvePaths: compose(
            map(prop("Path")),
            path(["Curves", "selectedCurves", "curves"])
          )(getState()),
        })
      ),
      flatMap((x) => x),
      RxMap((x) =>
        compose(
          assoc("Permissions", x.Roles),
          find(propEq("Path", x.Path))
        )(getState().Curves.selectedCurves.curves)
      ),
      toArray(),
      tap(compose(dispatch, updateSelectedCurvesList))
    )
    .subscribe(
      compose(dispatch, receivedData),
      compose(dispatch, handleNetworkError)
    );

export const removeAllNoneEditablePermissions = () => (dispatch, getState) =>
  RX.of(getState().Curves.selectedCurves.curves)
    .pipe(
      tap(compose(dispatch, fetchingCurves)),
      flatMap((x) => x),
      toArray(),
      tap(compose(dispatch, updateSelectedCurvesList))
    )
    .subscribe(
      compose(dispatch, receivedData),
      compose(dispatch, handleNetworkError)
    );

export const removeAllSelectedCurvesAction = () => [removeAllSelectedCurves()];
export const removeFacetLabelsAction = (facet) => [
  removeFacetLabels(facet),
  requestCurvesAction(),
];
export const setFilterTextAction = (filterText) => [
  setFilterText(filterText),
  requestCurvesAction(),
];
export const setPageNoAction = (pageNo) => [
  setPageNo(pageNo),
  requestCurvesAction(),
];
export const showCurveWarningModalAction = (state) => [
  showCurveWarningModal(state),
];
export const closeCurveWarningModalAction = () => [hideCurveWarningModal()];

export const toggleTableRowSelectionAction = (row, isSelected) => [
  toggleTableRowSelectionRawAction(row, isSelected),
];

export const toggleSelectLabelAction = (facet, label) => [
  toggleLabel(facet, label),
  setPageNo(1),
  requestCurvesAction(),
];

export const removeAllSelectedLabelsAction = () => [
  clearFiltersBtnAction(),
  removeAllSelectedLabels(),
  setSelectedCustomFilters({
    name: "",
    id: null,
  }),
  setPageNo(1),
  requestCurvesAction(),
];

export const setPageSortColumnAction = (sortColumn, sortDirection) => [
  setPageSortColumn({
    sortColumn,
    sortDirection,
  }),
  setPageNo(1),
  requestCurvesAction(),
];

export const setPageSizeAction = (pageSize) => [
  setPageSize(pageSize),
  setPageNo(1),
  requestCurvesAction(),
];

export const confirmCurveWarningModalAction = () => [
  hideCurveWarningModal(),
  selectAllCurvesAction(),
];

export const setToDefaultAction = () => [
  setPageSize(50),
  setPageNo(1),
  removeAllSelectedCurves(),
  removeAllSelectedLabels(),
  setPageSortColumn({
    sortColumn: "MarketDataId",
    sortDirection: "asc",
  }),
  setFilterText(""),
  setSelectedCustomFilters({
    name: "",
    id: null,
  }),
];

const allDataFunc = (res, r1, facet) =>
  isEmpty(prop(r1, res))
    ? []
    : pipe(
        prop(r1),
        find(propEq("FacetName", facet)),
        prop("Values"),
        map(prop("Value"))
      )(res);

export const openCustomModalModalAction = (row) => [openCustomModalModal(row)];
export const closeCustomModalModalAction = () => [closeCustomModalModal()];
export const updateCustomModalModalRowAction = (row) => [
  updateCustomModalModalRow(row),
];
export const createCustomFilterAction = (row, filterId, etag) => [
  createCustomFilter(row, filterId, etag),
  closeCustomModalModal(),
  requestCurvesAction(),
];
export const setCustomFilterAction = (row) => [
  setCustomFilter(row),
  requestCurvesAction(),
];

export const deleteCustomFilterAction = (filterId, name) => [
  deleteCustomFilter(filterId, name),
  closeCustomModalModal(),
  removeAllSelectedLabelsAction(),
  requestCurvesAction(),
];

export const updateCustomFilterAction = (row, filterId, etag) => [
  updateCustomFilter(row, filterId, etag),
  closeCustomModalModal(),
  requestCurvesAction(),
];

export const setExtractCustomFilterStateAction = (res) => [
  setExtractCustomFilterState(res),
];

export const aclMeAllPathsPermissionAction = () => [aclMeAllPathsPermission()];

export const openPermissionCheckModalAction = () => [
  openPermissionCheckModal(),
];
export const closePermissionCheckModalAction = () => [
  closePermissionCheckModal(),
];
export const confirmPermissionCheckModalAction = () => [
  closePermissionCheckModal(),
  aclMeAllPathsPermission(),
];

export const CurvesSelectors = {
  all: (s) => s.Curves,
  allProviders: createSelector(prop("Curves"), (x) =>
    allDataFunc(x, "facetDataStatic", "ProviderName")
  ),
  availableFacets: createSelector(prop("Curves"), (x) =>
    allDataFunc(x, "facetData", "Type")
  ),
  selectedCurves: createSelector(prop("Curves"), prop("selectedCurves")),
  curvesFetching: createSelector(prop("Curves"), prop("isfetching")),
  tablesOptions: createSelector(prop("Curves"), prop("tablesOptions")),
  curveData: createSelector(prop("Curves"), prop("curveData")),
  facetData: createSelector(prop("Curves"), prop("facetData")),
  allCustomFilters: createSelector(prop("Curves"), prop("allCustomFilters")),
  selectedCustomFilter: createSelector(
    prop("Curves"),
    prop("selectedCustomFilter")
  ),
  selectedlabels: createSelector(prop("Curves"), prop("selectedlabels")),
  customFilterModal: createSelector(prop("Curves"), prop("customFilterModal")),
  extractCustomFilterState: createSelector(
    prop("Curves"),
    prop("extractCustomFilterState")
  ),
};
