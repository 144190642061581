import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Toggle from "react-bootstrap-toggle";
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import { Button } from "../../../components";

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fa fa-bars" aria-hidden="true" />
  </span>
));
const SortableItem = SortableElement(({ value, data, props }) => (
  <div className="dragableItem">
    <div className="form-control label-default">
      <div className="dragHandle">
        <DragHandle />
        {value}
      </div>
      <Toggle
        className="btn-toggle visCheckbox"
        onClick={() => props.setHidden(props.index)}
        on="Show"
        off="Hide"
        size="md"
        onstyle="mainBtnColor"
        offstyle="artesianYellow"
        active={!data.hidden}
      />
    </div>
  </div>
));

const SortableListContainer = SortableContainer(({ items, props }) => (
  <div className="dragableContainer">
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={value.title}
        data={value}
        props={{ ...props, index }}
      />
    ))}
  </div>
));

const SortableList = (props) => (
  <div className="sortableList">
    <div className="header">
      <h3>Customize the market data table to your preference</h3>
    </div>
    <SortableListContainer
      axis="y"
      distance={1}
      helperClass="dragableHelper"
      items={props.items}
      onSortEnd={props.setItems}
      props={props}
    />
    <div className="sortableListFooter">
      <Button
        onClick={props.saveTableOrder}
        btnText="Save"
        className="settingsButtons"
      />
      <Button onClick={props.resetTableOrder} btnText="Reset" />
      <Button onClick={props.clearTableOrder} btnText="Clear" />
    </div>
  </div>
);

export default SortableList;
