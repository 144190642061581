import React from "react";
import * as R from "ramda";
import { DropDownScroll, InputAutoComplete } from "../../../components";

const { contains, equals } = R;

export const getPathDetails = (props) => {
  let stringPath = ``;

  if (
    R.includes(props.selectedPath1, [
      "apikey",
      "expectation",
      "filter",
      "gmepublicoffer",
      "principal",
      "timetransform",
    ])
  ) {
    stringPath = `/${props.selectedPath1}/${props.customPath}`;
  }

  if (
    equals(props.selectedPath1, "marketdata") &&
    equals(props.selectedPath2, "system")
  ) {
    stringPath = `/${props.selectedPath1}/${props.selectedPath2}/${props.selectedPath3}`;
  }

  if (equals(props.selectedPath2, "private")) {
    stringPath = `/${props.selectedPath1}/${props.selectedPath2}/${props.customPath}`;
  }

  props.addPathAction({
    Path: stringPath,
    Roles: props.userPermission.map((val) => ({
      Role: val.value,
      InheritedFrom: null,
      Principal: {
        PrincipalType: "Group",
        PrincipalId: `${props.selectedGroup.ID}`,
      },
    })),
  });
  props.setDefault();
};

export const SelectedPath3 = (props) => {
  if (!contains(props.selectedPath1, props.onlyCustomPath1)) {
    return <div />;
  }
  switch (props.selectedPath2) {
    case "system":
      return (
        <div className="d-flex col-lg-2 col-md-12 col-sm-12 pathOption">
          <span className="adminLabel pathSplit">/</span>

          <InputAutoComplete
            value={props.selectedPath3}
            list={props.allProviders}
            id="pathDrop3"
            update={props.setSelectedPath3}
            className="width100"
            placeholder="Add Path"
          />
        </div>
      );
    default:
      return <div />;
  }
};

export const SelectedPath2 = (props) => {
  switch (props.selectedPath1) {
    case "marketdata":
      return (
        <div className="d-flex col-lg-2 col-md-12 col-sm-12 pathOption">
          <span className="adminLabel pathSplit">/</span>
          <DropDownScroll
            selected={props.selectedPath2}
            dropDownValues={props.pathList2}
            onClick={props.setSelectedPath2}
            className="width100"
            dropID="pathDrop2"
          />
        </div>
      );
    default:
      return <div />;
  }
};

export const PermissionOption = ({ value, permissons, setPermission }) => (
  <div key={value} className="groupPermissionChecks">
    <label>
      <input
        type="checkbox"
        value={value}
        checked={contains(value, permissons)}
        onChange={(e) => setPermission(value, e.target.checked)}
      />
      <span>{value}</span>
    </label>
  </div>
);
