import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { withHandlers, withProps, pure, withState } from "recompose";
import { MenuHeader } from "../../menu";
import { auth } from "../../appContext";
import { setTenant } from "../../redux/modules/tenantSelection";
import { setToDefaultAction } from "../../redux/modules/curves";
import { toggleTableSettingsAction } from "../../redux/modules/settings";
import DefaultMenu from "./components/defaultMenu";
import CompanyList from "./components/companyList";

const { compose, map, replace, concat } = R;
const companyMapper = [
  {
    authName: "ArkDev",
    companyName: "Ark Dev",
  },
  {
    authName: "ArkTest",
    companyName: "Ark Test",
  },
  {
    authName: "ArkDemo",
    companyName: "Artesian",
  },
  {
    authName: "DufeIT",
    companyName: "Duferco Energia IT",
  },
  {
    authName: "Axpi",
    companyName: "Axpi",
  },
  {
    authName: "K4view",
    companyName: "K4view",
  },
];

const getName = (name, list) =>
  R.pipe(
    R.filter(R.propEq("authName", name)),
    R.map(R.prop("companyName")),
    (res) => (R.isEmpty(res) ? name : res)
  )(list);

const Company = ({ name, changeTenat }) => (
  <div className="company" onClick={() => changeTenat(name)}>
    <div className="title">{getName(name, companyMapper)}</div>
    <div className="auth0Name">{name}</div>
  </div>
);

const mapStateToProps = (state) => ({
  ...state.Menu,
  ...state.Authentication,
  ...state.tenant,
  location: state.router.location,
});
const formatTenant = replace("tenant:", "");
export default compose(
  pure,
  connect(mapStateToProps, {
    setTenant,
    setToDefaultAction,
    toggleTableSettingsAction,
  }),
  withHandlers({
    getName: () => (name) => getName(name, companyMapper),
  }),
  withState("switchCompany", "updateSwitchCompany", false),
  withProps((props) => ({
    userName: R.path(["profile", "name"], props) || R.path(["profile", "email"], props),
    tenant: formatTenant(props.tenant),
    options: map(formatTenant, props.options),
  })),
  withProps((props) => ({
    companyName: props.getName(props.tenant),
    displayCompanySwitch: R.gt(R.length(props.options), 1),
  })),
  withHandlers({
    logout: () => () => auth.logout({ returnUrl: "" }),
    redirectToApiKeys: (props) => () => {
      props.updateUserMenuState(false);
      props.toggleTableSettingsAction();
    },
    updateTenat: (props) => (val) => {
      props.updateUserMenuState(false);
      props.setTenant(concat("tenant:", val));
    },
    setUserMenuState: (props) => () =>
      R.pipe(
        () => props.updateSwitchCompany(false),
        () => props.updateUserMenuState(!props.userMenuState)
      )([]),
    openSwitchCompany: (props) => () => props.updateSwitchCompany(true),
  }),
  withHandlers({
    changeTenat: (props) => (val) =>
      R.pipe(
        () => props.updateSwitchCompany(false),
        () => props.updateTenat(val)
      )([]),
  }),
  withProps((props) => ({
    tentantComponents: props.options.map((name) => (
      <Company
        key={name}
        name={name}
        changeTenat={props.changeTenat}
        title={props.getName(name)}
      />
    )),
  })),
  withProps((props) => ({
    component: props.switchCompany ? (
      <CompanyList
        tentantComponents={props.tentantComponents}
        close={() => props.updateSwitchCompany(false)}
      />
    ) : (
      <DefaultMenu
        email={R.path(["profile", "email"], props)}
        redirectToApiKeys={props.redirectToApiKeys}
        openSwitchCompany={props.openSwitchCompany}
        displayCompanySwitch={props.displayCompanySwitch}
        logout={props.logout}
      />
    ),
  }))
)(MenuHeader);
