import React from "react";
import { compose, equals, isNil, prop, any, contains, pipe } from "ramda";
import { connect } from "react-redux";
import moment from "moment";
import { withHandlers, withProps, withState } from "recompose";
import {
  deleteApiKeyAction,
  createApiKeyAction,
  newApiKeyDefaultAction,
  copyNewKeyToClipBoardAction,
} from "../../../redux/modules/settings";
import {
  APIKeysSpinner,
  CreateApiKeyForm,
  CreateApiKeySuccess,
  CreateApiKeyFailure,
  CreateApiKeyPermissions,
} from "../../../settings/components";

const Creation = (props) => {
  if (props.createAPIKeyPermission) {
    return <CreateApiKeyPermissions />;
  }

  switch (props.requestNewApiKeyState) {
    case "NEW_KEY_FETCHING":
      return <APIKeysSpinner />;
    case "NEW_KEY_RECIEVED":
      return (
        <CreateApiKeySuccess
          cancel={props.resetToDefault}
          copy={props.copyNewKeyToClipBoard}
          copyClip={props.copyClip}
          keyString={props.createdApiKeys}
        />
      );
    case "NEW_KEY_FAILED":
      return (
        <CreateApiKeyFailure
          retry={props.createNewKey}
          cancel={props.resetToDefault}
        />
      );
    default:
      return <CreateApiKeyForm {...props} />;
  }
};

const mapStateToProps = (state) => ({
  ...state.Settings,
  apiKeyPermission: prop("apiKey", state.Permissions.menuPermission) || [],
  defaultDate: moment().add(1, "days"),
});

const enhancer = compose(
  connect(mapStateToProps, {
    deleteApiKeyAction,
    createApiKeyAction,
    newApiKeyDefaultAction,
    copyNewKeyToClipBoardAction,
  }),
  withProps((props) => ({
    createAPIKeyPermission: !any(equals(true))([
      contains("Creator", props.apiKeyPermission),
      contains("Owner", props.apiKeyPermission),
    ]),
  })),
  withState("description", "updateDescription", ""),
  withState("copyClip", "updateCopyClip", false),
  withState(
    "expirationDate",
    "updateExpirationDate",
    ({ defaultDate }) => defaultDate
  ),
  withState(
    "expirationDateSelected",
    "updateExpirationDateSelected",
    "expireDate"
  ),
  withProps((props) => ({
    disableCreateBtn: isNil(props.description) || equals(props.description, ""),
  })),
  withHandlers({
    newApiKeyDefaultAction: (props) => () => props.newApiKeyDefaultAction(),
  }),
  withHandlers({
    resetToDefault: (props) => () => {
      props.updateDescription();
      props.newApiKeyDefaultAction();
      props.updateExpirationDate(moment().add(1, "days"));
      props.updateExpirationDateSelected("expireDate");
    },
    setDescription: (props) => (e) => props.updateDescription(e.target.value),
    setExpirationDate: (props) => (val) =>
      props.updateExpirationDate(isNil(val) ? props.defaultDate : moment(val)),
    copyNewKeyToClipBoard: (props) => () =>
      pipe(
        () => props.copyNewKeyToClipBoardAction(),
        () => props.updateCopyClip(true),
        () => setTimeout(() => props.updateCopyClip(false), 800)
      )([]),
    createNewKey: (props) => () =>
      props.createApiKeyAction({
        Id: 0,
        UsagePerDay: -1,
        ExpiresAt: equals(props.expirationDateSelected, "expireDate")
          ? `${moment(props.expirationDate).format(
              "YYYY-MM-DD"
            )}T00:00:00.0000000Z`
          : null,
        Description: props.description,
      }),
  })
);

export default enhancer(Creation);
