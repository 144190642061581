import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { equals } from "ramda";
import { InputTextBox, Button } from "../components";

const AddUserModal = (props) => (
  <div>
    <div
      className={`modal fade show ${
        props.customFilterModal.open ? "d-block" : ""
      }`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${
          props.customFilterModal.open ? "fadeIn" : ""
        }`}
        id="errorModalOverlay"
        onClick={props.closeModal}
        aria-hidden="true"
      />
      <div className="modal-dialog artesainModal" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">Custom Filter</h5>
          </div>
          <div className="modal-body">
            {props.filterModalPermission ? (
              <div className="statusApiKey">
                <div>
                  <div>
                    <i className="fas fa-thumbs-down failed" />
                  </div>
                  <h4 className="text-center">
                    You have insufficient permissions to access this feature.
                    <br /> Please contact you admin.
                  </h4>
                </div>
              </div>
            ) : (
              <div>
                <div className="customFilteModalrDiv">
                  <span className="customFilterModalTitle">Filter Name:</span>
                  <InputTextBox
                    value={props.filterName}
                    onChange={props.setFilterName}
                    id="filtlerName"
                  />
                </div>
                <div className="customFilteModalrDiv">
                  <span className="customFilterModalTitle">Search Text: </span>
                  <InputTextBox value={props.searchText || ""} disabled />
                </div>
                <div className="customFilteModalrDiv">
                  <span className="customFilterModalTitle">
                    Selected Labels:
                  </span>
                  <Scrollbars
                    className="customFilterSelectedLabels"
                    renderThumbHorizontal={(vals) => (
                      <div {...vals} className="thumb-horizontal" />
                    )}
                    renderThumbVertical={(vals) => (
                      <div {...vals} className="thumb-vertical" />
                    )}
                  >
                    {props.selectedLabels}
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer float-right">
            {props.filterModalPermission ? (
              <Button
                btnText="Close"
                className="modalClose"
                onClick={props.closeModal}
              />
            ) : (
              <div>
                {equals(props.customFilterModal.type, "createUpdateFilter") ? (
                  <Button
                    btnText="Update Filter"
                    className="modalClose"
                    disabled={props.disableUpdate}
                    onClick={props.updateCustomFilter}
                  />
                ) : null}
                {equals(props.customFilterModal.type, "createUpdateFilter") ? (
                  <Button
                    btnText="Delete Filter"
                    className="modalClose delete"
                    onClick={props.deleteCustomFilter}
                  />
                ) : null}
                <Button
                  btnText="Create Filter"
                  className="modalClose"
                  disabled={props.disableCreate}
                  onClick={props.createCustomFilter}
                />
                <Button
                  btnText="Close"
                  className="modalClose"
                  onClick={props.closeModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddUserModal;
