import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button, InputAutoComplete } from "../components";
import {
  setTutorialModalOpenAction,
  setVideoUrlAction,
} from "../redux/modules/tutorial";
import { connect } from "react-redux";

const CurvesClassification = ({
  setTutorialModalOpenAction,
  setVideoUrlAction,
  ...props
}) => (
  <div className="curvesClassification">
    <div className="classify pageTitle">
      <span className="paddingLeftFive">
        <i className="fa fa-tag marginRight10 artesianIconNoHover" />
      </span>
      Classify
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/TSqZkgCAvrk?si=NyfcwDG3YghE5Tie"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="selectedFunction">
      <div
        className={`effectedCurves ${
          props.effectedCurves
            ? "effectedCurveContainerOpen"
            : "effectedCurveContainerClosed"
        }`}
      >
        <div
          className={`effectedCurvesContainer ${
            props.effectedCurves ? "open" : "closed"
          }`}
          id="tutEffCurvesContainer"
        >
          {props.effectedCurvesTable}
        </div>
        <Button
          onClick={props.toggleEffectedCurves}
          btnText={
            <i
              className={`fa ${
                props.effectedCurves
                  ? "fa-angle-double-left"
                  : "fa-angle-double-right"
              }`}
            />
          }
          className="effectedCurvesDisplayBtn"
        />
      </div>
      <div
        className={`curvesClassificationBody ${
          props.effectedCurves ? "componentOpen" : "componentClosed"
        }`}
      >
        <div className="d-flex classifyInputs">
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <InputAutoComplete
              value={props.tagName}
              list={props.allTagNames}
              id="labelInput"
              update={props.setTagName}
              className={props.tagNameValid ? "valid" : "invalid"}
              placeholder={
                props.tagNameValid
                  ? "Assign a new TAG"
                  : props.tagNameInvalidMsg
              }
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <InputAutoComplete
              value={props.labelName}
              list={props.allLabelNames}
              id="tagInput"
              update={props.setLabelName}
              className={props.labelValid ? "valid" : "invalid"}
              placeholder={
                props.labelValid ? "Assign a new Label" : props.labelInvalidMsg
              }
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <Button
              className="btn applyClassifyBtn"
              disabled={props.disableClassifyBtn}
              onClick={props.applyClassification}
              btnText={
                <Tooltip
                  arrow
                  position="bottom"
                  html={<span>Classify</span>}
                  theme="arkDefaultTooltip"
                >
                  CLASSIFY
                </Tooltip>
              }
            />
          </div>
        </div>
        <div className="exisitingClassifications">
          <h4>Exisiting Classification: {props.tagKeysCount}</h4>
        </div>
        <Scrollbars
          className="displayClassifications"
          renderThumbHorizontal={(vals) => (
            <div {...vals} className="thumb-horizontal" />
          )}
          renderThumbVertical={(vals) => (
            <div {...vals} className="thumb-vertical" />
          )}
        >
          {props.displayLabels}
        </Scrollbars>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction,
};
export default connect(null, mapDispatchToProps)(CurvesClassification);
