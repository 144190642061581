import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

export const curvesTutorial = [
  // Intro
  {
    position: "center",
    content: (
      <div>
        <h3>Artesian UI tutorial</h3>
        <br />
        To begin the Artesian UI tutorial click &nbsp;
        <i className="fa fa-arrow-right" />
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  {
    selector: '[class="mainFacetsContainer transition facetsContainerOpen"]',
    position: "right",
    content: (
      <div>
        <h3>Artesian Facets</h3>
        The Facet Panel provides a method of filtration for market data based on the
        many labels by which they can be grouped. Selecting one or several of
        these labels will refine the collection of market data returned to only those
        bearing your selected labels. Finding specific labels by name can be
        achieved through the Facet Panel’s search bar.
      </div>
    ),
    action: (node) => {
      if (node) {
        node.click();
      }
    },
    style: toolTipStyleLeftMargin,
  },
  // Custom Filter
  {
    selector: '[id="customFilterDropDown"]',
    position: "right",
    content: (
      <div>
        Create filters of your own that applies facets and search text of your
        preference in one simple click.
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName(
          "col-1 facetIcons fa facetIconsOpen fa-minus-square Type"
        ).length < 1
      ) {
        return document.getElementById("TypeBtn").click();
      }
      return null;
    },
    style: toolTipStyleLeftMargin,
  },
  // Type
  {
    selector: '[id="Type"]',
    position: "right",
    content: (
      <div>
        Clicking any facet will display all of that facet’s associated labels
      </div>
    ),
    action: () => {
      if (
        document.getElementsByClassName(
          "col-1 facetIcons fa facetIconsOpen fa-minus-square Type"
        ).length < 1
      ) {
        return document.getElementById("TypeBtn").click();
      }
      return null;
    },
    style: toolTipStyleLeftMargin,
  },
  // Market Assessmnet
  {
    selector: '[class="col-12 collapse facetPanel  displayPanel"]',
    position: "bottom",
    content: (
      <div>
        Facet labels show the number of market data which have that label. Clicking
        the label will filter out all market data that do not have that label from
        your current selection. This can be done with several labels for a more
        refined selection.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Sort Curves
  {
    selector: '[class="d-flex width100 noPadding Curves"]',
    position: "bottom",
    content: (
      <div>
        <h3>Order MarketData</h3> <br />
        By default market data are ordered by MarketData ID Asc. <br />
        This dropdown lets you change the order in which market data are returned.
        The following ordering options are available: MarketData ID Desc, Name Asc,
        Name Desc, Provider Asc, ProviderDesc, MarketData Type Asc, or MarketData Type
        Desc
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Search Curves
  {
    selector: '[class="input-group curveSearchContainer"]',
    position: "bottom",
    content: (
      <div>
        <h3>Free Text Search</h3> <br />
        When looking for a specific market data our free text search field lets you
        isolate the market data you want. Search by providers, names, or any other
        data linked with the market data in question.
        <br />
        Details on using logical operators for refined searching within this
        field can be found in the information dropdown &nbsp;
        <i className="fas fa-info" />
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Select all
  {
    selector: '[class="btn selectAllCurvesBtn "]',
    position: "bottom",
    content: (
      <div>
        <h3>Select All MarketData</h3> <br />
        Having filtered down your market data, the Select All button displays all
        market data that match your given criteria, allowing for the seamless
        addition of resulting market data to your basket for further analysis.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Clear filters
  {
    selector: '[class="btn extractFilterBtn "]',
    position: "bottom",
    content: (
      <div>
        <h3>Clear All Filters</h3> <br />
        This button resets all filters to their default state, removing all
        selected facets in the process
        <br />
        If you want to empty your results set and to reset the search parameters
        and selections you can do so by clicking on the clear filters button
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Extract filters
  {
    selector: '[id="extractFiltersBtn"]',
    position: "bottom",
    content: (
      <div>
        <h3>Extract Filters</h3> <br />
        After creating a filter of yor preference, you can extract all the
        market data returned using the filters ID in the extraction page.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Curves
  {
    selector: '[class="react-bs-container-body curveTableBody"]',
    position: "left",
    content: (
      <div>
        <h3>MarketData Table</h3>
        Once you have filtered all market data down to the selection that you desire
        the MarketData Table shows all market data that meet your specifications.
        Clicking a row will add that market data into your basket for later
        Classification, Extraction, or Enrichment.
      </div>
    ),
    action: () => {
      if (
        document.getElementsByTagName("tr")[1].className !== "selectedCurve"
      ) {
        return document.getElementsByTagName("tr")[1].click();
      }
      return null;
    },
    style: toolTipStyleNoMargin,
  },
  // Menu Items
  {
    selector: '[class="menuItems"]',
    position: "right",
    content: (
      <div>
        <h3>Option&apos;s Menu</h3>
        Once you are happy with your selection of market data the Options Menu will
        become accessible, allowing you to Refine, Classify, Extract, and Enrich
        your chosen MarketData. <br />
        To continue this tutorial with information on how to Refine MarketData
        through Artesian.
        <br /> Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    style: toolTipStyleLeftMargin,
  },
  // GOTO Refine Page.
  {
    selector: '[class="btn  menuBtnItem Refine "]',
    action: (node) => node.click(),
    content: (
      <div>
        If this step is shown, a market data has not been selected.
        <br />
        Go back through steps and to select a market data.
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
];
export default curvesTutorial;
