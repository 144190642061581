import React from "react";
import EditModal from "./components/editModal";

const FieldDefaultsModal = (props) => (
  <EditModal
    openModal={props.deleteModal.open}
    closeModal={props.closeModal}
    modalBody={props.GetDropDownGroups(props)}
    type={props.deleteModal.type}
    title={props.deleteModal.title}
    row={props.deleteModal.row}
    originalRow={props.deleteModal.orig}
    commitChanges={props.commitChanges}
    hasConfirmWarning
    disableApplyBtn={props.applyBtnIsDisabled()}
  />
);

export default FieldDefaultsModal;
