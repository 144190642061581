import React from "react";
import { Button } from "../../components";

export const CreateApiKeyFailed = ({ retry, cancel }) => (
  <div className="statusApiKey">
    <div>
      <i className="fas fa-times failed" />
    </div>
    <h4 className="text-center">Your request has failed.</h4>
    <div className="text-right">
      <Button
        id="tryAgainApiKey"
        onClick={retry}
        btnText={
          <span>
            <i className="fa fa-redo" /> &nbsp;
            <span className="d-inline">Retry</span>
          </span>
        }
      />
      <Button
        id="cancelApiKey"
        onClick={cancel}
        btnText={
          <span>
            <i className="fa fa-ban" /> &nbsp;
            <span className="d-inline">Cancel</span>
          </span>
        }
      />
    </div>
  </div>
);
export default CreateApiKeyFailed;
