import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fa fa-bars" aria-hidden="true" />
  </span>
));
const ToggleCom = ({ data, props }) => (
  <div
    className={`toggle visCheckbox btn ${!data.hidden ? "" : "off"}`}
    onClick={() => props.setHidden(props.index)}
  >
    <input id="toggle-one" checked="" type="checkbox" />
    <div className="toggle-group">
      <label className="btn toggle-on btn-mainBtnColor">Show</label>
      <label className="btn toggle-off btn-artesianYellow">Hide</label>
      <span className="toggle-handle btn btn-default" />
    </div>
  </div>
);
const SortableItem = SortableElement(({ value, data, props }) => (
  <div className="dragableItem">
    <div className="form-control label-default">
      <div className="dragHandle">
        <DragHandle />
        {value}
      </div>
      <ToggleCom data={data} props={props} />
    </div>
  </div>
));

const SortableListContainer = SortableContainer(({ items, props }) => (
  <div className="dragableContainer">
    {items.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={value.title}
        data={value}
        props={{ ...props, index }}
      />
    ))}
  </div>
));

const SortableList = (props) => (
  <div className="sortableList">
    <div className="header" />
    <SortableListContainer
      axis="y"
      distance={1}
      helperClass="dragableHelper"
      items={props.items}
      onSortEnd={props.setItems}
      props={props}
    />
  </div>
);

export default SortableList;
