import * as R from "ramda";
import { LOCATION_CHANGE } from "react-router-redux";
import { Selectors as TenantSelector } from "../tenantSelection";
import { TUTORIAL_ON } from "../tutorial/index";
import {
  SET_DASHBOARD_FILTER,
  ADD_NEW_ALERT,
  DELETE_NEW_ALERT,
  ADD_CURVES_ALERT,
} from "../expectationMonitor/index";
import { DOWNLOADXLSX } from "../curveExtract/index";
import {
  CREATE_NEW_TT,
  UPDATE_TT,
  DELETE_TT,
} from "../enrichTimeTransform/index";
import {
  DOWNLOAD_GME_PUBLIC_OFFER,
  PREVIEW_GME_PUBLIC_OFFER,
  Selectors as GMESelector,
} from "../gmePublicOffers/index";
import {
  CREATE_NEW_GROUP,
  DELTE_NEW_GROUP,
  EDIT_PATH,
  CREATE_NEW_PATH,
  DELETE_PATH,
  Selectors as AdminGroupsSelectors,
} from "../adminGroups/index";
import {
  CREATE_UPDATE_USER,
  DELETE_USER,
  Selectors as AdminUsersSelectors,
} from "../adminUsers/index";
import {
  ADD_SELECTED_CURVE,
  REMOVE_SELECTED_CURVE,
  SET_CURVES_FILTER_TEXT,
  SELECT_ALL_CURVES,
  CLEAR_FILTERS,
  EXTRACT_CUSOM_FILTER,
  REMOVE_SELECTED_CURVES,
  UPDATE_SELECTED_CURVES,
  CLASSIFY_CURVES,
  TOGGLE_SELECTED_CURVE,
  CHECK_All_SELECTED_CURVES_PERMISSIONS,
  CurvesSelectors,
} from "../curves/index";
import { FILTER_REFINE_CURVES } from "../refineCurves/index";
import { CURVE_PRIVEIEW_REQUEST } from "../curvePreview/index";
import { CREATE_NEW_METADATA } from "../curveEnrich/index";
import {
  SELECT_CURVES,
  DELIVERY_DASHBOARD,
  REFINE_BASKET,
  CURVE_CLASSIFICATION,
  EXTRACT_CURVES,
  ENRICH_METADATA,
  ENRICH_TIME_TRANSFORM,
  ADMIN_GROUP,
  ADMIN_USER,
} from "../menu/index";

export const eventTracker = ({ action, state }) => {
  const tenant = TenantSelector.tenant(state);
  switch (action.type) {
    case SELECT_CURVES:
    case DELIVERY_DASHBOARD:
    case REFINE_BASKET:
    case CURVE_CLASSIFICATION:
    case EXTRACT_CURVES:
    case ENRICH_METADATA:
    case ENRICH_TIME_TRANSFORM:
    case ADMIN_GROUP:
    case ADMIN_USER: {
      return {
        name: `${tenant}-${action.page}_Page_View`,
        properties: {},
      };
    }
    case LOCATION_CHANGE: {
      return action.payload.pathname === "/"
        ? null
        : {
            name: `${tenant}-GME_Page_View`,
            properties: {},
          };
    }
    case FILTER_REFINE_CURVES: {
      return {
        name: `${tenant}-Filter_Refine_Curves`,
        properties: {
          Types: action.filters.map((d) => d.label),
        },
      };
    }
    case CURVE_PRIVEIEW_REQUEST: {
      return {
        name: `${tenant}-Curve_Preview`,
        properties: {
          Id: action.data.MarketDataId,
        },
      };
    }
    case TUTORIAL_ON: {
      return {
        name: `${tenant}-Tutorial_On`,
        properties: {},
      };
    }
    case ADD_SELECTED_CURVE: {
      return {
        name: `${tenant}-Add_Selected_Curve`,
        properties: {
          MarketDataId: action.row.map((d) => d.MarketDataId),
        },
      };
    }
    case REMOVE_SELECTED_CURVE: {
      return {
        name: `${tenant}-Remove_Selected_Curve`,
        properties: {
          MarketDataId: action.row.map((d) => d.MarketDataId),
        },
      };
    }
    case SET_CURVES_FILTER_TEXT: {
      return {
        name: `${tenant}-Set_Curves_Filter_Text`,
        properties: {
          FilterText: action.filterText,
        },
      };
    }
    case SELECT_ALL_CURVES: {
      const info = CurvesSelectors.all(state);
      return {
        name: `${tenant}-Select_All_Curves`,
        properties: {
          facetes: info.selectedlabels,
          filterText: info.tablesOptions.filterText,
        },
      };
    }
    case CLEAR_FILTERS: {
      return {
        name: `${tenant}-Clear_Filters`,
        properties: {},
      };
    }
    case EXTRACT_CUSOM_FILTER: {
      const info = CurvesSelectors.all(state);
      const data = info.selectedCustomFilter;
      return R.isNil(data.id) || R.isEmpty(data.id)
        ? false
        : {
            name: `${tenant}-Extract_Selected_Custom_Filter`,
            properties: { ...data },
          };
    }
    case REMOVE_SELECTED_CURVES: {
      return {
        name: `${tenant}-Remove_All_Selected_Curves_By_Type`,
        properties: {
          removedType: action.curveType,
        },
      };
    }
    case ADD_NEW_ALERT: {
      return {
        name: `${tenant}-Delivery_Dashboard_Add_Alert`,
        properties: {
          details: action.details,
        },
      };
    }
    case DELETE_NEW_ALERT: {
      return {
        name: `${tenant}-Delivery_Dashboard_Add_Alert`,
        properties: {
          id: action.id,
        },
      };
    }
    case ADD_CURVES_ALERT:
      return {
        name: `${tenant}-Delivery_Dashboard_Add_Curves_Alert`,
        properties: {
          id: action.id,
          marketDataIdList: action.marketDataIdList,
        },
      };
    case SET_DASHBOARD_FILTER: {
      return {
        name: `${tenant}-Delivery_Dashboard_Filter`,
        properties: {
          FilterText: action.filter,
        },
      };
    }
    case UPDATE_SELECTED_CURVES: {
      return {
        name: `${tenant}-Remove_Read_Only_Curves`,
        properties: {},
      };
    }
    case CHECK_All_SELECTED_CURVES_PERMISSIONS: {
      return {
        name: `${tenant}-Check_All_Selected_Curves_Permissions`,
        properties: {},
      };
    }
    case DOWNLOADXLSX: {
      return {
        name: `${tenant}-Curve_Extract_Download`,
        properties: {
          Url: action.url,
        },
      };
    }
    case CREATE_NEW_METADATA: {
      return {
        name: `${tenant}-Enrich_Metadata`,
        properties: {
          ...action.data,
        },
      };
    }
    case CREATE_NEW_TT: {
      return {
        name: `${tenant}-Create_New_Time_Transform`,
        properties: {
          ...action.data,
        },
      };
    }
    case UPDATE_TT: {
      return {
        name: `${tenant}-Update_New_Time_Transform`,
        properties: {
          ...action.data,
        },
      };
    }
    case DELETE_TT: {
      return {
        name: `${tenant}-Delete_Time_Transform`,
        properties: {
          Id: action.deleteTimeTransformModal.id,
        },
      };
    }
    case CREATE_NEW_GROUP: {
      return {
        name: `${tenant}-Add_Admin_Group`,
        properties: {
          ...action.data,
        },
      };
    }
    case DELTE_NEW_GROUP: {
      return {
        name: `${tenant}-Delete_Admin_Group`,
        properties: {
          id: action.data.ID,
          name: action.data.Name,
        },
      };
    }
    case EDIT_PATH: {
      return {
        name: `${tenant}-Edit_Group_Permission`,
        properties: { ...action.data },
      };
    }
    case CREATE_NEW_PATH: {
      return {
        name: `${tenant}-Create_New_Path_Permission`,
        properties: { ...action.data },
      };
    }
    case DELETE_PATH: {
      const info = AdminGroupsSelectors.all(state);
      return {
        name: `${tenant}-Delete_Path_Permission`,
        properties: {
          Id: info.selectedGroup.ID,
          Path: info.deletePathModal.row.Path,
        },
      };
    }
    case CREATE_UPDATE_USER: {
      const info = AdminUsersSelectors.all(state);
      const type = info.addUserModal.actionType;
      return {
        name: `${tenant}-${type === "edit" ? "Edit" : "New"}_User`,
        properties: {
          ...info.addUserModal.row,
        },
      };
    }
    case DELETE_USER: {
      const info = AdminUsersSelectors.all(state);
      return {
        name: `${tenant}-Delete_User`,
        properties: {
          ...info.userDeleteModal.row,
        },
      };
    }
    case DOWNLOAD_GME_PUBLIC_OFFER: {
      const info = GMESelector.all(state);
      return {
        name: `${tenant}-Download_GME_Public_Offer`,
        properties: {
          ...info.data.filters,
        },
      };
    }
    case PREVIEW_GME_PUBLIC_OFFER: {
      const info = GMESelector.all(state);
      return {
        name: `${tenant}-Preview_GME_Public_Offer`,
        properties: {
          ...info.data.filters,
        },
      };
    }
    case CLASSIFY_CURVES: {
      return {
        name: `${tenant}-Classify_Curves`,
        properties: {
          ids: action.IDS.map((row) => row.ID),
          facets: action.OperationList,
        },
      };
    }
    case TOGGLE_SELECTED_CURVE: {
      return {
        name: `${tenant}-Toggle_Facets`,
        properties: {
          facet: action.facet,
          label: action.label,
        },
      };
    }
    default:
      return false;
  }
};

export default eventTracker;

