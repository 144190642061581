import React from "react";
import { connect } from "react-redux";
import { compose, concat, prepend, map, pick, isEmpty, equals } from "ramda";
import { lifecycle, withProps, withState, withHandlers } from "recompose";
import {
  DropDown,
  TimeTransformDropdown,
  EffectedClassificationTable,
  EffectCurvesSizePerPageDropDown,
} from "../../components";
import { CurveEnrich } from "../../curveEnrich";
import { getTrId, filterTable, getTrName, removeInvalidChars } from "./helper";
import {
  getAllTimeTransformDataAction,
  showSendSelectedCurveEnrichWarningModalAction,
} from "../../redux/modules/curveEnrich";
import { tutorialOnAction } from "../../redux/modules/tutorial";

const mapStateToProps = (state) => ({
  ...state.Curves,
  ...state.CurveEnrich,
});

export default compose(
  connect(mapStateToProps, {
    getAllTimeTransformDataAction,
    showSendSelectedCurveEnrichWarningModalAction,
    tutorialOn: tutorialOnAction,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAllTimeTransformDataAction();
    },
  }),
  withProps((props) => ({
    transformValues: concat(
      props.userTransformValues,
      props.systemTransformValues
    ),
  })),
  withProps((props) => ({
    transformValuesOptions: compose(
      prepend({ Name: "None Selected", ID: 0 }),
      prepend({ Name: "No Time Transform", ID: 9999 }),
      map(pick(["Name", "ID"]))
    )(props.transformValues),
  })),
  withState("selectedTimezone", "updateSelectedTimezone", "None Selected"),
  withState("selectedTransform", "updateSelectedTransform", "None Selected"),
  withState(
    "selectedAggregationRule",
    "updateSelectedAggregationRule",
    "None Selected"
  ),
  withState("descriptionText", "updateDescriptionText", ""),
  withState("effectedCurves", "updateEffectedCurves", true),
  withState("filterVal", "updateFilterVal", []),
  withState("effectCurvePageSize", "updateEffectCurvePageSize", 30),
  withHandlers({
    applyDisabled: (props) => () => {
      if (props.selectedTimezone !== "None Selected") {
        return false;
      }
      if (props.selectedTransform !== "None Selected") {
        return false;
      }
      if (props.selectedAggregationRule !== "None Selected") {
        return false;
      }
      if (props.descriptionText !== "") {
        return false;
      }
      return true;
    },
  }),
  withHandlers({
    toggleEffectedCurves: (props) => () =>
      props.updateEffectedCurves(!props.effectedCurves),
    applyEnrichment: (props) => () =>
      props.showSendSelectedCurveEnrichWarningModalAction({
        msg: (
          <div>
            <div>
              Are you sure you want to apply the following enrichment to all
              selected market data?
            </div>
            <table className="table curveEnrichTable">
              <thead>
                <tr>
                  <th colSpan="2">Enrich MetaData Settings</th>
                </tr>
              </thead>
              <tbody>
                {props.selectedTimezone === "None Selected" ? null : (
                  <tr>
                    <th>Timezone</th>
                    <td>{props.selectedTimezone}</td>
                  </tr>
                )}
                {props.selectedTransform === "None Selected" ? null : (
                  <tr>
                    <th>Time Transform</th>
                    <td>{props.selectedTransform}</td>
                  </tr>
                )}
                {props.selectedAggregationRule === "None Selected" ? null : (
                  <tr>
                    <th>Aggregation</th>
                    <td>{props.selectedAggregationRule}</td>
                  </tr>
                )}
                {props.descriptionText === "" ? null : (
                  <tr>
                    <th>Description</th>
                    <td>{props.descriptionText}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ),
        timezone: props.selectedTimezone,
        transform: equals(props.selectedTransform, "No Time Transform")
          ? 9999
          : getTrId(props.transformValues, props.selectedTransform),
        aggregationRule: props.selectedAggregationRule,
        description: props.descriptionText,
      }),
    setDescriptionText: (props) => (val) =>
      props.updateDescriptionText(removeInvalidChars(val.target.value)),
    setPageSize: (props) => (size) => props.updateEffectCurvePageSize(size),
  }),
  withHandlers({
    effectedCurveTrName: (props) => (id) =>
      getTrName(props.transformValues, id),
    renderDropDown: (props) => () =>
      EffectCurvesSizePerPageDropDown(props.effectCurvePageSize, props),
    selectedFilters: (props) => (val) => props.updateFilterVal(val),
  }),
  withProps((props) => ({
    filterDropdownOptions: [
      { label: "Market Assessment", value: "MarketAssessment" },
      { label: "Actual Time Series", value: "ActualTimeSerie" },
      { label: "Versioned Time Series", value: "VersionedTimeSerie" },
    ],
    selectedCurveData: isEmpty(props.filterVal)
      ? props.selectedCurves.curves
      : filterTable(props.selectedCurves.curves, props.filterVal),
    effectedCurvesTableSettings: {
      sizePerPage: props.effectCurvePageSize,
      sizePerPageDropDown: props.renderDropDown,
      prePage: "Previous",
      nextPage: "Next",
      clickToExpand: true,
    },
    expandColumnOptionsSettings: {
      expandColumnVisible: true,
      expandColumnBeforeSelectColumn: true,
      columnWidth: 25,
    },
    isNoneSelected: (val) => val === "None Selected",
  })),
  withProps((props) => ({
    timezoneDropDown: (
      <DropDown
        selected={props.selectedTimezone}
        dropDownValues={props.timezonesValues}
        onClick={props.updateSelectedTimezone}
        dropID="EnrichCurveTimezone"
      />
    ),
    transformDropDown: (
      <TimeTransformDropdown
        selected={props.selectedTransform}
        dropDownValues={props.transformValuesOptions}
        onClick={props.updateSelectedTransform}
        id="EnrichCurveTimeTransform"
      />
    ),
    aggregationRuleDropDown: (
      <DropDown
        selected={props.selectedAggregationRule}
        dropDownValues={props.aggregationRuleValues}
        onClick={props.updateSelectedAggregationRule}
        dropID="EnrichCurveAggregation"
      />
    ),
    effectedCurvesTable: (
      <EffectedClassificationTable
        headerTitle="Effected MarketData"
        data={props.selectedCurveData}
        filterVal={props.filterVal}
        selectedFilters={props.selectedFilters}
        settings={props.effectedCurvesTableSettings}
        expandSettings={props.expandColumnOptionsSettings}
        effectedCurveTrName={props.effectedCurveTrName}
        filterDropdownOptions={props.filterDropdownOptions}
      />
    ),
  }))
)(CurveEnrich);
