import React from "react";
import { Tooltip } from "react-tippy";

const SelectedFilter = (props) => (
  <div className="pull-left selectedFilterContainer">
    <Tooltip
      arrow
      position="bottom"
      html={<span>Remove Facet</span>}
      theme="transparent"
    >
      <span
        onClick={props.remove}
        className="label-default hoverColor"
        aria-hidden="true"
      >
        {props.label}
      </span>
    </Tooltip>
  </div>
);

export default SelectedFilter;
