import React from "react";

export const CreateApiKeyFailed = () => (
  <div className="statusApiKey">
    <div>
      <i className="fas fa-thumbs-down failed" />
    </div>
    <h4 className="text-center">
      You have insufficient permissions to access this feature. <br /> Please
      contact you admin.
    </h4>
  </div>
);
export default CreateApiKeyFailed;
