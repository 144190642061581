export const relativeRangeValues = [
  "Rolling Week",
  "Rolling Month",
  "Rolling Quarter",
  "Rolling Year",
  "Week To Date",
  "Month To Date",
  "Quarter To Date",
  "Year To Date",
];
export const granularityValues = [
  "TenMinute",
  "FifteenMinute",
  "ThirtyMinute",
  "Hour",
  "Day",
  "Week",
  "Month",
  "Season",
  "Year",
];
export const productTypesValues = [
  "Relative",
  "Absolute",
  "WD",
  "BOM",
  "BOQ",
  "BOW",
  "BOY",
  "DA",
  "IntraDay",
  "WE",
  "WDY-NW",
];
export const timezonesValues = [
  "CET",
  "WET",
  "EET",
  "UTC",
  "Europe/Istanbul",
  "Europe/Moscow",
];
export const versionValues = [
  "LastofMonths",
  "LastofDays",
  "Last",
  "MUV",
  "Version",
  "MostRecentByPeriod",
  "MostRecentByDate"
];
export const gridPointValues = [
  "Month",
  "Year",
  "Quarter",
  "Season",
  "Day",
  "Week",
  "Weekend",
];
export const timeSeriesDateTypeValues = [
  "Date Range",
  "Relative",
  "Period From/To",
  "Period",
];
export const fillerValues = [
  "Null",
  "CustomValue",
  "LatestValidValue",
  "NoFill",
];
