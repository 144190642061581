import React from "react";
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";
import { compose } from "ramda";
import { withProps } from "recompose";
import { CurvePreviewModal } from "../../curvePreview";
import {
  toggleCurvePreviewAction,
  getPreviewTableAction,
} from "../../redux/modules/curvePreview";
import { curveTableMapTypeNameFn } from "./helper";
import MarketAssessmentPreviewTable from "./components/marketAssessmentPreviewTable";
import AuctionPreviewTable from "./components/auctionPreviewTable";
import TimeSeriesPreviewTable from "./components/timeSeriesPreviewTable";
import CurvePreviewModalSpinner from "./components/curvePreviewModalSpinner";

const CurvePreviewMsg = ({ msg }) => (
  <div className="curvePreviewMsg">{msg}</div>
);

const previewTable = (props) => {
  switch (props.curveType) {
    case "MarketAssessment":
      return (
        <MarketAssessmentPreviewTable
          data={props.curvePreviewTable}
          dataFormatFn={props.dataFormatFn}
        />
      );
    case "Auction":
      return (
        <AuctionPreviewTable
          data={props.curvePreviewTable}
          dataFormatFn={props.dataFormatFn}
        />
      );
    default:
      return (
        <TimeSeriesPreviewTable
          data={props.curvePreviewTable}
          dataFormatFn={props.dataFormatFn}
        />
      );
  }
};

const getPreviewTableState = (props) => {
  switch (props.requestState) {
    case "FETCHINGDATA":
      return <CurvePreviewModalSpinner />;
    case "FETCHINGFAILED":
      return <CurvePreviewMsg msg="Request Failed" />;
    case "RECIEVEDDATA":
      return previewTable(props);
    case "RECIEVEDNODATA":
      return <CurvePreviewMsg msg="No Data" />;
    default:
      return <CurvePreviewMsg msg="No Data" />;
  }
};

const mapStateToProps = (state) => ({
  ...state.CurvePreview,
});
export default compose(
  connect(mapStateToProps, {
    toggleCurvePreview: toggleCurvePreviewAction,
    getPreviewTable: getPreviewTableAction,
  }),
  withProps(() => ({
    dataFormatFn: (cell) => (
      <Tooltip
        arrow
        touchHold
        position="bottom"
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
  })),
  withProps((props) => ({
    curveIDHeader: props.curveID,
    curveTableMapTypeName: curveTableMapTypeNameFn(props.curveType),
    drawPreviewTable: getPreviewTableState(props),
  }))
)(CurvePreviewModal);
