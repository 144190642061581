import React from "react";
import { isEmpty, isNil } from "ramda";
import { setErrorAction } from "../actions";

export const dispatchNetworkError = (err) => {
  const hasCustomMsg =
    isNil(err.response) ||
    isNil(err.response.ErrorMessage) ||
    isEmpty(err.response.ErrorMessage);

  if (err.status > 0) {
    if (err.status === 403) {
      return setErrorAction({
        title: "Error: ".concat(err.status),
        message: (
          <div>
            You do not have sufficient permissions to modify selected market data.
            <br />
            Contact support for more information.
          </div>
        ),
      });
    }
    return setErrorAction({
      title: "Error: ".concat(err.status),
      message: hasCustomMsg
        ? "Please refresh the page. If the issue persists, please contact support."
        : err.response.ErrorMessage,
    });
  }
  return setErrorAction({
    title: "Request Failed",
    message:
      "An error has occurred. Please refresh the page. If the issue persists, please contact support.",
  });
};

export default dispatchNetworkError;
