import React, { useState, useEffect } from "react";
import { InputNumberTextBox } from "../../../components";

const ImpactedPeriodComponent = ({ setPeriodFrom, setPeriodTo, setIsValid }) => {
  const [dayTo, setDayTo] = useState(0);
  const [monthTo, setMonthTo] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [dayFrom, setDayFrom] = useState(0);
  const [monthFrom, setMonthFrom] = useState(0);
  const [yearFrom, setYearFrom] = useState(0);

  useEffect(() => {
    if (dayFrom || monthFrom || yearFrom) {
      setPeriodFrom(`P${yearFrom}Y${monthFrom}M${dayFrom}D`);
      setIsValid(yearFrom+((monthFrom/12)+(dayFrom/365)) >= yearTo+((monthTo/12)+(dayTo/365)));
    }
    if (yearTo || monthTo || dayTo) {
      setPeriodTo(`P${yearTo}Y${monthTo}M${dayTo}D`);
      setIsValid(yearFrom+((monthFrom/12)+(dayFrom/365)) >= yearTo+((monthTo/12)+(dayTo/365)));
    }
  }, [dayFrom, monthFrom, yearFrom, yearTo, monthTo, dayTo]);
  return (
    <div className="groupBox">
      <div>Impacted Period From</div>
      <div className="periodComponent">
        <div>Y</div>
        <div>M</div>
        <div>D</div>
      </div>

      <div className="periodComponent">
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={yearFrom}
          onChange={(val) => {
            setYearFrom(val);
          }}
        />
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={monthFrom}
          onChange={(val) => {
            setMonthFrom(val);
          }}
        />
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={dayFrom}
          onChange={(val) => {
            setDayFrom(val);
          }}
        />
      </div>
      <br />
      <div>Impacted Period to</div>

      <div className="periodComponent">
        <div>Y</div>
        <div>M</div>
        <div>D</div>
      </div>
      <div className="periodComponent">
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={yearTo}
          onChange={(val) => {
            setYearTo(val);
          }}
        />
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={monthTo}
          onChange={(val) => {
            setMonthTo(val);
          }}
        />
        <InputNumberTextBox
          className="artesianInputNumberTextBox periodTextBox"
          value={dayTo}
          onChange={(val) => {
            setDayTo(val);
          }}
        />
      </div>
      <br />
      <div>
        {`${
          yearFrom + (monthFrom / 12 + dayFrom / 365) >=
          yearTo + (monthTo / 12 + dayTo / 365)
            ? "Impacted Period To offset needs to be higher than Impacted Period From"
            : ""
        }
                    `}
      </div>
    </div>
  );
};

export default ImpactedPeriodComponent;
