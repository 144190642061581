import React from "react";
import Button from "./button";
import { auth } from "../appContext";
import HashParser from "./hashParser";
import ModalAlert from "./modalAlert";
import { useState } from "react";
import {SignInButton} from "./signInButton";
import icon from "../styles/images/Artesian_Logo_Main.png";
import {signUpRequest} from '../authConfig'

const Login = (props) => {
  const [modal, showModal] = useState(false);

  if (props.location.search) {
    return <HashParser />;
  }

  return (
    <div className="container-fluid noPadding">
      <div className="artesianLoginScreen">
        <div className="col-12">
          <div className="artesianLogoLogin">
            <div className="artesianLogoMain" />
          </div>
        </div>
        <div className="col-12">
          <div className="loginContainerInfo">
            <div>{props.location.state}</div>
            <div className="buttonContainer">
              <SignInButton />
              {signUpRequest?.authority &&
               signUpRequest?.authority !== '' &&
              <Button
                className="loginBtn"
                onClick={() => showModal(true)}
                btnText="Sign Up"
              />
              }
              {modal && (
                <ModalAlert
                  showModal
                  closeModal={() => showModal(false)}
                  confirm={() =>
                    auth.login(signUpRequest)
                  }
                  modalTitle={<img src={icon} alt="logo"></img>}
                  modalMessage="Sign Up for a 30 day free trial"
                ></ModalAlert>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
