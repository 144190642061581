import * as RX from "rxjs";
import { ajax } from "rxjs/ajax";
import platform from "platform";
import * as RxOp from "rxjs/operators";
import EnvironmentSettings from "../../../environment";

export const request = ({ token, api, userDefined }) =>
  ajax({
    url: `${api}/v2.1/timeTransform/entity?page=1&pageSize=50&userDefined=${userDefined}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const requestVersioned = ({ token, api, id, pageSize, pageNo }) =>
  ajax({
    url: `${api}/v2.1/marketdata/entity/${id}/curves?page=${pageNo}&pageSize=${pageSize}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const requestExcelBlob = ({ excelType, templateName, type, fileType = "xlsx" }) =>
  ajax({
    url: `${EnvironmentSettings["UI.curveExcelBlob"]}${templateName}.${fileType}`,
    responseType: "blob",
  }).pipe(RxOp.map((x) => x.response));

export const requestSystemDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "false" });

export const requestUserDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "true" });

export const downloadXLSXFile = (url, token) => () =>
  RX.Observable.create((obs) => {
    const xMLHttpRequest = new XMLHttpRequest();
    xMLHttpRequest.open("GET", url, true);
    xMLHttpRequest.responseType = "blob";
    xMLHttpRequest.setRequestHeader("Authorization", `Bearer ${token}`);
    xMLHttpRequest.setRequestHeader(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    xMLHttpRequest.onload = function RequestLoad() {
      if (xMLHttpRequest.status >= 400) return obs.error(xMLHttpRequest);
      // eslint-disable-next-line
      let response = xMLHttpRequest.response;
      if (typeof response === "string") {
        try {
          response = JSON.parse(xMLHttpRequest.response);
        } catch (e) {
          // eslint-disable-next-line
          response = xMLHttpRequest.response;
        }
      }
      obs.next(response);
      obs.complete();
    };
    xMLHttpRequest.error = function RequestError(e) {
      obs.error(e.message);
    };
    xMLHttpRequest.send();
  });

const getCurveVersions = ({ api, token, id, page, pageSize, versionFrom, versionTo }) =>
  ajax({
    url: `${api}/v2.1/marketdata/entity/${id}/curves?page=${page}&pageSize=${pageSize}&versionFrom=${versionFrom}&versionTo=${versionTo}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllCurveVersions = ({ token, api, id, versionFrom, versionTo }) => {
  const info = {
    token,
    api,
    page: 1,
    pageSize: 500,
    versionFrom,
    versionTo,
    id,
  };
  return getCurveVersions(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getCurveVersions({
            ...info,
            page: val.Page + 1
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};
