import React from "react";
import { SearchTextBox, Spinner } from "./components";
import PaginationComponent from "../components/tableComponents/tablePagination";
import * as R from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip } from "react-tippy";
import { Button } from "../components";
import { getStatusColour } from "../expectationAlertDashboard/helper";
import moment from "moment";

const expectationMonitorPage = "dashboard";

const expectationMonitorHisoryPage = "history";
const expectationMonitorHisoryDetailsPage = "historyDetails";

const HeaderButton = ({ title, onClick }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{title}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="filterBtn"
      onClick={() =>onClick()}
      btnText={<span className="filterBtnText">{title}</span>}
    />
  </Tooltip>
);

export default function HistoryDetailsPage(props) {
  return (
    <React.Fragment>
      <div className="artesianTableContainer">
        <div className="tableFilter">
          <div>
            <HeaderButton
            onClick={() => props.setPage(expectationMonitorHisoryPage)}
            title="Back"
          />
          <HeaderButton
            onClick={() => { props.parentProps.setPageNoAction(1); props.parentProps.setPageSizeAction(50); props.setPage(expectationMonitorPage)}}
            title="Back to dashboard"
          />
          </div>
        </div>
        {R.pathOr(
          false,
          ["parentProps", "info", "data", "isFetching"],
          props
        ) ? (
          <Spinner />
        ) : (
          <Scrollbars
            className="scrollFacets"
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            <div className="artesianTableTitleHeader">
              {R.pathOr([], ["parentProps", "info", "history", "data", 0, "ExpectationAlertName"], props)} Details, Check Time: {moment(props.selectedHistoryId).format("DD-MM-YYYY HH:mm:ss")}, Timezone: {R.pathOr([], ["parentProps", "info", "history", "data", 0, "CheckTimeZone"], props)}, Impacted Timezone: {R.pathOr([], ["parentProps", "info", "history", "data", 0, "ImpactedDataRangeTMZ"], props)}
            </div>
            
            <table className="artesianTable">
                <thead>
                  <tr>
                    <th />
                    <th>Status</th>
                    <th>MarketDataName</th>
                    <th>MarketDataID</th>
                    <th>Arrived At</th>
                  </tr>
                </thead>
              <tbody>
                {R.map((x) => (
                  <React.Fragment key={x.ID}>
                    
                    {R.map((y) => (
                    
                    <React.Fragment>
                        {props.selectedHistoryId == R.path(["CheckTime"])(x) ? 
                            <tr key={y.MarketDataID}>
                                <td>
                                    <div className="tableExpectationIcon">
                                    <i
                                        onClick={() =>
                                        props.parentProps.openModal(
                                            "Delete Alert",
                                            true,
                                            x.ID,
                                            "deleteAlert"
                                        )
                                        }
                                        className={`fa fa-trash`}
                                    />
                                    </div>
                                </td>
                                <td>{getStatusColour(y.Status)}</td>
                                <td>{y.MarketDataName}</td>
                                <td>{y.MarketDataID}</td>
                                <td>
                                  {R.path(["ArrivedAt"])(y)
                                    ? moment(
                                        R.path([
                                          "ArrivedAt",
                                        ])(y)
                                      ).format("DD-MM-YYYY HH:mm:ss")
                                    : null}
                                </td>
                            </tr>
                        :
                        <React.Fragment></React.Fragment>
                        }
                    </React.Fragment>
              ))(
                  (R.path(["MarketDataList"])(x)) 
              )}     
                  </React.Fragment>
                ))(
                  
                  R.pathOr([], ["parentProps", "info", "history", "data"], props)
                )}
              </tbody>
            </table>
          </Scrollbars>
        )}
      </div>
    </React.Fragment>
  );
};
