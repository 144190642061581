import { combineReducers } from "redux";
import * as R from "ramda";
import * as RX from "rxjs";
import * as RxOp from "rxjs/operators";
import { flatMap, tap, delay } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import { fetchingReducerCreator } from "../../reducerCreators";
import { dispatchNetworkError, dispatchNetworkErrorAddAlert } from "./helper";
import {
  requestData,
  deleteAlertRequest,
  addAlertRequest,
  deleteMarketDataIdsRequest,
  addMarketDataIdsRequest,
  requestHistoricalDataRequest,
} from "./requests";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector } from "../tenantSelection";

const FETCHING = "expectation-monitor/FETCHING";
const RECIEVED = "expectation-monitor/RECIEVED";
const POLLING = "expectation-monitor/POLLING";
const SET_PAGE_SIZE = "expectation-monitor/SET_CURVES_PAGE_SIZE";
const SET_PAGE_NO = "expectation-monitor/SET_CURVES_PAGE_NO";
export const SET_DASHBOARD_FILTER = "expectation-monitor/DASHBOARD_FILTER";
export const MODAL_VISIBILITY = "expectation-monitor/MODAL_VISIBILITY";
const RESET_MODAL = "expectation-monitor/RESET_MODAL";
const FETCHING_HISTORY = "expectation-monitor/FETCHING_HISTORY";
const RECIEVED_HISTORY = "expectation-monitor/RECIEVED_HISTORY";
const SET_HISTORY_PAGE_SIZE = "expectation-monitor/SET_HISTORY_PAGE_SIZE";
const SET_HISTORY_PAGE_NO = "expectation-monitor/SET_HISTORY_PAGE_NO";
export const ADD_NEW_ALERT = "expectation-monitor/ADD_NEW_ALERT";
export const DELETE_NEW_ALERT = "expectation-monitor/DELETE_NEW_ALERT";
export const ADD_CURVES_ALERT = "expectation-monitor/ADD_CURVES_ALERT";

const INITIAL_MODAL = "expectation-monitor/INITIAL_HISTORY";
const DELETING_MODAL = "expectation-monitor/DELETING_HISTORY";
const DELETED_MODAL = "expectation-monitor/DELETED_HISTORY";
const DELETE_MODAL_ERROR = "expectation-monitor/DELETE_MODAL_ERROR";

const modalInitialState = {
  visible: false,
  title: "",
  id: null,
  modalType: null,
  state: "initial",
  errorMsg: "",
};
const tableInitialState = {
  page: 1,
  pageSize: 50,
  count: 0,
  filter: "",
  isFetching: false,
  isPolling: false,
  data: [],
};
const historicalAlertReducer = (state = tableInitialState, action) => {
  switch (action.type) {
    case FETCHING_HISTORY:
      return { state, isFetching: true };
    case RECIEVED_HISTORY:
      return {
        ...state,
        data: action.response.Data,
        count: action.response.Count,
        isFetching: false,
      };
    case SET_HISTORY_PAGE_SIZE:
      return action.result;
    case SET_HISTORY_PAGE_NO:
      return { ...state, page: action.pageNo };
    default:
      return state;
  }
};
const expectationMonitorReducer = (state = tableInitialState, action) => {
  switch (action.type) {
    case FETCHING:
      return { ...state, isFetching: true };
    case POLLING:
      return { ...state, isPolling: true };
    case RECIEVED:
      return {
        ...state,
        data: action.response.Data,
        count: action.response.Count,
        isFetching: false,
        isPolling: false,
      };
    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.pageSize };
    case SET_PAGE_NO:
      return { ...state, page: action.pageNo };
    case SET_DASHBOARD_FILTER:
      return { ...state, filter: action.filter };
    default:
      return state;
  }
};
const modalReducer = (state = modalInitialState, action) => {
  switch (action.type) {
    case RESET_MODAL:
      return { ...state, visible: false, title: "", id: null, modalType: null, state: "initial", errorMsg:"" };
    case DELETING_MODAL:
      return { ...state, state: "fetching" };
    case DELETED_MODAL:
      return { ...state, state: "initial" };
    case DELETE_MODAL_ERROR: 
      return { ...state, state: "error", errorMsg: R.pathOr("", ["error","response","detail"], action)};
    case MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.visible,
        title: action.title,
        id: action.id,
        modalType: action.modalType,
        state: "initial",
        errorMsg:""
      };
    }
    default:
      return state;
  }
};
export const reducer = combineReducers({
  isfetching: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
  }),
  modal: modalReducer,
  history: historicalAlertReducer,
  data: expectationMonitorReducer,
});
const fetchingData = () => ({
  type: FETCHING,
});
const pollingData = () => ({
  type: POLLING,
});
const receivedData = (response) => ({
  type: RECIEVED,
  response,
});
const resetModal = () => ({
  type: RESET_MODAL,
});

const deletingAlertModal = () => ({
  type: DELETING_MODAL,
});

const deletedAlertModal = () => ({
  type: DELETED_MODAL,
});

const deleteNetworkError = (error) => ({
  type: DELETE_MODAL_ERROR,
  error: error
})
const fetchingrHistoryData = () => ({
  type: FETCHING_HISTORY,
});
const receivedHistoryData = (response) => ({
  type: RECIEVED_HISTORY,
  response,
});

const setModalVisibility = (title, visible, id, modalType) => ({
  type: MODAL_VISIBILITY,
  title,
  visible,
  id,
  modalType,
});

const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE,
  pageSize,
});
const setPageNo = (pageNo) => ({
  type: SET_PAGE_NO,
  pageNo,
});
const setExpectationDashboardFilter = (filter) => ({
  type: SET_DASHBOARD_FILTER,
  filter,
});

const handleNetworkError = R.compose(
  R.ap([dispatchNetworkError, receivedData]),
  R.of
);

const handleDeleteNetworkError = R.compose(
  R.ap([deleteNetworkError, receivedData]),
  R.of
);

const handleModalNetworkError = R.compose(
  R.ap([dispatchNetworkError, receivedData, resetModal]),
  R.of
);
const handleModalNetworkErrorAddAlert = R.compose(
  R.ap([dispatchNetworkErrorAddAlert, receivedData, resetModal]),
  R.of
);

const tokenFromState = R.compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxOp.map((token) => ({
      token,
      api: TenantSelector.api(state),
      state,
    }))
  );

export const requestAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(R.compose(dispatch, fetchingData)),
      delay(1000),
      flatMap(({ token, api }) =>
        requestData({
          pageNo: getState().ExpectationMonitor.data.page,
          pageSize: getState().ExpectationMonitor.data.pageSize,
          filter: getState().ExpectationMonitor.data.filter,
          token,
          api,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, (x) => receivedData(x)),
      R.compose(dispatch, handleNetworkError)
    );
export const requestPollAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(R.compose(dispatch, pollingData)),
      delay(1000),
      flatMap(({ token, api }) =>
        requestData({
          pageNo: getState().ExpectationMonitor.data.page,
          pageSize: getState().ExpectationMonitor.data.pageSize,
          filter: getState().ExpectationMonitor.data.filter,
          token,
          api,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, (x) => receivedData(x)),
      R.compose(dispatch, handleNetworkError)
    );

export const requestHistoryAction = (alertID) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(R.compose(dispatch, fetchingrHistoryData)),
      delay(1000),
      flatMap(({ token, api }) =>
        requestHistoricalDataRequest({
          id: alertID,
          pageNo: getState().ExpectationMonitor.data.page,
          pageSize: getState().ExpectationMonitor.data.pageSize,
          token,
          api,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, (x) => receivedHistoryData(x)),
      R.compose(dispatch, handleModalNetworkError)
    );
export const requestNewAction = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(R.compose(dispatch, fetchingData)),
      delay(100),
      flatMap(({ token, api }) => {
        return requestData({
          pageNo: getState().ExpectationMonitor.data.page,
          pageSize: getState().ExpectationMonitor.data.pageSize,
          filter: getState().ExpectationMonitor.data.filter,
          token,
          api,
        });
      })
    )
    .subscribe(
      R.compose(dispatch, (x) => receivedData(x)),
      R.compose(dispatch, handleNetworkError)
    );

const deleteAlert = (id) => ({
  type: DELETE_NEW_ALERT,
  id,
});

export const deleteAlertAction = (alertID) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(() => dispatch(deleteAlert(alertID))),
      flatMap(() => tokenApiFromState(getState())),
      tap(() => dispatch(deletingAlertModal())),
      delay(100),
      flatMap(({ token, api }) => {
        return deleteAlertRequest({
          id: alertID,
          token,
          api,
        });
      })
    )
    .subscribe(
      R.compose(dispatch, resetDeleteModalAction),
      R.compose(dispatch, handleDeleteNetworkError)
    );

const addAlert = (details) => ({
  type: ADD_NEW_ALERT,
  details,
});
export const addAlertAction = (alertDetails) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(() => dispatch(addAlert(alertDetails))),
      flatMap(() => tokenApiFromState(getState())),
      tap(R.compose(dispatch, fetchingData)),
      flatMap(({ token, api }) => {
        return addAlertRequest({
          alertDetails,
          token,
          api,
        });
      })
    )
    .subscribe(
      R.compose(dispatch, resetModalAction),
      R.compose(dispatch,
        (error) => { 
          var errorTitle = error.response.title === "EXCEPTION_ALERT_LIMIT_EXCEEDED" ? error.response.detail : error.response.title
          error.response.title = errorTitle;
          return handleModalNetworkErrorAddAlert(error)})
    );

const addCurvesAlert = (id, marketDataIdList) => ({
  type: ADD_CURVES_ALERT,
  id,
  marketDataIdList,
});

export const addMarketDataIds = (id, marketDataIdList) => (
  dispatch,
  getState
) =>
  RX.of(getState())
    .pipe(
      tap(() => dispatch(addCurvesAlert(id, marketDataIdList))),
      flatMap(() => tokenApiFromState(getState())),
      tap(R.compose(dispatch, fetchingData)),
      flatMap(({ token, api }) => {
        return addMarketDataIdsRequest({
          id,
          marketDataIdList,
          token,
          api,
        });
      })
    )
    .subscribe(
      R.compose(dispatch, resetModalAction),
      R.compose(dispatch, handleModalNetworkError)
    );
const resetModalAction = R.compose(R.ap([requestNewAction, resetModal, deletedAlertModal, fetchingData, requestAction]), R.of);
const resetDeleteModalAction = R.compose(R.ap([requestNewAction, resetModal, deletedAlertModal]), R.of);

export const deleteMarketDataIdsAction = (id, marketDataIdList) => (
  dispatch,
  getState
) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(R.compose(dispatch, fetchingData)),
      flatMap(({ token, api }) => {
        return deleteMarketDataIdsRequest({
          id,
          marketDataIdList,
          token,
          api,
        });
      })
    )
    .subscribe(
      R.compose(dispatch, resetModalAction),
      R.compose(dispatch, handleModalNetworkError)
    );

export const setPageNoAction = (pageNo) => [setPageNo(pageNo), requestAction()];

export const setPageSizeAction = (pageSize) => [
  setPageSize(pageSize),
  setPageNo(1),
];
export const setExpectationDashboardFilterAction = (filter) => [
  setPageNo(1),
  setExpectationDashboardFilter(filter),
  requestAction(),
];
export const openModal = (title, visible, id, modalType) => [
  setModalVisibility(title, visible, id, modalType),
];

export const epic = combineEpics();

export const Selectors = {
  ExpectationMonitor: R.path(["ExpectationMonitor"]),
  getSelectedCurves: R.path(["Curves", "selectedCurves", "curves"]),
};
