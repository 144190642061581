import React from "react";
import { connect } from "react-redux";
import {
  compose,
  length,
  lt,
  toPairs,
  isEmpty,
  equals,
  contains,
  any,
  not,
  keys,
  concat,
  head,
} from "ramda";
import { withHandlers, withProps, withState } from "recompose";
import { RefineCurves } from "../../refineCurves";
import { RemoveSelectedOrder, RemoveAllSelectedOrder } from "./components";
import { RenderSizePerPageDropDown } from "../../components";
import {
  setPageNoAction,
  setPageSizeAction,
  filterRefineCurvesAction,
} from "../../redux/modules/refineCurves";
import {
  toggleTableRowSelectionAction,
  removeAllSelectedCurvesAction,
  aclMeAllPathsPermissionAction,
  openPermissionCheckModalAction,
  updateSelectedCurvesList,
} from "../../redux/modules/curves";

import { colDataFotmatFn, filterTable } from "./helper";
import {
  tutorialOnAction,
  tutorialOffAction,
} from "../../redux/modules/tutorial";

const mapStateToProps = (state) => ({
  ...state.RefineCurves,
  ...state.Settings,
  ...state.Curves,
});

export default compose(
  connect(mapStateToProps, {
    toggleTableRowSelectionAction,
    removeAllSelectedCurvesAction,
    setPageSizeAction,
    openPermissionCheckModalAction,
    updateSelectedCurvesList,
    setPageNoAction,
    aclMeAllPathsPermissionAction,
    tutorialOn: tutorialOnAction,
    tutorialOff: tutorialOffAction,
    filterRefineCurves: filterRefineCurvesAction,
  }),
  withState("filterVal", "updateFilterVal", []),
  withHandlers({
    checkAllPaths: (props) => () =>
      length(props.selectedCurves.curves) > 100
        ? props.openPermissionCheckModalAction()
        : props.aclMeAllPathsPermissionAction(),
    selectedFilters: (props) => (val) => {
      props.updateFilterVal(val);
      props.filterRefineCurves(val);
    },
    removeAllSelectedCurves: (props) => () => {
      props.removeAllSelectedCurvesAction();
      props.showNavigationModal();
      props.navHome();
    },
  }),
  withProps((props) => ({
    tagKeys: toPairs(props.createdTags),
    disableCheckAllCurvesBtn: length(props.selectedCurves.curves) > 500,
    containsPermissions: compose(
      not,
      contains("Permissions"),
      keys,
      head
    )(props.selectedCurves.curves),
    selectedCurveCount: length(props.selectedCurves.curves),
    selectedCurveData: isEmpty(props.filterVal)
      ? props.selectedCurves.curves
      : filterTable(props.selectedCurves.curves, props.filterVal),
    removeAllSelectedOrderBtn: <RemoveAllSelectedOrder {...props} />,
  })),
  withHandlers({
    removeNonEditable: (props) => () => {
      const filterCurves = props.selectedCurves.curves.filter((row) =>
        any(equals(true))([
          contains("Creator", row.Permissions),
          contains("Contributor", row.Permissions),
          contains("Editor", row.Permissions),
          contains("Owner", row.Permissions),
        ])
      );

      props.updateSelectedCurvesList(filterCurves);
    },
    removeAndCheckNavigation: (props) => (row) =>
      equals(props.selectedCurveCount, 1)
        ? props.removeAllSelectedCurves()
        : props.toggleTableRowSelectionAction(row, false),
  }),
  withProps((props) => ({
    tagKeysCount: length(props.tagKeys),
    containsData: lt(props.selectedCurveCount, 1),
    containsMarketAssessmentData: !props.selectedCurves.curves.some(
      (curve) => curve.Type === "MarketAssessment"
    ),
    containsVersionedTimeSeriesData: !props.selectedCurves.curves.some(
      (curve) => curve.Type === "VersionedTimeSerie"
    ),
    containsActualTimeSeriesData: !props.selectedCurves.curves.some(
      (curve) => curve.Type === "ActualTimeSerie"
    ),
    basicDropdownOptions: [
      { label: "Market Assessment", value: "MarketAssessment", filter: "Type" },
      { label: "Auction", value: "Auction", filter: "Type" },
      { label: "BidAsk", value: "BidAsk", filter: "Type" },
      { label: "Actual Time Series", value: "ActualTimeSerie", filter: "Type" },
      {
        label: "Versioned Time Series",
        value: "VersionedTimeSerie",
        filter: "Type",
      },
    ],
    permissionsDropdownOptions: [
      { label: "Read Only MarketData", value: "ReadOnlyCurves", filter: "Read" },
      { label: "Edit Only MarketData", value: "EditOnlyCurves", filter: "Edit" },
    ],
  })),
  withHandlers({
    setPageSize: (props) => (val) => props.setPageSizeAction(val),
    setPageNo: (props) => (val) => props.setPageNoAction(val),
    removeSelectedOrderBtn: (props) => (cell, row) => (
      <RemoveSelectedOrder key={row.MarketDataId} row={row} {...props} />
    ),
    permssionFormat: () => (cell, colType) => (
      <span
        className={`pathStatus ${
          contains(colType, cell) ? "access" : "denied"
        }`}
      />
    ),
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(
        props.refineCurvesTablesOptions.pageSize,
        props
      ),
  }),
  withProps((props) => ({
    tableSettings: {
      page: props.refineCurvesTablesOptions.pageNo,
      sizePerPage: props.refineCurvesTablesOptions.pageSize,
      onPageChange: props.setPageNo,
      onSizePerPageList: props.setPageSize,
      sizePerPageDropDown: props.renderDropDown,
      prePage: "Previous",
      nextPage: "Next",
    },
    dataFormat: (field, cell, row) => colDataFotmatFn(field, cell, row),
    filterDropdownOptions: props.containsPermissions
      ? props.basicDropdownOptions
      : concat(props.permissionsDropdownOptions, props.basicDropdownOptions),
  }))
)(RefineCurves);
