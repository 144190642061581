import React from "react";
import { Tooltip } from "react-tippy";
import { ContextMenuTrigger } from "react-contextmenu";

const CurvePreview = (row, props) => (
  <Tooltip
    arrow
    position="bottom"
    trigger="mouseenter"
    delay={800}
    followCursor={false}
    html={<span>Data Preview</span>}
    theme="arkDefaultTooltip"
  >
    <ContextMenuTrigger id="curveTableContextMenu" collect={() => ({ row })}>
      <div>
        <i
          className="fa fa-table tooltipIcon"
          aria-hidden="true"
          onClick={() => props.getPreviewTable(row)}
        />
      </div>
    </ContextMenuTrigger>
  </Tooltip>
);

export default CurvePreview;
