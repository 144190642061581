import React from "react";
import { SearchTextBox } from "./components";

const CurveTableFilter = (props) => (
  <div className="searchTextBoxContainer noPadding">
    <SearchTextBox {...props} />
  </div>
);

export default CurveTableFilter;
