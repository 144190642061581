import React from "react";
import { compose, join } from "ramda";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { withHandlers } from "recompose";
import { Tooltip } from "react-tippy";

const ClassificationTable = (props) => (
  <div className="height100 paddingLeft5">
    <div className="pageTitle">{props.headerTitle}</div>
    <BootstrapTable
      data={props.data}
      condensed
      printable
      pagination
      version="4"
      options={props.settings}
      expandableRow={() => true}
      expandComponent={props.nestedTable}
      expandColumnOptions={props.expandSettings}
      keyField="MarketDataId"
      containerClass="effectedCurvesContainerClass paddingRight"
      tableContainerClass="effectedCurvesTableContainer"
      bodyContainerClass="effectedCurvesTableBody effectedNested"
    >
      <TableHeaderColumn dataField="MarketDataId" hidden dataSort>
        MarketData ID
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="MarketDataName"
        dataSort
        dataFormat={props.curveNameFormat}
      >
        NAME
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="ProviderName"
        dataSort
        width="200"
        columnClassName="classifactionCol"
      >
        PROVIDER
      </TableHeaderColumn>
    </BootstrapTable>
  </div>
);

const enhancer = compose(
  withHandlers({
    curveNameFormat: () => (cell) => (
      <Tooltip
        arrow
        position="top"
        html={<span>{cell}</span>}
        theme="arkDefaultTooltip"
      >
        {cell}
      </Tooltip>
    ),
  }),
  withHandlers({
    nestedTable: (props) => (row) => (
      <div className="nestableTable">
        <BootstrapTable
          data={row.Tags}
          version="4"
          keyField="Key"
          containerClass="effectedCurvesContainerClass"
          tableContainerClass="effectedCurvesTableContainer"
          bodyContainerClass="nestedTableBody"
        >
          <TableHeaderColumn dataField="Key" dataSort>
            FACET
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Value"
            dataSort
            className="paddingLeft10"
            dataFormat={(cell) => props.curveNameFormat(join(", ", cell))}
          >
            LABEL
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    ),
  })
);

export default enhancer(ClassificationTable);
