import React from "react";
import { Redirect } from "react-router";
import { compose, withState, lifecycle } from "recompose";
import { taggedSum } from "daggy";
import { auth } from "../appContext";
import PortalSpinner from "../components/portalSpinner";

const ParsedState = taggedSum("ParsedState", {
  Parsing: [],
  Error: ["error"],
  Success: [],
});
const enhancer = compose(
  withState("parsing", "setParse", ParsedState.Parsing),
  lifecycle({
    componentWillMount() {
      auth
        .handleRedirect()
        .then(
          (s) => ParsedState.Success,
          (f) => ParsedState.Error(f)
        )
        .then((x) => this.props.setParse(x));
    },
  })
);

export default enhancer(({ parsing }) =>
  parsing.cata({
    Parsing: () => <PortalSpinner spinnerState />,
    Error: (err) => (
      <Redirect
        to={{
          pathname: "/Login",
          state: err.message,
        }}
      />
    ),
    Success: () => <Redirect to="" />,
  })
);
