import React from "react";
import moment from "moment";
import { compose, range, gt } from "ramda";
import { withState, withHandlers, withProps } from "recompose";
import {
  Button,
  DropDown,
  InputNumberTextBox,
  MultiSelect,
} from "../../../components";
import GasYearCheckboxes from "./gasYearCheckboxes";
import DatePicker from "./datePicker";
// import MultiSelect from "./multiSelect";

const buildMonthDropdown = (year) =>
  range(1, 13).map(
    (x) => `${moment((x < 10 ? "0" : "") + x, "MM").format("MMM")}-${year}`
  );

const buildWeekDropdown = (substring, year) =>
  range(1, 53).map((x) => `${substring}${(x < 10 ? "0" : "") + x}-${year}`);

const buildProductNameDropdown = (type, yearOffset, absoluteDayDate) => {
  const year = yearOffset;
  const shortYear = year.substring(2, 4);
  switch (type) {
    case "Month":
      return buildMonthDropdown(shortYear);
    case "Quarter":
      return [
        `Q1${shortYear}`,
        `Q2${shortYear}`,
        `Q3${shortYear}`,
        `Q4${shortYear}`,
      ];
    case "Year":
      return [`GasYr-${shortYear}`, `${year}`];
    case "Season":
      return [`Win-${shortYear}`, `Sum-${shortYear}`];
    case "Day":
      return [`${absoluteDayDate.format("dddYYYY-MM-DD")}`];
    case "Week":
      return buildWeekDropdown("Wk", shortYear);
    case "Weekend":
      return buildWeekDropdown("WkEnd", shortYear);
    default:
      return [];
  }
};

const Offset = (props) => (
  <div className="productContainer">
    <label>Offset</label>
    <InputNumberTextBox
      className="offsetInputNum"
      min={0}
      value={props.offset}
      onChange={props.setOffset}
    />
  </div>
);

const YearOffset = (props) => (
  <div className="productContainer">
    <label>Offset</label>
    <InputNumberTextBox
      placeholder={props.yearOffsetValid ? "Input Year" : "Invalid Year"}
      className={`offsetInputYear ${props.yearOffsetValid ? "" : "invalid"}`}
      value={props.yearOffset}
      onChange={props.setYearOffset}
    />
  </div>
);

const GridPoint = (props) => (
  <div className="productContainer">
    <label>Gridpoint</label>
    <DropDown
      dropDownValues={props.gridPointValues}
      selected={props.gridpoint}
      onClick={props.setGridPoint}
    />
  </div>
);

const Product = (props) => (
  <div className="productContainer">
    <label>Type</label>
    <DropDown
      dropDownValues={props.productTypesValues}
      selected={props.productType}
      onClick={props.setSelectedProduct}
    />
  </div>
);

const RelativeDatePicker = (props) => (
  <div className="productContainer">
    <label className="productLabel">Offset</label>
    <DatePicker
      selected={props.absoluteDayDate}
      onChange={props.setAbsoluteDate}
    />
  </div>
);

const ProductName = (props) => (
  <div className="productContainer">
    <label>Name</label>
    <MultiSelect
      name="MultiSelect"
      value={props.productNames}
      change={props.setProductNames}
      options={props.productNameOptions}
      disabled={!props.yearOffsetValid}
      noOptionsMessage={() => "No more products available"}
    />
  </div>
);

const yearOffsetCal = (props) => (
  <div className="productSelect">
    <Offset {...props} />
    <GasYearCheckboxes {...props} />
  </div>
);

const absolutDateProduct = (props) => (
  <div className="productSelect">
    <RelativeDatePicker {...props} />
    <ProductName {...props} />
  </div>
);

const absoluteYearOffProduct = (props) => (
  <div className="productSelect">
    <YearOffset {...props} />
    <ProductName {...props} />
  </div>
);

const selectedProductRelative = (props) => {
  switch (props.gridpoint) {
    case "Year":
      return yearOffsetCal(props);
    default:
      return <Offset {...props} />;
  }
};

const selectedProductAbsolute = (props) => {
  switch (props.gridpoint) {
    case "Day":
      return absolutDateProduct(props);
    default:
      return absoluteYearOffProduct(props);
  }
};

const selectedProdType = (props) => {
  switch (props.productType) {
    case "Relative":
      return <GridPoint {...props} />;
    case "Absolute":
      return <GridPoint {...props} />;
    default:
      return <div />;
  }
};

const selectedGridType = (props) => {
  switch (props.productType) {
    case "Relative":
      return (
        <div className="productDropdown">{selectedProductRelative(props)}</div>
      );
    case "Absolute":
      return (
        <div className="productDropdown">{selectedProductAbsolute(props)}</div>
      );
    default:
      return <div />;
  }
};

const ProductPicker = (props) => (
  <div>
    <div
      className={`${
        gt(props.productCount, 1) ? "productAddRemove" : "productAdd"
      } noPadding d-flex`}
    >
      <Button
        onClick={props.addProduct}
        btnText={<i className="fa fa-plus" />}
      />
      <Button
        className={`${gt(props.productCount, 1) ? "" : "hide"}`}
        onClick={props.removeItem}
        btnText={<i className="fa fa-minus" />}
      />
    </div>
    <div className="product">
      <div className="productDropdown">
        <Product {...props} />
      </div>
      <div className="productDropdown">{selectedProdType(props)}</div>
      {selectedGridType(props)}
    </div>
  </div>
);

const enhancer = compose(
  withState("yearOffsetValid", "updateYearOffsetValid", true),
  withHandlers({
    setSelectedProduct: (props) => (val) => {
      props.updateProduct(props.value, { productNames: [], productType: val });
    },
    setGridPoint: (props) => (val) => {
      props.updateProduct(props.value, { productNames: [], gridpoint: val });
    },
    setOffset: (props) => (val) => {
      props.updateProduct(props.value, { productNames: [], offset: val });
    },
    setYearOffset: (props) => (val) => {
      props.updateProduct(props.value, { productNames: [], yearOffset: val });
      props.updateYearOffsetValid(moment(val, "YYYY", true).isValid());
    },
    setGY: (props) => () => {
      props.updateProduct(props.value, {
        productNames: [],
        gasYearChecked: !props.gasYearChecked,
      });
    },
    setCal: (props) => () => {
      props.updateProduct(props.value, {
        productNames: [],
        calYearChecked: !props.calYearChecked,
      });
    },
    setAbsoluteDate: (props) => (date) => {
      props.updateProduct(props.value, {
        productNames: [],
        absoluteDayDate: date,
      });
    },
    setProductNames: (props) => (val) => {
      props.updateProduct(props.value, { productNames: val });
    },
  }),
  withProps((props) => ({
    productNameOptions: props.yearOffsetValid
      ? buildProductNameDropdown(
          props.gridpoint,
          props.yearOffset,
          props.absoluteDayDate
        ).map((x) => ({ value: x, label: x }))
      : [],
  }))
);

export default enhancer(ProductPicker);
