import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateRange = (props) => (
  <DatePicker
    className="datePicker"
    selected={props.selected}
    dateFormat="YYYY-MM-DD"
    onChange={props.onChange}
  />
);

export default DateRange;
