import React from "react";
import {
  toolTipStyleNoMargin,
  toolTipStyleTopMargin,
  toolTipStyleLeftMargin,
} from "./tooltipStyles";

export const enrichMetadataTutorial = [
  // GOTO previous page.
  {
    selector: '[class="btn  menuBtnItem Extract "]',
    action: (node) => node.click(),
    content: <div />,
  },
  {
    position: "center",
    content: (
      <div>
        <h3>Enrich Metadata Tutorial</h3>
        On this page, the metadata can be updated for all selected market data at
        once.
        <br />
        If you do not select a change and the selected value from the dropdown
        reads &quot;None Selected&quot;, then no enrichment to the market data will
        occur for the field and will remain as previous
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
  // Timezone
  {
    selector: '[class="d-flex width100 noPadding EnrichCurveTimezone"]',
    position: "bottom",
    content: (
      <div>
        <h3>Update Timezone</h3>
        Update the market data timezone metadata.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Time Transform
  {
    selector: '[id="EnrichCurveTimeTransform"]',
    position: "bottom",
    content: (
      <div>
        <h3>Time Transform</h3>
        Add, update or remove time transforms from the market data. To remove a time
        transform, select &quot;No Time transform&quot;.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Aggregation
  {
    selector: '[class="d-flex width100 noPadding EnrichCurveAggregation"]',
    position: "bottom",
    content: (
      <div>
        <h3>Update Aggregation</h3>
        Update the market data Aggregation.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Description TextArea
  {
    selector: '[class="col-lg-12 col-md-12 col-sm-12 separator"]',
    position: "bottom",
    content: (
      <div>
        <h3>Update Description</h3>
        Update the Description for the market data selected. (MAX CHAR: 1000).
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Apply Button
  {
    selector: '[id="applyEnrichmentBtn"]',
    position: "bottom",
    content: (
      <div>
        <h3>Apply Enrichment</h3>
        When you want to apply the changes to the metadata of your selected
        market data, click this button to apply changes to all market data.
      </div>
    ),
    style: toolTipStyleTopMargin,
  },
  // Back to enrich button
  {
    selector: '[class="btn active menuBtnItem Enrich "]',
    position: "bottom",
    content: (
      <div>
        <h3>Go to Enrich Time Transfom Page</h3>
        Click &nbsp;
        <i className="fa fa-arrow-right" />
        &nbsp; to continue
      </div>
    ),
    action: (node) => node.click(),
    style: toolTipStyleTopMargin,
  },
  {
    selector: '[id="MenuEnrichTimeTransform"]',
    content: <div />,
    action: (node) => node.click(),
  },
];

export default enrichMetadataTutorial;
