export const granularityValues = [
  "TenMinute",
  "FifteenMinute",
  "Hour",
  "Day",
  "Week",
  "Month",
  "Season",
  "Year",
];
export default granularityValues;
