import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import platform from "platform";
import { todaysDate, yesterdaysDate, lastWeekDate } from "./helper";
import { Selectors } from "../tenantSelection";

const getUrl = (row, api) => {
  switch (row.Type) {
    case "MarketAssessment":
      return `${api}/query/v1.0/mas/${lastWeekDate}/${todaysDate}?id=${row.MarketDataId}&p=M%2B1&p=GY%2B1&p=Cal%2B1&p=Q%2B1&p=S%2B1&p=D%2B1&p=WkEnd%2B1`;
    case "ActualTimeSerie":
      return `${api}/query/v1.0/ts/${row.OriginalGranularity}/${yesterdaysDate}/${todaysDate}?id=${row.MarketDataId}`;
    case "Auction":
      return `${api}/query/v1.0/auction/${row.DataRangeStart ||
        yesterdaysDate}/${row.DataRangeEnd || todaysDate}?id=${
        row.MarketDataId
      }`;
    default:
      return `${api}/query/v1.0/vts/MUV/${row.OriginalGranularity}/${yesterdaysDate}/${todaysDate}?id=${row.MarketDataId}`;
  }
};

export const requestData = (state, token, row) =>
  ajax({
    url: getUrl(row, Selectors.api(state)),
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(map((x) => x.response));

export default requestData;
