import React from "react";
import {
  compose,
  map,
  prop,
  contains,
  equals,
  filter,
  any,
  flatten,
} from "ramda";
import { Tooltip } from "react-tippy";

const curveTableMapTypeNameFn = (type) => {
  if (type === "VersionedTimeSerie") {
    return "Versioned Time Series";
  } else if (type === "ActualTimeSerie") {
    return "Actual Time Series";
  } else if (type === "MarketAssessment") {
    return "Market Assessment";
  }
  return type || "Not Classified";
};

export const colDataFotmatFn = (field, cell, row) => {
  switch (field) {
    case "Type":
      return curveTableMapTypeNameFn(cell);
    case "AggregationRule":
      return row.Type === "MarketAssessment" ? "" : cell;
    case "MarketDataName":
      return (
        <Tooltip
          arrow
          touchHold
          position="bottom"
          html={<span> {cell} </span>}
          theme="arkDefaultTooltip"
        >
          {cell}
        </Tooltip>
      );
    default:
      return cell;
  }
};

export const filterTable = (data, filterRes) => {
  const filterTypes = map(prop("filter"), filterRes);
  const typeFilters = compose(
    map(prop("value")),
    filter((x) => equals(x.filter, "Type"))
  )(filterRes);

  if (contains("Read", filterTypes) && contains("Edit", filterTypes)) {
    return data;
  }
  let res = data;
  filterTypes.map((t) => {
    if (equals(t, "Read")) {
      res = filter(
        (row) =>
          contains("Reader", row.Permissions) &&
          equals(row.Permissions.length, 1),
        res
      );
    }
    if (equals(t, "Edit")) {
      res = filter(
        (row) =>
          any(equals(true))([
            contains("Creator", row.Permissions),
            contains("Contributor", row.Permissions),
            contains("Editor", row.Permissions),
            contains("Owner", row.Permissions),
          ]),
        res
      );
    }
    if (equals(t, "Type")) {
      res = compose(
        flatten,
        map((f) => filter((z) => equals(z.Type, f), res))
      )(typeFilters);
    }
    return true;
  });
  return res;
};
