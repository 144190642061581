import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

const toolTipTextHandler = ({ disabled, hasFilterChanged }) => {
  if (!hasFilterChanged) {
    return (
      <div>
        <i className="fa fa-exclamation-triangle yellow" />
        &nbsp;Selected Filter result has changed <br /> Please update/remove
        changes to extract
      </div>
    );
  }

  if (disabled) {
    return (
      <div>
        <i className="fa fa-exclamation-triangle red" /> No Filter Selected
      </div>
    );
  }

  return <span>Extract Selected Filter</span>;
};

const ClearFilterBtn = ({ extractFilterFunc, disabled, hasFilterChanged }) => (
  <Tooltip
    arrow
    position="bottom"
    html={toolTipTextHandler({ disabled, hasFilterChanged })}
    theme="arkDefaultTooltip"
  >
    <Button
      className="clearFiltersBtn"
      id="extractFiltersBtn"
      onClick={extractFilterFunc}
      disabled={disabled || !hasFilterChanged}
      btnText={
        <span>
          <i
            className={`fas fa-exclamation-triangle yellow ${
              hasFilterChanged ? "hide" : ""
            }`}
          />
          &nbsp;
          <i className="fa fa-bolt" /> Extract
          <span className="filterBtnText"> Filter</span>
        </span>
      }
    />
  </Tooltip>
);

export default ClearFilterBtn;
