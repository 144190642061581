import React from "react";
import * as R from "ramda";

const { compose, gt, prop, equals, map, filter, flatten, pipe, head } = R;

export const getTrName = (arr, id) =>
  arr.filter((x) => x.ID === id).map(prop("Name"));

const filterMapper = (val) => {
  switch (val) {
    case "mas":
      return ["MarketAssessment"];
    case "Auction":
      return ["Auction"];
    case "BidAsk":
      return ["BidAsk"];
    case "vts":
      return ["VersionedTimeSerie"];
    case "ats":
      return ["ActualTimeSerie"];
    case "atvs":
      return ["ActualTimeSerie", "VersionedTimeSerie"];
    default:
      return "";
  }
};

export const tableDisplay = (val, comn) => {
  switch (val) {
    case "displayTable":
      return comn;
    case "displaySpinner":
      return (
        <div className="spinnerOverlay fadeIn" id="overlay">
          <div className="loader curvePreview">
            <div className="box" />
            <div className="box" />
            <div className="box" />
            <div className="box" />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export const filterTable = (data, filterType) =>
  compose(
    flatten,
    map((f) => filter((z) => equals(z.Type, f), data)),
    filterMapper
  )(filterType);

export const componentDisplayHelper = (isCustomFilter, filterDisplay, count, com) => {
  if (isCustomFilter === true) return com;
  if (equals(filterDisplay, true)) {
    return null;
  }
  return gt(count, 0) ? com : null;
};

const hasComponentHelper = (filterDisplay, count) => {
  if (equals(filterDisplay, true)) {
    return false;
  }
  return gt(count, 0);
};

export const componentList = (props) => {
  const hasTsvAsTsComponent =
    gt(props.versionedTimeSeriesDataCount, 0) &&
    gt(props.actualTimeSeriesDataCount, 0);

  const hasTsvComponent = hasComponentHelper(
    props.filterDisplay,
    props.versionedTimeSeriesDataCount
  );

  const hasTsComponent = hasComponentHelper(
    props.filterDisplay,
    props.actualTimeSeriesDataCount
  );

  const hasExtractComponent = gt(props.auctionDataCount, 0);
  const hasMasComponent = gt(props.marketAssessmentDataCount, 0);
  const hasBidAskComponent = gt(props.bidAskDataCount, 0);

  const arr = [
    { name: "tsvAsTsComponent", res: hasTsvAsTsComponent },
    { name: "tsvComponent", res: hasTsvComponent },
    { name: "tsComponent", res: hasTsComponent },
    { name: "tsComponent", res: hasTsComponent },
    { name: "auctionExtractComponent", res: hasExtractComponent },
    { name: "bidAskExtractComponent", res: hasBidAskComponent },
    { name: "masComponent", res: hasMasComponent },
  ];

  return pipe(
    filter((x) => equals(x.res, true)),
    head,
    prop("name")
  )(arr);
};

export const getTypeData = ({ data, type }) =>
  R.reduce((a, b) => (b.Type === type ? R.append(b, a) : a), [], data);

export const getFacetTypeData = ({ data, type }) =>
  R.pipe(
    R.reduce((a, b) => (b.Value === type ? R.append(b, a) : a), []),
    R.pathOr(0, [0, "Count"])
  )(data);

export const getCurvesCount = ({ props, filterData, defaultData }) =>
  props.extractCustomFilterState
    ? props[filterData]
    : R.length(props[defaultData]);

export const curveSelectionError = ({ data, filters }) => {
  let resultGranularity = [];
  let resultTimezone = [];
  let resultTransform = [];
  let resultAggregationRule = [];

  // Granularity
  if (!R.isNil(filters.granularity)) {
    resultGranularity = R.reduce(
      (a, b) =>
        b.OriginalGranularity !== filters.granularity
          ? R.append(b.MarketDataId, a)
          : a,
      [],
      data
    );
  }

  // Timezone
  if (!R.isNil(filters.timezone)) {
    resultTimezone = R.reduce(
      (a, b) =>
        b.OriginalTimezone !== filters.timezone
          ? R.append(b.MarketDataId, a)
          : a,
      [],
      data
    );
  }
  // Transform
  if (!R.isNil(filters.transform) && !R.equals(filters.transform, "None")) {
    resultTransform = R.reduce(
      (a, b) => {
        if (R.isNil(b.Transform)) {
          return R.append(b.MarketDataId, a);
        }
        return b.Transform.Name !== filters.transform
          ? R.append(b.MarketDataId, a)
          : a;
      },
      [],
      data
    );
  }
  // AggregationRule only used with granularity, reason
  if (!R.isNil(filters.granularity)) {
    resultAggregationRule = R.reduce(
      (a, b) =>
        b.AggregationRule === "Undefined" &&
        filters.granularity !== b.OriginalGranularity
          ? R.append(b.MarketDataId, a)
          : a,
      [],
      data
    );
  }

  return {
    granularity: resultGranularity,
    timezone: resultTimezone,
    transform: resultTransform,
    aggregationRule: resultAggregationRule,
    all: R.pipe(
      R.flatten,
      R.uniq
    )([
      resultGranularity,
      resultTimezone,
      resultTransform,
      resultAggregationRule,
    ]),
  };
};
