import React from "react";
import * as R from "ramda";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { Tooltip } from "react-tippy";

export const DateRange = ({
  startDate,
  endDate,
  onChange,
  defaultStartDate,
  defaultEndDate,
}) => {
  function fromValid(checkDate) {
    const newDate = checkDate.toDate();
    return newDate < endDate.toDate() || newDate.toDateString() === endDate.toDate().toDateString();
  }

  function toValid(checkDate) {
    const newDate = checkDate.toDate();
    return newDate > startDate.toDate() || newDate.toDateString() === startDate.toDate().toDateString();
  }

  return (
    <Tooltip
      arrow
      html="Select from date from the first calendar and end date from the second calendar"
      theme="arkDefaultTooltip"
    >
      <div className="dateRangeHolder">
        <Datetime
          input={false}
          value={startDate} // Assuming startDate is a Moment object
          isValidDate={fromValid}
          onChange={(date) => {
            const newStartDate = date.toDate();
            const newEndDate = endDate.toDate();
            return onChange(
              getChangeValue({
                date: newStartDate,
                date2: newEndDate,
                defaultStartDate,
                defaultEndDate,
              })
            );
          }}
          timeFormat="HH:mm:ss"
          dateFormat="DD-MM-YYYY HH:mm:ss"
        />

        <Datetime
          input={false}
          value={endDate} // Assuming endDate is a Moment object
          minDate={startDate}
          isValidDate={toValid}
          onChange={(date) => {
            const newEndDate = date.toDate();
            const newStartDate = startDate.toDate();
            return onChange(
              getChangeValue({
                date: newStartDate,
                date2: newEndDate,
                defaultStartDate,
                defaultEndDate,
              })
            );
          }}
          timeFormat="HH:mm:ss"
          dateFormat="DD-MM-YYYY HH:mm:ss"
        />
      </div>
    </Tooltip>
  );
};

const getChangeValue = ({ date, date2, defaultStartDate, defaultEndDate }) => {
  if (R.isNil(date)) return [defaultStartDate, defaultEndDate];

  const startDate = moment(date);
  const endDate = moment(date2);

  if (startDate && endDate) {
    return [startDate, endDate];
  }

  if (startDate && endDate === null) {
    return [startDate, startDate.clone().add(1, "days")];
  }

  if (endDate && startDate === null) {
    return [endDate.clone().subtract(1, "days"), endDate];
  }

  return [defaultStartDate, defaultEndDate];
};

export default DateRange;
