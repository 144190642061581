import { combineReducers } from "redux";
import * as R from "ramda";
import { of } from "rxjs";
import * as RxOp from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { format, subDays } from "date-fns";
import { combineEpics } from "redux-observable";
import XLSX from "xlsx-populate/browser/xlsx-populate";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector } from "../tenantSelection";
import { getRequest, getAllRequests, searchBuilder } from "../helper";
import { dispatchNetworkError } from "./helper";
import EnvironmentSettings from "../../../environment";

export const EMPTY_DATA = "gmePublicOffers/EMPTY_DATA";
export const REQUEST_ENUM_OPERATORS = "gmePublicOffers/REQUEST_ENUM_OPERATORS";
export const SET_ENUM_OPERATORS = "gmePublicOffers/SET_ENUM_OPERATORS";
export const REQUEST_ENUM_UNITS = "gmePublicOffers/REQUEST_ENUM_UNITS";
export const SET_ENUM_UNITS = "gmePublicOffers/SET_ENUM_UNITS";

export const SET_GME_PUBLIC_OFFER_PAGE =
  "gmePublicOffers/SET_GME_PUBLIC_OFFER_PAGE";
export const SET_GME_PUBLIC_OFFER_PAGE_SIZE =
  "gmePublicOffers/SET_GME_PUBLIC_OFFER_PAGE_SIZE";
export const CLEAR_GME_PUBLIC_OFFER_FILERS =
  "gmePublicOffers/CLEAR_GME_PUBLIC_OFFER_FILERS";
export const CLEAR_ALL_GME_PUBLIC_OFFER_FILERS =
  "gmePublicOffers/CLEAR_ALL_GME_PUBLIC_OFFER_FILERS";
export const SET_GME_PUBLIC_OFFER_FILERS =
  "gmePublicOffers/SET_GME_PUBLIC_OFFER_FILERS";
export const GET_GME_PUBLIC_OFFER_URL =
  "gmePublicOffers/GET_GME_PUBLIC_OFFER_URL";
export const SET_GME_PUBLIC_OFFER_API =
  "gmePublicOffers/SET_GME_PUBLIC_OFFER_API";
export const SET_GME_PUBLIC_OFFER_DATA =
  "gmePublicOffers/SET_GME_PUBLIC_OFFER_DATA";
export const DOWNLOAD_GME_PUBLIC_OFFER =
  "gmePublicOffers/DOWNLOAD_GME_PUBLIC_OFFER";
export const COMPLETE_GME_PUBLIC_OFFER =
  "gmePublicOffers/COMPLETE_GME_PUBLIC_OFFER";
export const PREVIEW_GME_PUBLIC_OFFER =
  "gmePublicOffers/PREVIEW_GME_PUBLIC_OFFER";
export const SET_PREVIEW_GME_PUBLIC_OFFER_DATA =
  "gmePublicOffers/SET_PREVIEW_GME_PUBLIC_OFFER_DATA";
export const SET_PREVIEW_GME_PUBLIC_OFFER_PAGE =
  "gmePublicOffers/SET_PREVIEW_GME_PUBLIC_OFFER_PAGE";
export const PREVIEW_GME_PUBLIC_OFFER_CLOSE =
  "gmePublicOffers/PREVIEW_GME_PUBLIC_OFFER_CLOSE";
export const SET_ERROR = "gmePublicOffers/SET_ERROR";
export const CLOSE_ERROR = "gmePublicOffers/CLOSE_ERROR";
export const CANCEL = "gmePublicOffers/CANCEL";

const gmePublicOffersState = {
  isFetching: false,
  api: null,
  query: null,
  filters: {
    date: format(subDays(new Date(), 7), "yyyy-MM-dd"),
    purpose: "Bid",
    status: "ACC",
    operators: [],
    unit: [],
    market: [],
    scope: [],
    baType: [],
    zone: [],
    unitType: [],
    generationType: [],
  },
};

const setApiFilter = (filters) => {
  const fils = R.pipe(
    R.omit(["date", "purpose", "status"]),
    R.map(R.map(encodeURIComponent))
  )(filters);
  const queryString = searchBuilder({
    filters: fils,
  });
  return `/gmepublicoffer/v2.0/extract/${filters.date}/${filters.purpose}/${filters.status}?page=1&pageSize=999${queryString}`;
};

const gmePublicOffers = (state = gmePublicOffersState, action) => {
  switch (action.type) {
    case SET_GME_PUBLIC_OFFER_FILERS:
      return {
        ...state,
        filters: action.filters,
        query: setApiFilter(action.filters),
      };
    case SET_GME_PUBLIC_OFFER_API:
      return { ...state, api: action.api, query: setApiFilter(state.filters) };
    case CLEAR_ALL_GME_PUBLIC_OFFER_FILERS:
      return {
        ...state,
        filters: gmePublicOffersState.filters,
        query: setApiFilter(gmePublicOffersState.filters),
      };
    case CLEAR_GME_PUBLIC_OFFER_FILERS: {
      const filters = {
        ...state.filters,
        ...R.omit(["date", "purpose", "status"], gmePublicOffersState.filters),
      };
      return {
        ...state,
        filters,
        query: setApiFilter(filters),
      };
    }
    case DOWNLOAD_GME_PUBLIC_OFFER:
      return { ...state, isFetching: true };
    case COMPLETE_GME_PUBLIC_OFFER:
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const previewGmePublicOffersState = {
  page: 1,
  pageSize: 10,
  isFetching: false,
  open: false,
  data: [],
};

const previewGmePublicOffersReducer = (
  state = previewGmePublicOffersState,
  action
) => {
  switch (action.type) {
    case PREVIEW_GME_PUBLIC_OFFER:
      return { ...state, isFetching: true };
    case SET_PREVIEW_GME_PUBLIC_OFFER_DATA:
      return { ...state, isFetching: false, open: true, data: action.data };
    case PREVIEW_GME_PUBLIC_OFFER_CLOSE:
      return { ...state, open: false };
    case SET_PREVIEW_GME_PUBLIC_OFFER_PAGE:
      return { ...state, page: action.page };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const operatorEnumState = {
  isFetching: false,
  data: [],
};
const operatorEnumReducer = (state = operatorEnumState, action) => {
  switch (action.type) {
    case REQUEST_ENUM_OPERATORS:
      return { ...state, isFetching: true };
    case SET_ENUM_OPERATORS:
      return { ...state, isFetching: false, data: action.data };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const unitEnumState = {
  isFetching: false,
  data: [],
};
const unitEnumReducer = (state = unitEnumState, action) => {
  switch (action.type) {
    case REQUEST_ENUM_UNITS:
      return { ...state, isFetching: true };
    case SET_ENUM_UNITS:
      return { ...state, isFetching: false, data: action.data };
    case SET_ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
const errorInitialState = { error: false, title: "", message: "" };
export default function errorHandlerReducer(state = errorInitialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: true,
        title: action.details.title,
        message: action.details.message,
      };
    case CLOSE_ERROR:
      return errorInitialState;
    default:
      return state;
  }
}

export const reducer = combineReducers({
  data: gmePublicOffers,
  previewData: previewGmePublicOffersReducer,
  operatorEnum: operatorEnumReducer,
  unitEnum: unitEnumReducer,
  errorHandler: errorHandlerReducer,
});

const tokenFromState = R.compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxOp.map((token) => ({
      token,
      api: TenantSelector.api(state),
      state,
    }))
  );

export const setError = (details) => ({
  type: SET_ERROR,
  details,
});
export const closeError = () => ({
  type: CLOSE_ERROR,
});
export const cancel = () => ({
  type: CANCEL,
});
export const clearData = () => ({
  type: EMPTY_DATA,
});

export const setGmePublicOfferFilters = (filters) => ({
  type: SET_GME_PUBLIC_OFFER_FILERS,
  filters,
});
export const clearGmePublicOfferFilters = () => ({
  type: CLEAR_GME_PUBLIC_OFFER_FILERS,
});
export const clearAllGmePublicOfferFilters = () => ({
  type: CLEAR_ALL_GME_PUBLIC_OFFER_FILERS,
});
export const startDownlaodGmePublicOffer = () => ({
  type: DOWNLOAD_GME_PUBLIC_OFFER,
});

const setGmePublicOfferAPI = (api) => ({
  type: SET_GME_PUBLIC_OFFER_API,
  api,
});
const GmePublicOfferComplete = () => ({
  type: COMPLETE_GME_PUBLIC_OFFER,
});

const processFile = ({ url, excelType }) => (response) =>
  XLSX.fromDataAsync(response)
    .then((wk) => {
      const queries = wk.sheet("Queries");

      queries
        .row(2)
        .cell(1)
        .value(url);

      return wk;
    })
    .then((wk) => wk.outputAsync("arraybuffer"))
    .then((wbout) => {
      const blob = new Blob([wbout], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${excelType}.xlsx`);
      } else {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `${excelType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });

const requestExcelBlob = () =>
  ajax({
    url: `${EnvironmentSettings["UI.blobUrl"]}gme-excel/GmePublicOffersExtraction.xlsx`,
    responseType: "blob",
  }).pipe(RxOp.map((x) => x.response));

export const downloadGmePublicOffer = ({ url }) => (dispatch, getState) =>{
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startDownlaodGmePublicOffer())),
      RxOp.flatMap(() => requestExcelBlob())
    )
    .subscribe(
      R.compose(
        dispatch,
        GmePublicOfferComplete,
        processFile({
          url,
          excelType: "GmePublicOffersExtraction",
        })
      ),
      R.compose(dispatch, setError, dispatchNetworkError)
    );
}
export const getGmePublicOfferApi = () => (dispatch, getState) =>
  of(getState())
    .pipe(RxOp.mapTo(getState()), RxOp.flatMap(tokenApiFromState))
    .subscribe(({ api }) => dispatch(setGmePublicOfferAPI(api)), null);

export const startPreviewGmePublicOffer = () => ({
  type: PREVIEW_GME_PUBLIC_OFFER,
});
export const previewGmePublicOfferClose = () => ({
  type: PREVIEW_GME_PUBLIC_OFFER_CLOSE,
});
export const setPreviewGmePublicOfferPage = (page) => ({
  type: SET_PREVIEW_GME_PUBLIC_OFFER_PAGE,
  page,
});
const setPreviewGmePublicOfferData = (data) => ({
  type: SET_PREVIEW_GME_PUBLIC_OFFER_DATA,
  data,
});

export const previewGmePublicOffer = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startPreviewGmePublicOffer())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api, state }) => {
        const info = Selectors.all(state);
        const { filters } = info.data;
        const preview = info.previewData;
        return getRequest({
          api: `${api}/gmepublicoffer/v2.0/extract/${filters.date}/${filters.purpose}/${filters.status}`,
          token,
          page: preview.page,
          pageSize: preview.pageSize,
          filters: R.omit(["date", "purpose", "status"], filters),
        });
      })
    )
    .subscribe(
      R.compose(dispatch, (x) => setPreviewGmePublicOfferData(x.Data)),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

const startOperatorEnum = () => ({
  type: REQUEST_ENUM_OPERATORS,
});
const setOperatorEnum = (data) => ({
  type: SET_ENUM_OPERATORS,
  data,
});

export const requestOperatorEnum = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startOperatorEnum())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api }) =>
        getAllRequests({
          api: `${api}/gmepublicoffer/v2.0/enums/operators`,
          token,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, setOperatorEnum),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

const setUnitsEnum = (data) => ({
  type: SET_ENUM_UNITS,
  data,
});

export const requestUnitsEnum = () => (dispatch, getState) =>
  of(getState())
    .pipe(
      RxOp.map(() => dispatch(startOperatorEnum())),
      RxOp.mapTo(getState()),
      RxOp.flatMap(tokenApiFromState),
      RxOp.flatMap(({ token, api }) =>
        getAllRequests({
          api: `${api}/gmepublicoffer/v2.0/enums/units`,
          token,
        })
      )
    )
    .subscribe(
      R.compose(dispatch, setUnitsEnum),
      R.compose(dispatch, setError, dispatchNetworkError)
    );

export const epic = combineEpics();

export const Selectors = {
  all: R.path(["GmePublicOffers"]),
};
