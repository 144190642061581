import React from "react";
import { Tooltip } from "react-tippy";
import {
  contains,
  assoc,
  isNil,
  isEmpty,
  append,
  concat,
  compose,
  head,
  filter,
  equals,
} from "ramda";
import { ContextMenuTrigger } from "react-contextmenu";
import { TableTooltip } from "../../components";

export const refSort = (refData, facetData) =>
  // Sort array of indices according to the reference data.
  facetData.sort((indexA, indexB) => {
    const textA = indexA.FacetName.toUpperCase();
    const textB = indexB.FacetName.toUpperCase();
    for (let x = 0; x < refData.length; x += 1) {
      if (refData[x] === textA) return -1;
      else if (refData[x] === textB) return 1;
    }
    // selected cardinality sort
    if (indexA.SelectedCardinality < indexB.SelectedCardinality) {
      return 1;
    } else if (indexA.SelectedCardinality > indexB.SelectedCardinality) {
      return -1;
    } else if (indexA.Cardinality < indexB.Cardinality) {
      // cardinality sort
      return 1;
    } else if (indexA.Cardinality > indexB.Cardinality) {
      return -1;
    } else if (textA < textB) {
      // alphabetical sort
      return -1;
    } else if (textA > textB) {
      return 1;
    }
    return 0;
  });

export const cardinalities = (facets, selectedLabelList) =>
  facets.map((tag) => {
    let Cardinality = 0;
    let SelectedCardinality = 0;
    tag.Values.forEach((labels) => {
      if (
        labels.Value &&
        selectedLabelList[tag.FacetName] &&
        selectedLabelList[tag.FacetName].indexOf(labels.Value) > -1
      ) {
        SelectedCardinality += labels.Count;
      }
      if (labels.Value) {
        Cardinality += labels.Count;
      }
    });
    return { ...tag, SelectedCardinality, Cardinality };
  });

export const isLabelSelected = (list, selectedLabel) =>
  contains(selectedLabel, list || []);
export const mapTypeNameFn = (obj) => {
  if (obj.Value === "VersionedTimeSerie") {
    return assoc("DisplayName", "Versioned Time Series", obj);
  } else if (obj.Value === "ActualTimeSerie") {
    return assoc("DisplayName", "Actual Time Series", obj);
  } else if (obj.Value === "MarketAssessment") {
    return assoc("DisplayName", "Market Assessment", obj);
  }
  return assoc("DisplayName", obj.Value || "Not Classified", obj);
};
export const handleEmptyFacetFn = (props) => {
  if (isEmpty(props.data.Values)) {
    const selectedlabels = props.selectedlabels[props.data.FacetName];
    // eslint-disable-next-line
    props.data.Values = isNil(selectedlabels)
      ? [{ Value: "Not Classified", nonSelectable: true }]
      : selectedlabels.map((x) => ({ Value: x, Count: 0 }));
  }
};
export const displaySelectedFacetsFn = (props) => {
  if (!isEmpty(props.selectedlabels)) {
    const selectedlabels = props.selectedlabels[props.data.FacetName];
    if (!isNil(selectedlabels)) {
      selectedlabels.map((label) => {
        if (!props.data.Values.find((data) => data.Value === label)) {
          // eslint-disable-next-line
          return (props.data.Values = append(
            { Value: label, Count: 0 },
            props.data.Values
          ));
        }
        return props.data.Values;
      });
    }
  }
};

export const curveTableMapTypeNameFn = (type) => {
  if (type === "VersionedTimeSerie") {
    return "Versioned Time Series";
  } else if (type === "ActualTimeSerie") {
    return "Actual Time Series";
  } else if (type === "MarketAssessment") {
    return "Market Assessment";
  }
  return type || "Not Classified";
};

export const sortLabelListFn = (labels) =>
  labels.sort(
    (a, b) => b.Count - a.Count || a.DisplayName.localeCompare(b.DisplayName)
  );

const addRelavance = (props) => {
  if (!contains("Relevance", props.sortValues)) {
    props.updateSortValues(concat(["Relevance"], props.sortValues));
  }
  props.sortDropdownFn("Relevance");
};

export const updateDropdown = (props) =>
  isEmpty(props.filterText)
    ? props.setSortDropdownDefault("MarketData ID Asc")
    : addRelavance(props);

export const cellFormatter = (cell, row, field) => {
  switch (field) {
    case "infoCol":
      return TableTooltip(cell, row);
    case "Type":
      return curveTableMapTypeNameFn(cell);
    case "AggregationRule":
      return row.Type === "MarketAssessment" ? "" : cell;
    case "MarketDataName":
      return (
        <Tooltip
          arrow
          touchHold
          position="bottom"
          trigger="mouseenter"
          delay={800}
          followCursor={false}
          html={<span>{cell}</span>}
          theme="arkDefaultTooltip"
        >
          {cell}
        </Tooltip>
      );
    default:
      return cell;
  }
};
export const colDataFotmatFn = (cell, row, field) => (
  <ContextMenuTrigger id="curveTableContextMenu" collect={() => ({ row })}>
    {cellFormatter(cell, row, field)}
  </ContextMenuTrigger>
);

const ranges = [
  "\ud83c[\udf00-\udfff]", // U+1F300 to U+1F3FF
  "\ud83d[\udc00-\ude4f]", // U+1F400 to U+1F64F
  "\ud83d[\ude80-\udeff]", // U+1F680 to U+1F6FF
];

export const removeInvalidChars = (str) =>
  str.replace(new RegExp(ranges.join("|"), "g"), "");

export const checkIfFilterChanged = (
  selectedFilterId,
  allFilters,
  allLabels,
  filterText
) => {
  if (isNil(selectedFilterId)) {
    return true;
  }
  const selectedFilter = compose(
    head,
    filter((x) => equals(x.Id, selectedFilterId))
  )(allFilters);

  if (isNil(selectedFilter)) {
    return true;
  }

  const labelsCheck = compose(
    (x) => equals(allLabels, x),
    (row) =>
      row.Filters.reduce(
        (state, { Key, Value }) => ({ ...state, [Key]: Value }),
        {}
      )
  )(selectedFilter);

  const filterCheck = equals(selectedFilter.SearchText, filterText);
  return filterCheck && labelsCheck;
};
