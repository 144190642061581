import * as RX from "rxjs";
import platform from "platform";
import { ajax } from "rxjs/ajax";
import * as RxOp from "rxjs/operators";

export const requestData = ({
  pageSize,
  pageNo,
  sort,
  filterText,
  labels,
  token,
  api,
}) =>
  ajax({
    url: `${api}/v2.1/marketdata/searchfacet?pageSize=${pageSize}&page=${pageNo}${sort}${filterText}${labels}`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,  'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`
    },
  }).pipe(RxOp.map((x) => x.response));

export const requestAllCurvesData = ({
  tenant,
  countSize,
  sort,
  filterText,
  labels,
  token,
}) =>
  ajax({
    url: `${window.location.origin}/pl/requestAllCurves/${tenant}/${countSize}?${sort}${filterText}${labels}`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,  'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`
    },
  }).pipe(RxOp.map((x) => x.response));

export const classificationRequest = ({ bodyData, token, api }) =>
  ajax({
    url: `${api}/v2.1/marketdata/operations`,
    responseType: "json",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    },
    body: JSON.stringify(bodyData),
  });

export const createCustomFilterRequest = ({ filterData, token, api }) =>
  ajax({
    url: `${api}/v2.1/filter`,
    responseType: "json",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filterData),
  }).pipe(RxOp.map((x) => x.response));

export const requestAclMeAllPathsPermission = ({ token, tenant, curvePaths }) =>
  ajax({
    url: `${window.location.origin}/pl/aclMeAllPaths/${tenant}`,
    responseType: "json",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(curvePaths),
  }).pipe(RxOp.map((x) => x.response));

export const updateCustomFilterRequest = ({
  token,
  api,
  filterId,
  filterData,
}) =>
  ajax({
    url: `${api}/v2.1/filter/${filterId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filterData),
  }).pipe(RxOp.map((x) => x.response));

export const deleteCustomFilterRequest = ({ token, api, filterId }) =>
  ajax({
    url: `${api}/v2.1/filter/${filterId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` 
    },
  }).pipe(RxOp.map((x) => x.response));

const getCustomFilters = ({ api, token, page, pageSize }) =>
  ajax({
    url: `${api}/v2.1/filter?page=${page}&pageSize=${pageSize}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}` },
  }).pipe(RxOp.map((x) => x.response));

export const getAllCustomFilter = ({ token, api }) => {
  const info = {
    token,
    api,
    page: 1,
    pageSize: 500,
  };
  return getCustomFilters(info).pipe(
    RxOp.expand((val) => {
      const currResults = val.Page * info.pageSize;
      return currResults < val.Count
        ? getCustomFilters({
            ...info,
            page: val.Page + 1,
          })
        : RX.EMPTY;
    }),
    RxOp.map((x) => x.Data),
    RxOp.concatMap((x) => x),
    RxOp.toArray()
  );
};
