import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import flow from "redux-flo";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { createEpicMiddleware } from "redux-observable";
import { rootReducer, rootEpic } from "./modules";

const epicMiddleware = createEpicMiddleware();

export default function reduxStore(initialState, history) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
  const routing = routerMiddleware(history);
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, flow(), epicMiddleware, routing))
  );

  // if (module.hot) {
  //   module.hot.accept("./modules", () => {
  //     store.replaceReducer(require("./modules").rootReducer); // eslint-disable-line global-require
  //   });
  // }

  epicMiddleware.run((...args) =>
    rootEpic(...args).pipe(
      catchError(e => {
        console.error("Unhandled Redux Error"); // eslint-disable-line
        return of({ type: "REDUX_ERROR", info: e });
      })
    )
  );

  return store;
}
