import React from "react";

const NonOptimizedDisplay = ({ isIE }) => (
  <div className="nonOptimizedDisplay">
    {isIE ? (
      <span>
        Internet Explorer is not a supported browser. <br />
        Please try another browser for an improved experience.
      </span>
    ) : (
      <span>Artesian is not optimized for mobile or tablet devices.. </span>
    )}
  </div>
);

export default NonOptimizedDisplay;
