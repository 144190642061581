
import EnvironmentSettings from "./environment";
export const msalConfig = {
  auth: {
    clientId: EnvironmentSettings["UI.clientID"],
    authority:  EnvironmentSettings["UI.authority"],
    knownAuthorities: [EnvironmentSettings["UI.knownAuthorities"]],// You must identify your tenant's domain as a known authority.
    redirectUri: EnvironmentSettings["UI.redirectUri"]
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};
const scope = [EnvironmentSettings["UI.scopes"]]

export const loginRequest = EnvironmentSettings["UI.authConnection"] === "K4View" ?
  {
    scopes: scope,
    extraQueryParameters: { domain_hint: 'k4view' }
  } : {
    scopes: scope
  };

export const signUpRequest = {
  authority:  EnvironmentSettings["UI.authoritySignUp"],
}
