import React from "react";
import Tour from "reactour";

const ArtesianTut = (props) => (
  <Tour
    steps={props.steps}
    key={props.name}
    update={props.selectedTutotialPage}
    isOpen={props.state}
    onRequestClose={props.close}
    maskClassName="tourMaskStyle"
    highlightedMaskClassName="tourMaskPortalStyle"
    maskSpace={0}
    closeWithMask={false}
    showNavigationNumber={false}
    disableInteraction
    disableDotsNavigation
    showNavigation={false}
    showNumber={true}
    updateDelay={3}
    startAt={props.startAtStep}
    badgeContent={(curr, tot) => props.startAtStep ? `${curr - props.startAtStep}` : `${curr}` }
  />
);

export default ArtesianTut;
