import React from "react";

const ErrorsComp = ({ errors }) => (
  <div className="extractErrorsContainer">
    {errors.granularity.length > 0  ? (
      <div className="extractError">
        <div className="extractErrorWarning">
          <i className="fa fa-exclamation-circle" />
          From the selected market data, the selected granularity does not match with
          all, the data extracted will be transformed accordingly to your
          selection
        </div>
      </div>
    ) : null}
    {errors.timezone.length === 0 ? null : (
      <div className="extractError">
        <div className="extractErrorWarning">
          <i className="fa fa-exclamation-circle" />
          From the selected market data, the selected timezone does not match with
          all, the data extracted will be transformed accordingly to your
          selection
        </div>
      </div>
    )}
    {errors.transform.length === 0 ? null : (
      <div className="extractError">
        <div className="extractErrorWarning">
          <i className="fa fa-exclamation-circle" />
          From the selected market data, the selected transform does not match with
          all, the data extracted will be transformed accordingly to your
          selection
        </div>
      </div>
    )}

    {errors.granularity.length > 0 && errors.aggregationRule.length > 0 ? (
      <div className="extractError">
        <div className="extractErrorAlert">
          <i className="fa fa-exclamation-triangle" />
          The aggregation rule has not been defined and the selected granularity
          does not match with all your selected market data
        </div>
      </div>
    ) : null}
  </div>
);

export default ErrorsComp;
