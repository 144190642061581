import React from "react";
import { connect } from "react-redux";
import {
  compose,
  length,
  lt,
  toPairs,
  prop,
  unnest,
  map,
  uniq,
  isEmpty,
  filter,
  without,
  sortBy,
  toLower,
  propEq,
  contains,
} from "ramda";
import { withHandlers, withProps, withState } from "recompose";
import { CurvesClassifaction } from "../../curveClassification";
import {
  ClassificationDisplayLabel,
  EffectedClassificationTable,
} from "./components";
import {
  classificationRequestAction,
  removeAllSelectedCurvesAction,
} from "../../redux/modules/curves";
import {
  createClassificationTags,
  mapAllFacetsFn,
  filterTable,
  deleteClassificationFn,
  applyClassifyFn,
  removeInvalidChars,
} from "./helper";
import { EffectCurvesSizePerPageDropDown } from "../../components";
import {
  tutorialOnAction,
  tutorialOffAction,
} from "../../redux/modules/tutorial";

const mapStateToProps = (state) => ({
  ...state.CurveClassification,
  ...state.Curves,
});

export default compose(
  connect(mapStateToProps, {
    removeAllSelectedCurvesAction,
    classificationRequestAction,
    tutorialOn: tutorialOnAction,
    tutorialOff: tutorialOffAction,
  }),
  withState("tagName", "updateTagName", ""),
  withState("tagNameValid", "updateTagNameValidation", true),
  withState("tagNameInvalidMsg", "updateTagNameInvalidMsg", null),
  withState("labelName", "updateLabelName", ""),
  withState("labelValid", "updateLabelNameValidation", true),
  withState("labelInvalidMsg", "updateLabelNameInvalidMsg", null),
  withState("fullyClassifiedCount", "updateFullyClassifiedCount", 0),
  withState("effectedCurves", "updateEffectedCurves", true),
  withState("filterVal", "updateFilterVal", []),
  withState("effectCurvePageSize", "updateEffectCurvePageSize", 30),
  withProps((props) => ({
    facetTags: compose(unnest, mapAllFacetsFn)(props.facetDataStatic),
  })),
  withProps((props) => ({
    allTagNames: compose(
      without([
        "Type",
        "ProviderName",
        "OriginalGranularity",
        "OriginalTimezone",
      ]),
      sortBy(toLower),
      uniq,
      map(prop("tag"))
    )(props.facetTags),
  })),
  withProps((props) => ({
    allLabelNames:
      isEmpty(props.tagName) || !contains(props.tagName, props.allTagNames)
        ? compose(
            sortBy(toLower),
            filter((n) => n),
            uniq,
            map(prop("label"))
          )(props.facetTags)
        : compose(
            sortBy(toLower),
            filter((n) => n),
            uniq,
            map(prop("label")),
            filter(propEq("tag", props.tagName))
          )(props.facetTags),
  })),
  withHandlers({
    selectedFilters: (props) => (val) => props.updateFilterVal(val),
    toggleEffectedCurves: (props) => () =>
      props.updateEffectedCurves(!props.effectedCurves),
    setTagName: (props) => (val) =>
      props.updateTagName(removeInvalidChars(val)),
    setTagValid: (props) => (val) => props.updateTagNameValidation(val),
    setTagNameInvalidMsg: (props) => (val) =>
      props.updateTagNameInvalidMsg(val),
    setLabelName: (props) => (val) =>
      props.updateLabelName(removeInvalidChars(val)),
    setLabelValid: (props) => (val) => props.updateLabelNameValidation(val),
    setLabelNameInvalidMsg: (props) => (val) =>
      props.updateLabelNameInvalidMsg(val),
    incrementFullyClassifiedCount: (props) => () =>
      props.updateFullyClassifiedCount((n) => n + 1),
    setPageSize: (props) => (size) => props.updateEffectCurvePageSize(size),
  }),
  withProps((props) => ({
    createdTags: createClassificationTags(props),
    coreTags: props.coreTags,
  })),
  withHandlers({
    applyClassification: (props) => () => applyClassifyFn(props),
    removeClassification: (props) => (tags, obj) =>
      deleteClassificationFn(tags, obj, props),
    renderDropDown: (props) => () =>
      EffectCurvesSizePerPageDropDown(props.effectCurvePageSize, props),
  }),
  withProps((props) => ({
    tagKeys: toPairs(props.createdTags),
    selectedCurveCount: length(props.selectedCurves.curves),
    filterDropdownOptions: [
      { label: "Market Assessment", value: "MarketAssessment" },
      { label: "Actual Time Series", value: "ActualTimeSerie" },
      { label: "Versioned Time Series", value: "VersionedTimeSerie" },
    ],
    selectedCurveData: isEmpty(props.filterVal)
      ? props.selectedCurves.curves
      : filterTable(props.selectedCurves.curves, props.filterVal),
    effectedCurvesTableSettings: {
      sizePerPage: props.effectCurvePageSize,
      sizePerPageDropDown: props.renderDropDown,
      prePage: "Previous",
      nextPage: "Next",
      clickToExpand: true,
    },
    expandColumnOptionsSettings: {
      expandColumnVisible: true,
      expandColumnBeforeSelectColumn: true,
      columnWidth: 25,
    },
  })),
  withProps((props) => ({
    tagKeysCount: length(props.tagKeys),
    containsData: lt(props.selectedCurveCount, 1),
    disableClassifyBtn: props.tagName.length < 1 || props.labelName.length < 1,
    displayLabels: props.tagKeys.map(([labelObj, obj]) => (
      <ClassificationDisplayLabel
        key={labelObj}
        label={labelObj}
        obj={obj}
        {...props}
      />
    )),
    effectedCurvesTable: (
      <EffectedClassificationTable
        headerTitle="Effected MarketData"
        data={props.selectedCurveData}
        filterVal={props.filterVal}
        selectedFilters={props.selectedFilters}
        settings={props.effectedCurvesTableSettings}
        expandSettings={props.expandColumnOptionsSettings}
        filterDropdownOptions={props.filterDropdownOptions}
      />
    ),
  }))
)(CurvesClassifaction);
