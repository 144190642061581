import React from "react";
import { Tooltip } from "react-tippy";

const Warning = (
  <span className="menuWarningTriangle">
    <Tooltip
      arrow
      position="left"
      html={<span>Warning</span>}
      theme="transparent"
    >
      <i className="fa fa-exclamation-triangle" />
    </Tooltip>
  </span>
);

export default Warning;
