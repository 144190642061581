import React, { useState } from "react";
import { DropDown, InputTextBox } from "../../../components";
import ImpactedPeriodComponent from "./impactedPeriodComponent";
import CronSelection from "../cronSelection/cronSelection";

const AddAlertBody = ({
  timezone,
  setTimezone,
  impactedDataRangeTimezone,
  setImpactedDataRangeTimezone,
  cronExpression,
  setCronExpression,
  alertName,
  setAlertName,
  dateTo,
  setIsValid,
  setDateTo,
  dateFrom,
  setDateFrom,
  type,
  pageNo,
  setPageNo
}) => {
  const timeZones = ["CET", "GMT", "UTC"];
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const [
    toggleImpactedDataRangeTimezoneDropdown,
    setImpactedDataRangeTimezoneDropdown,
  ] = useState(true);
  return (
    <div className="addAlert">
      {pageNo == 1 ? 
        <div className="inlineComponentsContainer">
          <div className="inlineComponent">
            <div className="alertName" >Alert Name</div>
            <InputTextBox
              value={alertName}
              disabled={type === "edit"}
              onChange={(x) => setAlertName(x)}
              placeholder="Add Alert Name"
            />
          </div>
          <br />
          
        </div>
      :
        <React.Fragment>
          <div className = "alertName">Set Alert Impacted Period</div>
         
        </React.Fragment>
      }
      <br />

      <div className="inlineComponentsContainer">
      {pageNo == 1 ?  
        <CronSelection
          cronExpression={cronExpression || ""}
          onChange={setCronExpression}
        />
        :
        <ImpactedPeriodComponent
          periodFrom={dateFrom}
          periodTo={dateTo}
          setIsValid={setIsValid}
          setPeriodFrom={setDateFrom}
          setPeriodTo={setDateTo}
        />
      }
      </div>
    </div>
  );
};

export default AddAlertBody;
