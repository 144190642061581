import React from "react";
import { compose, length, equals } from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import { withState, withHandlers } from "recompose";
import matchSorter from "match-sorter";

const InputAutoComplete = (props) => (
  <div
    className={`dropdown artesianDropdownContanier ${props.className || ""}`}
  >
    <div className="btn-group width100">
      <div className="d-flex width100 noPadding" data-toggle="dropdown">
        <input
          type="text"
          className={`form-control ${props.inputClassName}`}
          placeholder={props.placeholder}
          value={props.value}
          id={props.id || "inputGroup"}
          onChange={props.onInputChange}
        />
      </div>
      {length(props.getListItems(props.value)) > 0 ? (
        <div className="dropdown-menu artesianDropdown inputArtesianDropdown">
          <Scrollbars
            autoHeight
            autoHide
            className="autocomplete"
            renderTrackHorizontal={(val) => (
              <div
                {...val}
                style={{ display: "none" }}
                className="track-horizontal"
              />
            )}
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            {props.getListItems(props.value).map((val) => (
              <span
                key={val}
                value={val}
                className={`dropdown-item ${props.dropdownItemClass}`}
                onClick={() =>
                  equals("No Items Available", val) ? null : props.onChange(val)
                }
                aria-hidden="true"
              >
                {val}
              </span>
            ))}
          </Scrollbars>
        </div>
      ) : null}
    </div>
  </div>
);

const enhancer = compose(
  withState("dropdownState", "updateDropdownState", false),
  withHandlers({
    toggleDropdown: (props) => () =>
      props.updateDropdownState(!props.dropdownState),
    onInputChange: (props) => (val) => {
      props.updateDropdownState(true);
      props.update(val.target.value || "");
    },
    onChange: (props) => (val) => {
      props.updateDropdownState(true);
      props.update(val || "");
    },
    getListItems: (props) => (val) => {
      if (val) {
        return matchSorter(props.list, val);
      }
      return length(props.list) > 0 ? props.list : ["No Items Available"];
    },
  })
);
export default enhancer(InputAutoComplete);
