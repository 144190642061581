import React from "react";

const ModalAlert = (props) => (
  <div>
    <div
      className={`modal fade show ${props.openModal ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${props.openModal ? "fadeIn" : ""}`}
        id="errorModalOverlay"
        onClick={props.closeModal}
      />
      <div className="modal-dialog artesainModal" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">{props.title}</h5>
          </div>
          <div className="modal-body text-center">
            <span>
              <span>{props.modalBody}</span>
            </span>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className={`btn confirmClose ${
                props.disableApplyBtn ? "btnDisabled" : ""
              }`}
              onClick={props.commitChanges}
              disabled={props.disableApplyBtn}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn modalClose"
              onClick={props.closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalAlert;
