import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const MarketAssessmentPreviewTable = ({ data, dataFormatFn }) => (
  <BootstrapTable
    data={data}
    condensed
    keyField="ID"
    version="4"
    containerClass="refineCurvesContainer curvePreviewContainer"
    tableContainerClass="curveTableContainer"
    bodyContainerClass="curveTableBody"
  >
    <TableHeaderColumn width="10%" dataField="P" dataAlign="center" hidden>
      Provider
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="N" dataAlign="center">
    MarketDataName
    </TableHeaderColumn>
    <TableHeaderColumn
      width="20%"
      dataField="ID"
      dataAlign="center"
      dataFormat={dataFormatFn}
    >
      ID
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="T" dataAlign="center">
      Time
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="S" dataAlign="center">
      Side
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="D" dataAlign="center">
      Price
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="Q" dataAlign="center">
      Quantity
    </TableHeaderColumn>
  </BootstrapTable>
);

export default MarketAssessmentPreviewTable;
