import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, DropDownScroll } from "../components";
import { setTutorialModalOpenAction, setVideoUrlAction } from '../redux/modules/tutorial';
import { connect } from "react-redux";

const CurvesClassification = ({ setTutorialModalOpenAction, setVideoUrlAction, ...props }) => (
  <Scrollbars
    className="groupAdmin"
    autoHide
    renderThumbHorizontal={(vals) => (
      <div {...vals} className="thumb-horizontal" />
    )}
    renderThumbVertical={(vals) => <div {...vals} className="thumb-vertical" />}
  >
    <div className="pageTitle">
      <span className="paddingLeftFive">
        <i className="fa fa-users-cog marginRight10 artesianIconNoHover" />
      </span>
      Group Administration
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/3Lu5ZW3SLHw?si=_kMeOdyZ__S6JejC"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="groupAdminBody">
      <div className="width100 d-flex">
        <h5 className="adminLabel paddingRight">Group:</h5>
        <DropDownScroll
          selected={props.selectedGroupName}
          dropDownValues={props.selectedGroupOption}
          onClick={props.setSelectedGroup}
          dropID="selectedGroup"
        />
        <Button
          btnText="Add New Group"
          className="addGroupBtn"
          onClick={props.openGroupModal}
          permissionDisabled={props.createGroupPermission}
          id="AddNewGroup"
        />
        <Button
          btnText="Remove Group"
          className="addGroupBtn"
          onClick={props.openDeleteGroupModal}
          permissionDisabled={props.removeGroupPermission}
          id="DeleteGroup"
        />
      </div>
      <div className="groupPermissionsContainer">
        <h4 className="adminLabel">
          Existing {props.selectedGroupName} Group Permissions
        </h4>
        <BootstrapTable
          data={props.tableData}
          version="4"
          keyField="Path"
          pagination
          condensed
          options={props.tableSettings}
          containerClass="adminGroupsContainer"
          tableContainerClass="adminGroupsTableContainer"
          bodyContainerClass="adminGroupsTableBody"
        >
          <TableHeaderColumn width="64" dataFormat={props.dataFormat} />
          <TableHeaderColumn
            dataField="Path"
            dataSort
            dataFormat={props.infoTooltip}
          >
            PATH
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Role"
            dataSort
            dataFormat={props.PermissionFormat}
          >
            PERMISSION
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
      {props.addPermissions}
    </div>
  </Scrollbars>
);

const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction
};
export default connect(null, mapDispatchToProps)(CurvesClassification);
