import * as RX from "rxjs";
import * as RxOp from "rxjs/operators";
import { combineReducers } from "redux";
import { always, compose, ap, of, prop } from "ramda";
import clipboard from "copy-to-clipboard";
import CurveTableConfig from "./tableColSettings";
import { auth } from "../../../appContext";
import { Selectors } from "../tenantSelection";
import { dispatchNetworkError } from "./helper";
import { createApiKeyRequest, getAllUsersApiKeys, deleteApiKeyRequest } from "./requests";
import {
  fetchingReducerCreator,
  simpleSetReducerCreator,
} from "../../reducerCreators";

const FETCHING = "artesian-ui/settings/FETCHING";
const RECIEVED = "artesian-ui/settings/RECIEVED";
const CANCEL_FETCHING = "artesian-ui/settings/CANCEL_FETCHING";
export const TOGGLE_TABLE_SETTINGS_MODAL =
  "artesian-ui/settings/TOGGLE_TABLE_SETTINGS_MODAL";
export const UPDATE_CURVE_TABLE_ORDER =
  "artesian-ui/settings/UPDATE_CURVE_TABLE_ORDER";
const SHOW_SETTINGS_WARNING_MODAL =
  "artesian-ui/settings/SHOW_SETTINGS_WARNING_MODAL";
const HIDE_SETTINGS_WARNING_MODAL =
  "artesian-ui/settings/HIDE_SETTINGS_WARNING_MODAL";
const CLEAR_TABLE_ORDER = "artesian-ui/settings/CLEAR_TABLE_ORDER";
const SET_ALL_USER_API_KEYS = "artesian-ui/settings/SET_ALL_USER_API_KEYS";
const CREATED_API_KEYS = "artesian-ui/settings/CREATED_API_KEYS";
export const DELETE_APIKEY = "artesian-ui/settings/DELETE_APIKEY";
export const NEW_KEY_FETCHING = "artesian-ui/settings/NEW_KEY_FETCHING";
const NEW_KEY_RECIEVED = "artesian-ui/settings/NEW_KEY_RECIEVED";
const NEW_KEY_FAILED = "artesian-ui/settings/NEW_KEY_FAILED";
const NEW_KEY_DEFAULT = "artesian-ui/settings/NEW_KEY_DEFAULT";

const { flatMap, tap, map } = RxOp;

const getTableSettingsFromStorage = () =>
  JSON.parse(localStorage.getItem("tableSettings")) || null;
const storeTableSettings = (data) =>
  localStorage.setItem("tableSettings", JSON.stringify(data));
const showSettingsWarningModal = (state) => ({
  type: SHOW_SETTINGS_WARNING_MODAL,
  state,
});
const hideSettingsWarningModal = (state) => ({
  type: HIDE_SETTINGS_WARNING_MODAL,
  state,
});
const clearTableOrder = (state) => ({ type: CLEAR_TABLE_ORDER, state });

const requestState = (state = "", action) => {
  switch (action.type) {
    case FETCHING:
      return "FETCHING";
    case RECIEVED:
      return "RECIEVED";
    default:
      return state;
  }
};

const requestNewApiKeyState = (state = "", action) => {
  switch (action.type) {
    case NEW_KEY_FETCHING:
      return "NEW_KEY_FETCHING";
    case NEW_KEY_RECIEVED:
      return "NEW_KEY_RECIEVED";
    case NEW_KEY_FAILED:
      return "NEW_KEY_FAILED";
    case NEW_KEY_DEFAULT:
      return "";
    default:
      return state;
  }
};

const tableSettingsModalState = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_TABLE_SETTINGS_MODAL:
      return !state;
    default:
      return state;
  }
};

const curveTableConfigSettings = (
  state = getTableSettingsFromStorage() || CurveTableConfig,
  action
) => {
  switch (action.type) {
    case UPDATE_CURVE_TABLE_ORDER:
      return { ...state, curveTable: action.order };
    case CLEAR_TABLE_ORDER:
      return CurveTableConfig;
    default:
      return state;
  }
};

const settingsWarningModalDefaultState = {
  showModal: false,
  modalMessage: "",
};

const settingsWarningModalState = (
  state = settingsWarningModalDefaultState,
  action
) => {
  switch (action.type) {
    case SHOW_SETTINGS_WARNING_MODAL:
      return {
        ...state,
        showModal: true,
        modalMessage: "Do you want to exit without saving?",
      };
    case HIDE_SETTINGS_WARNING_MODAL:
      return settingsWarningModalDefaultState;
    default:
      return state;
  }
};

export default combineReducers({
  settingsFetching: fetchingReducerCreator({
    fetching: FETCHING,
    received: RECIEVED,
    cancelFetching: CANCEL_FETCHING,
  }),
  tableSettingsModalState,
  curveTableConfigSettings,
  settingsWarningModalState,
  requestState,
  requestNewApiKeyState,
  defaultCurveTableConfigSettings: always(CurveTableConfig),
  allUserApiKeys: simpleSetReducerCreator({
    setAction: SET_ALL_USER_API_KEYS,
    prop: "apiKeys",
    initialState: [],
  }),
  createdApiKeys: simpleSetReducerCreator({
    setAction: CREATED_API_KEYS,
    prop: "apiKey",
    initialState: "",
  }),
});

const fetching = () => ({ type: FETCHING });
const received = () => ({ type: RECIEVED });
const deleteApiKeyStore = () => ({ type: DELETE_APIKEY });
const newApiKeyFetching = () => ({ type: NEW_KEY_FETCHING });
const newApiKeyReceived = () => ({ type: NEW_KEY_RECIEVED });
const newApiKeyFailed = () => ({ type: NEW_KEY_FAILED });
const newApiKeyDefault = () => ({ type: NEW_KEY_DEFAULT });
const setUsersApiKeys = (apiKeys) => ({ type: SET_ALL_USER_API_KEYS, apiKeys });

const handleNetworkError = compose(ap([dispatchNetworkError, received]), of);

const handleNetworkFailedApiKeyError = compose(
  ap([dispatchNetworkError, received, newApiKeyFailed]),
  of
);

const setCreateApiKey = (apiKey) => ({
  type: CREATED_API_KEYS,
  apiKey,
});

const receivednewApiKey = compose(
  ap([received, newApiKeyReceived, setCreateApiKey]),
  of
);

const tokenFromState = compose(auth.getToken, Selectors.tenant);
const tokenApiFromState = (state) =>
  tokenFromState(state).pipe(
    RxOp.map((token) => ({
      token,
      api: Selectors.api(state),
      state,
    }))
  );

const requestUsersApiKeys = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetching)),
      flatMap(getAllUsersApiKeys),
      tap(compose(dispatch, setUsersApiKeys))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkError)
    );

const deleteApiKey = (keyId) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      
      tap(compose(dispatch, fetching)),
      tap(compose(dispatch, deleteApiKeyStore)),      
      flatMap(tokenApiFromState),
      flatMap(({ token, api }) =>
      deleteApiKeyRequest({
          token,
          api,
          keyId,
        })
      ),
      map(getState),
      flatMap((state)=> tokenApiFromState(state)),
      flatMap(getAllUsersApiKeys),
      tap(compose(dispatch, setUsersApiKeys))
    )
    .subscribe(
      compose(dispatch, received),
      compose(dispatch, handleNetworkError)
    );

const createApiKey = (data) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, newApiKeyFetching)),
      flatMap(tokenApiFromState),
      flatMap(({ token, api }) =>
        createApiKeyRequest({
          token,
          api,
          data,
        })
      )
    )
    .subscribe(
      compose(dispatch, receivednewApiKey),
      compose(dispatch, handleNetworkFailedApiKeyError)
    );

export const showSettingsWarningModalAction = (state) => [
  showSettingsWarningModal(state),
];

const toggleTableSettingsModalState = () => ({
  type: TOGGLE_TABLE_SETTINGS_MODAL,
});
const updateTableOrder = (order) => ({ type: UPDATE_CURVE_TABLE_ORDER, order });

export const toggleTableSettingsAction = () => [
  toggleTableSettingsModalState(),
];

export const updateTableOrderAction = (order) => [
  updateTableOrder(order),
  storeTableSettings({ curveTable: order }),
];

export const closeSettingsWarningModalAction = () => [
  hideSettingsWarningModal(),
  toggleTableSettingsModalState(),
  clearTableOrder(),
];

export const confirmSettingsWarningModalAction = () => [
  hideSettingsWarningModal(),
];

export const requestUsersApiKeysAction = () => [requestUsersApiKeys()];

export const deleteApiKeyAction = (keyId) => [deleteApiKey(keyId)];
export const createApiKeyAction = (data) => [createApiKey(data)];
export const newApiKeyDefaultAction = () => [newApiKeyDefault()];

const copyToClipBoardFunc = (propKey) => (dispatch, getState) =>
  RX.of(getState())
    .pipe(tap((state) => clipboard(prop(propKey, state.Settings))))
    .subscribe();

export const copyNewKeyToClipBoardAction = () => [
  copyToClipBoardFunc("createdApiKeys"),
];
