import React from "react";

const GasYearCheckboxes = (props) => (
  <div className="productContainer">
    <label>Gas Year</label>
    <div className="d-flex">
      <span className="GasCheckbox">
        <label>
          <input
            name="GY"
            type="checkbox"
            checked={props.gasYearChecked}
            onChange={props.setGY}
          />
          GY
        </label>
      </span>
      <span className="GasCheckbox">
        <label>
          <input
            name="CAL"
            type="checkbox"
            checked={props.calYearChecked}
            onChange={props.setCal}
          />
          CAL
        </label>
      </span>
    </div>
  </div>
);

export default GasYearCheckboxes;
