import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "../components";
import { setTutorialModalOpenAction, setVideoUrlAction } from '../redux/modules/tutorial';
import { connect } from "react-redux";

const CurvesClassification = ({ setTutorialModalOpenAction, setVideoUrlAction, ...props }) => (
  <div className="userGroupsPage">
    <div className="classify pageTitle">
      <span className="paddingLeftFive">
        <i className="fa fa-user-cog marginRight10 artesianIconNoHover" />
      </span>
      User Management
      <span aria-hidden="true">
        <i
          className="fas fa-question-circle artesianIconHover"
          onClick={() => {
            setVideoUrlAction(
              "https://www.youtube.com/embed/NvHw1L1wg4I?si=Tx9lli--bfoUnf76"
            );
            setTutorialModalOpenAction(true);
          }}
        />
      </span>
    </div>
    <div className="width100 d-flex paddingBottom10">
      <Button
        btnText={
          <span>
            <i className="fa fa-user-plus" /> Add User
          </span>
        }
        className="addGroupBtn addUserBtn"
        permissionDisabled={props.createUserPermission}
        onClick={props.openAddUserModal}
      />
    </div>
    <div className="adminMainContainer">
      <BootstrapTable
        data={props.tableData}
        version="4"
        keyField="user"
        pagination
        condensed
        printable
        options={props.tableSettings}
        containerClass="usersGroupsContainer"
        tableContainerClass="usersGroupsTableContainer"
        bodyContainerClass="usersGroupsTableBody"
      >
        <TableHeaderColumn width="64" dataFormat={props.dataFormat} />
        <TableHeaderColumn
          dataField="user"
          dataSort
          dataFormat={props.infoFormat}
        >
          USER
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="user"
          dataSort
          dataFormat={props.groupList}
        >
          GROUPS
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  </div>
);
const mapDispatchToProps = {
  setTutorialModalOpenAction,
  setVideoUrlAction
};
export default connect(null, mapDispatchToProps)(CurvesClassification);
