import { combineReducers } from "redux";
import { tablesOptionsReducerCreator } from "../../reducerCreators";

const SET_CURVES_BASKET_PAGE_SIZE =
  "artesian-ui/refineCurves/SET_CURVES_BASKET_PAGE_SIZE";
const SET_CURVES_BASKET_PAGE_NO =
  "artesian-ui/refineCurves/SET_CURVES_BASKET_PAGE_NO";
const REMOVE_TABLE_SETTINGS = "artesian-ui/refineCurves/REMOVE_TABLE_SETTINGS";
export const FILTER_REFINE_CURVES =
  "artesian-ui/refineCurves/FILTER_REFINE_CURVES";

const tableOptionsSettings = {
  setPageSize: SET_CURVES_BASKET_PAGE_SIZE,
  setPageNo: SET_CURVES_BASKET_PAGE_NO,
  removeTableSettings: REMOVE_TABLE_SETTINGS,
};

export default combineReducers({
  refineCurvesTablesOptions: tablesOptionsReducerCreator(tableOptionsSettings),
});

const setPageNo = (pageNo) => ({ type: SET_CURVES_BASKET_PAGE_NO, pageNo });
const setPageSize = (pageSize) => ({
  type: SET_CURVES_BASKET_PAGE_SIZE,
  pageSize,
});
const filterRefineCurves = (filters) => ({
  type: FILTER_REFINE_CURVES,
  filters,
});
const removeTableSettings = () => ({ type: REMOVE_TABLE_SETTINGS });

export const removeTableSettingsAction = () => [removeTableSettings()];
export const setPageNoAction = (pageNo) => [setPageNo(pageNo)];
export const setPageSizeAction = (pageSize) => [setPageSize(pageSize)];
export const filterRefineCurvesAction = (filters) => [
  filterRefineCurves(filters),
];
