import React from "react";
import { Tooltip } from "react-tippy";

const SearchTextBox = ({
  enterBtnPress,
  searchBtn,
  filterText,
  updateFilterTextPlusFilter,
}) => (
  <div className="input-group tableSearchContainer">
    <input
      type="text"
      className="form-control"
      placeholder="Search Alert Names"
      id="ArtesianSearchInput"
      onKeyPress={enterBtnPress}
      value={filterText}
      onChange={(e) => {
        updateFilterTextPlusFilter(e.target.value);
      }}
    />

    <span className="input-group-addon" onClick={searchBtn} aria-hidden="true">
      <Tooltip
        arrow
        position="bottom"
        html={<span>Click to Search</span>}
        theme="arkDefaultTooltip"
      >
        <i className="fa fa-search" />
      </Tooltip>
    </span>
  </div>
);

export default SearchTextBox;
