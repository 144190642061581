import React from "react";
import { toolTipStyleNoMargin } from "./tooltipStyles";

export const extractionTutorial = [
  // GOTO previous page.
  {
    selector: '[class="btn  menuBtnItem Classify "]',
    action: (node) => node.click(),
    content: <div />,
  },
  //  Main Intro
  {
    position: "center",
    content: (
      <div>
        <h3>Extract MarketData</h3>
        Here the market data can be extracted in a variety of formats. As the
        data will vary according to the types of market data selected, certain
        details may change. This tutorial will demonstrate how data can be
        extracted from a generic market data type.
      </div>
    ),
    style: toolTipStyleNoMargin,
  },
];

export default extractionTutorial;
