import React from "react";
import { equals, prop, map, filter, compose, last, contains } from "ramda";
import { InputNumberTextBox, InputAutoComplete } from "../../../components";
import {
  DateRangePicker,
  DateRangeVersions,
  DateRangeTimePicker,
  PeriodISOPicker,
} from "../components";
import { Tooltip } from "react-tippy";
import EnvironmentSettings from "../../../environment";

const PeriodInfoTooltip = () => (
  <Tooltip
    arrow
    interactive
    position="top"
    html={
      <span className="periodInfoIconAnchor">
        What is a Period Duration? -&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
        >
          Click Here
        </a>
      </span>
    }
    theme="arkDefaultTooltip"
  >
    <i
      className="fa fa-info-circle tooltipIcon periodTooltipIcon"
      aria-hidden="true"
    />
  </Tooltip>
);

export const versionedRange = (props) => {
  switch (props.selectedVersionedRange) {
    case "LastofMonths":
    case "LastofDays":
      return (
        <DateRangePicker
          startDate={props.versionedRangeStartDate}
          endDate={props.versionedRangeEndDate}
          onChange={props.setVersionRangeDates}
          defaultStartDate={props.defaultStartDate}
          defaultEndDate={props.defaultEndDate}
        />
      );
    case "Last":
      return (
        <InputNumberTextBox
          className="artesianInputNumberTextBox lastInput"
          value={props.lastValue}
          min={1}
          onChange={props.setLastValue}
        />
      );
    case "MostRecentByPeriod":{
      return (
        <div className="periodFromTo" key="vtsPeriodFromTo">
          <div className="periodFromPadding">
            <PeriodISOPicker
              isoDate={props.mostRecentPeriodFrom}
              update={props.updateMostRecentPeriodFrom}
              label={<span>From&nbsp;-&nbsp;</span>}
            />
          </div>
          <div className="d-flex">
            <PeriodISOPicker
              isoDate={props.mostRecentPeriodTo}
              update={props.updateMostRecentPeriodTo}
              label={<span className="paddingRight15">To&nbsp;-&nbsp;</span>}
            />
            <div className="periodInfoTooltipPadding">
              <PeriodInfoTooltip />
            </div>
          </div>
        </div>
      );
    }
    case "MostRecentByDate":
      return (
        <DateRangeTimePicker
          startDate={props.versionedRangeStartDate}
          endDate={props.versionedRangeEndDate}
          onChange={props.setVersionRangeDates}
          defaultStartDate={props.defaultStartDate}
          defaultEndDate={props.defaultEndDate}
        />
      );
    case "Version": {
      return (
        <div className="versionSelector">
          <div className="grid-container">
            <div className="grid-child purple">
              <DateRangeVersions
                startDate={props.versionedRangeStartDate}
                updateStartDate={props.setVersionedRangeStartDate}
                endDate={props.versionedRangeEndDate}
                updateEndDate={props.setVersionedRangeEndDate}
                getCurveIDVersionAction={props.getCurveIDVersionAction}
                onChange={() => props.setSelectedVersion("")}
              />
            </div>

            <div className="grid-child green">
              {props.availableVersionFetching ? (
                <div
                  className="form-control versionDropdown"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "#484848",
                    height: "26px",
                    borderRadius: "0",
                    backgroundColor: "white",
                  }}
                >
                  <i className="fas fa-spinner fa-pulse"></i>
                </div>
              ) : (
                <InputAutoComplete
                  className="versionDropdown"
                  value={props.selectedVersion}
                  list={props.curveVersioned}
                  placeholder={props.versionInputPlaceholder}
                  update={props.setSelectedVersion}
                  id="vtsVersion"
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    default:
      return <div />;
  }
};
const getVersionDateRange = (props) => {
  switch (props.selectedVersionedRange) {
    case "LastofMonths":
    case "LastofDays": {
      //debugger
      return `/${props.versionedRangeStartDate.format(
        "YYYY-MM-DD"
      )}/${props.versionedRangeEndDate.format("YYYY-MM-DD")}/`;
    }
    case "MostRecentByDate": {
      //debugger
      return `/${props.versionedRangeStartDate.format(
        "YYYY-MM-DDTHH:mm:ss"
      )}/${props.versionedRangeEndDate.format("YYYY-MM-DDTHH:mm:ss")}/`;
    }
    case "MostRecentByPeriod": {
      //debugger
      return (
        "/" + props.mostRecentPeriodFrom + "/" + props.mostRecentPeriodTo + "/"
      );
    }
    default:
      return "";
  }
};

const versionedURLInput = (value, list) => {
  if (!equals(value, "")) {
    return contains(value, list) ? value : "VERSION DOES NOT EXIST";
  }
  return "NO VERSION SELECTED";
};

const getTimeSeriesVersion = (props) => {
  switch (props.selectedVersionedRange) {
    case "LastofMonths":
      return "LastofMonths";
    case "LastofDays":
      return "LastofDays";
    case "Last":
      return `Last${props.lastValue}/`;
    case "MUV":
      return "MUV/";
    case "Version":
      return `Version/${versionedURLInput(
        props.selectedVersion,
        props.curveVersioned
      )}/`;
    case "MostRecentByPeriod":
    case "MostRecentByDate":
      return `MostRecent`;
    default:
      return "";
  }
};

const dateToString = (props, ds) => {
  switch (props.selectedRangeType) {
    case "Date Range":
      return `${ds.startDate.format("YYYY-MM-DD")}/${ds.endDate.format(
        "YYYY-MM-DD"
      )}`;
    case "Period From/To":
      return `${ds.selectPeriodFrom}/${ds.selectPeriodTo}`;
    case "Period":
      return `${ds.selectPeriod}`;
    default:
      return `${ds.range.replace(/ /g, "")}`;
  }
};

const getTrId = (arr, val) =>
  equals(val, "None")
    ? ""
    : `&tr=${compose(
        last,
        map(prop("ID")),
        filter((x) => x.Name === val)
      )(arr)}`;

const buildQuery = (props, timeSeriesVersion, versionDateRange, curveIds) => {
  let dateStringOptions = {
    startDate: props.startDate,
    endDate: props.endDate,
  };
  switch (props.selectedRangeType) {
    case "Relative":
      dateStringOptions = { range: props.selectedRelative };
      break;
    case "Period From/To":
      dateStringOptions = {
        selectPeriodFrom: props.selectPeriodFrom,
        selectPeriodTo: props.selectPeriodTo,
      };
      break;
    case "Period":
      dateStringOptions = {
        selectPeriod: props.selectPeriod,
      };
      break;
    default:
      break;
  }
  const dataTypeId = props.extractCustomFilterState
    ? `filterId=${props.selectedCustomFilter.id}`
    : `id=${curveIds}`;
  const period = dateToString(props, dateStringOptions);

  const fillerType = `&fillerK=${props.selectedFiller}`;
  const customFillers =
    props.selectedFiller === "CustomValue"
      ? `&fillerDV=${props.customFillValue}`
      : "";

  return `${timeSeriesVersion}${versionDateRange}${
    props.selectedGranularity
  }/${period}?tz=${props.selectedTimezone}${getTrId(
    props.transformValues,
    props.selectedTransform
  )}${fillerType}${customFillers}&${dataTypeId}`;
};

export const buildExtractionString = (props) => {
  const curveIds = props.selectedCurves.curves
    .filter((curve) => curve.Type === "VersionedTimeSerie")
    .map((curve) => curve.MarketDataId)
    .join(",");
  return (
    props.api +
    EnvironmentSettings["UI.tsvExtractionEndpoint"] +
    buildQuery(
      props,
      getTimeSeriesVersion(props),
      getVersionDateRange(props),
      curveIds
    )
  );
};
