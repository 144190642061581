import React from "react";
import { Tooltip } from "react-tippy";
import { compose } from "ramda";
import { withHandlers } from "recompose";
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = (props) => (
  <div
    className={`dropdown artesianDropdownContanier ${props.className || ""}`}
  >
    <div className="btn-group width100">
      <div
        className={`d-flex width100 noPadding ${props.tutCurvesID ||
          props.tutExtractID ||
          props.tutEnrichCurveId ||
          props.tutTransformID ||
          props.dropID ||
          ""}`}
        data-toggle="dropdown"
      >
        <button
          type="button"
          className="btn dropdown-width"
          onClick={props.toggleDropdown}
        >
          <Tooltip
            arrow
            position="bottom"
            html={<span>{props.selected}</span>}
            theme="arkDefaultTooltip"
          >
            {props.selected}
          </Tooltip>
        </button>
        <button
          type="button"
          className="btn dropdown-toggle dropdown-toggle-split artesianDropdownToggle"
          onClick={props.toggleDropdown}
        >
          <span className="sr-only">Toggle Dropdown</span>
        </button>
      </div>
      <div
        className={`dropdown-menu artesianDropdown ${props.tutExtractID ||
          props.dropID ||
          ""}`}
      >
        {props.dropDownValues.length > 0 ? (
          props.dropDownValues.map((val) => (
            <span
              key={val}
              value={val}
              className={`dropdown-item ${props.dropdownItemClass}`}
              onClick={() => props.clickFn(val)}
              aria-hidden="true"
            >
              {val}
            </span>
          ))
        ) : (
          <span
            className={`dropdown-item ${props.dropdownItemClass}`}
            aria-hidden="true"
          >
            No Items Available
          </span>
        )}
      </div>
    </div>
  </div>
);

const enhancer = compose(
  withHandlers({
    clickFn: (props) => (val) => props.onClick(val),
  })
);
export default enhancer(Dropdown);
