import React from "react";

export const APIKeysSpinner = () => (
  <div className="spinnerOverlay fadeIn" id="overlay">
    <div className="loader curvePreview">
      <div className="box" />
      <div className="box" />
      <div className="box" />
      <div className="box" />
    </div>
  </div>
);

export default APIKeysSpinner;
