import React from "react";
import moment from "moment";
import { Tooltip } from "react-tippy";
import * as R from "ramda";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangeVersions = (props) => {
  var defaultStartDate = new Date()
  var defaultEndDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 1);
  return (<Tooltip
    arrow
    html={
      "Click on calendar icon to change date range"
    }
    theme="arkDefaultTooltip"
  >
        <DateRangePicker
          readonly
          onChange={(dateVal) => {
            dateVal = dateVal || [defaultStartDate, defaultEndDate]
            getChangeValue(
              dateVal,
              defaultEndDate,
              defaultStartDate
            )
            
            if(props.onChange)
              props.onChange();

            props.updateEndDate(moment(dateVal[1]));
            props.updateStartDate(moment(dateVal[0]));
        
            let searchStartDate = new Date(dateVal[0])
            let searchEndDate = new Date(dateVal[1])
            
            if( searchStartDate.getFullYear().toString().length === 4 && searchEndDate.getFullYear().toString().length === 4
                && searchStartDate <= searchEndDate)              
              props.getCurveIDVersionAction(
                moment(new Date(dateVal[0])).format("YYYY-MM-DD"),
                moment(new Date(dateVal[1])).add(1, "days").format("YYYY-MM-DD")
              )
            }
          }
          value={[new Date(props.startDate), new Date(props.endDate)]}
          format="dd-MM-yyyy"
          className="calendarInput"
          calendarClassName="calendarStyle"
        />
  </Tooltip>
  );
}

const getChangeValue = ({ date, defaultStartDate, defaultEndDate }) => {
  if (R.isNil(date)) return [defaultStartDate, defaultEndDate];

  const [startDate, endDate] = date;
  if (startDate && endDate) {
    return [moment(startDate), moment(endDate)];
  }

  if (startDate && endDate === null) {
    return [moment(startDate), moment(startDate).add(1, "days")];
  }

  if (endDate && startDate === null) {
    return [moment(endDate).subtract(1, "days"), moment(endDate)];
  }

  return [defaultStartDate, defaultEndDate];
};



export default DateRangeVersions;