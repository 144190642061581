import React from "react";

const Modal = ({ show, title, body, close }) => (
  <div>
    <div
      className={`modal fade show ${show ? "d-block" : ""}`}
      id="errorModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modalOverlay ${show ? "fadeIn" : ""}`}
        onClick={() => close()}
      />
      <div
        className="modal-dialog curvePreviewModal artesainModal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body text-center">
            <div className="col curvePreviewBody">{body}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn errorModalClose"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
