import * as RX from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import platform from "platform";

export const classificationRequest = ({ bodyData, token, api }) =>
  ajax({
    url: `${api}/v2.1/marketdata/operations`,
    responseType: "json",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  });

const request = ({ token, api, userDefined }) =>
  ajax({
    url: `${api}/v2.1/timeTransform/entity?page=1&pageSize=50&userDefined=${userDefined}`,
    responseType: "json",
    headers: { Authorization: `Bearer ${token}`, 'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`},
  }).pipe(map((x) => x.response));

export const requestSystemDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "false" });

export const requestUserDefinedData = ({ token, api }) =>
  request({ token, api, userDefined: "true" });

export const downloadXLSXFile = (url, token) => () =>
  RX.Observable.create((obs) => {
    const xMLHttpRequest = new XMLHttpRequest();
    xMLHttpRequest.open("GET", url, true);
    xMLHttpRequest.responseType = "blob";
    xMLHttpRequest.setRequestHeader("Authorization", `Bearer ${token}`);
    xMLHttpRequest.setRequestHeader(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    xMLHttpRequest.onload = function RequestLoad() {
      if (xMLHttpRequest.status >= 400) return obs.error(xMLHttpRequest);
      let { response } = xMLHttpRequest;
      if (typeof response === "string") {
        try {
          response = JSON.parse(xMLHttpRequest.response);
        } catch (e) {
          // eslint-disable-next-line
          response = xMLHttpRequest.response;
        }
      }
      obs.next(response);
      obs.complete();
    };
    xMLHttpRequest.error = function RequestError(e) {
      obs.error(e.message);
    };
    xMLHttpRequest.send();
  });

export const enrichCurvesRequest = ({ token, api, data }) =>
  ajax.post(`${api}/v2.1/marketdata/operations`, data, {
    Authorization: `Bearer ${token}`,
    'X-Artesian-Agent': `ArtesianUI:1.0,${platform.os},${platform.name}:${platform.version}`,
    "Content-Type": "application/json",
  });
