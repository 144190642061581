import React from "react";
import { connect } from "react-redux";
import {
  compose,
  equals,
  is,
  merge,
  toPairs,
  prop,
  map,
  propEq,
  find,
} from "ramda";
import { withHandlers } from "recompose";
import { DropDown } from "../../components";
import { AdminDeleteGroupsModal } from "../../adminGroups";
import {
  closeDeleteGroupModalAction,
  updateDeleteGroupModalRowAction,
  deleteNewGroupAction,
} from "../../redux/modules/adminGroups";

const mapStateToProps = (state) => ({
  ...state.AdminGroups,
});

export default compose(
  connect(mapStateToProps, {
    closeDeleteGroupModalAction,
    updateDeleteGroupModalRowAction,
    deleteNewGroupAction,
  }),
  withHandlers({
    updateRow: (props) => (row) => props.updateDeleteGroupModalRowAction(row),
    commitChanges: (props) => () =>
      props.deleteNewGroupAction(props.deleteModal.row),
    closeModal: (props) => () => props.closeDeleteGroupModalAction(),
  }),
  withHandlers({
    applyBtnIsDisabled: () => () => false,
    GetDropDown: (props) => (type, cellValue = "") => {
      const objUpdater = compose(props.updateRow, (x) =>
        merge(props.deleteModal.row, find(propEq("Name", x))(props.allGroups))
      );
      const value =
        is(Object, cellValue) && equals(cellValue.overridden, true)
          ? cellValue.value
          : cellValue;
      switch (type) {
        case "Name":
          return (
            <div>
              <label htmlFor="select">
                <b>Group Name</b>
              </label>
              <DropDown
                className="artesianDropdownBorderColor"
                selected={value}
                dropDownValues={map(prop("Name"), props.allGroups)}
                onClick={objUpdater}
              />
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withHandlers({
    GetDropDownGroups: (props) => () =>
      toPairs(props.deleteModal.row).map(([type, value]) => (
        <div className={`form-group ${type}`} key={type}>
          {props.GetDropDown(type, value || "")}
        </div>
      )),
  })
)(AdminDeleteGroupsModal);
