import React from "react";
import { Tooltip } from "react-tippy";
import { Button } from "../../components";

const SelectAllBtn = ({ count, selectAllFn }) => (
  <Tooltip
    arrow
    position="bottom"
    html={<span>{`Select ${count} Available MarketData`}</span>}
    theme="arkDefaultTooltip"
  >
    <Button
      className="selectAllCurvesBtn"
      onClick={selectAllFn}
      btnText={
        <span>
          <i className="fa fa-check" />
          &nbsp;
          <span>Select {count} Available MarketData</span>
        </span>
      }
    />
  </Tooltip>
);

export default SelectAllBtn;
