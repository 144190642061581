import React from "react";
import { connect } from "react-redux";
import {
  compose,
  length,
  map,
  pick,
  concat,
  prepend,
  filter,
  prop,
  toLower,
  equals,
  sortBy,
  reverse,
  pipe,
  split,
  propEq,
} from "ramda";
import moment from "moment";
import { Tooltip } from "react-tippy";
import { withHandlers, withProps, withState, lifecycle } from "recompose";
import { CurvesVersionedTimeSeries } from "../../../curveExtract/extractionComponents";
import {
  getAllTimeTransformDataAction,
  copyToClipboardAction,
  downloadXLSXAction,
  getCurveIDVersionAction,
  setExtractionFilters
} from "../../../redux/modules/curveExtract";
import { DropDownScroll, TimeTransformDropdown } from "../../../components";
import { DateRangePicker, PeriodISOPicker } from "../components";
import { versionedRange, buildExtractionString } from "./helper";
import { Selectors } from "../../../redux/modules/tenantSelection";

const PeriodInfoTooltip = () => (
  <Tooltip
    arrow
    interactive
    position="top"
    html={
      <span className="periodInfoIconAnchor">
        What is a Period Duration? -&nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://en.wikipedia.org/wiki/ISO_8601#Durations"
        >
          Click Here
        </a>
      </span>
    }
    theme="arkDefaultTooltip"
  >
    <i
      className="fa fa-info-circle tooltipIcon periodTooltipIcon"
      aria-hidden="true"
    />
  </Tooltip>
);

const excelNames = [
  { name: "VersionedTSExtraction", excel: "VersionedTSExtraction" ,templateName: "GenericExtraction", type: null},
  {
    name: "VersionedTSExtraction Pivoted CurveName",
    excel: "VersionedTSExtraction_Pivoted_CurveName",
    templateName: "GenericExtraction",
    type: "CurveName"
  },
  {
    name: "VersionedTSExtraction Pivoted Time",
    excel: "VersionedTSExtraction_Pivoted_Time",
    templateName: "GenericExtraction",
    type: "Time"
  },
  {
    name: "VersionedTSExtraction Pivoted Version",
    excel: "VersionedTSExtraction_Pivoted_Version",
    templateName: "GenericExtraction",
    type: "Version"
  },
];

const getExcelName = (type, name) =>
  pipe(filter(propEq("name", type)), map(prop(name)))(excelNames);

const mapStateToProps = (state) => ({
  ...state.CurveExtract,
  ...state.Curves,
  api: Selectors.api(state),
  defaultStartDate: moment().subtract(1, "days"),
  defaultEndDate: moment(),
});

export default compose(
  connect(mapStateToProps, {
    downloadXLSXAction,
    copyToClipboardAction,
    getAllTimeTransformDataAction,
    getCurveIDVersionAction,
    setExtractionFilters
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.extractCustomFilterState) {
        this.props.getCurveIDVersionAction(moment(new Date()).subtract(1, 'd').format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD"));
      }
    },
  }),
  withProps((props) => ({
    curveVersioned: compose(
      reverse,
      sortBy(toLower),
      filter((n) => n),
      map(prop("Version"))
    )(props.curveVersion),
    dropdownValues: props.relativeRangeValues,
    transformValues: concat(
      props.userTransformValues,
      props.systemTransformValues
    ),
  })),
  withState(
    "startDate",
    "updateStartDate",
    ({ defaultStartDate }) => defaultStartDate
  ),
  withState("endDate", "updateEndDate", ({ defaultEndDate }) => defaultEndDate),
  withState(
    "versionedRangeStartDate",
    "updateVersionedRangeStartDateStartDate",
    ({ defaultStartDate }) => defaultStartDate
  ),
  withState(
    "versionedRangeEndDate",
    "updateVersionedRangeStartDateEndDate",
    ({ defaultEndDate }) => defaultEndDate
  ),
  withState("copyClip", "updateCopyClip", false),
  withState("selectedVersion", "updateSelectedVersion", ""),
  withState("excelType", "updateExcelType", "VersionedTSExtraction"),
  withState("selectedRelative", "updateSelectedRelative", "Rolling Week"),
  withState("selectedGranularity", "updateSelectedGranularity", "Hour"),
  withState("selectedTimezone", "updateSelectedTimezone", "CET"),
  withState("selectedTransform", "updateSelectedTransform", "None"),
  withState("selectedRangeType", "updateSelectedRangeType", "Date Range"),
  withState("selectedVersionedRange", "updateSelectedVersionedRange", "MUV"),
  withState("lastValue", "updateLastValue", 1),
  withState("selectPeriod", "updateSelectedPeriod", "P0Y0M0D"),
  withState("selectPeriodFrom", "updateSelectedPeriodFrom", "P0Y0M0D"),
  withState("selectPeriodTo", "updateSelectedPeriodTo", "P0Y0M0D"),
  withState("mostRecentPeriodFrom", "updateMostRecentPeriodFrom", "P0Y0M0D"),
  withState("mostRecentPeriodTo", "updateMostRecentPeriodTo", "P0Y0M0D"),
  withState("selectedFiller", "updateSelectedFiller", "Null"),
  withState("customFillValue", "setCustomFillValue", 0),
  withHandlers({
    setStartDate: (props) => (start) => props.updateStartDate(start),
    setExcelType: (props) => (val) => props.updateExcelType(val),
    setEndDate: (props) => (end) => props.updateEndDate(end),
    setVersionedRangeStartDate: (props) => (start) =>
      props.updateVersionedRangeStartDateStartDate(start),
    setVersionedRangeEndDate: (props) => (end) =>
      props.updateVersionedRangeStartDateEndDate(end),
    selectDropdownItem: (props) => (item) => props.updateSelectedRelative(item),
    setSelectedRangeType: (props) => (end) =>
      props.updateSelectedRangeType(end),
    setSelectedVersion: (props) => (end) => props.updateSelectedVersion(end),
    setSelectedVersionedRange: (props) => (ver) =>
      props.updateSelectedVersionedRange(ver),
    setLastValue: (props) => (val) => props.updateLastValue(val),
    setSelectedGranularity: (props) => (gran) =>
      props.updateSelectedGranularity(gran),
    updateSelected: (props) => () =>
      props.updateSelectedExtraction(
        equals(props.selected, props.prodId) ? "" : "vts"
      ),
    openTutorial: (props) => () => props.toggleTutorial(),
    setDates: (props) => ([startDate, endDate]) => {
      props.updateStartDate(startDate);
      props.updateEndDate(endDate);
    },
    setVersionRangeDates: (props) => ([startDate, endDate]) => {      
      props.updateVersionedRangeStartDateStartDate(startDate)
      props.updateVersionedRangeStartDateEndDate(endDate)
    },
    selectFiller: (props) => (item) => {
      props.updateSelectedFiller(item);
    }
  }),
  withHandlers({
    chooseComponent: (props) => () => {
      switch (props.selectedRangeType) {
        case "Date Range":
          return (
            <DateRangePicker
              startDate={props.startDate}
              endDate={props.endDate}
              onChange={props.setDates}
              defaultStartDate={props.defaultStartDate}
              defaultEndDate={props.defaultEndDate}
            />
          );
        case "Relative":
          return (
            <DropDownScroll
              selected={props.selectedRelative}
              dropDownValues={props.relativeRangeValues}
              onClick={props.updateSelectedRelative}
            />
          );
        case "Period From/To":
          return (
            <div className="periodFromTo" key="vtsPeriodFromTo">
              <div className="periodFromPadding">
                <PeriodISOPicker
                  isoDate={props.selectPeriodFrom}
                  update={props.updateSelectedPeriodFrom}
                  label={<span>From&nbsp;-&nbsp;</span>}
                />
              </div>
              <div className="d-flex">
                <PeriodISOPicker
                  isoDate={props.selectPeriodTo}
                  update={props.updateSelectedPeriodTo}
                  label={
                    <span className="paddingRight15">To&nbsp;-&nbsp;</span>
                  }
                />
                <div className="periodInfoTooltipPadding">
                  <PeriodInfoTooltip />
                </div>
              </div>
            </div>
          );
        case "Period":
          return (
            <div className="d-flex" key="vtsPeriod">
              <PeriodISOPicker
                isoDate={props.selectPeriod}
                update={props.updateSelectedPeriod}
                label=""
              />
              <div className="periodInfoTooltipPadding">
                <PeriodInfoTooltip />
              </div>
            </div>
          );
        default:
          return <div />;
      }
    },
  }),
  withProps((props) => ({
    versionInputPlaceholder:
      length(props.curveVersioned) > 0
        ? "Select a Version"
        : "No Version Available",
    transformValuesOptions: compose(
      prepend({ Name: "None", ID: 0 }),
      map(pick(["Name", "ID"]))
    )(props.transformValues),
  })),
  withProps((props) => ({
    dataCount: length(
      props.selectedCurves.curves.filter(
        (curve) => curve.Type === "VersionedTimeSerie"
      )
    ),

    selectedRangeTypeComponent: props.chooseComponent(props),
    excelTypeDropDown: (
      <DropDownScroll
        selected={props.excelType}
        dropDownValues={map(prop("name"), excelNames)}
        onClick={props.setExcelType}
        dropID="ATSexcelTypeDropDown"
      />
    ),
    timeSeriesDateTypeDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedRangeType}
        dropDownValues={props.timeSeriesDateTypeValues}
        onClick={props.updateSelectedRangeType}
        dropID="VTSTimeSeriesDateType"
      />
    ),
    granularityDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedGranularity}
        dropDownValues={props.granularityValues}
        onClick={props.updateSelectedGranularity}
        dropID="VTSGranularity"
      />
    ),
    versionOptionsDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedVersionedRange}
        dropDownValues={props.versionValues}
        onClick={props.updateSelectedVersionedRange}
        dropID="VTSVersionOptions"
      />
    ),
    timezoneDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedTimezone}
        dropDownValues={props.timezonesValues}
        onClick={props.updateSelectedTimezone}
        dropID="VTSTimezone"
      />
    ),
    transformDropDown: (
      <TimeTransformDropdown
        className="curveExtractDropdown"
        selected={props.selectedTransform}
        dropDownValues={props.transformValuesOptions}
        onClick={props.updateSelectedTransform}
        dropID="VTSTransform"
      />
    ),
    fillerDropDown: (
      <DropDownScroll
        className="curveExtractDropdown"
        selected={props.selectedFiller}
        dropDownValues={props.fillerValues}
        onClick={props.selectFiller}
        dropID="VTSFiller"
      />
    ),
    selectedRangeClass: props.selectedVersionedRange.concat("Selected"),
    selectedRangeComponent: versionedRange(props),
    urlString: buildExtractionString(props),
  })),
  withHandlers({
    copyURL: (props) => () =>
      pipe(
        () => props.copyToClipboardAction(props.urlString),
        () => props.updateCopyClip(true),
        () => setTimeout(() => props.updateCopyClip(false), 800)
      )([]),
    downloadXLSX: (props) => () => {
      props.downloadXLSXAction({
        url: split("&id", props.urlString)[0],
        excelType: getExcelName(props.excelType, "excel"),
        templateName: getExcelName(props.excelType, "templateName"),
        type: getExcelName(props.excelType, "type"),
        isFilterId: props.extractCustomFilterState,
        curveList: props.selectedCurves.curves.filter(
          (curve) => curve.Type === "VersionedTimeSerie"
        ),
      });
      if (!localStorage.getItem("finishedExcelTour")) props.openTutorial();
    },
  })
)(CurvesVersionedTimeSeries);
