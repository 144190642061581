import { connect } from "react-redux";
import React from "react";
import { compose, adjust, not, evolve, F, prop } from "ramda";
import { withHandlers, withProps, pure, withState } from "recompose";
import { arrayMove } from "react-sortable-hoc";
import { CurvesTable } from "../../curves";
import { RenderSizePerPageDropDown } from "../../components";
import { CurvePreview } from "../../curvePreview";
import PaginationComponent from "../../components/tableComponents/curveTablePagination";
import {
  setPageSizeAction,
  setPageNoAction,
  toggleTableRowSelectionAction,
  CurvesSelectors,
} from "../../redux/modules/curves";
import { getPreviewTableAction } from "../../redux/modules/curvePreview";
import { updateTableOrderAction } from "../../redux/modules/settings";
import { colDataFotmatFn } from "./helper";

const mapStateToProps = (state) => ({
  ...state.Settings,
  ...state.CurvePreview,
  selectedCurves: CurvesSelectors.selectedCurves(state),
  tablesOptions: CurvesSelectors.tablesOptions(state),
  curveData: CurvesSelectors.curveData(state),
});

export default compose(
  pure,
  connect(mapStateToProps, {
    toggleTableRowSelectionAction,
    setPageSizeAction,
    setPageNoAction,
    updateTableOrderAction,
    getPreviewTable: getPreviewTableAction,
  }),
  withState(
    "items",
    "updateItems",
    ({ curveTableConfigSettings }) => curveTableConfigSettings.curveTable
  ),
  withState("saveState", "updateSaveState", false),
  withState("data", "updateData", null),
  withState("dropUp", "dropUpOpen", false),
  withState("saveOpenSettings", "updateOpenSettings", false),
  withHandlers({
    setItems: (props) => ({ oldIndex, newIndex }) => {
      props.updateItems(arrayMove(props.items, oldIndex, newIndex));
      props.updateTableOrderAction(arrayMove(props.items, oldIndex, newIndex));
      props.updateSaveState(true);
    },
    setHidden: (props) => (index) => {
      const res =
        props.items.filter(prop("hidden")).length !== 4
          ? adjust(index, evolve({ hidden: not }), props.items)
          : adjust(index, evolve({ hidden: F }), props.items);
      props.updateItems(res);
      props.updateTableOrderAction(res);
    },
    clearTableOrder: (props) => () => {
      props.updateItems(props.curveTableConfigSettings.curveTable);
      props.updateSaveState(false);
      props.updateOpenSettings(false);
    },
    resetTableOrder: (props) => () => {
      props.updateTableOrderAction(
        props.defaultCurveTableConfigSettings.curveTable
      );
      props.updateItems(props.defaultCurveTableConfigSettings.curveTable);
      props.updateData(props.defaultCurveTableConfigSettings.curveTable);

      props.updateSaveState(true);
      props.updateOpenSettings(false);
    },
    saveTableOrder: (props) => () => {
      props.updateTableOrderAction(
        props.items || props.defaultCurveTableConfigSettings.curveTable
      );
      props.updateSaveState(false);
      props.updateOpenSettings(false);
    },
    setPageSortColumn: (props) => ({ sortName, sortOrder }) => {
      props.setPageSortColumnAction(sortName, sortOrder);
    },
  }),
  withHandlers({
    toggleSettings: (props) => () =>
      props.updateOpenSettings(!props.saveOpenSettings),
    curvePreview: (props) => (cell, row) => CurvePreview(row, props),
    setPageSize: (props) => (val) => props.setPageSizeAction(val),
    setPageNo: (props) => (val) => props.setPageNoAction(val),
    setPageSortColumn: (props) => ({ sortName, sortOrder }) => {
      props.setPageSortColumnAction(sortName, sortOrder);
    },
  }),
  withHandlers({
    renderDropDown: (props) => () =>
      RenderSizePerPageDropDown(props.tablesOptions.pageSize, props),
    onRowSelect: (props) => (row, isSelected) =>
      props.toggleTableRowSelectionAction([row], isSelected),
    onSelectAllRows: (props) => (isSelected, row) =>
      props.toggleTableRowSelectionAction(row, isSelected),
  }),
  withHandlers({
    renderPaginationPanel: (props) => () => (
      <div className="paginationControls">
        <div>{props.renderDropDown(props)}</div>
        <PaginationComponent
          count={props.tablesOptions.curveCount}
          pageSize={props.tablesOptions.pageSize}
          page={props.tablesOptions.pageNo}
          onPageChange={props.setPageNo}
        />
      </div>
    ),
  }),
  withProps((props) => ({
    dynamicTableKey: props.curveTableConfigSettings.curveTable
      .filter((col) => col.hidden)
      .map((col) => col.title)
      .join(),
  })),
  withProps((props) => ({
    selectedCurves: props.selectedCurves.curves.map(
      (curve) => curve.MarketDataId
    ),
  })),
  withProps((props) => ({
    tableSettings: {
      page: props.tablesOptions.pageNo,
      sizePerPage: props.tablesOptions.pageSize,
      onPageChange: props.setPageNo,
      onSizePerPageList: props.setPageSize,
      sizePerPageDropDown: props.renderDropDown,
      paginationPanel: props.renderPaginationPanel,
      expandBy: "column",
      prePage: "Previous",
      nextPage: "Next",
    },
    selectRowOptions: {
      mode: "checkbox",
      clickToSelectAndEditCell: true,
      columnWidth: "40px",
      className: "selectedCurve",
      hideSelectColumn: true,
      onSelect: props.onRowSelect,
      selected: props.selectedCurves,
    },
    dataFormat: colDataFotmatFn,
  }))
)(CurvesTable);
