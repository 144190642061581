import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const MarketAssessmentPreviewTable = ({ data, dataFormatFn }) => (
  <BootstrapTable
    data={data}
    condensed
    keyField="ID"
    version="4"
    containerClass="refineCurvesContainer curvePreviewContainer"
    tableContainerClass="curveTableContainer"
    bodyContainerClass="curveTableBody"
  >
    <TableHeaderColumn width="10%" dataField="ID" dataAlign="center" hidden>
      TSID
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="PR" dataAlign="center">
      Product
    </TableHeaderColumn>
    <TableHeaderColumn
      width="20%"
      dataField="N"
      dataAlign="center"
      dataFormat={dataFormatFn}
      className="previewColOverflow"
    >
      Marketdata Name
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="T" dataAlign="center">
      DateTime
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="O" dataAlign="center">
      Open
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="C" dataAlign="center">
      Close
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="H" dataAlign="center">
      High
    </TableHeaderColumn>
    <TableHeaderColumn width="10%" dataField="L" dataAlign="center">
      Low
    </TableHeaderColumn>
  </BootstrapTable>
);

export default MarketAssessmentPreviewTable;
