import {
  contains,
  uncurryN,
  ifElse,
  always,
  head,
  pipe,
  map,
  tap,
  of,
  prop,
  replace,
  isEmpty,
} from "ramda";
import { routerActions } from "react-router-redux";
import { createSelector } from "reselect";
import rootSelector from "./rootSelector";
import environment from "../../../environment";
import { auth } from "../../../appContext";

const SET_TENANT = "Artesian/tenantSelection/SET_TENANT";
const SET_TENANT_OPTIONS = "Artesian/tenantSelection/SET_TENANT_OPTIONS";

const initialState = { tenant: localStorage.tenant || "", options: [] };

const protectTenant = uncurryN(2, (tenant) =>
  ifElse(contains(tenant), always(tenant), head)
);
export default function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TENANT:
      return { ...state, tenant: action.tenant };
    case SET_TENANT_OPTIONS:
      return {
        ...state,
        options: action.options,
        tenant: protectTenant(state.tenant, action.options) || "",
      };
    default:
      return state;
  }
}

export const setOptions = ifElse(
  isEmpty,
  () => [
    routerActions.push({
      pathname: "/Login",
      state:
        "You do not have the required permission to access an arkive instance",
    }),
  ],
  (permissions) => [
    {
      type: SET_TENANT_OPTIONS,
      options: permissions.filter(contains("tenant")),
    },
  ]
);

export const setTenant = pipe(
  of,
  tap((x) => {
    localStorage.tenant = x;
    window.location.reload();
  }),
  map((x) => ({ type: SET_TENANT, tenant: x }))
);

const tenantSelect = pipe(prop("tenant"), replace("tenant:", ""));
const apiSelect = pipe(
  prop("tenant"),
  replace("tenant:", ""),
  (t) => `${environment["UI.arkiveApiUrl"]}/${t}`
);

export const Selectors = {
  tenant: createSelector(rootSelector, tenantSelect),
  api: createSelector(rootSelector, apiSelect),
};
