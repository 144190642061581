import React, { useState } from "react";
import * as R from "ramda";
import { Tooltip } from "react-tippy";
import { compose, isEmpty, isNil } from "ramda";
import { withHandlers } from "recompose";
import { Scrollbars } from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = (props) => {
  const [filter, setFilter] = useState(null);
  const opts = R.ifElse(
    (x) => R.isEmpty(x) || R.isNil(x),
    () => props.dropDownValues,
    (x) =>
      R.filter(
        (title) => R.contains(R.toLower(x), R.toLower(title)),
        props.dropDownValues
      )
  )(filter);

  return (
    <div
      className={`dropdown artesianDropdownContanier ${props.className || ""}`}
    >
      <div className="btn-group width100">
        <div
          className={`d-flex width100 noPadding ${props.tutCurvesID ||
            props.tutExtractID ||
            props.tutEnrichCurveId ||
            props.tutTransformID ||
            props.dropID ||
            ""}`}
          data-toggle="dropdown"
        >
          <button
            type="button"
            className="btn dropdown-width"
            onClick={props.toggleDropdown}
          >
            {isEmpty(props.selected) || isNil(props.selected) ? (
              "No Items Selected"
            ) : (
              <Tooltip
                arrow
                position="bottom"
                html={<span>{props.selected}</span>}
                theme="arkDefaultTooltip"
              >
                {props.selected}
              </Tooltip>
            )}
          </button>
          <button
            type="button"
            className="btn dropdown-toggle dropdown-toggle-split artesianDropdownToggle"
            onClick={props.toggleDropdown}
          >
            <span className="sr-only">Toggle Dropdown</span>
          </button>
        </div>
        <div
          className={`dropdown-menu artesianDropdown ${props.tutExtractID ||
            props.dropID ||
            ""}`}
        >
          <Scrollbars
            autoHeight
            autoHide
            className="autocomplete"
            renderTrackHorizontal={(p) => (
              <div
                {...p}
                style={{ display: "none" }}
                className="track-horizontal"
              />
            )}
            renderThumbHorizontal={(vals) => (
              <div {...vals} className="thumb-horizontal" />
            )}
            renderThumbVertical={(vals) => (
              <div {...vals} className="thumb-vertical" />
            )}
          >
            {props.containsFilter ? (
              <div className="dropdownFilter">
                <input
                  value={filter || ""}
                  placeholder="Filter items..."
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
            ) : null}

            {opts.length > 0 ? (
              opts.map((val) => (
                <span
                  key={val}
                  value={val}
                  className={`dropdown-item ${props.dropdownItemClass}`}
                  onClick={() => props.clickFn(val)}
                  aria-hidden="true"
                >
                  {val}
                </span>
              ))
            ) : (
              <span
                className={`dropdown-item ${props.dropdownItemClass}`}
                aria-hidden="true"
              >
                No Items Available
              </span>
            )}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

const enhancer = compose(
  withHandlers({
    clickFn: (props) => (val) => props.onClick(val),
  })
);
export default enhancer(Dropdown);
