import { combineReducers } from "redux";
import { compose, of, ap, prop, merge, always, dissoc } from "ramda";
import * as RX from "rxjs";
import * as R from "ramda";
import { flatMap, tap, map as RxMap } from "rxjs/operators";
import {
  simpleSetReducerCreator,
  fetchingReducerCreator,
} from "../../reducerCreators";
import { dispatchNetworkError } from "./helper";
import {
  requestUserDefinedData,
  requestSystemDefinedData,
  deleteTimeTransform,
  createTimeTransformRequest,
  updateTimeTransformRequest,
} from "./request";
import { auth } from "../../../appContext";
import { Selectors as TenantSelector} from "../tenantSelection";
import { granularityValues } from "./constants";

const FETCHINGDATA = "artesian-ui/enrichTimeTransform/FETCHINGDATA";
const RECIEVEDDATA = "artesian-ui/enrichTimeTransform/RECIEVEDDATA";
const RECIEVED_SYSTEM_TRANSFORM_DATA =
  "artesian-ui/enrichTimeTransform/RECIEVED_SYSTEM_TRANSFORM_DATA";
const RECIEVED_USER_TRANSFORM_DATA =
  "artesian-ui/enrichTimeTransform/RECIEVED_USER_TRANSFORM_DATA";
export const OPEN_EDIT_MODAL = "artesian-ui/enrichTimeTransform/OPEN_EDIT_MODAL";
export const CLOSE_EDIT_MODAL = "artesian-ui/enrichTimeTransform/CLOSE_EDIT_MODAL";
export const UPDATE_MODAL_ROW = "artesian-ui/enrichTimeTransform/UPDATE_MODAL_ROW";
const OPEN_DELETE_MODAL = "artesian-ui/enrichTimeTransform/OPEN_DELETE_MODAL";
export const CLOSE_DELETE_MODAL = "artesian-ui/enrichTimeTransform/CLOSE_DELETE_MODAL";
export const CREATE_NEW_TT = "artesian-ui/enrichTimeTransform/CREATE_NEW_TT";
export const UPDATE_TT = "artesian-ui/enrichTimeTransform/UPDATE_TT";
export const DELETE_TT = "artesian-ui/enrichTimeTransform/DELETE_NEW_TT";

const modalInitialState = { open: false, row: {}, type: "" };
const modal = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_EDIT_MODAL:
      return { ...action.edit, orig: action.edit.row, open: true };
    case UPDATE_MODAL_ROW:
      return { ...state, row: action.row };
    case CLOSE_EDIT_MODAL:
      return modalInitialState;
    default:
      return state;
  }
};

const deleteTimeTransformModal = (
  state = { open: false, id: null },
  action
) => {
  switch (action.type) {
    case OPEN_DELETE_MODAL:
      return { ...state, open: true, id: action.id };
    case CLOSE_DELETE_MODAL:
      return { ...state, open: false, id: null };
    default:
      return state;
  }
};

export default combineReducers({
  granularityValues: always(granularityValues),
  enrichTimeTransformFetching: fetchingReducerCreator({
    fetching: FETCHINGDATA,
    received: RECIEVEDDATA,
  }),
  systemTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_SYSTEM_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  userTransformValues: simpleSetReducerCreator({
    setAction: RECIEVED_USER_TRANSFORM_DATA,
    prop: "data",
    initialState: [],
  }),
  modal,
  deleteTimeTransformModal,
});

const fetchingData = () => ({ type: FETCHINGDATA });
const receivedData = () => ({ type: RECIEVEDDATA });
const createNewTT = (data) => ({ type: CREATE_NEW_TT, data });
const updateTT = (data) => ({ type: UPDATE_TT, data });
const deleteTT = (data) => ({ type: DELETE_TT, data });
const openEditModal = edit => ({ type: OPEN_EDIT_MODAL, edit });
const updateModalRow = row => ({ type: UPDATE_MODAL_ROW, row });
const closeEditModal = () => ({ type: CLOSE_EDIT_MODAL });
const openDeleteModal = (id) => ({ type: OPEN_DELETE_MODAL, id });
const closeDeleteModal = () => ({ type: CLOSE_DELETE_MODAL });
const receivedSystemTransformData = (data) => ({
  type: RECIEVED_SYSTEM_TRANSFORM_DATA,
  data,
});
const receivedUserTransformData = (data) => ({
  type: RECIEVED_USER_TRANSFORM_DATA,
  data,
});
const handleNetworkError = compose(
  ap([dispatchNetworkError, receivedData]),
  of
);
const resultsSystemRecieved = compose(
  ap([compose(receivedSystemTransformData, prop("Data")), receivedData]),
  of
);
const resultsUserRecieved = compose(
  ap([compose(receivedUserTransformData, prop("Data")), receivedData]),
  of
);

const tokenFromState = compose(auth.getToken, TenantSelector.tenant);
const tokenApiFromState = state =>
  tokenFromState(state).pipe(
    RxMap((token) => ({
      token,
      api: TenantSelector.api(state)
    }))
  );
const getSystemDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingData)),
      flatMap(requestSystemDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsSystemRecieved),
      compose(dispatch, handleNetworkError)
    );

const getUserDefinedTransformData = () => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      flatMap(tokenApiFromState),
      tap(compose(dispatch, fetchingData)),
      flatMap(requestUserDefinedData)
    )
    .subscribe(
      compose(dispatch, resultsUserRecieved),
      compose(dispatch, handleNetworkError)
    );

export const getAllTimeTransformDataAction = () => [
  getSystemDefinedTransformData(),
  getUserDefinedTransformData(),
];
export const deleteTimeTransformId = data => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, () => deleteTT(data))),
      flatMap(() => tokenApiFromState(getState())),
      tap(compose(dispatch, fetchingData)),
      flatMap(({ token, api }) =>
        deleteTimeTransform({
          token,
          api,
          id: getState().EnrichTimeTransform.deleteTimeTransformModal.id,
        })
      )
    )
    .subscribe(
      compose(dispatch, getAllTimeTransformDataAction),
      compose(dispatch, handleNetworkError)
    );

const staticData = {
  ID: 1001,
  ETag: "00000000-0000-0000-0000-000000000000",
  Type: "SimpleShift",
};

export const createTimeTransform = data => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, () => createNewTT(data))),
      flatMap(() => tokenApiFromState(getState())),
      tap(compose(dispatch, fetchingData)),
      flatMap(({ token, api }) =>
        createTimeTransformRequest({
          token,
          api,
          data: merge(staticData, data),
        })
      )
    )
    .subscribe(
      compose(dispatch, getAllTimeTransformDataAction),
      compose(dispatch, handleNetworkError)
    );

export const updateTimeTransform = data => (dispatch, getState) =>
  RX.of(getState())
    .pipe(
      tap(compose(dispatch, () => updateTT(data))),
      flatMap(() => tokenApiFromState(getState())),
      tap(compose(dispatch, fetchingData)),
      flatMap(({ token, api }) =>
        updateTimeTransformRequest({
          token,
          api,
          data: dissoc("DefinedBy", data),
          id: data.ID,
        })
      )
    )
    .subscribe(
      compose(dispatch, getAllTimeTransformDataAction),
      compose(dispatch, handleNetworkError)
    );
export const openEditModalAction = (edit) => [openEditModal(edit)];
export const closeEditModalAction = () => [closeEditModal()];
export const openDeleteModalAction = (id) => [openDeleteModal(id)];
export const closeDeleteModalAction = () => [closeDeleteModal()];
export const updateModalRowAction = (row) => [updateModalRow(row)];
export const confirmDeleteRowAction = () => [
  deleteTimeTransformId(),
  closeDeleteModal(),
];
export const createTimeTransformAction = (data) => [
  closeEditModal(),
  createTimeTransform(data),
];
export const updateTimeTransformAction = (data) => [
  closeEditModal(),
  updateTimeTransform(data),
];

export const Selectors = {
  all: R.path(["EnrichTimeTransform"]),
};
