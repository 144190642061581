import React from "react";
import { Tooltip } from "react-tippy";
import { compose } from "ramda";
import { withState, withHandlers } from "recompose";
import { Button } from "../../components";

const DisabledSubItemTooltip = ({ text }) => (
  <Tooltip
    arrow
    touchHold
    position="top"
    theme="arkDefaultTooltip"
    html={
      <div>
        <i className="fa fa-exclamation-triangle red" /> No MarketData Selected
      </div>
    }
    duration={375}
  >
    <span
      id="MenuEnrichMetaData"
      className="col disableMultiMenuItem "
      aria-hidden="true"
    >
      <i className="fa fa-puzzle-piece" />
      &nbsp;
      {text}
    </span>
  </Tooltip>
);
const SubItemTooltip = ({ text, active, close }) => (
  <span
    id="MenuEnrichMetaData"
    className={`col ${active ? "active" : ""} subMenuBtn`}
    onClick={close}
    aria-hidden="true"
  >
    <i className={`fa fa-puzzle-piece ${active ? "active" : "inactive"}`} />
    &nbsp;
    {text}
  </span>
);

const TTComponent = ({ activeEnrichTimeTransform, closeTimeTransform }) => (
  <div className="d-flex subMenuStyle">
    <span
      id="MenuEnrichTimeTransform"
      className={`col ${activeEnrichTimeTransform ? "active" : ""} subMenuBtn`}
      onClick={closeTimeTransform}
      aria-hidden="true"
    >
      <i
        className={`fa fa-clock  ${
          activeEnrichTimeTransform ? "active" : "inactive"
        }`}
      />
      &nbsp;Enrich TimeTransform
    </span>
  </div>
);

const DisplayTT = ({
  activeEnrichTimeTransform,
  closeTimeTransform,
  timeTransFormPermissions,
  globalAdmin,
}) => {
  if (globalAdmin) {
    return (
      <TTComponent
        activeEnrichTimeTransform={activeEnrichTimeTransform}
        closeTimeTransform={closeTimeTransform}
      />
    );
  }
  return timeTransFormPermissions ? null : (
    <TTComponent
      activeEnrichTimeTransform={activeEnrichTimeTransform}
      closeTimeTransform={closeTimeTransform}
    />
  );
};

const Sub = ({
  closeMetaData,
  navigationDisabled,
  activeEnrichMetaData,
  activeEnrichTimeTransform,
  closeTimeTransform,
  timeTransFormPermissions,
  globalAdmin,
}) => (
  <div className="noPadding d-block">
    <div className="d-flex subMenuStyle">
      {navigationDisabled ? (
        <DisabledSubItemTooltip text="Enrich MetaData" />
      ) : (
        <SubItemTooltip
          text="Enrich MetaData"
          active={activeEnrichMetaData}
          close={closeMetaData}
        />
      )}
    </div>
    <DisplayTT
      activeEnrichTimeTransform={activeEnrichTimeTransform}
      closeTimeTransform={closeTimeTransform}
      timeTransFormPermissions={timeTransFormPermissions}
      globalAdmin={globalAdmin}
    />
  </div>
);

const EnabledTooltip = (props) => (
  <Tooltip
    arrow
    touchHold
    position="right"
    interactive
    open={props.state}
    onRequestClose={props.close}
    className="EnrichTooltip"
    hideOnClick
    trigger="click focus"
    theme="arkDefaultTooltip enrichMenu"
    html={
      <Sub
        openEnrichMetadata={props.btnFnEnrichMetadata}
        openEnrichTimeTransform={props.btnFnEnrichTimeTransform}
        navColour={props.navColour}
        changeNavTab={props.changeNavTab}
        activeEnrichMetaData={props.activeEnrichMetaData}
        activeEnrichTimeTransform={props.activeEnrichTimeTransform}
        closeMetaData={props.closeMetaData}
        closeTimeTransform={props.closeTimeTransform}
        navigationDisabled={props.navigationDisabled}
        timeTransFormPermissions={props.ttPermissionAccess}
        globalAdmin={props.globalAdmin}
      />
    }
    duration={props.duration || 375}
  >
    <Button
      onClick={props.toggleTooltip}
      className={`${props.btnClass} ${props.itemNameId}`}
      btnText={props.btnText}
    />
  </Tooltip>
);
const MenuItem = (props) => (
  <div className="menuIcons" id={props.itemNameId}>
    <EnabledTooltip {...props} />
  </div>
);

const enhancer = compose(
  withState("state", "updateState", false),
  withHandlers({
    toggleTooltip: (props) => () => props.updateState(!props.state),
    close: (props) => () => props.updateState(false),
    closeMetaData: (props) => () => {
      props.btnFnEnrichMetadata();
      props.updateState(false);
    },
    closeTimeTransform: (props) => () => {
      props.btnFnEnrichTimeTransform();
      props.updateState(false);
    },
  })
);

export default enhancer(MenuItem);
