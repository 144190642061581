import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "react-datepicker/dist/react-datepicker.css";
import { DropDown, InputTextBox } from "../../../components";

const DailySelection = ({ dailyCron, onChange }) => {
  const [cron, setCron] = useState(dailyCron);
  const [cronTime, setCronTime] = useState("1");
  const [cronMinutes, setCronMinutes] = useState("0");
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const [toggleHourDropdown, setToggleHourDropdown] = useState(true);

  return (
    <div>
      <div className="textLeft">Hour</div>
      <DropDown
        onClick={(x) => {
          setCronTime(x);
          setCron([cron[0], x, "*", "*", "*"]);
          onChange([cron[0], x, "*", "*", "*"]);
        }}
        selected={cronTime}
        dropDownValues={[
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "0",
        ]}
        toggleDropdown={() => setToggleDropdown(!toggleDropdown)}
      />
      <div className="textLeft">Minute</div>
      <DropDown
        onClick={(x) => {
          setCronMinutes(x);
          setCron([x, cron[1], "*", "*", "*"]);
          onChange([x, cron[1], "*", "*", "*"]);
        }}
        selected={cronMinutes}
        dropDownValues={["0", "15", "30", "45"]}
        toggleDropdown={() => setToggleHourDropdown(!toggleHourDropdown)}
      />
    </div>
  );
};
const MonthlySelection = ({ monthlyCron, onChange }) => {
  const [cronDate, setCronDate] = useState("1");
  const [cron, setCron] = useState(monthlyCron);
  const [toggleHourDropdown, setToggleHourDropdown] = useState(true);

  return (
    <div>
      <div className="textLeft">Date</div>
      <DropDown
        onClick={(x) => {
          setCronDate(x);
          setCron([cron[0], cron[1], x, "*", "*"]);
          onChange([cron[0], cron[1], x, "*", "*"]);
        }}
        selected={cronDate}
        dropDownValues={[
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ]}
        toggleDropdown={() => setToggleHourDropdown(!toggleHourDropdown)}
      />
    </div>
  );
};

const CronSelection = ({ cronExpression, onChange }) => {
  const [cronType, setCronType] = useState("Daily");
  const [cronPage, setCronPage] = useState("Standard");
  const [newCronExpression, setNewCronExpression] = useState(cronExpression);
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const [cron, setCron] = useState(["0", "1", "*", "*", "*"]);
  const cronDaily = ["0", "1", "*", "*", "*"];
  const cronMonthly = ["0", "0", "1", "*", "*"];

  useEffect(() => {
    if (cron) {
      setNewCronExpression(
        cronType === "Daily" ? R.join(" ")(cron) : R.join(" ")(cron)
      );
      onChange(R.join(" ")(cron));
    }
  }, [cron]);

  const cronComponent = (
    <div className="cronMainContainer">
      <div className="pills">
        <div
          className={`${cronPage === "Standard" ? "active" : ""} pillItem`}
          onClick={() => {
            setCronPage("Standard");
            setCronType("Daily");
            setCron(cronDaily);
          }}
        >
          Standard
        </div>
        <div
          className={`${cronPage === "Custom" ? "active" : ""} pillItem`}
          onClick={() => setCronPage("Custom")}
        >
          Custom
        </div>
      </div>
      {cronPage === "Standard" ? (
        <div className="cronContainer">
          <div>CronExpression</div>
          <div className="textLeft">Type</div>
          <DropDown
            onClick={(x) => {
              setCronType(x);
              setCron(cronType === "Daily" ? cronMonthly : cronDaily);
            }}
            selected={cronType}
            dropDownValues={["Daily", "Monthly"]}
            toggleDropdown={() => setToggleDropdown(!toggleDropdown)}
          />
          {cronType === "Daily" ? (
            <DailySelection
              splitCron={cron}
              dailyCron={cronDaily}
              onChange={setCron}
            />
          ) : (
            <MonthlySelection
              splitCron={cron}
              monthlyCron={cronMonthly}
              onChange={setCron}
            />
          )}
        </div>
      ) : (
        <div className="cronContainer">
          <div>CronExpression</div>
          <InputTextBox
            value={newCronExpression}
            onChange={(x) => {
              setNewCronExpression(x);
              onChange(x);
            }}
            placeholder="Add Cron Expression"
          />
        </div>
      )}
    </div>
  );
  return cronComponent;
};
export default CronSelection;
