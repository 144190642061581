import React from "react";
import { Tooltip } from "react-tippy";

const Button = (props) =>
  props.permissionDisabled ? (
    <Tooltip
      arrow
      html={
        <span>
          <i className="fas fa-thumbs-down failed" />
          &nbsp;Insufficient Permissions
        </span>
      }
      theme="arkDefaultTooltip"
    >
      <button
        type="button"
        className={`btn ${props.className || ""} btnDisabled`}
        disabled
        id={`${props.id}Disabled`}
      >
        {props.btnText}
      </button>
    </Tooltip>
  ) : (
    <button
      type="button"
      className={`btn ${props.className || ""} ${
        props.disabled ? "btnDisabled" : ""
      }`}
      onClick={props.onClick}
      title={props.title}
      disabled={props.disabled}
      id={props.id}
    >
      {props.btnText}
    </button>
  );

export default Button;
