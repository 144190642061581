export const simpleSetReducerCreator = ({
  setAction,
  prop,
  initialState = ""
}) =>
  function SetReducer(state = initialState, action) {
    switch (action.type) {
      case setAction:
        return action[prop];
      default:
        return state;
    }
  };

export const fetchingReducerCreator = ({
  fetching,
  cancelFetching,
  received
}) =>
  function isfetching(state = false, action) {
    switch (action.type) {
      case fetching:
        return true;
      case received:
      case cancelFetching:
        return false;
      default:
        return state;
    }
  };

const tablesOptionsInitialState = {
  pageSize: 50,
  pageSizeList: [50, 100, 200],
  pageNo: 1,
  filterText: "",
  sortColumn: "",
  sortDirection: "",
  curveCount: 0
};
export const tablesOptionsReducerCreator = ({
  setFilterText,
  setPageSize,
  setPageNo,
  setSortColumn,
  setCurveCount,
  removeTableSettings
}) =>
  function tablesOptions(state = tablesOptionsInitialState, action) {
    switch (action.type) {
      case setFilterText:
        return { ...state, filterText: action.filterText };
      case setPageSize:
        return { ...state, pageSize: action.pageSize };
      case setPageNo:
        return { ...state, pageNo: action.pageNo };
      case setSortColumn:
        return {
          ...state,
          sortColumn: action.sortColumn,
          sortDirection: action.sortDirection
        };
      case setCurveCount:
        return { ...state, curveCount: action.count };
      case removeTableSettings:
        return { ...tablesOptionsInitialState };
      default:
        return state;
    }
  };
