import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { Main } from "./containers";
import "../node_modules/react-tippy/dist/tippy.css";
import "./styles/css/main.css";

import { Login } from "./components";

export default function App(props) {

  return (
    <div id="App">
      <ConnectedRouter history={props.history}>
        <Switch>
          <Route path="" component={Main} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
}
